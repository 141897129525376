export default {
    path: {
        d: "M13 2.47206H1.5V1.01206C1.5 0.882985 1.44732 0.7592 1.35355 0.667932C1.25979 0.576664 1.13261 0.525391 1 0.525391C0.867392 0.525391 0.740215 0.576664 0.646447 0.667932C0.552678 0.7592 0.5 0.882985 0.5 1.01206V10.7454C0.5 10.8745 0.552678 10.9982 0.646447 11.0895C0.740215 11.1808 0.867392 11.2321 1 11.2321C1.13261 11.2321 1.25979 11.1808 1.35355 11.0895C1.44732 10.9982 1.5 10.8745 1.5 10.7454V8.79872H14.5V10.7454C14.5 10.8745 14.5527 10.9982 14.6464 11.0895C14.7402 11.1808 14.8674 11.2321 15 11.2321C15.1326 11.2321 15.2598 11.1808 15.3536 11.0895C15.4473 10.9982 15.5 10.8745 15.5 10.7454V4.90539C15.5 4.26003 15.2366 3.6411 14.7678 3.18476C14.2989 2.72843 13.663 2.47206 13 2.47206ZM1.5 3.44539H6V7.82539H1.5V3.44539Z",
    },
    properties: {
        viewBox: "0 0 16 12",
        outlined: false,
        strokeWidth: "0",
    },
};
