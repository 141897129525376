import { jsx as _jsx } from "react/jsx-runtime";
import { Picker } from '@react-native-picker/picker';
const PickerScroll = ({ data, selectedHandler, selected }) => {
    const onSelected = (item) => {
        selectedHandler(item);
    };
    return (_jsx(Picker, { itemStyle: { fontSize: 16 }, selectedValue: selected?.value, onValueChange: (itemValue, itemIndex) => {
            if (data) {
                onSelected(data[itemIndex]);
            }
        }, children: data?.map((item, index) => _jsx(Picker.Item, { label: item.label, value: item.value }, index)) }));
};
export default PickerScroll;
