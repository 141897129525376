export default {
    path: {
        d: "M26 15.8421L22.3333 12.2632V14.9474H14.0833V16.7368H22.3333V19.4211L26 15.8421ZM20.5 18.5263V23H5.83333V14.9474H4L13.1667 6L20.5 13.1579H17.75L13.1667 8.68421L7.66667 14.0526V21.2105H18.6667V18.5263H20.5Z",
    },
    properties: {
        viewBox: "0 0 30 30",
        outlined: false,
        strokeWidth: "0",
    },
};
