import { Box, Text, TouchableOpacity, useTheme } from "@kolmeo/ui-core";
import { Icon } from "@kolmeo/ui-components";

const BackLink = ({ onPress, label = "Back" }: { onPress: () => void; label?: string }) => {
  const { palette } = useTheme();
  return (
    <TouchableOpacity onPress={onPress} flexDirection="row" alignItems="center">
      <Box flexDirection="row">
        <Icon type={"chevron-left"} size={"sm"} color={palette.text.primary} />
        <Text
          color={palette.text.primary}
          variant="paragraph14"
          fontWeight={400}
          style={{
            fontFamily: "Inter"
          }}
        >
          {label}
        </Text>
      </Box>
    </TouchableOpacity>
  );
};

export default BackLink;
