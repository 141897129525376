export default {
    path: {
        d: "M10.4886 0.904962C10.765 0.652621 11.1257 0.512695 11.5 0.512695C11.8743 0.512695 12.235 0.652621 12.5114 0.904961C12.5118 0.905297 12.5121 0.905633 12.5125 0.905969L20.0107 7.71991L20.0212 7.72969C20.1694 7.86967 20.2881 8.03789 20.3703 8.22444C20.4526 8.41099 20.4966 8.61211 20.4999 8.81595L20.5 8.82808V17.5H22C22.4142 17.5 22.75 17.8357 22.75 18.25C22.75 18.6642 22.4142 19 22 19H1C0.585786 19 0.25 18.6642 0.25 18.25C0.25 17.8357 0.585786 17.5 1 17.5H2.5V8.82808L2.5001 8.81595C2.5034 8.61211 2.54745 8.41099 2.62966 8.22444C2.71187 8.03789 2.83057 7.86967 2.97879 7.72969L2.98935 7.71991L10.4875 0.905969C10.4879 0.905633 10.4882 0.905297 10.4886 0.904962ZM4 17.5H8.5V13C8.5 12.6021 8.65804 12.2206 8.93934 11.9393C9.22064 11.658 9.60218 11.5 10 11.5H13C13.3978 11.5 13.7794 11.658 14.0607 11.9393C14.342 12.2206 14.5 12.6021 14.5 13V17.5H19V8.83828C18.9997 8.83519 18.999 8.83217 18.9977 8.82933C18.9965 8.8266 18.9949 8.82408 18.9929 8.82187L11.5018 2.01438L11.5 2.0127L11.4982 2.01438L4.00711 8.82187C4.00512 8.82408 4.00349 8.8266 4.00229 8.82933C4.00103 8.83217 4.00026 8.83519 4 8.83828V17.5ZM13 17.5V13H10V17.5H13Z",
    },
    properties: {
        viewBox: "0 0 23 19",
        outlined: false,
        strokeWidth: "0",
    },
};
