export default {
    path: {
        d: "M14.5 4.93273H13.25C13.25 4.80366 13.1973 4.67988 13.1036 4.58861C13.0098 4.49734 12.8826 4.44607 12.75 4.44607H8.25C8.11739 4.44607 7.99021 4.49734 7.89645 4.58861C7.80268 4.67988 7.75 4.80366 7.75 4.93273H4V2.25607C4 2.06246 4.07902 1.87678 4.21967 1.73988C4.36032 1.60298 4.55109 1.52607 4.75 1.52607C4.94891 1.52607 5.13968 1.60298 5.28033 1.73988C5.42098 1.87678 5.5 2.06246 5.5 2.25607C5.5 2.38514 5.55268 2.50893 5.64645 2.60019C5.74021 2.69146 5.86739 2.74273 6 2.74273C6.13261 2.74273 6.25979 2.69146 6.35355 2.60019C6.44732 2.50893 6.5 2.38514 6.5 2.25607C6.5 1.80432 6.31563 1.37107 5.98744 1.05163C5.65925 0.732192 5.21413 0.552734 4.75 0.552734C4.28587 0.552734 3.84075 0.732192 3.51256 1.05163C3.18437 1.37107 3 1.80432 3 2.25607V4.93273H1.5C1.23478 4.93273 0.98043 5.03528 0.792893 5.21782C0.605357 5.40035 0.5 5.64792 0.5 5.90607V7.85273C0.5 8.75624 0.868749 9.62274 1.52513 10.2616C2.1815 10.9005 3.07174 11.2594 4 11.2594V12.2327C4 12.3618 4.05268 12.4856 4.14645 12.5769C4.24021 12.6681 4.36739 12.7194 4.5 12.7194C4.63261 12.7194 4.75979 12.6681 4.85355 12.5769C4.94732 12.4856 5 12.3618 5 12.2327V11.2594H11V12.2327C11 12.3618 11.0527 12.4856 11.1464 12.5769C11.2402 12.6681 11.3674 12.7194 11.5 12.7194C11.6326 12.7194 11.7598 12.6681 11.8536 12.5769C11.9473 12.4856 12 12.3618 12 12.2327V11.2594C12.9283 11.2594 13.8185 10.9005 14.4749 10.2616C15.1313 9.62274 15.5 8.75624 15.5 7.85273V5.90607C15.5 5.64792 15.3946 5.40035 15.2071 5.21782C15.0196 5.03528 14.7652 4.93273 14.5 4.93273ZM12.25 5.4194V7.6094H8.75V5.4194H12.25Z",
    },
    properties: {
        viewBox: "0 0 16 13",
        outlined: false,
        strokeWidth: "0",
    },
};
