import axios from "axios";
import { useQuery } from "@tanstack/react-query";
import { useAxios } from "@src/lib/http/useAxios";
import { StatementsHistoryResponse } from "./StatementsTypes";
import { useAuth } from "react-oidc-context";
import { useCurrentUser } from "@src/hooks";

export const useStatementsHistory = ({ propertyId }: { propertyId: number }) => {
  const { instance, isEnabled } = useAxios("OWNER_API");

  return useQuery<StatementsHistoryResponse[]>({
    queryKey: ["statements-history", propertyId],
    queryFn: async () => {
      try {
        const response = await instance.get(`/statements/${propertyId}`, { params: { take: 100, skip: 0 } });
        return response.data;
      } catch (err) {
        console.log("== statements history error ==");
        console.error(err);
      }
    },
    enabled: isEnabled
  });
};

export const useStatementDownload = ({ documentUrl, name }: StatementsHistoryResponse) => {
  const { user } = useAuth();
  const { currentUser } = useCurrentUser();
  const handleDocumentFetch = async (): Promise<IntentionalAny<"how do you type a Blob?">> => {
    return await axios(documentUrl, {
      headers: {
        Authorization: `Bearer ${user?.id_token}`,
        ["x-kol-userprofile-id"]: currentUser?.userProfileId
      },
      method: "GET",
      responseType: "blob"
    });
  };

  const handleFile = async (fileData: Blob, fileName: string, method: "download" | "preview") => {
    const FILENAME = `${fileName}.pdf`;
    const file = new Blob([fileData], { type: "application/pdf" });
    const href = URL.createObjectURL(file);

    const aElement = document.createElement("a");
    aElement.href = href;
    if (method === "download") {
      aElement.setAttribute("download", FILENAME);
      aElement.setAttribute("target", "_blank");
      aElement.click();
      URL.revokeObjectURL(href);
    }
    if (method === "preview") {
      aElement.setAttribute("target", "_blank");
      aElement.click();
    }
  };

  const handleDownload = () => {
    handleDocumentFetch()
      .then(({ data }) => {
        handleFile(data, name, "download")
          .then((res) => console.log(res))
          .catch((err) => console.error(err));
      })
      .catch((err) => console.error(err));
  };
  const handlePreview = () => {
    handleDocumentFetch()
      .then(({ data }) => {
        handleFile(data, name, "preview")
          .then((res) => console.log(res))
          .catch((err) => console.error(err));
      })
      .catch((err) => console.error(err));
  };

  return { handleDownload, handlePreview };
};
