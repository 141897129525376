import { useEffect, useMemo, useState } from "react";
import { useCurrentUser } from "@src/hooks";
import { transformProfileTypeId } from "@src/utils";
import { UserProfile } from "@src/types";

const REACT_APP_Pendo_App_Id = process.env.REACT_APP_Pendo_App_Id;

export const usePendo = () => {
  const [enabled, setEnabled] = useState(false);
  const { currentUser } = useCurrentUser();

  const pendo = useMemo(() => {
    if (!window.pendo && REACT_APP_Pendo_App_Id !== "") {
      (function (apiKey) {
        (function (p, e, n, d, o) {
          let v, w, x, y, z;
          o = p[d] = p[d] || {};
          o._q = o._q || []; /* eslint-disable-line */
          v = ["initialize", "identify", "updateOptions", "pageLoad", "track"];
          for (w = 0, x = v.length; w < x; ++w)
            (function (m) {
              /* eslint-disable-line */
              o[m] =
                o[m] ||
                function () {
                  o._q[m === v[0] ? "unshift" : "push"]([m].concat([].slice.call(arguments, 0)));
                };
            })(v[w]); /* eslint-disable-line */
          y = e.createElement(n);
          y.async = !0;
          y.src = "https://cdn.pendo.io/agent/static/" + apiKey + "/pendo.js"; /* eslint-disable-line */
          z = e.getElementsByTagName(n)[0];
          z.parentNode.insertBefore(y, z);
        })(window, document, "script", "pendo"); /* eslint-disable-line */
      })(REACT_APP_Pendo_App_Id); /* eslint-disable-line */
      return window.pendo;
    }
  }, []);

  useEffect(() => {
    if (pendo && currentUser) {
      const { emailAddress, firstName, lastName, userId, profileTypeId } = currentUser;
      pendo?.initialize({
        visitor: {
          id: userId,
          email: emailAddress,
          full_name: `${firstName} ${lastName}`,
          profileType: transformProfileTypeId(profileTypeId)
        }
      });
      setEnabled(true);
    }
  }, [currentUser, pendo]);

  return { pendo, enabled };
};
