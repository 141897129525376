import styled from "styled-components/native";
import { color, space, layout, position, border, flexbox, boxShadow, grid, } from "styled-system";
const Box = styled.View `
  box-sizing: border-box;
  ${color}
  ${space}
  ${layout}
  ${position}
  ${border}
  ${flexbox}
  ${boxShadow}
  ${grid}
`;
export default Box;
