import { useDispatch, useSelector, type TypedUseSelectorHook } from "react-redux";
import { RootState, AppDispatch, persistor } from "./store";
import { resetPropertyState } from "./features/properties/properties.reducer";
import { resetTenancyId } from "./features/tenancySlice";

export const useAppDispatch: () => AppDispatch = useDispatch;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const useGroupPropertyId = () => useSelector((state: RootState) => state.propertyState.groupPropertyId);

export const useSelectedProperty = () => useSelector((state: RootState) => state.propertyState.selectedProperty);

export const useGlobalTenancyId = () => useSelector((state: RootState) => state.tenancyState.tenancyId);
export const usePreviousScreenName = () => useSelector((state: RootState) => state.navigationState.previousScreenName);

export const useClearStore = () => {
  const dispatch = useDispatch();

  const clearStore = async () => {
    await persistor.purge();
    dispatch(resetPropertyState());
    dispatch(resetTenancyId());
    await persistor.flush();
  };
  return { clearStore };
};
