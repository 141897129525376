import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { en } from "./en";

export * from "./components";

export const resources = {
  en
} as const;

export default i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  fallbackLng: "en"
});
