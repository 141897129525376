import { Dimensions } from "react-native";

export const GALLERY_VIEW_COLUMNS = 3;
export const SCREEN_WIDTH = Dimensions.get("window").width;
export const SCREEN_HEIGHT = Dimensions.get("window").height;

export const GALLERY_IMAGE_PADDING = 5;
export const FULLSCREEN_BUFFERS_BOTTOM = 48;
export const FULLSCREEN_BUFFERS_TOP = 48;
export const FULLSCREEN_IMAGE_WIDTH = SCREEN_WIDTH;
export const FULLSCREEN_IMAGE_HEIGHT = SCREEN_HEIGHT;
export const GALLERY_IMAGE_WIDTH =
  (SCREEN_WIDTH - 2 * GALLERY_IMAGE_PADDING - GALLERY_VIEW_COLUMNS * GALLERY_IMAGE_PADDING * 2) / GALLERY_VIEW_COLUMNS;

export const THUMBNAIL_IMAGE_WIDTH = 64;
export const THUMBNAIL_IMAGE_HEIGHT = 48;

export const GALLERY_IMAGE_HEIGHT = 110;

export const BOX_SHADOW = "0px 0px 4px #ffffff";
export const CAROUSEL_HEIGHT = 100;
