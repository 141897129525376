import { FeatureFlagLabels } from "@src/lib/FeatureFlags";
import { useAxios } from "@src/lib/http";
import { useQuery } from "@tanstack/react-query";

export const useOwnerFeatureFlags = (propertyId?: number) => {
  const { instance, isEnabled } = useAxios("OWNER_API");

  return useQuery({
    queryKey: ["features-flags-owner", propertyId],
    queryFn: async () => {
      const response = await instance.get(`/features/${propertyId}/${FeatureFlagLabels.OwnerTenant}`);
      return response.data;
    },
    enabled: isEnabled && !!propertyId
  });
};
