export default {
    path: {
        d1: "M0.732233 0.732233C1.20107 0.263392 1.83696 0 2.5 0H20.5C20.6326 0 20.7598 0.0526784 20.8536 0.146447L31.8536 11.1464C31.9473 11.2402 32 11.3674 32 11.5V37.5C32 38.163 31.7366 38.7989 31.2678 39.2678C30.7989 39.7366 30.163 40 29.5 40H2.5C1.83696 40 1.20107 39.7366 0.732233 39.2678C0.263392 38.7989 0 38.163 0 37.5V2.5C0 1.83696 0.263393 1.20107 0.732233 0.732233ZM2.5 1C2.10218 1 1.72064 1.15804 1.43934 1.43934C1.15804 1.72064 1 2.10218 1 2.5V37.5C1 37.8978 1.15804 38.2794 1.43934 38.5607C1.72064 38.842 2.10217 39 2.5 39H29.5C29.8978 39 30.2794 38.842 30.5607 38.5607C30.842 38.2794 31 37.8978 31 37.5V12H22.5C21.837 12 21.2011 11.7366 20.7322 11.2678C20.2634 10.7989 20 10.163 20 9.5V1H2.5ZM21 1.70711L30.2929 11H22.5C22.1022 11 21.7206 10.842 21.4393 10.5607C21.158 10.2794 21 9.89782 21 9.5V1.70711Z",
        d3: "M16.367 35.0002H13.6738V26.2729H16.4863C17.3329 26.2729 18.0574 26.4477 18.6596 26.7971C19.2619 27.1437 19.7235 27.6423 20.0446 28.2928C20.3656 28.9406 20.5261 29.7161 20.5261 30.6195C20.5261 31.5286 20.3642 32.3113 20.0403 32.9676C19.7164 33.621 19.2449 34.1238 18.6255 34.4761C18.0062 34.8255 17.2534 35.0002 16.367 35.0002ZM14.7306 34.0627H16.2988C17.0204 34.0627 17.6184 33.9235 18.0929 33.6451C18.5673 33.3667 18.921 32.9704 19.1539 32.4562C19.3869 31.942 19.5034 31.3298 19.5034 30.6195C19.5034 29.915 19.3883 29.3085 19.1582 28.7999C18.9281 28.2886 18.5843 27.8965 18.127 27.6238C17.6696 27.3482 17.1 27.2104 16.4181 27.2104H14.7306V34.0627Z",
        d4: "M22.2988 35.0002V26.2729H27.5318V27.2104H23.3556V30.1593H27.1397V31.0968H23.3556V35.0002H22.2988Z",
        d5: "M6.05664 35.0002V26.2729H9.0055C9.69016 26.2729 10.2498 26.3965 10.6845 26.6437C11.122 26.888 11.4458 27.219 11.6561 27.6366C11.8663 28.0542 11.9714 28.5201 11.9714 29.0343C11.9714 29.5485 11.8663 30.0158 11.6561 30.4363C11.4487 30.8568 11.1277 31.192 10.693 31.442C10.2583 31.6891 9.70153 31.8127 9.02255 31.8127H6.90891V30.8752H8.98846C9.45721 30.8752 9.83363 30.7943 10.1177 30.6323C10.4018 30.4704 10.6078 30.2516 10.7356 29.9761C10.8663 29.6977 10.9316 29.3837 10.9316 29.0343C10.9316 28.6849 10.8663 28.3724 10.7356 28.0968C10.6078 27.8212 10.4004 27.6053 10.1135 27.4491C9.82653 27.29 9.44585 27.2104 8.97141 27.2104H7.11346V35.0002H6.05664Z",
    },
    properties: {
        viewBox: "0 0 33 40",
        outlined: false,
        strokeWidth: "0",
    },
};
