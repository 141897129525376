const useNeighbouringTabs = ({ tabs, currentTab }) => {
    const getNeighbouringTabIndex = (direction) => {
        const currentIndex = tabs.findIndex((tab) => `${tab.key}` === currentTab);
        const neighbouringTabIndex = currentIndex + (direction == "next" ? 1 : -1);
        if (neighbouringTabIndex < 0 || neighbouringTabIndex > tabs.length - 1)
            return -1;
        return neighbouringTabIndex;
    };
    return {
        getNeighbouringTabIndex,
    };
};
export default useNeighbouringTabs;
