export const borderRadius = {
    br4: 4,
    br8: 8,
    br12: 12,
    br16: 16,
    br20: 20,
    br24: 24,
    br32: 32,
    br36: 36,
    br40: 40,
    br64: 64,
    br80: 80,
};
export default borderRadius;
