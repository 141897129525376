export default {
    path: {
        d: "M14 6.25C14.0003 6.34871 13.9714 6.44531 13.9169 6.52762C13.8624 6.60992 13.7848 6.67425 13.6938 6.7125C13.6321 6.73699 13.5664 6.74971 13.5 6.75C13.3672 6.74944 13.2397 6.69799 13.1438 6.60625L11.625 5.08125L9.35 7.35625C9.25667 7.44817 9.13099 7.49978 9 7.5C8.86723 7.49944 8.73973 7.44799 8.64375 7.35625C8.55024 7.26252 8.49773 7.13553 8.49773 7.00313C8.49773 6.87073 8.55024 6.74374 8.64375 6.65L10.9187 4.375L9.39375 2.85625C9.32592 2.78396 9.27994 2.69394 9.26114 2.59661C9.24234 2.49927 9.25148 2.39861 9.2875 2.30625C9.32575 2.21525 9.39008 2.13761 9.47239 2.08311C9.55469 2.02861 9.65129 1.99969 9.75 2H13.5C13.6326 2 13.7598 2.05268 13.8536 2.14645C13.9473 2.24022 14 2.36739 14 2.5V6.25ZM11.5 8.5C11.3674 8.5 11.2402 8.55268 11.1464 8.64645C11.0527 8.74022 11 8.86739 11 9V13H3V5H7C7.13261 5 7.25979 4.94732 7.35355 4.85356C7.44732 4.75979 7.5 4.63261 7.5 4.5C7.5 4.36739 7.44732 4.24022 7.35355 4.14645C7.25979 4.05268 7.13261 4 7 4H3C2.73478 4 2.48043 4.10536 2.29289 4.2929C2.10536 4.48043 2 4.73479 2 5V13C2 13.2652 2.10536 13.5196 2.29289 13.7071C2.48043 13.8946 2.73478 14 3 14H11C11.2652 14 11.5196 13.8946 11.7071 13.7071C11.8946 13.5196 12 13.2652 12 13V9C12 8.86739 11.9473 8.74022 11.8536 8.64645C11.7598 8.55268 11.6326 8.5 11.5 8.5Z",
        fill: "white",
    },
    properties: {
        viewBox: "0 0 18 18",
        outlined: false,
        strokeWidth: "0",
    },
};
