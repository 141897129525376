export default {
  ErrorTooManyFiles: "You have selected too many files.",
  ErrorFilesTooLarge: "Files must be less than {{maxSize}}mb.",
  ErrorFileFormatInvalid: "Please upload files with supported formats: {{permittedFormats}}",
  SelectFromGalleryApp: "Take a photo, or browse to upload",
  SelectFromGalleryDesktop: "Drag & drop, or browse to upload",
  GenericServerError: "There has been an error submitting.",
  RequiredError: "This field is required",
  LabelBsb: "BSB* ({{numberOfDigits}} digits)",
  LabelAccountNumber: "Account Number* ({{minDigits}}-{{maxDigits}} digits)",
  LabelAccountName: "Account Name*",
  LabelAccountNickname: "Account Nickname",
  PlaceholderExample: "E.g. {{example}}",
  PlaceholderBSB: "E.g. 123456",
  PlaceholderName: "Account Name",
  PlaceholderAccountNumber: "Enter Account Number",
  PlaceholderNickname: "Nickname",
  CancelModalHeader: "Warning",
  CancelModalContentDefault: "If you leave this page your data will be lost",
  LeavePage: "Leave Page",
  StayOnPage: "Stay On Page",
  ErrorMessageExactCharactersReq: "{{fieldName}} must be {{exactCharacters}} characters",
  ErrorMessageExactDigitsReq: "{{fieldName}} must be {{exactCharacters}} digits",
  ErrorMinGreaterThanCharactersReq: "{{fieldName}} must be {{minCharacters}} or more characters",
  ErrorMinGreaterThanDigitsReq: "{{fieldName}} must be {{minCharacters}} or more digits",
  ErrorMaxCharacters: "{{fieldName}} must be less than {{maxCharacters}} characters",
  ErrorAccountNameRequired: "Please enter an account name",
  ErrorAccountNumberRequired: "Please enter a valid account number",
  ErrorBSBRequired: "Please enter a valid BSB",
  ErrorDebitAuthority: "Please accept the above Debit Request"
};
