import { createContext, useContext, useState, useEffect } from "react";
import { ThemeProvider } from "@kolmeo/ui-core";

import { useCurrentUser } from "@src/hooks";
import { transformProfileTypeId } from "@src/utils";

import GlobalStyle from "./GlobalStyles";

import { baseTheme } from "./baseTheme";
import { UserProfile } from "@src/types";

export type KittosContextType = {
  setPrimaryColor: (userProfile: UserProfile) => void;
};

const KiitosContext = createContext<KittosContextType>({} as KittosContextType);

export const KiitosProvider = ({ children }: { children: JSX.Element }) => {
  const [currentTheme, setCurrentTheme] = useState(baseTheme);
  const { currentUser } = useCurrentUser();

  const setPrimaryColor = (currentUser: UserProfile) => {
    const { profileTypeId } = currentUser;
    const profileType = transformProfileTypeId(profileTypeId);

    setCurrentTheme({
      ...baseTheme,
      colors: {
        primary: profileType === "Tenant" ? "#1E1890" : "#006890",
        background: "#F3F3F7"
      }
    });
  };

  useEffect(() => {
    if (currentUser) {
      setPrimaryColor(currentUser);
    }
  }, [currentUser]);

  return (
    <KiitosContext.Provider value={{ setPrimaryColor }}>
      <ThemeProvider theme={currentTheme}>
        <GlobalStyle />
        {children}
      </ThemeProvider>
    </KiitosContext.Provider>
  );
};

export const useKiitos = () => useContext(KiitosContext);
