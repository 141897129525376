export const PropertyStatus = {
  active: 1312001,
  doNotRelet: 1312002,
  inactive: 1312003,
  lead: 1312004,
  preActive: 1312005,
  vacant: 1312007
} as const;
export type PropertyStatusType = keyof typeof PropertyStatus;
export type PropertyStatusCodes = (typeof PropertyStatus)[keyof typeof PropertyStatus];
