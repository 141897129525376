export const sizing = {
    size4: 4,
    size8: 8,
    size12: 12,
    size16: 16,
    size20: 20,
    size24: 24,
    size32: 32,
    size40: 40,
    size64: 64,
    size80: 80,
};
export default sizing;
