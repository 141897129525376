import { jsx as _jsx } from "react/jsx-runtime";
import { cloneElement } from "react";
import Svg from "react-native-svg";
import { iconSizeToIntConverter } from "./iconHelpers";
import { Box } from "@kolmeo/ui-core";
const IconWrapper = ({ children, title = "", description = "", viewBox, size, opacity, height, width, ...props }) => {
    const svgSize = typeof size !== "number" ? iconSizeToIntConverter(size) : size;
    return (_jsx(Box, { ...props, width: width || svgSize, height: height || svgSize, accessibilityLabel: title, accessibilityHint: description, children: _jsx(Svg, { width: width || svgSize, height: height || svgSize, viewBox: viewBox, opacity: opacity, children: children && cloneElement(children, { ...props }) }) }));
};
IconWrapper.defaultProps = {
    accessible: true,
    enableBackground: "new 0 0 30 30",
    viewBox: "0 0 30 30",
    size: "md",
};
export default IconWrapper;
