export function getPrevAndNextItems(list, isItem) {
    function getNext(last, thisItem) {
        switch (last.state) {
            case "Done":
                return last;
            case "IsNext":
                return { state: "Done", item: thisItem };
            case "NotFoundYet":
                return isItem(thisItem) ? { state: "IsNext", item: null } : last;
        }
    }
    const initialState = {
        state: "NotFoundYet",
        item: null,
    };
    const prevItem = list.reduceRight(getNext, initialState).item;
    const nextItem = list.reduce(getNext, initialState).item;
    return { prevItem, nextItem };
}
