import { createContext, useContext, useEffect, useState } from "react";
import { Feature, useGetFeatures } from "./useGetFeatures";
import { useAuth } from "react-oidc-context";

type FeatureFlags = {
  features: Feature[];
  isLoading: boolean;
  setFeatures?: (features: Feature[]) => void;
};

export const FeatureFlagsContext = createContext<FeatureFlags>({
  features: [] as Feature[],
  isLoading: true
} as FeatureFlags);

export const FeatureFlagsProvider = ({ children }: React.PropsWithChildren) => {
  const { isAuthenticated } = useAuth();
  const [root, tenant] = useGetFeatures(isAuthenticated);
  const [features, setFeatures] = useState<Feature[]>([]);
  const isLoading = root.isLoading && tenant.isLoading ? true : false;

  useEffect(() => {
    if (root.data && tenant.data) {
      setFeatures([...root.data, ...tenant.data]);
    }
  }, [root.data, tenant.data]);

  return <FeatureFlagsContext.Provider value={{ features, isLoading, setFeatures }}>{children}</FeatureFlagsContext.Provider>;
};

export const useFeatureFlags = () => useContext(FeatureFlagsContext);

export const useFeature = (tag: string) => {
  const { features } = useContext(FeatureFlagsContext);
  if (tag) return features?.find((item: Feature) => item.id === tag);
};

export const useUpdateFeatureFlags = () => {
  const context = useFeatureFlags();
  if (!context.setFeatures) {
    throw new Error("setFeatures is not defined in the context");
  }

  const updateFeatureFlags = (newFeatures: Feature[]) => {
    if (context.setFeatures) context.setFeatures(newFeatures);
  };

  return updateFeatureFlags;
};
