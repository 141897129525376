import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, Image, Text, TouchableOpacity, useSafeAreaInsets } from "@kolmeo/ui-core";
import { useEffect } from "react";
import { Easing, interpolate, useAnimatedStyle, useSharedValue, withTiming } from "react-native-reanimated";
import { createAnimatedComponent } from "../NativeAnimations";
const AnimatedBox = createAnimatedComponent(Box);
const AnimatedImage = createAnimatedComponent(Image);
const CameraImagePreview = ({ previewImagePath, onPreviewImagesPress, numberOfPhotosTaken }) => {
    const opacity = [0.8, 1];
    const scalePreviewImage = useSharedValue(0);
    const rPreviewImageStyles = useAnimatedStyle(() => ({
        transform: [{ scale: interpolate(scalePreviewImage.value, [0, 1], opacity) }],
    }));
    useEffect(() => {
        if (previewImagePath) {
            scalePreviewImage.value = withTiming(0.5, { duration: 100, easing: Easing.ease }, (isFinished) => {
                if (isFinished) {
                    scalePreviewImage.value = withTiming(1, {
                        duration: 200,
                        easing: Easing.ease,
                    });
                }
            });
        }
    }, [previewImagePath]);
    const insets = useSafeAreaInsets();
    return (_jsx(_Fragment, { children: previewImagePath ? (_jsxs(TouchableOpacity, { position: "absolute", left: 3, bottom: `${(insets.bottom || 5) + 15}px`, onPress: onPreviewImagesPress, children: [_jsx(Box, { backgroundColor: "white", position: "absolute", borderRadius: 50, right: -5, height: 25, width: 25, top: -5, zIndex: 100, alignItems: "center", justifyContent: "center", children: _jsx(Text, { variant: "textHeavy14", children: numberOfPhotosTaken }) }), _jsx(AnimatedBox, { borderWidth: 2, borderColor: "white", borderRadius: 10, backgroundColor: "white", overflow: "hidden", children: _jsx(AnimatedImage, { style: rPreviewImageStyles, source: { uri: previewImagePath }, width: 70, height: 70, borderRadius: 5, resizeMode: "cover" }) })] })) : null }));
};
export default CameraImagePreview;
