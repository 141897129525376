export default {
    path: {
        d: "M208,32H48A16,16,0,0,0,32,48V176h0v32a16,16,0,0,0,16,16H208a16,16,0,0,0,16-16V48A16,16,0,0,0,208,32Zm-28.7,80a16.1,16.1,0,0,0-22.6,0L112,156.7,91.3,136a16.1,16.1,0,0,0-22.6,0L48,156.7V48H208v92.7Z",
    },
    properties: {
        viewBox: "0 0 256 256",
        outlined: false,
        strokeWidth: "0",
    },
};
