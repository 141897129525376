export default {
  LeaseTileTitle: "Lease",
  FixedTermLabel: "Fixed term lease: {{content}}",
  PeriodicLabel: "Month-to-month lease from {{content}}",
  DaysRemainingLabel: "{{daysCalc}} days remaining.",
  LeaseStatus: {
    Ended: "Ended",
    Signed: "Signed",
    Pending: "Pending",
    Current: "Current",
    Default: "Unknown"
  },
  TenancyTitle: {
    CurrentTenancy: "Current tenancy",
    UpcomingTenancy: "Upcoming tenancy",
    PastTenancy: "Past tenancy",
    Default: "Property"
  },
  MoveInDate: "Move in date",
  VacateDate: "Vacate date"
};
