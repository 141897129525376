import { rest } from "msw";
import { GetAllOwnerProperties } from "../types";

export const getAllOwnerPropertiesMock: GetAllOwnerProperties[] = [
  {
    propertyId: 53208,
    propertyEntityId: "0c5f2be0-584d-4c85-bda3-b88872462606",
    propertyName: "1A Connect Avenue Street, Melbourne, VIC, 3000",
    propertyLogoUrl: "https://portal.dev.kolmeo.com/api/Documents/entity/image/24dd96ea-e6ea-ed11-9f71-000d3ad1fa25",
    officeName: "Nelson Alexander",
    propertyStatus: 1312001
  },
  {
    propertyId: 53209,
    propertyEntityId: "0c5f2be0-584d-4c85-bda3-b88872462607",
    propertyName: "2A Connect Avenue Street, Melbourne, VIC, 3000",
    propertyLogoUrl: "https://portal.dev.kolmeo.com/api/Documents/entity/image/2e629ef7-e6ea-ed11-9f71-000d3ad1fa25",
    officeName: "Little Real Estate",
    propertyStatus: 1312003
  }
];

export const getAllOwnerPropertiesHandler = rest.get(`https://owner-api.dev.kolmeo.com/api/v1/property`, (_, res, ctx) => {
  return res(ctx.status(200), ctx.json(getAllOwnerPropertiesMock));
});
