export default {
    path: {
        d1: "M0.732233 0.732233C1.20107 0.263392 1.83696 0 2.5 0H20.5C20.6326 0 20.7598 0.0526784 20.8536 0.146447L31.8536 11.1464C31.9473 11.2402 32 11.3674 32 11.5V37.5C32 38.163 31.7366 38.7989 31.2678 39.2678C30.7989 39.7366 30.163 40 29.5 40H2.5C1.83696 40 1.20107 39.7366 0.732233 39.2678C0.263392 38.7989 0 38.163 0 37.5V2.5C0 1.83696 0.263393 1.20107 0.732233 0.732233ZM2.5 1C2.10218 1 1.72064 1.15804 1.43934 1.43934C1.15804 1.72064 1 2.10218 1 2.5V37.5C1 37.8978 1.15804 38.2794 1.43934 38.5607C1.72064 38.842 2.10217 39 2.5 39H29.5C29.8978 39 30.2794 38.842 30.5607 38.5607C30.842 38.2794 31 37.8978 31 37.5V12H22.5C21.837 12 21.2011 11.7366 20.7322 11.2678C20.2634 10.7989 20 10.163 20 9.5V1H2.5ZM21 1.70711L30.2929 11H22.5C22.1022 11 21.7206 10.842 21.4393 10.5607C21.158 10.2794 21 9.89782 21 9.5V1.70711Z",
        d3: "M7.05682 35V26.2727H12.2898V27.2102H8.11364V30.1591H11.8977V31.0966H8.11364V35H7.05682Z",
        d4: "M13.9634 35V28.4545H14.9691V35H13.9634ZM14.4748 27.3636C14.2788 27.3636 14.1097 27.2969 13.9677 27.1634C13.8285 27.0298 13.7589 26.8693 13.7589 26.6818C13.7589 26.4943 13.8285 26.3338 13.9677 26.2003C14.1097 26.0668 14.2788 26 14.4748 26C14.6708 26 14.8384 26.0668 14.9776 26.2003C15.1197 26.3338 15.1907 26.4943 15.1907 26.6818C15.1907 26.8693 15.1197 27.0298 14.9776 27.1634C14.8384 27.2969 14.6708 27.3636 14.4748 27.3636Z",
        d5: "M17.8168 26.2727V35H16.8111V26.2727H17.8168Z",
        d6: "M22.4031 35.1364C21.7724 35.1364 21.2283 34.9972 20.771 34.7188C20.3164 34.4375 19.9656 34.0455 19.7184 33.5426C19.4741 33.0369 19.3519 32.4489 19.3519 31.7784C19.3519 31.108 19.4741 30.517 19.7184 30.0057C19.9656 29.4915 20.3093 29.0909 20.7496 28.804C21.1928 28.5142 21.7099 28.3693 22.3008 28.3693C22.6417 28.3693 22.9783 28.4261 23.3107 28.5398C23.6431 28.6534 23.9457 28.8381 24.2184 29.0938C24.4911 29.3466 24.7085 29.6818 24.8704 30.0994C25.0323 30.517 25.1133 31.0313 25.1133 31.642V32.0682H20.0678V31.1989H24.0906C24.0906 30.8295 24.0167 30.5 23.869 30.2102C23.7241 29.9205 23.5167 29.6918 23.2468 29.5241C22.9798 29.3565 22.6644 29.2727 22.3008 29.2727C21.9002 29.2727 21.5536 29.3722 21.261 29.571C20.9712 29.767 20.7482 30.0227 20.592 30.3381C20.4357 30.6534 20.3576 30.9915 20.3576 31.3523V31.9318C20.3576 32.4261 20.4428 32.8452 20.6133 33.1889C20.7866 33.5298 21.0266 33.7898 21.3335 33.9688C21.6403 34.1449 21.9968 34.233 22.4031 34.233C22.6673 34.233 22.9059 34.196 23.119 34.1222C23.3349 34.0455 23.521 33.9318 23.6772 33.7812C23.8335 33.6278 23.9542 33.4375 24.0394 33.2102L25.011 33.483C24.9087 33.8125 24.7369 34.1023 24.4954 34.3523C24.2539 34.5994 23.9556 34.7926 23.6005 34.9318C23.2454 35.0682 22.8462 35.1364 22.4031 35.1364Z",
    },
    properties: {
        viewBox: "0 0 33 40",
        outlined: false,
        strokeWidth: "0",
    },
};
