import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import { Platform, ActivityIndicator } from "react-native";
import ReactTooltip from "react-tooltip";
import { TouchableOpacity, Box, Text, coreTheme } from "@kolmeo/ui-core";
import Icon from "../Icon";
import { AnimatedIcon } from "../Icon/animateIcon";
const ButtonSizes = {
    small: {
        paddingX: "16px",
        paddingY: "7px",
    },
    medium: {
        paddingX: "24px",
        paddingY: "11px",
    },
    large: {
        paddingX: "32px",
        paddingY: "15px",
    },
};
const getButtonSize = (size) => {
    return size && ButtonSizes[size] ? ButtonSizes[size] : ButtonSizes.medium;
};
const Button = ({ isLoading = false, iconType, isIconRight = false, iconSize = "sm", iconAnimation, variant, fullwidth = false, buttonText, disabled, raised, size, textProps, ...props }) => {
    const { palette } = coreTheme;
    const buttonSize = getButtonSize(size);
    useEffect(() => {
        Platform.OS === "web" && ReactTooltip.rebuild();
    }, []);
    const typeColorCombination = {
        primary: {
            buttonBGColor: disabled ? palette.backgroundDisable : palette.backgroundPrimaryDefault,
            tintColor: disabled ? palette.persionBlueTransparent40 : palette.persianBlueDarken15,
            color: palette.white,
            borderColor: palette.transparent,
            spinnerColor: palette.white,
            iconColor: palette.white,
            hoverColor: palette.persianBlueLighten15,
            raised: false,
            height: "auto",
        },
        danger: {
            buttonBGColor: disabled ? palette.persionBlueTransparent40 : palette.backgroundDestructiveDefault,
            tintColor: disabled ? palette.persionBlueTransparent40 : palette.backgroundDestructiveDefault,
            color: palette.white,
            borderColor: palette.transparent,
            spinnerColor: palette.white,
            iconColor: palette.white,
            hoverColor: palette.persianBlueLighten15,
            raised: false,
            height: "auto",
        },
        secondary: {
            buttonBGColor: disabled ? palette.disabled : palette.white,
            tintColor: disabled ? palette.disabled : palette.white,
            color: palette.kolmeoBlack,
            borderColor: palette.transparent,
            spinnerColor: palette.white,
            iconColor: palette.kolmeoBlack,
            hoverColor: palette.persianBlueLighten15,
            raised: true,
            height: "auto",
        },
        confirm: {
            buttonBGColor: disabled ? palette.neomintDarken15Transparent40 : palette.neomintDarken15,
            tintColor: disabled ? palette.neomintDarken15Transparent40 : palette.neomintDarken15,
            color: palette.white,
            borderColor: palette.transparent,
            spinnerColor: palette.white,
            iconColor: palette.kolmeoBlack,
            hoverColor: palette.persianBlueLighten15,
            raised: true,
            height: "auto",
        },
        outlined: {
            buttonBGColor: palette.transparent,
            tintColor: disabled ? palette.disabled : palette.persianBlue,
            color: disabled ? palette.disabled : palette.persianBlue,
            borderColor: disabled ? palette.disabled : palette.persianBlue,
            spinnerColor: disabled ? palette.disabled : palette.persianBlue,
            iconColor: disabled ? palette.disabled : palette.persianBlue,
            hoverColor: palette.taivasLighten10,
            raised: false,
            height: "auto",
        },
        heroButton: {
            buttonBGColor: palette.persianBlueDarken15,
            tintColor: disabled ? palette.disabled : palette.white,
            color: palette.white,
            borderColor: palette.transparent,
            spinnerColor: palette.white,
            iconColor: disabled ? palette.disabled : palette.white,
            hoverColor: palette.persianBlueLighten15,
            raised: false,
            height: "auto",
        },
        modern: {
            buttonBGColor: palette.ateneoBlueLighten15,
            tintColor: disabled ? palette.disabled : palette.white,
            color: palette.white,
            borderColor: palette.transparent,
            spinnerColor: palette.white,
            iconColor: disabled ? palette.disabled : palette.white,
            hoverColor: palette.ateneoBlue,
            raised: false,
            height: 60,
        },
        none: null,
    };
    return (_jsxs(TouchableOpacity, { ...props, flexDirection: isIconRight ? "row" : "row-reverse", justifyContent: "center", alignItems: "center", borderWidth: 1, borderRadius: 8, alignSelf: fullwidth ? "stretch" : "auto", paddingX: buttonSize.paddingX, paddingY: buttonSize.paddingY, opacity: 1, height: typeColorCombination[variant ? variant : "none"]?.height || "auto", paddingLeft: iconType ? "12px" : "19px", disabled: disabled, bg: typeColorCombination[variant ? variant : "none"]?.buttonBGColor, borderColor: typeColorCombination[variant ? variant : "none"]?.borderColor, hoverStyle: !disabled && {
            backgroundColor: typeColorCombination[variant ? variant : "none"]?.hoverColor,
        }, style: typeColorCombination[variant ? variant : "none"]?.raised && {
            shadowColor: "#000000",
            shadowOffset: {
                width: 0,
                height: 4,
            },
            shadowOpacity: 0.19,
            shadowRadius: 5.62,
            elevation: 6,
        }, children: [iconType && (_jsx(Box, { position: "relative", overflowY: "visible", justifyContent: "center", height: 0, mr: isIconRight ? 2 : 0, ml: isIconRight ? 0 : 2, padding: 0, opacity: 1, children: _jsx(Box, { justifyContent: "center", alignContent: "center", height: 1, children: _jsx(AnimatedIcon, { type: iconAnimation, children: _jsx(Icon, { type: iconType, size: iconSize, color: typeColorCombination[variant ? variant : "none"]?.iconColor }) }) }) })), _jsx(Text, { ...textProps, padding: 0, margin: 0, variant: "buttonHeavy14", textAlign: "center", color: typeColorCombination[variant ? variant : "none"]?.color, children: buttonText }), isLoading && (_jsx(Box, { ml: 2, overflowY: "visible", height: 0, children: _jsx(ActivityIndicator, { color: typeColorCombination[variant ? variant : "none"]?.spinnerColor }) })), props.extraWebElements && _jsx(ReactTooltip, { id: props.extraWebElements?.["data-for"] })] }));
};
export default Button;
