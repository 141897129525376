import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from "react";
import { useSharedValue, useAnimatedStyle, withRepeat, withTiming, cancelAnimation, Easing } from "react-native-reanimated";
import { Box } from "@kolmeo/ui-core";
import { createAnimatedComponent } from "./helpers";
const AnimatedBox = createAnimatedComponent(Box);
const RotateAnimation = ({ children, rotationDuration = 1400, reverse = false, ...props }) => {
    const rotation = useSharedValue(0);
    const animatedStyles = useAnimatedStyle(() => {
        return {
            transform: [
                {
                    rotateZ: `${rotation.value}deg`,
                },
                {
                    translateY: 0,
                },
            ],
        };
    }, [rotation.value]);
    useEffect(() => {
        rotation.value = withRepeat(withTiming(360, {
            duration: rotationDuration,
            easing: Easing.linear,
        }), -1);
        return () => cancelAnimation(rotation);
    }, []);
    return (_jsx(AnimatedBox, { style: [animatedStyles], ...props, children: children }));
};
export default RotateAnimation;
