import { Box } from "@kolmeo/ui-core";

const Seperator = ({
  vertical,
  seperatorColor = "#BDBDBD",
  margin = 0
}: {
  vertical?: boolean;
  seperatorColor?: IntentionalAny<"How do we type our palette? Do we allow random colours">;
  margin?: IntentionalAny<"What are the margin types?">;
}) => {
  return (
    <Box
      backgroundColor={seperatorColor}
      zIndex={-1}
      {...(vertical ? { height: "100%", width: "1px" } : { height: "1px", width: "100%" })}
      marginX={vertical ? margin : null}
      marginY={vertical ? null : margin}
    />
  );
};

export default Seperator;
