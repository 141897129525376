import { PaymentMethod, type PaymentMethodCodes } from "@src/types";

import { useTranslation } from "react-i18next";

export const usePaymentMethodLabel = (code?: PaymentMethodCodes) => {
  const { t } = useTranslation("Common");
  const mapPaymentCodeToLabel = (code?: PaymentMethodCodes) => {
    if (code === PaymentMethod.bpay) return t(`Payments.BPAY`).toUpperCase();
    if (code === PaymentMethod.directDebit) return t(`Payments.DirectDebit`);
    if (code === PaymentMethod.creditCard) return t(`Payments.CreditCard`);
    if (code === PaymentMethod.debitNow) return t(`Payments.DebitNow`);
    if (code === PaymentMethod.virtualAccount) return t(`Payments.VirtualAccount`);
    if (!code) return t(`Payments.NoMethod`);
    return "";
  };

  return mapPaymentCodeToLabel(code);
};
