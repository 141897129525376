import { Seperator } from "@src/components";
import { Box, FlatList, Text, useTheme } from "@kolmeo/ui-core";
import { BankAccountDetailsResponse } from "@src/types/PaymentPreferences";
import { useTranslation } from "react-i18next";
import PaymentMethodCard from "../ListCards/PaymentMethodCard";
import AddPaymentMethod from "../CardButton";
import { ScreenEmptyState } from "@src/containers";
import { useBranding } from "@src/navigation/useBranding";
import { ButtonV2 } from "@kolmeo/ui-components";
import { useMediaQuery } from "@src/hooks";

type BankDetailsListProps = {
  data?: BankAccountDetailsResponse[];
  isBelowMobile: boolean;
  disableAddBankAccounts: boolean;
  onListCardPress: (item: BankAccountDetailsResponse) => void;
  onAddPaymentPress: () => void;
};
const BankDetailsList = ({ data, disableAddBankAccounts, onListCardPress, onAddPaymentPress }: BankDetailsListProps) => {
  const { t } = useTranslation(["Common", "Tenant"]);
  const { spacing, palette } = useTheme();
  const { brandingObject } = useBranding();
  const { isBelowTablet } = useMediaQuery();

  return (
    <FlatList
      ItemSeparatorComponent={() => <Seperator margin={spacing.px8} seperatorColor="transparent" />}
      testID={"bank-details-list"}
      style={{ zIndex: -1 }}
      data={data}
      ListHeaderComponent={
        <Box paddingBottom={spacing.px16} zIndex={-1}>
          {data && data?.length > 0 && <Text variant="headingH5">{t("Tenant:PaymentMethods.PaymentDetailsHeader")}</Text>}
        </Box>
      }
      ListEmptyComponent={
        <ScreenEmptyState
          textColor={palette.text.primary}
          title={t("Tenant:PaymentMethods.PaymentDetailsEmpty")}
          callToAction={
            !disableAddBankAccounts ? (
              <ButtonV2
                testID="button-add-bankaccount-nonemptystate"
                fullwidth={isBelowTablet}
                variant="primary"
                overrideVariant={{ bgColor: brandingObject?.primaryColor }}
                label={t("Tenant:PaymentMethods.AddPaymentMethod")}
                iconType="plus"
                onPress={onAddPaymentPress}
              />
            ) : (
              <></>
            )
          }
        />
      }
      renderItem={({ item, index }) => {
        return <PaymentMethodCard item={item} index={index} onPress={() => onListCardPress(item)} isFullWidth={isBelowTablet} />;
      }}
      contentContainerStyle={{
        paddingBottom: 6,
        paddingHorizontal: 1,
        flexGrow: 1,
        justifyContent: "center"
      }} // to show renderItem box shadows
      ListFooterComponent={
        <>
          {!disableAddBankAccounts && data && data?.length > 0 && (
            <AddPaymentMethod
              onPress={onAddPaymentPress}
              text={t("Tenant:PaymentMethods.AddPaymentMethod")}
              icon={"plus"}
              isFullWidth={isBelowTablet}
            />
          )}
        </>
      }
    />
  );
};

export default BankDetailsList;
