const headingFonts = {
    headingH1: {
        fontFamily: "Hornbill Medium",
        fontSize: "24px",
        fontWeight: 700,
        lineHeight: "28px",
    },
    headingH2: {
        fontFamily: "Hornbill Medium",
        fontSize: "20px",
        fontWeight: 700,
        lineHeight: "28px",
    },
    headingH3: {
        fontFamily: "Hornbill Medium",
        fontSize: "18px",
        fontWeight: 400,
        lineHeight: "25px",
    },
    headingH4: {
        fontFamily: "Hornbill Medium",
        fontSize: "18px",
        fontWeight: 500,
        lineHeight: "22px",
    },
    headingH5: {
        fontFamily: "Inter-600",
        fontSize: "14px",
        fontWeight: 600,
        lineHeight: "22px",
    },
    subHeading1: {
        fontFamily: "Inter-600",
        fontSize: "20px",
        fontWeight: 600,
        lineHeight: "24px",
    },
    subHeading2: {
        fontFamily: "Inter-600",
        fontSize: "18px",
        fontWeight: 600,
        lineHeight: "24px",
    },
};
const paragraphFonts = {
    paragraph16: {
        fontFamily: "Inter-400",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
    },
    paragraph14: {
        fontFamily: "Inter-400",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "20px",
    },
    paragraph12: {
        fontFamily: "Inter-400",
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "16px",
    },
};
const buttonFonts = {
    buttonNormal: {
        fontFamily: "Inter-600",
        fontSize: "12px",
        fontWeight: 600,
        lineHeight: "15px",
    },
    buttonHeavy9: {
        fontFamily: "Inter-600",
        fontSize: "9px",
        fontWeight: 600,
        lineHeight: "11px",
    },
    buttonHeavy14: {
        fontFamily: "Inter-600",
        fontSize: "14px",
        fontWeight: 600,
        lineHeight: "20px",
    },
};
const labelFonts = {
    label16: {
        fontFamily: "Inter-400",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
    },
    label16Active: {
        fontFamily: "Inter-600",
        fontSize: "16px",
        fontWeight: 600,
        lineHeight: "24px",
    },
    label14: {
        fontFamily: "Inter-600",
        fontSize: "14px",
        fontWeight: 600,
        lineHeight: "20px",
    },
    label14Regular: {
        fontFamily: "Inter-400",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "20px",
    },
    label12: {
        fontFamily: "Inter-400",
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "16px",
    },
    label12SemiBold: {
        fontFamily: "Inter-600",
        fontSize: "12px",
        fontWeight: 600,
        lineHeight: "16px",
    },
    label10: {
        fontFamily: "Inter-400",
        fontSize: "10px",
        fontWeight: 400,
        lineHeight: "16px",
    },
};
const textRegularFonts = {
    textRegular16: {
        fontFamily: "Inter-400",
        fontSize: "16px",
        fontWeight: 400,
        lineHeight: "24px",
    },
};
const textMediumFonts = {
    textMedium15: {
        fontFamily: "Inter-500",
        fontSize: "15px",
        fontWeight: 500,
        lineHeight: "18px",
    },
    textMedium14: {
        fontFamily: "Inter-500",
        fontSize: "14px",
        fontWeight: 400,
        lineHeight: "20px",
    },
    textMedium10: {
        fontFamily: "Inter-500",
        fontSize: "10px",
        fontWeight: 500,
        lineHeight: "16px",
    },
    textMedium16: {
        fontFamily: "Inter-500",
        fontSize: "16px",
        fontWeight: 500,
        lineHeight: "22px",
    },
};
const textHeavyFonts = {
    textHeavy10: {
        fontFamily: "Inter-600",
        fontSize: "10px",
        fontWeight: 600,
        lineHeight: "16px",
    },
    textHeavy14: {
        fontFamily: "Inter-600",
        fontSize: "14px",
        fontWeight: 600,
        lineHeight: "22px",
    },
    textHeavy15: {
        fontFamily: "Inter-600",
        fontSize: "15px",
        fontWeight: 600,
        lineHeight: "18px",
    },
    textHeavy16: {
        fontFamily: "Inter-600",
        fontSize: "16px",
        fontWeight: 600,
        lineHeight: "24px",
    },
};
const fonts = {
    ...headingFonts,
    ...paragraphFonts,
    ...textRegularFonts,
    ...textMediumFonts,
    ...textHeavyFonts,
    ...buttonFonts,
    ...labelFonts,
};
export default fonts;
