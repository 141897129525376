export default {
  RequestsHeading: "Requests",
  CreateRequestButtonLabel: "create",
  RequestFormHeading: "Create Request",
  GeneralRequestFormHeading: "General Maintenance Request",
  RequestFormTitleLabel: "Request Title",
  RequestFormTitlePlaceholder: "Enter a short request title",
  RequestFormTitleError: "Please enter a request title.",
  RequestFormTitleMaxLengthExceeded: "Request Title must be less than {{maxLength}} characters",
  RequestFormDetailsLabel: "Request Details",
  RequestFormDetailsMaxLengthExceeded: "Request Details must be less than {{maxLength}} characters",
  RequestFormDetailsPlaceholder:
    "So that we can address your issue as quickly as possible, please provide as much detail as you can. E.g. appliance type, make and model and whether gas or electricity.",
  RequestFormDetailsError: "Please enter request details.",
  RequestFormPhotosLabel: "Upload between {{minLength}} - {{maxLength}} photos",
  RequestFormPhotosMinimumPhotosRequired: "Please upload at least one photo.",
  RequestFormSubmitLabel: "Submit Request",
  RequestFormSuccessMessage: "Request Received!",
  RequestFormCancelTitle: "Are you sure you want to leave?",
  RequestFormCancelBody: "If you leave this page your data will be lost.",
  RequestFormCancelButtonLeave: "Leave Page",
  RequestFormCancelButtonStay: "Stay on page",
  GeneralRequestHeading: "General Maintenance",
  GeneralRequestLinkCardContent: "Need something fixed? Create a request to start the process.",
  UrgentRequestHeading: "Emergency Maintenance",
  UrgentRequestLinkCardContent:
    "If urgent works are needed out of business hours, contact emergency services or follow the procedure advised by your agency.",
  UrgentRequestParagraph1:
    "Emergency maintenance includes burst water service, blocked toilets, major leaks and flows, gas leaks, electrical faults, and a failure of gas, electricity or water supply.",
  UrgentRequestDuringBusinessHours: {
    Title: "During business hours",
    Content: "Call your property manager or agency office phone number."
  },
  UrgentRequestAfterBusinessHours: {
    Title: "Out of business hours",
    Content: "Follow the procedure advised by your agency at the start of your tenancy."
  },
  UrgentRequestImmediate: {
    Title: "Immediate danger",
    Content: "Call triple zero (000) for ambulance, police or fire services in an emergency.",
    SubTitle: "You should only call triple zero (000) if:",
    List: [
      "a person is seriously injured or needs urgent medical help",
      "your life or property is in danger",
      "you have just witnessed a serious accident or crime"
    ]
  },
  UrgentRequestParagraph3:
    "If you require urgent maintenance out of business hours, follow the procedure advised by your property manager/agency at the start of your tenancy.",
  UrgentRequestParagraph4:
    "If you are in immediate danger, please call 000 immediately. Emergency services such as police, ambulance, the CFA or SES may be more appropriate in some situations.",
  RequestDetailsHeading: "Request Details",
  ReturnToRequests: "Return To Requests",
  NewRequest: "New Request",
  All: "All",
  Current: "Current",
  Closed: "Closed",
  RequestsEmpty: "No requests to see yet"
};
