import { useWindowDimensions } from "react-native";

export enum ScreenSize {
  XSmallMobile = 280,
  SmallMobile = 320,
  LargeMobile = 480,
  Tablet = 768,
  Desktop = 1024
}
export const useMediaQuery = () => {
  const { width } = useWindowDimensions();

  const isXSmallMobile = width <= ScreenSize.XSmallMobile;
  const isSmallMobile = width <= ScreenSize.SmallMobile;
  const isMobile = width <= ScreenSize.LargeMobile;
  const isTablet = width >= ScreenSize.LargeMobile && width <= ScreenSize.Tablet;
  const isDesktop = width > ScreenSize.Tablet;

  const isBelowMobile = width < ScreenSize.LargeMobile;
  const isBelowTablet = width < ScreenSize.Tablet;

  const mq = ({
    phone,
    tablet,
    desktop
  }: {
    phone?: IntentionalAny<"Can be used to with any values">;
    tablet?: IntentionalAny<"Can be used to with any values">;
    desktop?: IntentionalAny<"Can be used to with any values">;
  }) => {
    switch (true) {
      case width <= ScreenSize.LargeMobile:
        return phone;
      case width > ScreenSize.LargeMobile && width <= ScreenSize.Tablet:
        return tablet;
      case width > ScreenSize.Tablet:
        return desktop;
    }
  };

  const phoneSizeUp = (array: [string | number, string | number]) => {
    const [phoneValue, upValue] = array;
    return mq({
      phone: phoneValue,
      tablet: upValue,
      desktop: upValue
    });
  };

  return {
    isTablet,
    isDesktop,
    isXSmallMobile,
    isSmallMobile,
    isMobile,
    isBelowTablet,
    isBelowMobile,
    phoneSizeUp,
    mq
  };
};
