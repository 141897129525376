import { ListRenderItemInfo } from "react-native";

import { Seperator } from "@src/components";
import { FlatList, useTheme, FlatListProps, Box } from "@kolmeo/ui-core";
import { useMediaQuery } from "@src/hooks";

const ResponsiveFlatList = <T,>({ data, renderItem, ...rest }: FlatListProps<T>) => {
  const { spacing } = useTheme();
  const { mq } = useMediaQuery();
  const NUM_COLUMNS: number = mq({ phone: 1, tablet: 2, desktop: 3 });

  const renderItemWithSeperator = (item: ListRenderItemInfo<T>) => {
    return renderItem ? (
      <>
        <Box flex={NUM_COLUMNS > 1 ? 1 / NUM_COLUMNS : null}>{renderItem(item)}</Box>
        {(item.index + 1) % NUM_COLUMNS !== 0 && <Seperator margin={spacing.px8} vertical seperatorColor="transparent" />}
      </>
    ) : null;
  };

  return (
    <FlatList
      data={data}
      key={NUM_COLUMNS}
      keyExtractor={(_, index) => `${index.toString()}-${NUM_COLUMNS}`}
      renderItem={renderItemWithSeperator}
      numColumns={NUM_COLUMNS}
      ItemSeparatorComponent={() => <Seperator margin={spacing.px8} seperatorColor="transparent" />}
      {...rest}
    />
  );
};

export default ResponsiveFlatList;
