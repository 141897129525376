import { Dispatch, SetStateAction, createContext, useContext, useEffect, useState } from "react";
import { TenantThemeBrandingObject, ThemeBrandingObject } from "@src/lib/Kiitos";

import AsyncStorage from "@react-native-async-storage/async-storage";
import { BRANDING_DATA_KEY } from "@src/types";

import { useAxios } from "@src/lib/http";
import { useQuery } from "@tanstack/react-query";

export const useGetBrandingObjectForTenant = (tenancyId?: number) => {
  const { instance, isEnabled } = useAxios("TENANT_API");

  return useQuery<TenantThemeBrandingObject>({
    queryKey: ["branding-object-tenant", tenancyId],
    queryFn: async () => {
      const response = await instance.get(`/branding/${tenancyId}`);
      const { logoUrl, primaryColor, font } = response.data;
      return { logoUrl, primaryColor, font };
    },
    enabled: isEnabled && !!tenancyId
  });
};
export const useGetBrandingObjectForOwner = (propertyId: number) => {
  const { instance, isEnabled } = useAxios("OWNER_API");

  return useQuery<{ branding: ThemeBrandingObject; propertyEntityId: string }>({
    queryKey: ["branding-object-owner", propertyId],
    queryFn: async () => {
      const response = await instance.get(`/property/property-overview/${propertyId}`);
      const { businessBrand: branding, propertyEntityId } = response.data;
      return { branding, propertyEntityId };
    },
    enabled: isEnabled && !!propertyId
  });
};

export type BrandingContextType = {
  brandingObject?: TenantThemeBrandingObject | undefined;
  setBrandingObject: Dispatch<SetStateAction<TenantThemeBrandingObject | ThemeBrandingObject | undefined>>;
};

export const BrandingContext = createContext<BrandingContextType>({} as BrandingContextType);

export const BrandingProvider = ({ children }: any) => {
  const [brandingObject, setBrandingObject] = useState<TenantThemeBrandingObject | ThemeBrandingObject | undefined>();

  useEffect(() => {
    // get branding data
    if (brandingObject) {
      // setBrandingObject(brandingData);
      setToStorageAsync(BRANDING_DATA_KEY, brandingObject);
    }
  }, [brandingObject]);

  useEffect(() => {
    loadDataFromStorage();
  }, []);

  const loadDataFromStorage = async () => {
    const brandingDataJson = await getFromStorageAsync(BRANDING_DATA_KEY);
    if (brandingDataJson) {
      const _brandingData = JSON.parse(brandingDataJson);
      setBrandingObject(_brandingData);
    }
  };

  const setToStorageAsync = async (key: string, value: any) => {
    await AsyncStorage.setItem(key, JSON.stringify(value));
  };

  const getFromStorageAsync = async (key: string) => {
    return await AsyncStorage.getItem(key);
  };
  return <BrandingContext.Provider value={{ brandingObject, setBrandingObject }}>{children}</BrandingContext.Provider>;
};
export const useBranding = () => useContext(BrandingContext);
