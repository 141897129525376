import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Text, TouchableOpacity } from "@kolmeo/ui-core";
import Icon from "../../Icon";
import coreThemeV2 from "../../../theme/coreTheme";
const { palette } = coreThemeV2;
export const toastTypes = {
    danger: {
        backgroundColor: palette.danger.background,
        textColor: palette.danger.text,
        iconColor: palette.danger.icon,
        tabColor: palette.danger.border,
        hasIcon: false,
        iconType: "close-circle",
    },
    info: {
        backgroundColor: palette.info.background,
        textColor: palette.info.text,
        iconColor: palette.info.icon,
        tabColor: palette.info.border,
        hasIcon: false,
    },
    warning: {
        backgroundColor: palette.warning.background,
        textColor: palette.warning.text,
        iconColor: palette.warning.icon,
        tabColor: palette.warning.border,
        hasIcon: false,
    },
    success: {
        backgroundColor: palette.success.background,
        textColor: palette.success.text,
        iconColor: palette.success.icon,
        tabColor: palette.success.border,
        hasIcon: false,
    },
    default: {
        backgroundColor: palette.feedback.general.background,
        textColor: palette.feedback.general.text,
        iconColor: palette.feedback.general.icon,
        tabColor: palette.feedback.general.border,
        hasIcon: false,
    },
};
const BaseToast = ({ ...props }) => {
    const { type, message, onPress, id } = props;
    const toastType = type ? type : "default";
    return (_jsxs(TouchableOpacity, { backgroundColor: toastTypes[toastType].backgroundColor, paddingX: 24, testID: "base-toast", activeOpacity: 1, onPress: () => onPress && onPress(id), paddingY: 12, borderRadius: 8, borderLeftWidth: 8, borderLeftColor: toastTypes[toastType].tabColor, marginX: 16, marginY: 1, style: {
            elevation: 4,
            shadowOffset: {
                width: 0,
                height: 4,
            },
            shadowOpacity: 0.1,
            shadowRadius: 8,
        }, flexDirection: "row", alignItems: "center", children: [toastTypes[toastType]?.hasIcon && toastTypes[toastType]?.iconType ? (_jsx(Box, { mr: 16, children: _jsx(Icon, { type: toastTypes[toastType].iconType, size: "md", color: toastTypes[toastType].iconColor }) })) : null, _jsx(Text, { variant: "textMedium14", color: toastTypes[toastType].textColor, children: message })] }));
};
export default BaseToast;
