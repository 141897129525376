import { coreThemeV2 } from "@kolmeo/ui-components";
const { palette } = coreThemeV2;

export const statusVariants = {
  default: {
    iconType: "information",
    borderColor: palette.border.default,
    iconColor: palette.text.secondary,
    backgroundColor: palette.background.default,
    titleColor: palette.text.primary,
    detailsColor: palette.text.primary,
    actionColor: palette.background.primary.default
  },
  info: {
    iconType: "information",
    borderColor: palette.info.border,
    iconColor: palette.info.icon,
    backgroundColor: palette.info.background,
    titleColor: palette.info.text,
    detailsColor: palette.info.text,
    actionColor: palette.background.primary.default
  },
  success: {
    iconType: "check-circle",
    borderColor: palette.success.border,
    iconColor: palette.success.icon,
    backgroundColor: palette.success.background,
    titleColor: palette.success.text,
    detailsColor: palette.success.text,
    actionColor: palette.background.primary.default
  },
  warning: {
    iconType: "information",
    borderColor: palette.warning.border,
    iconColor: palette.warning.icon,
    backgroundColor: palette.warning.background,
    titleColor: palette.warning.text,
    detailsColor: palette.warning.text,
    actionColor: palette.background.primary.default
  },
  danger: {
    iconType: "close-circle",
    borderColor: palette.danger.border,
    iconColor: palette.danger.icon,
    backgroundColor: palette.danger.background,
    titleColor: palette.danger.text,
    detailsColor: palette.danger.text,
    actionColor: palette.background.destructive.default
  }
};

export type StatusVariant = typeof statusVariants;
export type StatusVariantKey = keyof typeof statusVariants;
