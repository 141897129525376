import { Card, Icon, IconType } from "@kolmeo/ui-components";
import { Box, Text, toTitleCase, useTheme } from "@kolmeo/ui-core";

interface PaymentMethodCardType {
  onPress: () => void;
  text: "string";
  icon: IconType;
  isFullWidth: boolean;
}
const CardButton = ({ onPress, text: string, icon, isFullWidth }: PaymentMethodCardType) => {
  const { palette, spacing } = useTheme();

  return (
    <Card
      flexDirection={"row"}
      marginBottom={5}
      marginHorizontal={1}
      marginVertical={20}
      onPress={() => onPress()}
      paddingVertical={12}
      flex={1}
      forceRemoveShadow
      width={isFullWidth ? "100%" : "25%"}
    >
      <Box flexDirection={"row"} alignItems={"center"} flexWrap={"wrap"}>
        <Box paddingRight={spacing.px8}>{icon && <Icon type={icon} size={"sm"} color={palette.black} />}</Box>
        <Text variant="buttonHeavy14">{toTitleCase(string)}</Text>
      </Box>
    </Card>
  );
};

export default CardButton;
