export default {
    path: {
        d: "M22.7071 0.292893C23.0976 0.683417 23.0976 1.31658 22.7071 1.70711L8.70711 15.7071C8.31658 16.0976 7.68342 16.0976 7.29289 15.7071L0.292893 8.70711C-0.0976311 8.31658 -0.0976311 7.68342 0.292893 7.29289C0.683417 6.90237 1.31658 6.90237 1.70711 7.29289L8 13.5858L21.2929 0.292893C21.6834 -0.0976311 22.3166 -0.0976311 22.7071 0.292893Z",
    },
    properties: {
        viewBox: "0 0 23 16",
        outlined: false,
        strokeWidth: "0",
    },
};
