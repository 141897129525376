import { Box, useTheme } from "@kolmeo/ui-core";
import { ButtonV2 } from "@kolmeo/ui-components";
import { useBranding } from "@src/navigation/useBranding";
import { PendingUserProfileInvite, ProfileType } from "@src/types";
import { t } from "i18next";
import { useOwnerAcceptMutation, useOwnerDeclineMutation, useTenantAcceptMutation, useTenantDeclineMutation } from "./useAcceptInvite";

type UserProfileInviteCardProps = {
  invite: PendingUserProfileInvite;
  onSuccess: (userProfileId: number) => void;
};

const UserProfileInviteAction = ({ invite, onSuccess }: UserProfileInviteCardProps): JSX.Element => {
  const { spacing } = useTheme();
  const { brandingObject } = useBranding();
  const { mutateAsync: acceptOwnerMutation, isLoading: isAcceptOwnerLoading } = useOwnerAcceptMutation();
  const { mutateAsync: acceptTenantMutation, isLoading: isAcceptTenantLoading } = useTenantAcceptMutation();
  const { mutateAsync: declineOwnerMutation, isLoading: isDeclineOwnerloading } = useOwnerDeclineMutation();
  const { mutateAsync: declineTenantMutation, isLoading: isDeclineTenantLoading } = useTenantDeclineMutation();

  const onAccept = async (userProfileId: number, profileType: number) => {
    try {
      if (profileType === ProfileType.owner) {
        await acceptOwnerMutation({ UserProfileId: userProfileId });
      } else {
        await acceptTenantMutation({ UserProfileId: userProfileId });
      }
      onSuccess(userProfileId);
    } catch (error) {
      console.log(error, "onAccept:: ERROR");
    }
  };

  const onDecline = async (userProfileId: number, profileType: number) => {
    try {
      if (profileType === ProfileType.owner) {
        await declineOwnerMutation({ UserProfileId: userProfileId });
      } else {
        await declineTenantMutation({ UserProfileId: userProfileId });
      }
      onSuccess(userProfileId);
    } catch (error) {
      console.log(error, "onAccept:: ERROR");
    }
  };

  const isOwnerAcceptDisabled = invite.profileType == ProfileType.owner ? isAcceptOwnerLoading : isAcceptTenantLoading;
  const isTenantAcceptLoading = invite.profileType == ProfileType.owner ? isAcceptOwnerLoading : isAcceptTenantLoading;
  const isOwnerDeclineDisabled = invite.profileType == ProfileType.owner ? isDeclineOwnerloading : isDeclineTenantLoading;
  const isTenantDeclineLoading = invite.profileType == ProfileType.owner ? isDeclineOwnerloading : isDeclineTenantLoading;

  return (
    <Box flexDirection="row" justifyContent="space-between" mt={"auto"} paddingTop={spacing.px16}>
      <ButtonV2
        label={t("Accept")}
        width="49%"
        disabled={isOwnerAcceptDisabled}
        isLoading={isTenantAcceptLoading}
        overrideVariant={{ bgColor: brandingObject?.primaryColor }}
        variant="primary"
        onPress={() => onAccept(invite.userProfileId, invite.profileType)}
      />
      <ButtonV2
        label={t("Decline")}
        width="49%"
        ml={spacing.px16}
        variant="outlined"
        transparentHover
        disabled={isOwnerDeclineDisabled}
        isLoading={isTenantDeclineLoading}
        overrideVariant={{ borderColor: "#C0362D", color: "#C0362D" }}
        onPress={() => {
          onDecline(invite.userProfileId, invite.profileType);
        }}
      />
    </Box>
  );
};

export default UserProfileInviteAction;
