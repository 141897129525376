import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { TouchableOpacity, coreTheme } from "@kolmeo/ui-core";
import { useRef } from "react";
import { NativeHapticFeedback } from "../../../utils/system";
import { Divider } from "../../Divider";
import { Menu, MenuOption, MenuOptions, MenuTrigger, PressableMenuOption, ReadOnlyMenuOption } from "../components";
import HyperlinkMenuOption from "../components/HyperlinkMenuOption";
const { spacing, palette, borderRadius } = coreTheme;
const MenuList = ({ triggerComponent, options, optionsContainerStyle, disabled = false }) => {
    const menuRef = useRef(null);
    return (_jsxs(Menu, { ref: menuRef, style: {
            position: "relative",
        }, children: [_jsx(MenuTrigger, { customStyles: {
                    TriggerTouchableComponent: () => (_jsx(TouchableOpacity, { disabled: disabled, opacity: disabled ? 0.5 : 1, testID: "pop-up-menu-list-trigger", onPress: () => {
                            NativeHapticFeedback("impactMedium");
                            menuRef.current?.open();
                        }, children: triggerComponent })),
                } }), _jsx(MenuOptions, { optionsContainerStyle: [
                    {
                        shadowOpacity: 0,
                        borderRadius: borderRadius.radius16,
                        marginTop: spacing.spacing32,
                        paddingVertical: spacing.spacing16,
                        paddingHorizontal: spacing.spacing16,
                    },
                    optionsContainerStyle,
                ], children: options.map((option, index) => (_jsx(MenuOption, { customStyles: {
                        OptionTouchableComponent: () => (_jsxs(_Fragment, { children: [option.readOnlyOption ? (_jsx(ReadOnlyMenuOption, { option: option, primaryColor: palette.textPrimary })) : option.isLink ? (_jsx(HyperlinkMenuOption, { option: option, primaryColor: palette.textPrimary })) : (_jsx(PressableMenuOption, { primaryColor: palette.textPrimary, option: option, onDismiss: function () {
                                        menuRef.current?.close();
                                    } })), index !== options.length - 1 ? _jsx(Divider, { marginY: spacing.spacing16 }) : null] })),
                    } }, option.title))) })] }));
};
export default MenuList;
