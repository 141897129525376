import { useMediaQuery } from "@src/hooks";
import { PaymentHero } from "./components";
import { type PaymentsHistoryResponse } from "@src/types";
import { ScrollView, Text, useTheme, Box } from "@kolmeo/ui-core";
import type { ThemeBrandingObject } from "@src/lib/Kiitos";

import Detective from "@src/assets/svg/Detective.svg";

const PaymentDetails = ({
  item,
  brandingObject,
  children,
  isBlank = false
}: {
  item?: PaymentsHistoryResponse;
  brandingObject?: ThemeBrandingObject;
  children: JSX.Element;
  isBlank?: boolean;
}) => {
  const { sizing, colors } = useTheme();
  const { mq } = useMediaQuery();
  return item ? (
    <>
      <PaymentHero amount={item.amount} date={item.transactionDate} />

      <ScrollView
        paddingX={mq({ desktop: sizing.size32, tablet: sizing.size24, phone: sizing.size16 })}
        height={mq({ desktop: "auto", tablet: "auto", phone: "65vh" })}
        paddingTop={"32px"}
        contentContainerStyle={{ flexGrow: 1 }}
      >
        {children}
      </ScrollView>
    </>
  ) : !isBlank ? (
    <Box height="100%" justifyContent="center" alignItems="center">
      <Box width="265px" alignItems="center">
        <Text
          fontSize="18px"
          fontWeight={600}
          color={brandingObject?.primaryColor ?? colors.primary}
          marginBottom="34px"
          textAlign="center"
        >
          Select a payment to view the details here.
        </Text>
        <Detective width="75%" />
      </Box>
    </Box>
  ) : null;
};

export default PaymentDetails;
