export default {
    path: {
        d: "M18 15.8421L14 12.2632V14.9474H5V16.7368H14V19.4211L18 15.8421ZM25 14.9474H23V23H7V18.5263H9V21.2105H21V14.0526L15 8.68421L10 13.1579H7L15 6L25 14.9474Z",
    },
    properties: {
        viewBox: "0 0 30 30",
        outlined: false,
        strokeWidth: "0",
    },
};
