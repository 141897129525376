import { TenantThemeBrandingObject } from "@src/lib/Kiitos";
import { useAxios } from "@src/lib/http";
import {
  AddPaymentMethodsFormData,
  AddPaymentMethodsResponse,
  BankAccountDetailsResponse,
  DeactivateBankAccountData,
  DeactivateBankAccountResponse,
  UpdateDefaultPaymentAccountData,
  UpdatePaymentAccountNicknameData
} from "@src/types/PaymentPreferences";
import { useMutation, useQuery } from "@tanstack/react-query";
import { createContext, useContext } from "react";
import { queryClient } from "@src/lib/http";
import { useAuth } from "react-oidc-context";
import { useCurrentUser } from "@src/hooks";
import axios from "axios";

export type PaymentPreferencesContextType = {
  tenancyId: number;
  brandingObject?: TenantThemeBrandingObject;
  propertyEntityId?: string;
  propertyId?: number;
  propertyName?: string;
};

export const PaymentPreferencesContext = createContext<PaymentPreferencesContextType>({} as PaymentPreferencesContextType);

export const usePaymentPreferences = () => useContext(PaymentPreferencesContext);

// currently this is hardcoded to only accept pdf. In the future we need to be able to handle downloading different media types.
export const useAuthorityDownload = ({
  documentUrl,
  name,
  fileType,
  mime
}: {
  documentUrl: string;
  name: string;
  fileType?: string;
  mime: string;
}) => {
  const { user } = useAuth();
  const { currentUser } = useCurrentUser();
  const handleDocumentFetch = async (): Promise<IntentionalAny<"how do you type a Blob?">> => {
    return await axios(documentUrl, {
      headers: {
        Authorization: `Bearer ${user?.id_token}`,
        ["x-kol-userprofile-id"]: currentUser?.userProfileId
      },
      method: "GET",
      responseType: "blob"
    });
  };

  const handleFile = async (fileData: Blob, fileName: string, method: "download" | "preview") => {
    const FILENAME = `${fileName}.${fileType}`;
    const file = new Blob([fileData], { type: mime });
    file.type;
    const href = URL.createObjectURL(file);

    const aElement = document.createElement("a");
    aElement.href = href;
    if (method === "download") {
      aElement.setAttribute("download", FILENAME);
      aElement.setAttribute("target", "_blank");
      aElement.click();
      URL.revokeObjectURL(href);
    }
    if (method === "preview") {
      aElement.setAttribute("target", "_blank");
      aElement.click();
    }
  };

  const handleDownload = () => {
    handleDocumentFetch()
      .then(({ data }) => {
        handleFile(data, name, "download")
          .then((res) => console.log(res))
          .catch((err) => console.error(err));
      })
      .catch((err) => console.error(err));
  };
  const handlePreview = () => {
    handleDocumentFetch()
      .then(({ data }) => {
        handleFile(data, name, "preview")
          .then((res) => console.log(res))
          .catch((err) => console.error(err));
      })
      .catch((err) => console.error(err));
  };

  return { handleDownload, handlePreview };
};

export const useGetAllBankAccounts = (tenancyId?: number) => {
  const { instance, isEnabled } = useAxios("TENANT_API");

  return useQuery<BankAccountDetailsResponse[]>({
    queryKey: ["bank-account-list"],
    queryFn: async () => {
      const response = await instance.get(`/payments/${tenancyId}/bank-accounts`);
      return response.data;
    },
    enabled: isEnabled && !!tenancyId
    // refetchOnWindowFocus: "always"
  });
};
export const useUpdateDefaultBankAccountMutation = () => {
  const { instance } = useAxios("TENANT_API");

  const mutationFunction = async (formData: UpdateDefaultPaymentAccountData) => {
    const response = await instance.put("/payments/set-default-payment-account", formData);
    return response.data;
  };

  // First type is the return from the API
  return useMutation<boolean, unknown, UpdateDefaultPaymentAccountData>(mutationFunction, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(["formData"]);
      return data;
    },
    onError: (err) => err
  });
};

export const useUpdateBankAccountNicknameMutation = () => {
  const { instance } = useAxios("TENANT_API");

  const mutationFunction = async (formData: UpdatePaymentAccountNicknameData) => {
    const response = await instance.put("/payments/set-payment-account-nickname", formData);
    return response.data;
  };

  // First type is the return from the API
  return useMutation<boolean, unknown, UpdatePaymentAccountNicknameData>(mutationFunction, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(["formData"]);
      return data;
    },
    onError: (err) => err
  });
};

export const useDeactivateBankAccountMutation = () => {
  const { instance } = useAxios("TENANT_API");

  const mutationFunction = async (formData: DeactivateBankAccountData) => {
    console.log(formData, "formdata");
    const response = await instance.put("/payments/deactivate-bank-account", formData);
    return response.data;
  };

  // First type is the return from the API
  return useMutation<DeactivateBankAccountResponse, unknown, DeactivateBankAccountData>(mutationFunction, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(["formData"]);
      return data;
    },
    onError: (err) => err
  });
};

export const useAddBankAccountMutation = () => {
  const { instance } = useAxios("TENANT_API");

  const mutationFunction = async (formData: AddPaymentMethodsFormData) => {
    const response = await instance.post("/payments/add-bank-account", formData);
    return response.data;
  };

  // First type is the return from the API
  return useMutation<AddPaymentMethodsResponse, unknown, AddPaymentMethodsFormData>(mutationFunction, {
    onSuccess: (data) => {
      queryClient.invalidateQueries(["formData"]);
      return data;
    },
    onError: (err) => err
  });
};
