import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, coreTheme, FlatList, Text } from "@kolmeo/ui-core";
import { Platform } from "react-native";
import { NativeHapticFeedback } from "../../utils/system";
const HORIZONTAL_SPACING = 10;
const { palette, spacing } = coreTheme;
const SelectableItemsList = ({ headerTitle, onCancelSelection, isSelectShown = true, isSelectToggled, selectToggleHandler, ...props }) => {
    const onSelectPress = () => {
        if (Platform.OS !== "web")
            NativeHapticFeedback("impactLight");
        if (isSelectToggled) {
            onCancelSelection && onCancelSelection();
        }
        selectToggleHandler(!isSelectToggled);
    };
    return (_jsx(FlatList, { ...props, ListHeaderComponentStyle: {
            paddingTop: 15,
            paddingBottom: HORIZONTAL_SPACING,
            marginBottom: HORIZONTAL_SPACING,
            alignSelf: "stretch",
            flexDirection: "row",
            alignItems: "center",
            backgroundColor: palette.white,
            paddingHorizontal: HORIZONTAL_SPACING,
        }, flex: 1, ListHeaderComponent: _jsxs(Box, { flexDirection: "row", justifyContent: "space-between", alignItems: "center", width: "100%", children: [headerTitle ? _jsx(Text, { variant: "textHeavy14", children: headerTitle }) : null, isSelectShown ? (_jsx(Box, { flex: 1, alignItems: 'flex-end', justifyContent: 'flex-start', paddingRight: spacing.spacing12, children: _jsx(Text, { variant: "textHeavy14", color: palette.backgroundPrimaryDefault, onPress: onSelectPress, children: isSelectToggled ? "Done" : "Select" }) })) : null] }), windowSize: 10, stickyHeaderIndices: [0], contentContainerStyle: {
            display: "flex",
            alignItems: "flex-start",
            marginLeft: HORIZONTAL_SPACING / 2,
            flexGrow: 1,
            paddingBottom: 24,
        } }));
};
export default SelectableItemsList;
