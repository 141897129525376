export default {
    path: {
        d: "M25 4.00001H20.4625C19.902 3.37045 19.2146 2.86662 18.4454 2.52162C17.6763 2.17662 16.8429 1.99826 16 1.99826C15.1571 1.99826 14.3237 2.17662 13.5546 2.52162C12.7854 2.86662 12.098 3.37045 11.5375 4.00001H7C6.46957 4.00001 5.96086 4.21072 5.58579 4.5858C5.21071 4.96087 5 5.46958 5 6.00001V27C5 27.5304 5.21071 28.0392 5.58579 28.4142C5.96086 28.7893 6.46957 29 7 29H25C25.5304 29 26.0391 28.7893 26.4142 28.4142C26.7893 28.0392 27 27.5304 27 27V6.00001C27 5.46958 26.7893 4.96087 26.4142 4.5858C26.0391 4.21072 25.5304 4.00001 25 4.00001ZM16 4.00001C17.0599 4.00331 18.0754 4.42579 18.8248 5.17522C19.5742 5.92466 19.9967 6.94016 20 8.00001H12C12.0033 6.94016 12.4258 5.92466 13.1752 5.17522C13.9246 4.42579 14.9401 4.00331 16 4.00001ZM20 20H12C11.7348 20 11.4804 19.8947 11.2929 19.7071C11.1054 19.5196 11 19.2652 11 19C11 18.7348 11.1054 18.4804 11.2929 18.2929C11.4804 18.1054 11.7348 18 12 18H20C20.2652 18 20.5196 18.1054 20.7071 18.2929C20.8946 18.4804 21 18.7348 21 19C21 19.2652 20.8946 19.5196 20.7071 19.7071C20.5196 19.8947 20.2652 20 20 20ZM20 16H12C11.7348 16 11.4804 15.8947 11.2929 15.7071C11.1054 15.5196 11 15.2652 11 15C11 14.7348 11.1054 14.4804 11.2929 14.2929C11.4804 14.1054 11.7348 14 12 14H20C20.2652 14 20.5196 14.1054 20.7071 14.2929C20.8946 14.4804 21 14.7348 21 15C21 15.2652 20.8946 15.5196 20.7071 15.7071C20.5196 15.8947 20.2652 16 20 16Z",
    },
    properties: {
        viewBox: "0 0 32 32",
        outlined: false,
        strokeWidth: "0",
    },
};
