import { Box, Text, useTheme } from "@kolmeo/ui-core";
import { useMediaQuery } from "@src/hooks";

const PaymentAmount = ({ amount, size = "default" }: { amount: number; size?: "hero" | "default" }) => {
  const { mq } = useMediaQuery();
  const { sizing } = useTheme();

  const SETTINGS = {
    sizes: {
      hero: {
        fontSize: { phone: "24px", tablet: "24px", desktop: "24px" },
        marginY: `${sizing.size4}px`
      },
      default: {
        fontSize: { phone: "14px", tablet: "14px", desktop: "14px" },
        marginY: undefined
      }
    }
  };

  const isNegative = amount.toString().charAt(0) === "-" ? true : false;
  const strippedNumber = amount.toFixed(2).toString().replace("-", "");

  return (
    <Box flexDirection="row" alignItems="center" justifyContent="center" marginY={SETTINGS.sizes[size].marginY}>
      <Text
        mr="2px"
        fontSize={mq(SETTINGS.sizes[size].fontSize)}
        fontWeight={800}
        lineHeight={"24px"}
        color={isNegative ? "#F04438" : "#31AE76"}
      >
        {isNegative ? "-" : "+"}
      </Text>

      <Text
        fontFamily={size === "default" ? "Inter-400" : "Inter-600"}
        color="#000"
        fontSize={mq(SETTINGS.sizes[size].fontSize)}
        lineHeight={"24px"}
      >
        ${strippedNumber}
      </Text>
    </Box>
  );
};

export default PaymentAmount;
