import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useRef } from "react";
import styled from "styled-components/native";
import { color, space, layout, position, border, flexbox, boxShadow, variant, } from "styled-system";
const StyledTouchableOpacity = styled.TouchableOpacity(color, space, layout, position, border, flexbox, boxShadow, variant({
    variants: {
        primary: {
            color: "white",
            bg: "primary",
        },
        secondary: {
            color: "white",
            bg: "secondary",
        },
    },
}));
const TouchableOpacity = ({ hoverStyle = {}, extraWebElements = {}, onHoverIn = () => { }, onHoverOut = () => { }, ...props }) => {
    const componentRef = useRef();
    useEffect(() => {
        componentRef.current &&
            componentRef.current.setNativeProps(extraWebElements);
    }, [extraWebElements]);
    const handleHoverIn = () => {
        onHoverIn();
        hoverStyle &&
            componentRef.current &&
            componentRef.current.setNativeProps({ style: hoverStyle });
    };
    const handleHoverOut = () => {
        onHoverOut();
        componentRef.current && componentRef.current.setNativeProps({ style: {} });
    };
    return (_jsx(StyledTouchableOpacity, { ...props, ref: componentRef, onMouseEnter: handleHoverIn, onMouseLeave: handleHoverOut }));
};
export default TouchableOpacity;
