export default {
    path: {
        d: "M15.25 2.00009H11.8469C11.4265 1.52792 10.9109 1.15005 10.3341 0.891298C9.75726 0.632548 9.13221 0.498779 8.5 0.498779C7.86779 0.498779 7.24274 0.632548 6.66591 0.891298C6.08908 1.15005 5.57353 1.52792 5.15313 2.00009H1.75C1.35218 2.00009 0.970644 2.15813 0.68934 2.43943C0.408035 2.72074 0.25 3.10227 0.25 3.50009V19.2501C0.25 19.6479 0.408035 20.0294 0.68934 20.3108C0.970644 20.5921 1.35218 20.7501 1.75 20.7501H15.25C15.6478 20.7501 16.0294 20.5921 16.3107 20.3108C16.592 20.0294 16.75 19.6479 16.75 19.2501V3.50009C16.75 3.10227 16.592 2.72074 16.3107 2.43943C16.0294 2.15813 15.6478 2.00009 15.25 2.00009V2.00009ZM8.5 2.00009C9.29489 2.00256 10.0565 2.31943 10.6186 2.8815C11.1807 3.44358 11.4975 4.2052 11.5 5.00009H5.5C5.50247 4.2052 5.81934 3.44358 6.38141 2.8815C6.94349 2.31943 7.70511 2.00256 8.5 2.00009ZM11.5 14.0001H5.5C5.30109 14.0001 5.11032 13.9211 4.96967 13.7804C4.82902 13.6398 4.75 13.449 4.75 13.2501C4.75 13.0512 4.82902 12.8604 4.96967 12.7198C5.11032 12.5791 5.30109 12.5001 5.5 12.5001H11.5C11.6989 12.5001 11.8897 12.5791 12.0303 12.7198C12.171 12.8604 12.25 13.0512 12.25 13.2501C12.25 13.449 12.171 13.6398 12.0303 13.7804C11.8897 13.9211 11.6989 14.0001 11.5 14.0001ZM11.5 11.0001H5.5C5.30109 11.0001 5.11032 10.9211 4.96967 10.7804C4.82902 10.6398 4.75 10.449 4.75 10.2501C4.75 10.0512 4.82902 9.86041 4.96967 9.71976C5.11032 9.57911 5.30109 9.50009 5.5 9.50009H11.5C11.6989 9.50009 11.8897 9.57911 12.0303 9.71976C12.171 9.86041 12.25 10.0512 12.25 10.2501C12.25 10.449 12.171 10.6398 12.0303 10.7804C11.8897 10.9211 11.6989 11.0001 11.5 11.0001Z",
    },
    properties: {
        viewBox: "0 0 17 21",
        outlined: false,
        strokeWidth: "0",
    },
};
