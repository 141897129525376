import { FieldError, FieldLimitLabel, coreThemeV2 } from "@kolmeo/ui-components";
import { Box, Text, FlatList } from "@kolmeo/ui-core";
import { useCallback, useEffect, useState } from "react";
import Dropzone, { Accept, FileRejection } from "react-dropzone";
import { useTranslation } from "react-i18next";
import ImageThumbnail from "./ImageThumbnail";

type ImageUploaderProps = {
  id: string;
  onUpload: (acceptedFiles: File[]) => void;
  onError?: (fileRejection: FileRejection[]) => void;
  onDelete: (id: string) => void;
  disabled: boolean;
  maxSize?: number;
  disableDrag?: boolean;
  disableClick?: boolean;
  allowMultiple?: boolean;
  files: any[];
  maxFiles?: number;
  error?: any;
  isMobile: boolean;
  accept?: Accept;
};

const WebImageUploader = ({
  id,
  maxSize = 10000000,
  onUpload,
  onDelete,
  disabled,
  files,
  maxFiles,
  error,
  disableDrag = false,
  disableClick = false,
  allowMultiple = true,
  isMobile,
  accept
}: ImageUploaderProps) => {
  const { palette, spacing, borderRadius } = coreThemeV2;
  const [errors, setErrors] = useState([] as string[]);
  const { t } = useTranslation("Common", { keyPrefix: "Forms" });
  const DROPZONE_HEIGHT = 400;

  useEffect(() => {
    error && setErrors([error]);
  }, [error]);

  // useEffect(() => {
  //   // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
  //   return () => files.forEach((file) => URL.revokeObjectURL(file.preview));
  // }, []);

  const onDropAccepted = (acceptedFiles: any) => {
    setErrors([]);
    if (maxFiles && acceptedFiles.length + files.length > maxFiles) {
      setErrors((olderr) => [...olderr, t("ErrorTooManyFiles")]);
      return;
    }
    if (onUpload) {
      onUpload(acceptedFiles);
    }
  };

  const onRejected = useCallback((rejectedFiles: FileRejection[]) => {
    const tooManyFiles = rejectedFiles.some((f) => f.errors.some((x) => x.code == "too-many-files"));
    const fileTooLarge = rejectedFiles.some((f) => f.errors.some((x) => x.code == "file-too-large"));
    const errorsArray: string[] = [];
    if (tooManyFiles) {
      errorsArray.push(t("ErrorTooManyFiles"));
    }
    if (fileTooLarge) {
      errorsArray.push(t("ErrorFilesTooLarge", { maxSize: `${maxSize / 1000000}` }));
    }
    if (accept) {
      const invalidFileType = rejectedFiles.some((f) => f.errors.some((x) => x.code == "file-invalid-type"));
      const acceptedFilesArray = [...accept["image/*"]];
      const acceptedFiles = acceptedFilesArray.join(",").replaceAll(",", ", ");

      if (invalidFileType) {
        errorsArray.push(t("ErrorFileFormatInvalid", { permittedFormats: `${acceptedFiles}` }));
      }
    }
    setErrors(errorsArray);
  }, []);

  return (
    <Box width={"100%"}>
      <Box
        padding={spacing.sp4 / 2}
        flexDirection={"column"}
        borderRadius={borderRadius.br8}
        borderWidth={1}
        borderColor={errors.length > 0 ? palette.danger.border : palette.border.default}
        backgroundColor={palette.background.surface}
      >
        {files.length < 5 ? (
          <Dropzone
            disabled={disabled}
            maxFiles={maxFiles}
            accept={accept}
            maxSize={maxSize}
            noDrag={disableDrag}
            noClick={disableClick}
            onDropAccepted={(files) => onDropAccepted(files)}
            onDropRejected={onRejected}
            multiple={allowMultiple}
          >
            {({ getRootProps, getInputProps, isDragActive }) => (
              // using <div> because <Box> component prevents dropzone drag functionality
              <div
                style={{
                  cursor: "pointer",
                  flex: 1,
                  height: DROPZONE_HEIGHT,
                  padding: spacing.sp16,
                  alignItems: "center",
                  justifyContent: "center",
                  borderRadius: borderRadius.br8,
                  textAlign: "center",
                  borderWidth: 2,
                  borderStyle: "dashed",
                  borderColor: isDragActive ? palette.success.border : palette.border.default
                }}
                {...getRootProps()}
              >
                <input id={id} accept="image/*" {...getInputProps()} />
                {isMobile ? (
                  <>
                    <Text fontSize={16} color={palette.border.heavy} textAlign={"center"}>
                      {t("SelectFromGalleryApp")}
                    </Text>
                  </>
                ) : (
                  <>
                    <Text fontSize={16} color={palette.border.heavy} textAlign={"center"}>
                      {t("SelectFromGalleryDesktop")}
                    </Text>
                  </>
                )}
              </div>
            )}
          </Dropzone>
        ) : null}
        <FlatList
          centerContent
          horizontal
          scrollEnabled={true}
          data={files}
          renderItem={({ item, index }) => <ImageThumbnail index={index} id={item.id} onDelete={onDelete} previewSrc={item.preview} />}
        />
      </Box>
      <Box mt={`${spacing.sp4}px`} alignSelf={"column"} flexDirection={"row"} justifyContent={"space-between"} alignItems={"flex-start"}>
        <Box flex={4} paddingTop={1}>
          {errors?.map((err) => (
            <FieldError message={err} />
          ))}
        </Box>
        <Box flex={1} justifyContent={"center"}>
          {maxFiles && <FieldLimitLabel maxLength={maxFiles} currentLength={files.length} />}
        </Box>
      </Box>
    </Box>
  );
};

export default WebImageUploader;
