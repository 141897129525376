const shadow = {
    shadow100: {
        shadowColor: "#000000",
        shadowOffset: {
            x: 0,
            y: 0.8,
        },
        shadowRadius: 2,
    },
    shadow200: {
        shadowColor: "#000000",
        shadowOffset: {
            x: 0,
            y: 0,
        },
        shadowRadius: 2,
    },
};
export default shadow;
