import { Box } from "@kolmeo/ui-core";

const CenteredContainer = ({
  children,
  ...rest
}: {
  [rest: string]: IntentionalAny<"Value can actually be any">;
} & React.PropsWithChildren): JSX.Element => (
  <Box maxWidth="1280px" width="100%" margin="0 auto" {...rest}>
    {children}
  </Box>
);

export default CenteredContainer;
