export default {
    path: {
        d: "M29.4235 12.3265L29.4247 12.33C29.5238 12.6339 29.5296 12.9606 29.4413 13.2678C29.3531 13.5749 29.1749 13.8486 28.9298 14.0536C28.9297 14.0536 28.9295 14.0537 28.9294 14.0538L23.2803 18.7531L23.0398 18.9532L23.1145 19.257L24.902 26.5195L24.9019 26.5195L24.9048 26.5303C24.9671 26.7609 24.9756 27.0028 24.9298 27.2372C24.884 27.4717 24.7851 27.6925 24.6406 27.8827C24.4961 28.0729 24.3099 28.2275 24.0963 28.3345C23.8837 28.4411 23.6495 28.4977 23.4117 28.5C23.1225 28.4981 22.84 28.4133 22.5975 28.2558L22.5975 28.2557L22.5921 28.2523L16.2796 24.2648L16.1572 24.1875H16.0125H15.9875H15.8425L15.72 24.2651L9.85825 27.9771C9.85812 27.9772 9.85799 27.9773 9.85786 27.9774C9.56339 28.163 9.22023 28.2567 8.87226 28.2463C8.5248 28.2359 8.18832 28.1223 7.9057 27.92C7.61272 27.7057 7.39081 27.4084 7.26872 27.0665C7.14641 26.7241 7.12995 26.3528 7.22146 26.0008L7.22213 25.9982L8.90963 19.3607L8.98667 19.0577L8.74794 18.8557L3.07294 14.0557L3.07295 14.0557L3.07065 14.0538C2.82534 13.8488 2.64706 13.5751 2.55879 13.2678C2.47052 12.9606 2.47631 12.6339 2.57541 12.33L2.57592 12.3284C2.67188 12.0308 2.85495 11.7688 3.10143 11.5763C3.34774 11.384 3.64605 11.27 3.95784 11.2489C3.95806 11.2489 3.95828 11.2489 3.9585 11.2489L11.3322 10.774L11.6467 10.7537L11.7641 10.4613L14.5641 3.48627L14.5641 3.48627L14.5654 3.48292C14.6789 3.1941 14.8765 2.946 15.1327 2.77076C15.3883 2.59588 15.6904 2.50158 16 2.5C16.3097 2.50158 16.6118 2.59588 16.8674 2.77076C17.1235 2.946 17.3212 3.1941 17.4347 3.48292L17.4352 3.48425L20.1852 10.4218L20.3013 10.7146L20.6156 10.7363L28.0406 11.2488L28.0437 11.249C28.3555 11.2686 28.654 11.382 28.9003 11.5743C29.1465 11.7665 29.3289 12.0287 29.4235 12.3265Z",
    },
    properties: {
        viewBox: "0 0 32 32",
        outlined: true,
        strokeWidth: "1.3",
    },
};
