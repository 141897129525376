import { useEffect, useState } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { UserProfile } from "@src/types";

const CURRENT_USER_ITEM = "@current_user";

export const useCurrentUser = () => {
  const [currentUser, setCurrentUser] = useState<UserProfile>();

  useEffect(() => {
    AsyncStorage.getItem(CURRENT_USER_ITEM).then((res) => {
      if (res) {
        const parsedUser: UserProfile = JSON.parse(res);
        setCurrentUser(parsedUser);
      }
    });
  }, []);

  const setCurrentUserItem = (userProfile: UserProfile) => {
    AsyncStorage.setItem(CURRENT_USER_ITEM, JSON.stringify(userProfile));
    setCurrentUser(userProfile);
  };

  const getUserProfile = async () => {
    const user = await AsyncStorage.getItem(CURRENT_USER_ITEM);
    if (user) {
      const parsedUser: UserProfile = JSON.parse(user);
      return parsedUser;
    }
  };

  const getCurrentUserProfileId = async () => {
    const user = await AsyncStorage.getItem(CURRENT_USER_ITEM);
    if (user) {
      const parsedUser: UserProfile = JSON.parse(user);
      return parsedUser.userProfileId;
    }
  };

  const clearCurrentUser = () => AsyncStorage.removeItem(CURRENT_USER_ITEM);

  return {
    currentUser,
    getCurrentUserProfileId,
    getUserProfile,
    setCurrentUserItem,
    clearCurrentUser
  };
};
