import iconSize from "./iconSizes";
export const stringToIntConverter = (strValue = "0") => {
    try {
        return +strValue.match(/-?\d/g).join("");
    }
    catch (e) {
        return 0;
    }
};
export const iconSizeToIntConverter = (size) => stringToIntConverter(iconSize[size] || "0");
