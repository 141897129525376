export default {
    path: {
        d: "M3.99 10.5H3.49V11V12.7908L0.706223 10L3.49 7.20925V9V9.5H3.99H10.5V10.5H3.99ZM14.01 3.5H14.51V3V1.20925L17.2938 4L14.51 6.79075V5V4.5H14.01H7.5V3.5H14.01Z",
    },
    properties: {
        viewBox: "0 0 18 14",
        outlined: false,
        strokeWidth: "0",
    },
};
