import { createContext, useContext } from "react";
import { TenantThemeBrandingObject } from "@src/lib/Kiitos";

export type RequestContextType = {
  tenancyId?: number;
  brandingObject?: TenantThemeBrandingObject;
  propertyEntityId?: string;
  propertyId?: number;
};

export const RequestContext = createContext<RequestContextType>({} as RequestContextType);

export const useRequests = () => useContext(RequestContext);
