import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, coreTheme } from "@kolmeo/ui-core";
import { Modal as CustomModal } from "react-native";
import { Divider } from "../../Divider";
import ModalBody from "./ModalBody";
import ModalFooter from "./ModalFooter";
import ModalHeader from "./ModalHeader";
const Modal = ({ transparent = true, visible, onRequestClose, headerTitle, bodyComponent, footerActions, bodyProps, innerBodyProps, renderDividers = true, }) => {
    const { palette } = coreTheme;
    const BORDER_RADIUS = 20;
    return (_jsx(CustomModal, { transparent: transparent, visible: visible, onRequestClose: onRequestClose, animationType: "fade", children: _jsx(Box, { flex: 1, justifyContent: "center", alignItems: "center", backgroundColor: palette.transparentBlack60, style: {
                shadowColor: "#000",
                shadowOffset: {
                    width: 0,
                    height: 2,
                },
                shadowOpacity: 0.25,
                shadowRadius: 4,
                elevation: 4,
            }, ...bodyProps, children: _jsxs(Box, { backgroundColor: palette.white, borderRadius: BORDER_RADIUS, paddingX: 16, width: "80%", ...innerBodyProps, children: [headerTitle && _jsx(ModalHeader, { title: headerTitle, onClose: onRequestClose }), renderDividers && _jsx(Divider, {}), _jsx(ModalBody, { bodyComponent: bodyComponent }), renderDividers && _jsx(Divider, {}), footerActions && _jsx(ModalFooter, { actions: footerActions })] }) }) }));
};
export default Modal;
