export default {
    path: {
        d1: "M22 0H2C1.46957 0 0.960859 0.210714 0.585786 0.585786C0.210714 0.960859 0 1.46957 0 2V22C0 22.5304 0.210714 23.0391 0.585786 23.4142C0.960859 23.7893 1.46957 24 2 24H22C22.5304 24 23.0391 23.7893 23.4142 23.4142C23.7893 23.0391 24 22.5304 24 22V2C24 1.46957 23.7893 0.960859 23.4142 0.585786C23.0391 0.210714 22.5304 0 22 0ZM18.4125 10C18.036 9.629 17.5286 9.42103 17 9.42103C16.4714 9.42103 15.964 9.629 15.5875 10L10 15.5875L7.4125 13C7.03597 12.629 6.5286 12.421 6 12.421C5.4714 12.421 4.96403 12.629 4.5875 13L2 15.5875V2H22V13.5875L18.4125 10Z",
        d2: "M8.5 9C9.32843 9 10 8.32843 10 7.5C10 6.67157 9.32843 6 8.5 6C7.67157 6 7 6.67157 7 7.5C7 8.32843 7.67157 9 8.5 9Z",
    },
    properties: {
        viewBox: "0 0 24 24",
        outlined: false,
        strokeWidth: "0",
    },
};
