import { useEffect, useState } from "react";
import { getFreeDiskStorage, getUniqueId } from "react-native-device-info";
const useDeviceInfoProvider = () => {
    const [deviceId, setDeviceId] = useState(undefined);
    const [availableDiskStorage, setAvailableDiskStorage] = useState(undefined);
    useEffect(() => {
        (async () => {
            setDeviceId(await getUniqueId());
            const freeDiskStorage = await getFreeDiskStorage();
            setAvailableDiskStorage(`${(freeDiskStorage / 1000000000).toFixed(1)} GB`);
        })();
    }, []);
    return {
        deviceId,
        availableDiskStorage,
    };
};
export default useDeviceInfoProvider;
