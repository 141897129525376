import { useAxios } from "@src/lib/http";
import { useQueries } from "@tanstack/react-query";

export type Feature = {
  id: string;
  description: string;
  enabled: boolean;
  conditions: {
    client_filters: [
      {
        name: string;
        parameters: {
          value: string;
        };
      }
    ];
  };
};

export const FeatureFlagLabels = {
  OwnerTenant: "OwnerTenant-Frontend",
  Tenant: "Tenant-Frontend",
  Owner: "Owner-Frontend"
} as const;
export type FeatureFlagLabelKey = keyof typeof FeatureFlagLabels;
export type FeatureFlagLabelValue = (typeof FeatureFlagLabels)[keyof typeof FeatureFlagLabels];

export const useGetFeatures = (isAuthenticated: boolean) => {
  const { instance } = useAxios("GATEWAY");

  return useQueries({
    queries: [
      {
        queryKey: ["features-root", isAuthenticated],
        queryFn: async () => {
          const response = await instance.get(`/features/${FeatureFlagLabels.OwnerTenant}`);
          return response.data;
        },
        enabled: isAuthenticated
      },
      {
        queryKey: [`features-tenant`, isAuthenticated],
        queryFn: async () => {
          const response = await instance.get(`/features/${FeatureFlagLabels.Tenant}`);
          return response.data;
        },
        enabled: isAuthenticated
      }
      // {
      //   queryKey: [`features-${profileType.toLowerCase()}`, isAuthenticated, profileType],
      //   queryFn: async () => {
      //     const response = await instance.get(`/features/${FeatureFlagLabels[profileType]}`);
      //     return response.data;
      //   },
      //   enabled: isAuthenticated
      // }
    ]
  });
};
