import styled from 'styled-components/native';
import { flexbox, typography, color, space, position } from 'styled-system';
const TextInput = styled.TextInput `
  ${flexbox}
  ${typography}
  ${color}
  ${space}
  ${position}
`;
export default TextInput;
