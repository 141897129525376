import { navigationRef } from "@src/providers/DeepLinkingService";
import { setPreviousScreenName, store } from "@src/store";
import { transformScreenName } from "@src/utils/textTransformers/transformScreenName";

export const useSetPreviousScreenName = () => {
  // the array is useful for root stacks with forms, that need to navigate back to a screen that still has a back button. i.e., Payment Settings
  const setPreviousScreenNameToStore = (screensNotToChangeFrom: string[]) => {
    const rootState = navigationRef?.current?.getState();
    const routes = rootState?.routes;
    const lastRouteInCurrentStack = routes?.[routes?.length - 1];
    const lastRouteInPreviousStack = routes?.[routes?.length - 2];

    if (lastRouteInCurrentStack?.state?.routes) {
      // user is in a nested tab stack
      if (lastRouteInCurrentStack.state.index != undefined) {
        const _prevScreenName = lastRouteInCurrentStack.state.routes[lastRouteInCurrentStack?.state.index].name;
        if (!screensNotToChangeFrom.includes(_prevScreenName)) {
          store.dispatch(setPreviousScreenName(transformScreenName(_prevScreenName)));
        }
      }
    } else if (lastRouteInCurrentStack) {
      // user has refreshed or in a root stack with no children
      const _prevScreenName = lastRouteInCurrentStack?.name;
      if (!screensNotToChangeFrom.includes(_prevScreenName)) {
        store.dispatch(setPreviousScreenName(transformScreenName(_prevScreenName)));
      }
      // user has navigated from one root stack to another
    } else if (lastRouteInPreviousStack) {
      const _prevScreenName = lastRouteInPreviousStack?.name;
      if (!screensNotToChangeFrom.includes(_prevScreenName)) {
        store.dispatch(setPreviousScreenName(transformScreenName(_prevScreenName)));
      }
    } else {
      // fallback, just in case
      store.dispatch(setPreviousScreenName("Back"));
    }
  };
  return { setPreviousScreenNameToStore };
};
