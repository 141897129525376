import NetInfo from "@react-native-community/netinfo";
import { useEffect, useRef, useState } from "react";
const useIsConnectedValues = () => {
    const [isAppOnline, setIsAppOnline] = useState(false);
    const netInfoUnsubscribe = useRef();
    useEffect(() => {
        netInfoUnsubscribe.current = NetInfo.addEventListener((state) => {
            setIsAppOnline(state.isConnected || false);
        });
        return () => netInfoUnsubscribe.current && netInfoUnsubscribe.current();
    }, []);
    return isAppOnline;
};
export default useIsConnectedValues;
