export default {
    path: {
        d: "M25 21C25 22.5913 24.3679 24.1174 23.2426 25.2426C22.1174 26.3679 20.5913 27 19 27H17V29C17 29.2652 16.8946 29.5196 16.7071 29.7071C16.5196 29.8946 16.2652 30 16 30C15.7348 30 15.4804 29.8946 15.2929 29.7071C15.1054 29.5196 15 29.2652 15 29V27H13C11.4087 27 9.88258 26.3679 8.75736 25.2426C7.63214 24.1174 7 22.5913 7 21C7 20.7348 7.10536 20.4804 7.29289 20.2929C7.48043 20.1054 7.73478 20 8 20C8.26522 20 8.51957 20.1054 8.70711 20.2929C8.89464 20.4804 9 20.7348 9 21C9.0033 22.0599 9.42578 23.0754 10.1752 23.8248C10.9246 24.5742 11.9401 24.9967 13 25H19C20.0609 25 21.0783 24.5786 21.8284 23.8284C22.5786 23.0783 23 22.0609 23 21C23 19.9391 22.5786 18.9217 21.8284 18.1716C21.0783 17.4214 20.0609 17 19 17H13.5C11.9087 17 10.3826 16.3679 9.25736 15.2426C8.13214 14.1174 7.5 12.5913 7.5 11C7.5 9.4087 8.13214 7.88258 9.25736 6.75736C10.3826 5.63214 11.9087 5 13.5 5H15V3C15 2.73478 15.1054 2.48043 15.2929 2.29289C15.4804 2.10536 15.7348 2 16 2C16.2652 2 16.5196 2.10536 16.7071 2.29289C16.8946 2.48043 17 2.73478 17 3V5H18C19.5913 5 21.1174 5.63214 22.2426 6.75736C23.3679 7.88258 24 9.4087 24 11C24 11.2652 23.8946 11.5196 23.7071 11.7071C23.5196 11.8946 23.2652 12 23 12C22.7348 12 22.4804 11.8946 22.2929 11.7071C22.1054 11.5196 22 11.2652 22 11C21.9967 9.94015 21.5742 8.92465 20.8248 8.17521C20.0754 7.42578 19.0599 7.0033 18 7H13.5C12.4391 7 11.4217 7.42143 10.6716 8.17157C9.92143 8.92172 9.5 9.93913 9.5 11C9.5 12.0609 9.92143 13.0783 10.6716 13.8284C11.4217 14.5786 12.4391 15 13.5 15H19C20.5913 15 22.1174 15.6321 23.2426 16.7574C24.3679 17.8826 25 19.4087 25 21Z",
    },
    properties: {
        viewBox: "0 0 32 32",
        outlined: false,
        strokeWidth: "0",
    },
};
