import { IconType } from "@kolmeo/ui-components";
import { FlatList, TouchableOpacity, Text, Box, useTheme } from "@kolmeo/ui-core";

const SelectorMenu = <T,>({
  activeItem,
  onPress,
  options,
  icon,
  activeColor
}: {
  activeItem: T;
  onPress: (item: T, index?: number) => void;
  options: { label: string; value: T }[];
  icon?: IconType;
  activeColor?: string;
}) => {
  const { spacing, palette } = useTheme();
  return (
    <Box mb={spacing.px16}>
      <FlatList
        horizontal
        data={options}
        keyExtractor={(_, index) => index.toString()}
        renderItem={({ item }) => {
          const isActive = activeItem === item.value;
          const color = isActive ? (activeColor ? activeColor : palette.text.primary) : palette.text.secondary;
          return (
            <TouchableOpacity onPress={() => onPress(item.value)} testID="SelectorMenu-test">
              <Box borderBottomWidth={isActive ? 2 : 0} borderBottomColor={color} padding={spacing.px4} marginRight={spacing.px20}>
                {icon}
                <Text color={color} variant="label14">
                  {item.label}
                </Text>
              </Box>
            </TouchableOpacity>
          );
        }}
      />
    </Box>
  );
};

export default SelectorMenu;
