import { useQuery } from "@tanstack/react-query";

import { useAxios } from "@src/lib/http/useAxios";
import { GetPropertyDetails } from "./types";
import { ThemeBrandingObject } from "@src/lib/Kiitos";

export const useGetPropertyDetails = (propertyId: number) => {
  const { instance, isEnabled } = useAxios("OWNER_API");

  return useQuery<GetPropertyDetails>({
    queryKey: ["property-details", propertyId],
    queryFn: async () => {
      const response = await instance.get(`/property/property-overview/${propertyId}`);

      return response.data;
    },
    enabled: isEnabled && !!propertyId
  });
};

export const useGetBrandingObject = (propertyId: number) => {
  const { instance, isEnabled } = useAxios("OWNER_API");

  return useQuery<{ branding: ThemeBrandingObject; propertyEntityId: string }>({
    queryKey: ["branding-object-owner", propertyId],
    queryFn: async () => {
      const response = await instance.get(`/property/property-overview/${propertyId}`);
      const { businessBrand: branding, propertyEntityId } = response.data;
      return { branding, propertyEntityId };
    },
    enabled: isEnabled && !!propertyId
  });
};
