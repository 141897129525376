export const pluralise = (countOrList) => {
    const count = typeof countOrList === "number" ? countOrList : countOrList.length;
    return count === 1 ? "" : "s";
};
export const pluraliseString = (periodType, periodValue) => {
    return " " + periodType + (periodValue > 1 ? "s" : "") + " ";
};
export const areOrHasBeen = (count) => {
    return `${count === 1 ? "has been" : "are"}`;
};
export const captializeFirstCharacter = (text) => {
    text = text.toLowerCase();
    text = text[0].toUpperCase() + text.substring(1);
    return text;
};
export const toTitleCase = (sentence) => {
    if (!sentence.trim()) {
        return "";
    }
    const words = sentence.trim().split(/\s+/);
    const titleCaseWords = words.map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });
    const titleCaseSentence = titleCaseWords.join(" ");
    return titleCaseSentence;
};
