import { PaymentMethod, PaymentMethodCodes } from "@src/types";

export const useDisableFeatures = (isTenancyOccupancyStatus: boolean, paymentMethodCode?: PaymentMethodCodes) => {
  const disableSetDefault = () => {
    return paymentMethodCode !== PaymentMethod.debitNow || !isTenancyOccupancyStatus;
  };
  const hideCTA = () => {
    return paymentMethodCode === PaymentMethod.bpay || paymentMethodCode === PaymentMethod.virtualAccount || !isTenancyOccupancyStatus;
  };
  const disableDeactivation = () => {
    return false;
  };
  const disableAddBankAccounts = () => {
    return paymentMethodCode !== PaymentMethod.debitNow || !isTenancyOccupancyStatus;
  };
  const disableEditNickname = () => {
    return paymentMethodCode === PaymentMethod.bpay || !isTenancyOccupancyStatus;
  };
  const hidePaymentMethodList = () => {
    return (paymentMethodCode === PaymentMethod.bpay || paymentMethodCode === PaymentMethod.virtualAccount) && !isTenancyOccupancyStatus;
  };
  return {
    disableSetDefault: disableSetDefault(),
    disableDeactivation: disableDeactivation(),
    disableAddBankAccounts: disableAddBankAccounts(),
    disableEditNickname: disableEditNickname(),
    hidePaymentMethodList: hidePaymentMethodList(),
    hideCta: hideCTA()
  };
};
