import { jsx as _jsx } from "react/jsx-runtime";
import { Box, Text, coreTheme } from "@kolmeo/ui-core";
const { palette } = coreTheme;
const variantMap = {
    primary: {
        fontVariant: "label16",
        color: palette.textPrimary,
        paddingTop: "15px",
        paddingBottom: "15px",
    },
    primaryActive: {
        fontVariant: "label16Active",
        color: palette.white,
        paddingTop: "15px",
        paddingBottom: "15px",
    },
    secondary: {
        fontVariant: "buttonHeavy14",
        color: palette.yvesKleinBlue600,
        paddingTop: "18px",
        paddingBottom: "18px",
    },
    tertiary: {
        fontVariant: "label16",
        color: palette.white,
        paddingTop: "18px",
        paddingBottom: "18px",
    },
};
const ListItemTitle = ({ title, variant, testID }) => {
    return (_jsx(Box, { testID: testID, flexDirection: "row", paddingTop: variantMap[variant ?? "primary"]?.paddingTop, paddingBottom: variantMap[variant ?? "primary"]?.paddingBottom, children: _jsx(Text, { variant: variantMap[variant ?? "primary"]?.fontVariant, color: variantMap[variant ?? "primary"]?.color, children: title }) }));
};
export default ListItemTitle;
