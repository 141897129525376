export default {
  AuthorityHeader: `By clicking "Add Bank Account" you are proceeding with the use of Kolmeo's Debit Now payment option and populating this detail into the Direct Debit Request.`,
  TermsAndConditionsOne: "You agree to the ",
  TermsAndConditionsLink: "terms and conditions",
  TermsAndConditionsTwo:
    " of the Kolmeo platform and acknowledge and agree to the Direct Debit Request and Direct Debit Request Service Agreement.",
  ParagraphOne: "Debit Now is a customer initiated Direct Debit where you control the amount and timing of funds taken from your account.",
  ParagraphOnePointOne: "In submitting your bank account details here:",
  ParagraphTwo:
    "You confirm that you understand your payment obligations and preferences. They form part of the tenancy agreement for your property and remain in force in accordance with the terms and period described in that agreement.",
  ParagraphThree:
    "If you've chosen Debit Now, you confirm that the bank account details provided are correct, that you are the account holder and able to operate this account as a sole signatory.",
  ParagraphFour:
    "You understand that by proceeding, you have agreed that Debit Now is not a scheduled payment, payments must be authorised by you via the platform. After you initiate a payment, you will need to have sufficient funds in your account in order for the payment to clear.",
  ParagraphFive: "The Debit Now payment option does not enable your property manager to debit your account.",
  ViewDebitAuthority: "Click here to view the Direct Debit Request Service Agreement and Direct Debit Request"
};
