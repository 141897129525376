import { useFocusEffect } from "@react-navigation/core";
import { NativeStackScreenProps } from "@react-navigation/native-stack";

import { ButtonV2, Tooltip } from "@kolmeo/ui-components";
import { Box, Text, useTheme } from "@kolmeo/ui-core";

import { Seperator, TooltipBranded } from "@src/components";
import { PageContainer, ReactQueryContainer } from "@src/containers";
import { useCurrentUser, useMediaQuery } from "@src/hooks";
import { useSetPreviousScreenName } from "@src/hooks/useSetPreviousScreenName/useSetPreviousScreenName";
import { PaymentSettingsStackParamsList } from "@src/navigation/PaymentSettingsNavigator/PaymentSettingsNavigator";
import { useBranding } from "@src/navigation/useBranding";
import { TenancyPaymentDetails } from "@src/navigation/TenancyNavigator/types";
import { useCustomNavigation } from "@src/navigation/hooks/useCustomNavigation";
import { useGlobalTenant } from "@src/navigation/hooks/useGlobalTenant";
import { usePreviousScreenName } from "@src/store";
import { PaymentMethod, OccupancyStatus } from "@src/types";
import { BankAccountDetailsResponse, DeactivateBankAccountData } from "@src/types/PaymentPreferences";

import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import ActionRequiredModal from "../../../components/Modal/variants/ActionRequiredModal";
import ConfirmationModal from "../../../components/Modal/variants/AlertModal";
import { useGetAllTenancies, useGetTenancyDetails } from "../TenancyOverview/api/useTenancyOverview";
import { useDisableFeatures } from "../hooks/useDisableFeatures";
import { usePaymentMethodLabel } from "../hooks/usePaymentMethodLabel";
import { useDeactivateBankAccountMutation, useGetAllBankAccounts } from "../hooks/usePaymentPreferences";
import { useTenancyOccupancyStatus } from "../hooks/useTenancyOccupancyStatus";
import BankDetailsList from "./components/Lists/BankDetailsList";
import BPayDetailsList from "./components/Lists/BPayDetailsList";
import VirtualAccountDetailsList from "./components/Lists/VirtualAccountDetailsList";
import PaymentMethodModal from "./components/Modals/PaymentMethodModal/PaymentMethodModal";

const PaymentDetails = ({ navigation }: NativeStackScreenProps<PaymentSettingsStackParamsList, "Details">) => {
  const { t } = useTranslation(["Common", "Tenant"]);
  const { goBack } = useCustomNavigation();
  const { tenancyId, getSelectedTenancy, handleSetTenancyData, redirectId, handleClearRedirectId } = useGlobalTenant();
  const { brandingObject } = useBranding();
  const { data, isError, isLoading, error, refetch } = useGetAllBankAccounts(tenancyId);
  const { data: tenancyData } = useGetTenancyDetails(tenancyId);
  const { mutateAsync: deactivateBankAccountAsync } = useDeactivateBankAccountMutation();
  const isTenancyOccupancyStatus = useTenancyOccupancyStatus(tenancyData?.occupancyStatus);

  const { disableDeactivation, disableSetDefault, disableAddBankAccounts, disableEditNickname, hideCta, hidePaymentMethodList } =
    useDisableFeatures(isTenancyOccupancyStatus, tenancyData?.paymentMethodCode);

  const { isBelowMobile, isBelowTablet } = useMediaQuery();
  const handleBackLink = () => goBack();
  const { palette, spacing } = useTheme();
  // modal controls
  const [showPaymentMethodDetails, setShowPaymentMethodDetails] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [showCompletedModal, setShowCompletedModal] = useState(false);
  const [showCannotRemoveModalDueToRestriction, setShowCannotRemoveModalDueToRestriction] = useState(false);
  const [showCannotRemoveDueToDefaultModal, setShowCannotRemoveDueToDefaultModal] = useState(false);

  const [selectedDetails, setSelectedDetails] = useState<BankAccountDetailsResponse>();
  const [selectedPaymentDetails, setSelectedPaymentDetails] = useState<TenancyPaymentDetails>();
  const getAllTenancyRequests = useGetAllTenancies();
  const { currentUser } = useCurrentUser();

  const previousScreenName = usePreviousScreenName();
  const { setPreviousScreenNameToStore } = useSetPreviousScreenName();

  useEffect(() => {
    setPreviousScreenNameToStore(["PaymentDetails", "AddPaymentMethod", "Details"]);
  }, []);

  useFocusEffect(() => {
    // if the user has been redirected to this page from the add payment methods screen, open the modal with the new payment method data.
    const openAddedPaymentMethod = () => {
      if (redirectId !== undefined) {
        refetch().then((res) => {
          const newPaymentDetails = res.data?.find((pd) => pd.accountId == redirectId);
          setShowPaymentMethodDetails(true);
          setSelectedDetails(newPaymentDetails);
          handleClearRedirectId();
        });
      }
    };
    openAddedPaymentMethod();
  });

  useEffect(() => {
    if (tenancyId) navigation?.setParams({ tenancyId });
  }, [tenancyId]);

  useEffect(() => {
    if (selectedDetails || selectedPaymentDetails) {
      setShowPaymentMethodDetails(true);
    }
  }, [selectedDetails, selectedPaymentDetails]);

  useEffect(() => {
    if (getAllTenancyRequests.data) {
      handleSetTenancyData(
        getAllTenancyRequests.data.map((tenancy) => {
          return { tenancyId: tenancy.tenancyId, propertyName: tenancy.propertyName, status: tenancy.status };
        })
      );
    }
  }, [getAllTenancyRequests.data]);

  const paymentMethodLabel = usePaymentMethodLabel(tenancyData?.paymentMethodCode);

  const onPaymentMethodPress = (item: BankAccountDetailsResponse) => {
    setSelectedDetails(item);
  };
  const onBpayMethodPress = (item: TenancyPaymentDetails) => {
    setSelectedPaymentDetails(item);
  };

  const onRemoveAccountPress = () => {
    if (disableRemoveAccountConfirmation()) {
      if (tenancyData?.paymentMethodCode === PaymentMethod.directDebit && selectedDetails?.isDefault) {
        setShowCannotRemoveModalDueToRestriction(true);
      } else {
        setShowCannotRemoveDueToDefaultModal(true);
      }
    } else {
      setShowConfirmDelete(true);
    }
  };

  const onConfirmDeletePress = () => {
    setShowConfirmDelete(false);
    // call api, then
    if (selectedDetails?.accountId && tenancyId) {
      const form: DeactivateBankAccountData = {
        accountId: selectedDetails?.accountId,
        tenancyId: tenancyId,
        isDefault: selectedDetails.isDefault
      };
      deactivateBankAccountAsync(form).then((result) => {
        if (result.isSuccessful == true) {
          refetch().finally(() => {
            setShowCompletedModal(true);
            handleTogglePaymentDetailsModal(false);
          });
        } else {
          setShowCannotRemoveModalDueToRestriction(true);
        }
      });
    } else {
      handleTogglePaymentDetailsModal(false);
    }
  };

  const handleTogglePaymentDetailsModal = (toggleShow: boolean) => {
    setShowPaymentMethodDetails(toggleShow);
    setSelectedDetails(undefined);
    setSelectedPaymentDetails(undefined);
  };

  const disableRemoveAccountConfirmation = () => {
    return data?.length == 1 || (tenancyData?.paymentMethodCode === PaymentMethod.directDebit && selectedDetails?.isDefault);
  };

  const renderEmptyStateTitle = () => {
    if (tenancyData?.paymentMethodCode == undefined) {
      return t("Tenant:PaymentMethods.NoPaymentMethodTitle");
    }
    if (tenancyData?.paymentMethodCode == PaymentMethod.bpay) {
      return t("Tenant:PaymentMethods.BPAYPaymentMethodEmpty");
    } else {
      return t("Tenant:PaymentMethods.PaymentDetailsEmpty");
    }
  };
  const renderEmptyStateContent = () => {
    if (tenancyData?.paymentMethodCode == undefined) {
      return t("Tenant:PaymentMethods.NoPaymentMethodDescription");
    } else if (tenancyData.paymentMethodCode == PaymentMethod.bpay) {
      return t("Tenant:PaymentMethods.BPAYPaymentMethodEmptyDescription");
    }
  };

  const renderEmptyState = () => {
    switch (tenancyData?.paymentMethodCode) {
      case PaymentMethod.directDebit || PaymentMethod.debitNow:
        return !data || data.length == 0;
      case PaymentMethod.bpay || PaymentMethod.virtualAccount:
        return tenancyData.tenancyPaymentDetails == null;
      case null:
        return true;
      default: {
        return false;
      }
    }
  };

  const formatAccountName = (name: string | undefined): string | undefined => {
    if (name) {
      name = name.replace(/[^a-zA-Z0-9 ]/g, "");
      name = "Rent " + name;
      return name.length > 20 ? name.substring(0, 20) : name;
    } else return undefined;
  };

  return (
    <PageContainer
      isScrollable={true}
      brandingObject={brandingObject}
      headingText={t("PaymentMethods.PaymentSettingsHeader", { ns: "Tenant" })}
      navigateBack={handleBackLink}
      iconType={"cash-multiple"}
      navigateBackLabel={previousScreenName ?? "Back"}
    >
      <>
        <Box>
          <Text lineHeight={20} fontWeight={600}>
            {getSelectedTenancy?.propertyName}
          </Text>
          <Box flexDirection={"row"} alignItems={"center"}>
            <Text lineHeight={20}>
              {t("Tenant:PaymentMethods.RentPaymentMethod")}: <Text fontWeight={600}>{paymentMethodLabel}</Text>
            </Text>
            {getSelectedTenancy?.status !== OccupancyStatus.inactive && (
              <Box style={{ marginLeft: 4, alignItems: "center" }}>
                <TooltipBranded content={t("Tenant:PaymentMethods.ChangePaymentMethodMessage")} position={"bottom"} />
              </Box>
            )}
          </Box>
        </Box>
        <Seperator margin={spacing.px20} />
      </>
      <ReactQueryContainer
        isLoading={isLoading}
        isError={isError}
        error={error}
        showEmptyState={renderEmptyState()}
        emptyStateContent={{
          size: "md",
          title: renderEmptyStateTitle(),
          content: renderEmptyStateContent(),
          textColor: brandingObject?.primaryColor,
          setMaxHeight: true,
          callToAction: !disableAddBankAccounts ? (
            <ButtonV2
              testID="button-add-bankaccount-emptystate"
              fullwidth={isBelowMobile}
              variant="primary"
              overrideVariant={{ bgColor: brandingObject?.primaryColor }}
              iconType="plus"
              label={t("Tenant:PaymentMethods.CTAAddNew")}
              onPress={() => navigation?.navigate("AddPaymentMethod")}
            />
          ) : undefined
        }}
      >
        {!hidePaymentMethodList ? (
          (() => {
            switch (tenancyData?.paymentMethodCode) {
              case PaymentMethod.directDebit:
              case PaymentMethod.debitNow:
                return (
                  <BankDetailsList
                    data={data}
                    isBelowMobile={isBelowMobile}
                    disableAddBankAccounts={disableAddBankAccounts}
                    onListCardPress={onPaymentMethodPress}
                    onAddPaymentPress={() => navigation?.navigate("AddPaymentMethod")}
                  />
                );
              case PaymentMethod.bpay:
                return (
                  <BPayDetailsList
                    isBelowMobile={isBelowMobile}
                    onListCardPress={onBpayMethodPress}
                    data={tenancyData?.tenancyPaymentDetails && [tenancyData?.tenancyPaymentDetails]}
                  />
                );
              case PaymentMethod.virtualAccount:
                return (
                  <VirtualAccountDetailsList
                    onListCardPress={onBpayMethodPress}
                    isBelowMobile={isBelowMobile}
                    data={tenancyData?.tenancyPaymentDetails && [tenancyData?.tenancyPaymentDetails]}
                    propertyName={formatAccountName(getSelectedTenancy?.propertyName)}
                  />
                );
              default:
                return <></>;
            }
          })()
        ) : (
          <></>
        )}
      </ReactQueryContainer>
      {/* Payment Method Modal */}
      <PaymentMethodModal
        hideCTA={hideCta}
        isVisible={showPaymentMethodDetails}
        toggleVisibility={(toggleShow) => handleTogglePaymentDetailsModal(toggleShow)}
        item={selectedDetails}
        detailsItem={selectedPaymentDetails}
        paymentMethod={tenancyData?.paymentMethodCode}
        propertyName={formatAccountName(getSelectedTenancy?.propertyName)}
        handleRefetch={() =>
          refetch().then((res) => {
            const updatedSelected = res?.data?.find((r) => selectedDetails?.accountId == r.accountId);
            setSelectedDetails(updatedSelected);
          })
        }
        isReloading={isLoading}
        onDeleteAccountPress={onRemoveAccountPress}
        disableDeactivation={disableDeactivation}
        disableSetDefault={disableSetDefault}
        disableEditNickname={disableEditNickname}
      />
      {/* confirmation */}
      <ActionRequiredModal
        heading={t("Common:Generic.IrreversibleAction")}
        iconType={"danger"}
        actionType={"danger"}
        content={t("Tenant:PaymentMethods.DeleteConfirmation")}
        isVisible={showConfirmDelete}
        toggleVisibility={() => setShowConfirmDelete(false)}
        rightText={t("Common:Generic.Remove")}
        onRightButtonPress={onConfirmDeletePress}
      />
      {/* Completed */}
      <ConfirmationModal
        heading={t("Common:Generic.Thanks")}
        iconType={"success"}
        content={t("Tenant:PaymentMethods.DeleteConfirmationText")}
        isVisible={showCompletedModal}
        toggleVisibility={() => {
          refetch();
          setShowCompletedModal(false);
          handleTogglePaymentDetailsModal(false);
        }}
        brandingObject={brandingObject}
      />
      {/* Cannot remove account - display if api check returns false after clicking the remove account button*/}
      <ConfirmationModal
        heading={t("Tenant:PaymentMethods.HeadingCannotRemoveAccount")}
        iconType={"danger"}
        isVisible={showCannotRemoveModalDueToRestriction}
        content={t("Tenant:PaymentMethods.CannotRemoveAccountDescription")}
        toggleVisibility={() => {
          setShowCannotRemoveModalDueToRestriction(false);
        }}
        brandingObject={brandingObject}
      />
      {/* Cannot remove account due to default - display this when a user needs to add an additional account*/}
      <ConfirmationModal
        heading={t("Tenant:PaymentMethods.HeadingCannotRemoveAccount")}
        iconType={"danger"}
        isVisible={showCannotRemoveDueToDefaultModal}
        content={t("Tenant:PaymentMethods.AddAnotherBankAccount")}
        toggleVisibility={() => {
          setShowCannotRemoveDueToDefaultModal(false);
        }}
        brandingObject={brandingObject}
      />
    </PageContainer>
  );
};

export default PaymentDetails;
