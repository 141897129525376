import { Box, Text, useTheme } from "@kolmeo/ui-core";
import { Card } from "@kolmeo/ui-components";
import AvatarIcon from "./avatar.svg";
import ArrowIcon from "./arrow-right.svg";

interface UserProfileTypeProps {
  title: string;
  onPress?: () => void;
}

const UserProfileType = ({ title, onPress }: UserProfileTypeProps): JSX.Element => {
  const { spacing } = useTheme();

  return (
    <Card forceRemoveShadow width={"100%"} padding={0} onPress={onPress}>
      <Box flexDirection="row">
        <Box alignItems={"flex-end"} justifyContent={"flex-start"}>
          <AvatarIcon />
        </Box>
        <Box alignItems={"flex-start"} flex={1} justifyContent={"flex-start"} ml={spacing.px16}>
          <Text variant="headingH2" style={{ lineHeight: 20 }}>
            {title}
          </Text>
        </Box>
        <Box alignItems={"flex-end"} justifyContent={"flex-start"} top={spacing.px4} ml={spacing.px24}>
          <ArrowIcon />
        </Box>
      </Box>
    </Card>
  );
};

export default UserProfileType;
