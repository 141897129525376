import { MessageBar } from "@kolmeo/ui-components";
import EditPaymentMethodCard from "../../Cards/EditPaymentMethodCard";
import BPayMethodContentCard from "../../Cards/BpayMethodContentCard";
import { Box, useTheme } from "@kolmeo/ui-core";
import { BankAccountDetailsResponse } from "@src/types/PaymentPreferences";
import { TenancyPaymentDetails } from "@src/navigation/TenancyNavigator/types";
import { useTranslation } from "react-i18next";
import { PaymentMethod, PaymentMethodCodes } from "@src/types";
import VirtualAccountMethodContentCard from "../../Cards/VirtualAccountMethodContentCard";

export type PaymentMethodContainerType = {
  item?: BankAccountDetailsResponse;
  detailsItem?: TenancyPaymentDetails;
  paymentMethod?: PaymentMethodCodes;
  propertyName?: string;
  handleRefetch: () => void;
  handleDownloadDocument: () => void;
  disableEditNickname: boolean;
  showMessageBar: boolean;
  isSuccess: boolean;
  isReloading: boolean;
};
const PaymentMethodContainer = ({
  item,
  detailsItem,
  paymentMethod,
  propertyName,
  handleRefetch,
  handleDownloadDocument,
  disableEditNickname,
  showMessageBar,
  isSuccess,
  isReloading
}: PaymentMethodContainerType) => {
  const { spacing } = useTheme();
  const { t } = useTranslation(["Common", "Tenant"]);

  return (
    <Box paddingX={spacing.px20}>
      {!detailsItem && (
        <>
          <EditPaymentMethodCard
            item={item}
            disableEditNickname={disableEditNickname}
            disableAgreementButton={!item?.debitAuthorityDocumentDetails}
            onAgreementPress={() => handleDownloadDocument()}
            refreshModal={handleRefetch}
          />
          {showMessageBar && isSuccess && (
            <MessageBar marginTop={4} type={"success"} details={t("Tenant:PaymentMethods.MessagebarDefaultAccount")} />
          )}
          {!item?.debitAuthorityDocumentDetails && (
            <MessageBar
              marginTop={4}
              type={"warning"}
              details={t("Tenant:PaymentMethods.DebitAuthorityLoading")}
              actionInfo={{
                onPress: () => handleRefetch(),
                title: "Refresh",
                customButtonProps: {
                  variant: "textButton",
                  size: "small",
                  isLoading: isReloading,
                  disabled: isReloading
                }
              }}
            />
          )}
        </>
      )}
      {detailsItem &&
        (paymentMethod === PaymentMethod.virtualAccount ? (
          <VirtualAccountMethodContentCard item={detailsItem} propertyName={propertyName} />
        ) : (
          <BPayMethodContentCard item={detailsItem} />
        ))}
    </Box>
  );
};

export default PaymentMethodContainer;
