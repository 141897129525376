export default {
    path: {
        d: "M128,24A104,104,0,1,0,232,128,104.2,104.2,0,0,0,128,24Zm40,112H88a8,8,0,0,1,0-16h80a8,8,0,0,1,0,16Z",
    },
    properties: {
        viewBox: "0 0 256 256",
        outlined: false,
        strokeWidth: "0",
    },
};
