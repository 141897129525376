import palette from "./palette";
import sizing from "./sizing";
import shadow from "./shadow";
import borderRadius from "./borderRadius";
import spacing from "./spacing";
const coreThemeV2 = {
    palette,
    sizing,
    shadow,
    borderRadius,
    spacing,
};
export default coreThemeV2;
