import { Tooltip, TooltipProps } from "@kolmeo/ui-components";
import { useTheme } from "@kolmeo/ui-core";

type TooltipBrandedProps = {
  iconColor?: string;
} & Omit<TooltipProps, "iconColor">;
const TooltipBranded = ({ ...rest }: TooltipBrandedProps) => {
  const { palette } = useTheme();
  return <Tooltip iconColor={palette.text.primary} {...rest} />;
};
export default TooltipBranded;
