import Forms from "./common/Forms";
import Generic from "./common/Generic";
import DebitNow from "./common/DebitNow";
import propertyScreens from "./owner/propertyScreens";
import Overview from "./tenant/Overview";
import PaymentMethods from "./tenant/PaymentMethods";
import Requests from "./tenant/Requests";
import RentAuthority from "./tenant/RentAuthority";
import TAC from "./tenant/TAC";
import Payments from "./tenant/Payments";
import Welcome from "./common/Welcome";
import Lease from "./owner/Lease";
import LeaseTile from "./common/LeaseTile";
import MyProperties from "./owner/MyProperties";
import Statements from "./owner/Statements";
import NavigationLabels from "./common/NavigationLabels";

export const en = {
  Common: {
    Logout: "Log Out",
    Tagline: "Powered by Kolmeo",
    Created: "Created",
    Cancel: "Cancel",
    Due: "Due",
    LastModified: "Last Modified",
    PaymentDetails: "Payment Details",
    Period: "Period",
    Next: "Next",
    Now: "Now",
    ViewDetailsHere: "View details here",
    ThankYouTitle: "Thank you!",
    OKActionLabel: "OK",
    EditAmount: "Edit Amount",
    Immediately: "Immediately",
    Forms,
    Payments: DebitNow,
    Generic,
    Welcome,
    LeaseTile,
    NavigationLabels
  },
  landingPage: {
    title: "For tenants and owners"
  },
  Tenant: {
    Requests,
    Overview,
    PaymentMethods,
    RentAuthority: RentAuthority,
    TAC,
    Payments
  },
  Owner: {
    Lease,
    MyProperties,
    Statements
  },
  propertyScreens
};
