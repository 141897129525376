import { RentFrequencyCodes } from "@src/types";

const useRentFrequency = (rentAmount: number, frequencyCode: RentFrequencyCodes): string => {
  const prefix: { [key in RentFrequencyCodes]: string } = {
    1002002: "p/f",
    1002004: "p/m",
    1002009: "p/w",
    1002006: "Pro Rata"
  };
  if (rentAmount && frequencyCode) {
    return `$${rentAmount.toFixed(2)} ${prefix[frequencyCode]}`;
  } else if (frequencyCode) {
    return `$0 ${prefix[frequencyCode]}`;
  } else {
    return `$0`;
  }
};

export default useRentFrequency;

// this is a util function with the same logic as above hook.
// consider changing the hook to util function so it can be called from anywhere.
export const getRentAmountFrequency = (rentAmount: number, frequencyCode: RentFrequencyCodes): string => {
  const prefix: { [key in RentFrequencyCodes]: string } = {
    1002002: "p/f",
    1002004: "p/m",
    1002009: "p/w",
    1002006: "Pro Rata"
  };
  if (rentAmount && frequencyCode) {
    return `$${rentAmount.toFixed(2)} ${prefix[frequencyCode]}`;
  } else if (frequencyCode) {
    return `$0 ${prefix[frequencyCode]}`;
  } else {
    return `$0`;
  }
};
