import { Box, Pressable, TextInput, useTheme, Text } from "@kolmeo/ui-core";
import { ButtonV2, Card, Icon, FieldError } from "@kolmeo/ui-components";
import { BankAccountDetailsResponse, UpdatePaymentAccountNicknameData } from "@src/types/PaymentPreferences";
import { useTranslation } from "react-i18next";
import { StatusPill } from "@src/components";
import RowItem from "../RowItem";
import { sanitiseAccountNumber } from "@src/utils/textTransformers/sanitiseText";
import { useState } from "react";
import { MAX_NICKNAME } from "@src/types/constants/PaymentMethods";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Controller } from "react-hook-form";
import { useGlobalTenant } from "@src/navigation/hooks/useGlobalTenant";
import { useUpdateBankAccountNicknameMutation } from "../../../../hooks/usePaymentPreferences";

type EditPaymentMethodCardType = {
  item?: BankAccountDetailsResponse;
  disableEditNickname: boolean;
  disableAgreementButton: boolean;
  onAgreementPress: () => void;
  refreshModal: () => void;
};
const EditPaymentMethodCard = ({
  item,
  disableEditNickname,
  onAgreementPress,
  refreshModal,
  disableAgreementButton
}: EditPaymentMethodCardType) => {
  const DEFAULT_NAME = "Bank Account";
  const { palette, colors } = useTheme();
  const { tenancyId } = useGlobalTenant();
  const { t } = useTranslation(["Common", "Tenant"]);
  const [editMode, setEditMode] = useState(false);
  const { mutateAsync } = useUpdateBankAccountNicknameMutation();

  const editNicknameSchema = yup.object().shape({
    nickname: yup
      .string()
      .max(
        MAX_NICKNAME,
        t("Common:Forms.ErrorMaxCharacters", { fieldName: t("Tenant:PaymentMethods.AccountNickname"), maxCharacters: MAX_NICKNAME })
      )
  });
  const {
    control,
    getValues,
    setValue,
    handleSubmit,
    formState: { errors }
  } = useForm<UpdatePaymentAccountNicknameData>({
    defaultValues: {
      accountId: item?.accountId ? item.accountId : 0,
      tenancyId: tenancyId,
      nickname: item?.nickname
    },
    mode: "onChange",
    resolver: yupResolver(editNicknameSchema)
  });

  const onSubmit = async (formData: UpdatePaymentAccountNicknameData) => {
    const { nickname } = getValues();
    try {
      if (nickname == "") {
        formData.nickname = DEFAULT_NAME;
        // set the form data to DEFAULT_NAME;
      }
      if (nickname != item?.nickname) {
        await mutateAsync(formData, {
          onSuccess: () => {
            refreshModal();
            setValue("nickname", formData.nickname);
          },
          onError: () => console.log("error")
        });
      }
      setEditMode(false);
    } catch {
      console.error("An error has occurred and the nickname has not been updated.");
    }
  };
  const onError = (err: any) => {
    const { nickname } = getValues();
    // if nickname has not changed, then simply close the field without updating
    if (item?.nickname == nickname) {
      setEditMode(false);
    }
    console.log(err);
  };
  return (
    <Card
      flexDirection={"row"}
      marginBottom={1}
      marginHorizontal={1}
      renderShadow
      flex={1}
      borderColor={colors.primary}
      borderWidth={0.4}
      position={"relative"}
    >
      {/* <Box mb="auto" backgroundColor={"green"} width={"100%"}> */}
      <Box flexDirection={"row"} width={"100%"} flexWrap={"wrap"} paddingBottom={3}>
        <Box flexDirection={"column"} flex={2}>
          <RowItem alignment="right" label={<Icon type={"bank"} size={"lg"} color={palette.text.secondary} />} />
          {editMode ? (
            <Controller
              control={control}
              name="nickname"
              render={({ field: { onChange, value } }) => (
                <Box flexDirection={"column"}>
                  <Box
                    borderRadius={8}
                    borderColor={errors["nickname"]?.message && palette.danger.border}
                    borderWidth={2}
                    height={20}
                    position={"relative"}
                  >
                    <TextInput
                      paddingLeft={1}
                      paddingRight={50}
                      autoFocus={true}
                      value={value}
                      onChangeText={onChange}
                      onBlur={handleSubmit(onSubmit, onError)}
                      // maxLength={MAX_NICKNAME}
                    />
                    <Box right={1} top={0} position={"absolute"}>
                      <Text variant="textMedium10" color={palette.text.primary} textAlign={"right"} style={{ paddingRight: 2 }}>
                        {value?.length ?? 0} / {MAX_NICKNAME}
                      </Text>
                    </Box>
                  </Box>
                  <Box>{errors["nickname"]?.message && <FieldError message={errors["nickname"].message} />}</Box>
                </Box>
              )}
            />
          ) : (
            <Box flexDirection={"row"} alignItems={"center"}>
              <RowItem italic bold label={item?.nickname ? item?.nickname : t("Tenant:PaymentMethods.BankAccount")} />
              <Box marginLeft={2}>
                {!disableEditNickname && (
                  <Pressable testID="button-nickname-edit" onPress={() => setEditMode(true)}>
                    <Icon type={"pencil"} size={"micro"} />
                  </Pressable>
                )}
              </Box>
            </Box>
          )}
          <RowItem label={item?.accountName} />
          {item?.accountNumber ? (
            <RowItem
              label={t("Tenant:PaymentMethods.BankAccountEndingIn", { bankAccountNumber: sanitiseAccountNumber(item?.accountNumber) })}
            />
          ) : (
            <></>
          )}
          <RowItem
            label={
              <ButtonV2
                disabled={disableAgreementButton}
                variant="textButton"
                onPress={onAgreementPress}
                label={t("Tenant:PaymentMethods.ViewAuthority")}
              />
            }
          />
        </Box>
        <Box position={"absolute"} bottom={2} right={1}>
          {item?.isDefault && <StatusPill variant="warning" label={"Default"} capitalise={true}></StatusPill>}
        </Box>
      </Box>
    </Card>
  );
};

export default EditPaymentMethodCard;
