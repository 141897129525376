import {
  NavigationAction,
  NavigationContainerRef,
  NavigationState,
  PartialState,
  getActionFromState,
  getPathFromState,
  getStateFromPath
} from "@react-navigation/native";
import { linking } from "@src/navigation/linking";
import { createRef } from "react";

export const navigationRef = createRef<NavigationContainerRef<any>>();

export const checkDeepLinkResult = (url: string) => {
  const extractedUrl = new URL(url).pathname;
  const permitDeepLinkInit = !url.includes("logout") && !url.includes("auth-callback") && url.includes("requests");

  const linkState = getStateFromPath(extractedUrl, linking.config as any) as PartialState<NavigationState>;

  const linkPath = extractedUrl;

  const action = linkState ? (getActionFromState(linkState) as NavigationAction) : null;
  return {
    action,
    linkPath,
    permitDeepLinkInit
  };
};
