import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { TouchableOpacity, coreTheme, Text } from "@kolmeo/ui-core";
import Icon from "../Icon";
const SwipeButton = ({ iconType, buttonText, onPress, disabled, variant, ...props }) => {
    const { palette } = coreTheme;
    const typeColorCombination = {
        primary: {
            buttonBGColor: palette.persianBlueDarken15,
            iconColor: palette.white,
            color: palette.white,
        },
        danger: {
            buttonBGColor: palette.destructiveBackground,
            iconColor: palette.white,
            color: palette.white,
        },
    };
    return (_jsxs(TouchableOpacity, { ...props, justifyContent: "center", alignContent: "center", alignItems: "center", opacity: 1, width: "56px", bg: typeColorCombination[variant ? variant : "primary"]?.buttonBGColor, height: "100%", onPress: onPress, children: [_jsx(Icon, { type: iconType, size: "sm", color: typeColorCombination[variant ? variant : "primary"]?.iconColor }), _jsx(Text, { padding: 0, margin: 0, variant: "label10", textAlign: "center", color: typeColorCombination[variant ? variant : "primary"]?.color, children: buttonText })] }));
};
export default SwipeButton;
