export const borderRadius = {
    radius4: 4,
    radius8: 8,
    radius12: 12,
    radius16: 16,
    radius20: 20,
    radius24: 24,
    radius32: 32,
    radius36: 36,
    radius40: 40,
    radius64: 64,
    radius80: 80,
};
export default borderRadius;
