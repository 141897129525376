import { Text, Box, Image, useTheme } from "@kolmeo/ui-core";
import { PillItem } from "@kolmeo/ui-components";
import { PropertyStatus } from "@src/types";
import { PropertySelectCardProps } from "../PropertySelectCard";
import { ImageSourcePropType } from "react-native";
import { shortenTextWithEllipsis } from "@src/utils/textTransformers/shortenTextWithEllipsis";

const PropertySelectCardMobile = ({ details }: PropertySelectCardProps) => {
  const { propertyName, officeName, propertyLogoUrl, propertyStatus } = details;

  const { spacing, statusVariants, palette } = useTheme();

  return (
    <Box display={"flex"} flexDirection={"row"} maxHeight={150} position={"relative"}>
      <Box flex={1} overflow="hidden">
        <Image
          style={{ zIndex: 99, flex: 1, width: undefined, minHeight: 140, overflow: "hidden", backgroundColor: "#D9D9D9" }}
          resizeMode={propertyLogoUrl ? "cover" : "contain"}
          accessibilityLabel={propertyLogoUrl ? "property image" : "property image fallback"}
          source={propertyLogoUrl || require("../../assets/house_outline.png")}
        />
      </Box>
      <Box flex={2} paddingLeft={3} overflow={"hidden"} padding={spacing.px16} justifyContent={"space-between"}>
        <Box mb={10}>
          <Text fontWeight={600} variant="textHeavy14" numberOfLines={2}>
            {propertyName}
          </Text>
        </Box>
        <Box>
          <Box flexDirection="row" justifyContent="space-between" alignItems="flex-start">
            <Text variant="paragraph12" color={palette.text.error}>
              {officeName}
            </Text>
          </Box>
          <Box alignItems={"flex-start"}>
            {propertyStatus === PropertyStatus.inactive && (
              <PillItem
                text="Inactive"
                textProps={{ color: palette.text.secondary }}
                borderColor={palette.text.secondary}
                borderWidth={1}
                backgroundColor={statusVariants.default.backgroundColor}
                paddingY={spacing.px4}
                paddingX={spacing.px8}
                marginTop={spacing.px4}
              />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PropertySelectCardMobile;
