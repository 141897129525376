import { Box, FlatList, Pressable, useTheme, Text } from "@kolmeo/ui-core";
import StepBadge from "./components/StepBadge";

const FormStepSelector = <T,>({
  options,
  onTabPress,
  activeItem,
  activeColor
}: {
  onTabPress: (item: T) => void;
  options: { label: string; value: T; isComplete: boolean; isDisabled: boolean }[];
  activeItem: T;
  activeColor?: string;
}) => {
  const { spacing, palette } = useTheme();

  return (
    <Box mb={spacing.px16}>
      <FlatList
        horizontal
        data={options}
        keyExtractor={(_, index) => index.toString()}
        ItemSeparatorComponent={() => <Box width={12}></Box>}
        renderItem={({ item, index }) => {
          const isActive = activeItem === item.value;
          const color = item.isComplete
            ? palette.feedback.success.text
            : isActive
            ? activeColor
              ? activeColor
              : palette.text.primary
            : palette.text.secondary;
          return (
            <Pressable onPress={() => onTabPress(item.value)} disabled={item.isDisabled}>
              <Box borderBottomWidth={isActive ? 2 : 0} borderBottomColor={color} padding={spacing.px4} flexDirection={"row"}>
                <StepBadge
                  isComplete={item.isComplete}
                  isActive={isActive}
                  activeBgColor={item.isComplete ? palette.feedback.success.icon : activeColor ?? palette.text.primary}
                  inactiveBgColor={item.isComplete ? palette.feedback.success.icon : palette.text.secondary}
                  stepNumber={index + 1}
                  activeTextColor={item.isComplete || isActive ? palette.text.primaryInvert : palette.text.primaryInvert}
                  inactiveTextColor={item.isComplete ? palette.feedback.success.text : palette.text.secondary}
                  completedIconColor={palette.feedback.success.icon}
                />
                <Text color={color} variant="label14">
                  {item.label}
                </Text>
              </Box>
            </Pressable>
          );
        }}
      />
    </Box>
  );
};

export default FormStepSelector;
