import { jsx as _jsx } from "react/jsx-runtime";
import { View, StyleSheet } from "react-native";
import { kolmeoShapesLoader } from "../../utils/assetGetters/animationAssets";
import { LottieAnimation } from "../LottieAnimation";
const Loading = () => {
    return (_jsx(View, { style: styles.container, children: _jsx(View, { style: styles.maxSizeContainer, children: _jsx(LottieAnimation, { source: kolmeoShapesLoader }) }) }));
};
const styles = StyleSheet.create({
    container: {
        flex: 1,
        justifyContent: "center",
        alignItems: "center",
        padding: 10,
    },
    maxSizeContainer: {
        flex: 1,
        width: 50,
        height: 50,
    },
});
export default Loading;
