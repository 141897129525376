export default {
    path: {
        d: "M15 4.90539H14.325L12.5875 1.10331C12.5097 0.931013 12.3819 0.784563 12.2198 0.68191C12.0577 0.579257 11.8684 0.524863 11.675 0.525394H4.325C4.13163 0.524863 3.94229 0.579257 3.78021 0.68191C3.61813 0.784563 3.49034 0.931013 3.4125 1.10331L1.675 4.90539H1C0.867392 4.90539 0.740215 4.95667 0.646447 5.04794C0.552678 5.1392 0.5 5.26299 0.5 5.39206C0.5 5.52113 0.552678 5.64492 0.646447 5.73619C0.740215 5.82745 0.867392 5.87873 1 5.87873H1.5V10.7454C1.5 11.0035 1.60536 11.2511 1.79289 11.4336C1.98043 11.6162 2.23478 11.7187 2.5 11.7187H4C4.26522 11.7187 4.51957 11.6162 4.70711 11.4336C4.89464 11.2511 5 11.0035 5 10.7454V9.77206H11V10.7454C11 11.0035 11.1054 11.2511 11.2929 11.4336C11.4804 11.6162 11.7348 11.7187 12 11.7187H13.5C13.7652 11.7187 14.0196 11.6162 14.2071 11.4336C14.3946 11.2511 14.5 11.0035 14.5 10.7454V5.87873H15C15.1326 5.87873 15.2598 5.82745 15.3536 5.73619C15.4473 5.64492 15.5 5.52113 15.5 5.39206C15.5 5.26299 15.4473 5.1392 15.3536 5.04794C15.2598 4.95667 15.1326 4.90539 15 4.90539ZM5 7.82539H4C3.86739 7.82539 3.74021 7.77412 3.64645 7.68285C3.55268 7.59159 3.5 7.4678 3.5 7.33873C3.5 7.20966 3.55268 7.08587 3.64645 6.9946C3.74021 6.90333 3.86739 6.85206 4 6.85206H5C5.13261 6.85206 5.25979 6.90333 5.35355 6.9946C5.44732 7.08587 5.5 7.20966 5.5 7.33873C5.5 7.4678 5.44732 7.59159 5.35355 7.68285C5.25979 7.77412 5.13261 7.82539 5 7.82539ZM11 7.82539C10.8674 7.82539 10.7402 7.77412 10.6464 7.68285C10.5527 7.59159 10.5 7.4678 10.5 7.33873C10.5 7.20966 10.5527 7.08587 10.6464 6.9946C10.7402 6.90333 10.8674 6.85206 11 6.85206H12C12.1326 6.85206 12.2598 6.90333 12.3536 6.9946C12.4473 7.08587 12.5 7.20966 12.5 7.33873C12.5 7.4678 12.4473 7.59159 12.3536 7.68285C12.2598 7.77412 12.1326 7.82539 12 7.82539H11Z",
    },
    properties: {
        viewBox: "0 0 16 12",
        outlined: false,
        strokeWidth: "0",
    },
};
