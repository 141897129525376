import { Seperator } from "@src/components";
import { Box, FlatList, Text, useTheme } from "@kolmeo/ui-core";
import { useTranslation } from "react-i18next";
import { TenancyPaymentDetails } from "@src/navigation/TenancyNavigator/types";
import BPayMethodCard from "../ListCards/BpayMethodCard";
import { ScreenEmptyState } from "@src/containers";
import { useMediaQuery } from "@src/hooks";

type BPayDetailsListProps = {
  data?: TenancyPaymentDetails[];
  isBelowMobile: boolean;
  onListCardPress: (item: TenancyPaymentDetails) => void;
};
const BPayDetailsList = ({ data, onListCardPress }: BPayDetailsListProps) => {
  const { t } = useTranslation(["Common", "Tenant"]);
  const { palette, spacing } = useTheme();
  const { isBelowMobile } = useMediaQuery();

  return (
    <FlatList
      ItemSeparatorComponent={() => <Seperator margin={spacing.px8} seperatorColor="transparent" />}
      style={{ zIndex: -1 }}
      testID="bpay-method-list"
      data={data}
      ListHeaderComponent={
        <Box paddingBottom={spacing.px16} zIndex={-1}>
          {data && data?.length > 0 && <Text variant="headingH5">{t("Tenant:PaymentMethods.PaymentDetailsHeader")}</Text>}
        </Box>
      }
      renderItem={({ item, index }) => {
        return <BPayMethodCard item={item} index={index} onPress={() => onListCardPress(item)} isFullWidth={isBelowMobile} />;
      }}
      contentContainerStyle={{ paddingBottom: 6, paddingHorizontal: 1 }} // to show renderItem box shadows
    />
  );
};

export default BPayDetailsList;
