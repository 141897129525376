import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Pressable, Text, useTheme } from "@kolmeo/ui-core";
import Icon from "../Icon";
import FieldError from "../FieldError";
import { Dimensions } from "react-native";
const CheckBoxSingle = ({ value, disabled, onPress, content, selectedColor, error, containerStyles, textBold }) => {
    const { palette, borderRadius } = useTheme();
    return (_jsxs(Box, { ...containerStyles, marginY: 2, children: [_jsxs(Box, { opacity: disabled ? 0.3 : 1, accessibilityRole: "checkbox", flexDirection: "row", alignItems: "center", children: [_jsx(Pressable, { onPress: () => onPress(!value), padding: 1, backgroundColor: value === true ? (selectedColor ? selectedColor : palette.background.primary.default) : palette.background.surface, borderColor: value === true ? (selectedColor ? selectedColor : palette.background.primary.default) : palette.border.default, borderWidth: 1.2, borderRadius: borderRadius.br8, children: _jsx(Icon, { type: "check", size: "micro", color: value === true ? palette.background.default : palette.transparent }) }), _jsx(Pressable, { marginLeft: 2, onPress: () => onPress(!value), children: _jsx(Box, { width: Dimensions.get("screen").width - 50, children: typeof content == "string" ? (_jsx(Text, { numberOfLines: 2, fontWeight: textBold ? 600 : "normal", children: content })) : (content) }) })] }), _jsx(Box, { mt: `4px`, children: error && _jsx(FieldError, { message: error }) })] }));
};
export default CheckBoxSingle;
