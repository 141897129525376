import { Box, Text, useTheme } from "@kolmeo/ui-core";
import { useNavigation } from "@react-navigation/core";
import { SelectorMenu } from "@src/components";
import { useMediaQuery } from "@src/hooks";
import { Fragment, useEffect, useState } from "react";

export type TabOptions = {
  label: string;
  component: JSX.Element;
};

const DualTabContainer = ({ tabs }: { tabs: TabOptions[] } & React.PropsWithChildren) => {
  const { isBelowMobile } = useMediaQuery();
  const { spacing, palette } = useTheme();

  const mapSelectorOptions = tabs.map((item, index) => ({
    label: item.label,
    value: index
  }));

  const [selectedTab, setSelectedTab] = useState<{ label: string; value: number }>(mapSelectorOptions[0]);
  const navigation = useNavigation();
  useEffect(() => {
    const unsubscribe = navigation.addListener("blur", () => {
      setSelectedTab(mapSelectorOptions[0]);
    });

    return unsubscribe;
  }, [navigation, mapSelectorOptions]);

  return (
    <Box>
      {isBelowMobile ? (
        <>
          <SelectorMenu<number>
            options={mapSelectorOptions}
            onPress={(item) => setSelectedTab(mapSelectorOptions[item])}
            activeItem={selectedTab.value}
          />
          {tabs.map(({ component }, index) => {
            if (index === selectedTab.value)
              return (
                <Fragment key={index.toString()}>
                  <Box height={"100vh"}>{component}</Box>
                </Fragment>
              );
          })}
        </>
      ) : (
        <Box flexDirection="row" justifyContent="space-between" height={"100vh"}>
          {tabs.map(({ label, component }, index) => (
            <Box key={index} width={`calc(50% - ${spacing.px8})`}>
              <Text variant="headingH5" color={palette.text.primary} style={{ fontFamily: "Inter" }} marginBottom={spacing.px20}>
                {label}
              </Text>
              {component}
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};

export default DualTabContainer;
