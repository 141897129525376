import { rest } from "msw";
import { RequestItemDetails } from "../types";

export const getWorkflowDetails: RequestItemDetails = {
  workflowItems: [
    {
      workflowItemId: 491771,
      workflowItemGuidId: "307e0e1c-5f14-441c-bb95-cdaf252903be",
      subject: "Request Created",
      description: null,
      itemType: 4,
      profileTypeId: 3,
      createdDate: "2023-12-20T05:49:33.5839672",
      modifiedDate: "2023-12-20T05:49:33.5839672"
    }
  ],
  propertyId: 53208,
  workflowId: 180360,
  workflowEntityId: "7985b3f1-f0fe-4705-9c15-5cd64z0d3ca5a",
  subject: "new request from swagger",
  description: "testing from swagger",
  createdBy: 3,
  createdDate: "2023-12-20T05:49:33.5839672",
  modifiedDate: "2023-12-20T05:49:33.5839672",
  status: 1
};

export const getWorkflowDetailsHandler = rest.get(
  `https://tenant-api.dev.kolmeo.com/api/v1/workflow/details/68994/180360`,
  (_, res, ctx) => {
    return res(ctx.status(200), ctx.json(getWorkflowDetails));
  }
);
