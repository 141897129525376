import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, TouchableOpacity, coreTheme } from "@kolmeo/ui-core";
import Icon from "../Icon";
const { palette } = coreTheme;
const variations = {
    primary: {
        fillColor: palette.yvesKleinBlue600,
    },
    danger: {
        fillColor: palette.dangerIcon,
    },
};
const ListItemIcon = ({ onPress, onLongPress, iconType, variant, testID, iconSize = "sm" }) => {
    return (_jsx(TouchableListItem, { onPress: onPress, onLongPress: onLongPress, testID: testID, children: _jsx(Box, { width: "36px", height: "36px", justifyContent: "center", alignItems: "center", children: _jsx(Icon, { type: iconType, size: iconSize, color: variations[variant ?? "primary"].fillColor }) }) }));
};
const TouchableListItem = ({ children, onPress, onLongPress, disabled, testID }) => {
    return onLongPress || onPress ? (_jsx(TouchableOpacity, { onPress: onPress, onLongPress: onLongPress, disabled: disabled, testID: testID, children: children })) : (_jsx(_Fragment, { children: children }));
};
export default ListItemIcon;
