import { Box, Text, TouchableOpacity, useTheme } from "@kolmeo/ui-core";
import { Icon } from "@kolmeo/ui-components";
import { useProperty } from "@src/navigation/PropertyNavigator";
import { useStatementDownload } from "./useStatements";
import { StatementsHistoryResponse } from "./StatementsTypes";

export const StatementsRowItem = ({ item }: { item: StatementsHistoryResponse }): JSX.Element => {
  const { brandingObject } = useProperty();
  const { spacing, colors } = useTheme();

  const { handleDownload, handlePreview } = useStatementDownload(item);

  return (
    <Box flexDirection="row" alignItems="center" justifyContent="space-between" paddingX="40px" paddingY="20px">
      <Text>{item.name}</Text>
      <Box flexDirection="row">
        <TouchableOpacity onPress={() => handlePreview()}>
          <Icon type="eye" size="md" color={brandingObject?.primaryColor ?? colors.primary} />
        </TouchableOpacity>
        <TouchableOpacity marginLeft={spacing.px16} onPress={() => handleDownload()}>
          <Icon type="download" size="md" color={brandingObject?.primaryColor ?? colors.primary} />
        </TouchableOpacity>
      </Box>
    </Box>
  );
};
