export default {
    path: {
        d: "M19,13H5V11H19V13Z",
    },
    properties: {
        viewBox: "0 0 24 24",
        outlined: false,
        strokeWidth: "0",
    },
};
