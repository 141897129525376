import { coreThemeV2 } from "@kolmeo/ui-components";
import { statusVariants } from "./statusVariants";

const { palette, sizing, borderRadius } = coreThemeV2;

export const baseTheme = {
  colors: { primary: "#006890", background: "#F3F3F7" },
  spacing: {
    px4: "4px",
    px8: "8px",
    px16: "16px",
    px20: "20px",
    px24: "24px"
  },
  borderRadius,
  palette,
  sizing,
  statusVariants
};

export type BaseThemeType = typeof baseTheme;
