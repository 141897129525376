export default {
    path: {
        d: "M29 12C29 11.4696 28.7893 10.9609 28.4142 10.5858C28.0391 10.2107 27.5304 10 27 10H23V6C23 5.46957 22.7893 4.96086 22.4142 4.58579C22.0391 4.21071 21.5304 4 21 4H5C4.46957 4 3.96086 4.21071 3.58579 4.58579C3.21072 4.96086 3 5.46957 3 6V22C2.99947 22.1894 3.05339 22.3749 3.15534 22.5344C3.25728 22.694 3.40296 22.8209 3.575 22.9C3.7435 22.9824 3.93218 23.0144 4.11842 22.9922C4.30466 22.97 4.48055 22.8946 4.625 22.775L9 19.2375V23C9 23.5304 9.21072 24.0391 9.58579 24.4142C9.96086 24.7893 10.4696 25 11 25H22.7L27.375 28.775C27.5195 28.8946 27.6953 28.97 27.8816 28.9922C28.0678 29.0144 28.2565 28.9824 28.425 28.9C28.597 28.8209 28.7427 28.694 28.8447 28.5344C28.9466 28.3749 29.0005 28.1894 29 28V12ZM23.675 23.225C23.5 23.0782 23.2784 22.9985 23.05 23H11V19H21C21.5304 19 22.0391 18.7893 22.4142 18.4142C22.7893 18.0391 23 17.5304 23 17V12H27V25.9L23.675 23.225Z",
    },
    properties: {
        viewBox: "0 0 32 32",
        outlined: false,
        strokeWidth: "0",
    },
};
