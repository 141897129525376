import { Icon, coreThemeV2 } from "@kolmeo/ui-components";
import { Box, BoxProps, Text, TouchableOpacity, useTheme } from "@kolmeo/ui-core";
import { useMediaQuery } from "@src/hooks";
import React from "react";
import { ModalProps, Modal as RNModal, TouchableWithoutFeedback } from "react-native";
import Seperator from "../Seperator/Seperator";

export type GeneralModalProps = {
  toggleVisible: (value: boolean) => void;
  isVisible: boolean;
  title: string | React.ReactNode;
  callToAction?: JSX.Element;
  renderHeaderSeperator?: boolean;
  renderCloseIcon?: boolean;
  contentProps?: BoxProps;
  headerContentProps?: BoxProps;
  renderRightComponent?: React.ReactNode;
  touchToDismiss?: boolean;
  centerHeader?: boolean;
  contentContainerProps?: BoxProps;
  onClosePress?: () => void;
} & React.PropsWithChildren &
  ModalProps;

const Modal = ({
  toggleVisible,
  isVisible,
  children,
  title,
  callToAction,
  renderHeaderSeperator = false,
  renderCloseIcon = true,
  contentProps,
  headerContentProps,
  renderRightComponent,
  centerHeader,
  touchToDismiss,
  contentContainerProps,
  onClosePress,
  ...props
}: GeneralModalProps) => {
  const { sizing } = coreThemeV2;
  const { mq } = useMediaQuery();
  const { spacing } = useTheme();

  return (
    <RNModal visible={isVisible} transparent={true} onRequestClose={() => toggleVisible(!isVisible)} style={{ flex: 1 }} {...props}>
      {touchToDismiss ? (
        <TouchableWithoutFeedback onPress={() => toggleVisible(!isVisible)} data-pendo-id="cross-icon-cta">
          <Box position={"absolute"} style={{ top: 0, left: 0, right: 0, bottom: 0 }} backgroundColor="#000" opacity={0.3} />
        </TouchableWithoutFeedback>
      ) : (
        <Box height="100%" width="100%" backgroundColor="#000" opacity={0.3} position="absolute" />
      )}

      {touchToDismiss ? (
        <TouchableWithoutFeedback onPress={() => toggleVisible(!isVisible)}>
          <Box position={"absolute"} style={{ top: 0, left: 0, right: 0, bottom: 0 }} backgroundColor="#000" opacity={0.3} />
        </TouchableWithoutFeedback>
      ) : (
        <Box height="100%" width="100%" backgroundColor="#000" opacity={0.3} position="absolute" />
      )}
      <Box
        width={mq({
          desktop: 575,
          phone: "90%",
          tablet: 375
        })}
        backgroundColor="white"
        borderRadius={8}
        margin="auto"
        padding={sizing.size20}
        {...contentContainerProps}
      >
        <Box
          width="100%"
          justifyContent={centerHeader ? "center" : "flex-start"}
          flexDirection="row"
          alignItems={"flex-start"}
          marginBottom={sizing.size16}
          {...headerContentProps}
        >
          {typeof title === "string" ? (
            // mobile/body/1/regular
            <Text fontWeight={600} fontSize="18px" lineHeight="24px">
              {title}
            </Text>
          ) : (
            title
          )}
          {renderCloseIcon && (
            <Box paddingTop={"4px"} position={"absolute"} top={0} right={0}>
              <TouchableOpacity
                onPress={() => {
                  onClosePress && onClosePress();
                  toggleVisible(!isVisible);
                }}
              >
                <Icon type="close" size="xs" />
              </TouchableOpacity>
            </Box>
          )}
          {renderRightComponent && renderRightComponent}
        </Box>
        {renderHeaderSeperator && <Seperator margin={spacing.px4} />}
        <Box paddingY={spacing.px20} {...contentProps}>
          {children}
        </Box>
        <Box zIndex={-5}>{callToAction}</Box>
      </Box>
    </RNModal>
  );
};

export default Modal;
