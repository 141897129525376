export default {
    path: {
        d: "M7 0.5C5.71442 0.5 4.45772 0.881218 3.3888 1.59545C2.31988 2.30968 1.48676 3.32484 0.994786 4.51256C0.502816 5.70028 0.374095 7.00721 0.624899 8.26809C0.875703 9.52896 1.49477 10.6872 2.40381 11.5962C3.31285 12.5052 4.47104 13.1243 5.73192 13.3751C6.99279 13.6259 8.29973 13.4972 9.48744 13.0052C10.6752 12.5132 11.6903 11.6801 12.4046 10.6112C13.1188 9.54229 13.5 8.28558 13.5 7C13.4967 5.27711 12.8108 3.62573 11.5925 2.40746C10.3743 1.18918 8.72289 0.503304 7 0.5ZM10.5 7.5H7C6.86739 7.5 6.74022 7.44732 6.64645 7.35355C6.55268 7.25979 6.5 7.13261 6.5 7V3.5C6.5 3.36739 6.55268 3.24021 6.64645 3.14645C6.74022 3.05268 6.86739 3 7 3C7.13261 3 7.25979 3.05268 7.35356 3.14645C7.44732 3.24021 7.5 3.36739 7.5 3.5V6.5H10.5C10.6326 6.5 10.7598 6.55268 10.8536 6.64645C10.9473 6.74021 11 6.86739 11 7C11 7.13261 10.9473 7.25979 10.8536 7.35355C10.7598 7.44732 10.6326 7.5 10.5 7.5Z",
    },
    properties: {
        viewBox: "0 0 14 14",
        outlined: false,
        strokeWidth: "1",
    },
};
