import { useEffect, useState } from "react";
import { startOfDay, endOfDay, subDays } from "date-fns";

import { Box, Text, TouchableOpacity, useTheme } from "@kolmeo/ui-core";
import { Icon } from "@kolmeo/ui-components";

import { Modal } from "@src/components";
import DatePicker from "../DatePicker";
import SelectableChip from "./SelectableChip";
import { transformToShortDate } from "@src/utils/dateTransformers";

export type DateFilters = {
  fromDate: string | Date | undefined;
  toDate: string | Date | undefined;
};
type DateSelection = "30" | "60" | "90" | "custom" | undefined;

const formatActiveLabel = (dateObj: DateFilters) => {
  const { fromDate, toDate } = dateObj;
  return `${transformToShortDate(fromDate as string)} - ${transformToShortDate(toDate as string)}`;
};

const DateFilter = ({
  setFilterDates,
  color
}: {
  setFilterDates: React.Dispatch<React.SetStateAction<IntentionalAny<"Type after success">>>;
  color?: string;
}): JSX.Element => {
  const { palette, spacing, colors } = useTheme();

  const [isSelectorVisible, setIsSelectorVisible] = useState<boolean>(false);
  const [selection, setSelection] = useState<DateSelection>(undefined);

  const [internalFilterDates, setInternalFilterDates] = useState<DateFilters>({ fromDate: undefined, toDate: undefined });
  const [isFilterActive, setIsFilterActive] = useState<boolean>(false);

  const [isBothDatesDefined, setIsBothDatesDefined] = useState<boolean>(false);
  useEffect(() => {
    if (internalFilterDates.fromDate && internalFilterDates.toDate) {
      setIsBothDatesDefined(true);
    } else {
      setIsBothDatesDefined(false);
    }
  }, [internalFilterDates]);

  const disabledForCustom = () => {
    if (selection !== "custom") {
      return false;
    } else {
      if (isBothDatesDefined) {
        return false;
      } else {
        return true;
      }
    }
  };

  const setButtonColor = () => {
    const isDisabledForCustom = disabledForCustom();

    if (isDisabledForCustom) {
      return palette.background.disable;
    } else {
      return color ? color : colors.primary;
    }
  };

  const handleSelect = (tempUserSelection: DateSelection) => {
    setSelection(tempUserSelection);
    if (tempUserSelection) {
      if (tempUserSelection !== "custom") {
        const TODAY = new Date();
        setInternalFilterDates({
          fromDate: subDays(new Date(), parseInt(tempUserSelection)),
          toDate: TODAY
        });
      }
    }
  };

  const setFromDate = (date: Date) => {
    setInternalFilterDates({ ...internalFilterDates, fromDate: startOfDay(new Date(date)) });
  };

  const setToDate = (date: Date) => {
    setInternalFilterDates({ ...internalFilterDates, toDate: endOfDay(new Date(date)) });
  };

  const handleSubmit = () => {
    setFilterDates(internalFilterDates);
    setIsSelectorVisible(false);
    isBothDatesDefined ? setIsFilterActive(true) : setIsFilterActive(false);
  };

  const handleReset = () => {
    setSelection(undefined);
    setInternalFilterDates({ fromDate: undefined, toDate: undefined });
    setFilterDates({ fromDate: undefined, toDate: undefined });
    setIsFilterActive(false);
  };

  return (
    <>
      <Box flexDirection="row" alignItems="center">
        <TouchableOpacity onPress={() => setIsSelectorVisible(true)}>
          <Box
            borderColor={color ?? colors.primary}
            borderWidth={1}
            borderRadius={8}
            backgroundColor="white"
            justifyContent="center"
            flexDirection="row"
            padding="8px"
            overflow="hidden"
          >
            {isFilterActive ? (
              <Text color={color ?? colors.primary}>{isBothDatesDefined && formatActiveLabel(internalFilterDates)}</Text>
            ) : (
              <>
                <Text color={color ?? colors.primary} mr={spacing.px4}>
                  Date Filter
                </Text>
                <Icon type="calendar" size="xs" color={color ?? colors.primary} />
              </>
            )}
          </Box>
        </TouchableOpacity>
        {isFilterActive && (
          <TouchableOpacity onPress={() => handleReset()} marginLeft={spacing.px4}>
            <Text color={color ? color : colors.primary} style={{ textDecorationLine: "underline" }}>
              Reset Date Filter
            </Text>
          </TouchableOpacity>
        )}
      </Box>

      <Modal isVisible={isSelectorVisible} toggleVisible={setIsSelectorVisible} title="Date Filter">
        <Box borderTopColor={palette.border.light} borderTopWidth={1} paddingY={spacing.px16}>
          <Box flexDirection="row" justifyContent="space-between" marginBottom="16px">
            <Text>Time Period</Text>
          </Box>

          <Box flexWrap="wrap" flexDirection="row" width="100%" justifyContent="space-between">
            <SelectableChip
              onPress={() => handleSelect("30")}
              label="30 Days"
              isActive={selection === "30"}
              color={color ?? colors.primary}
            />
            <SelectableChip
              onPress={() => handleSelect("60")}
              label="60 Days"
              isActive={selection === "60"}
              color={color ?? colors.primary}
            />
            <SelectableChip
              onPress={() => handleSelect("90")}
              label="90 Days"
              isActive={selection === "90"}
              color={color ?? colors.primary}
            />
            <SelectableChip
              onPress={() => handleSelect("custom")}
              label="Custom"
              isActive={selection === "custom"}
              color={color ?? colors.primary}
            />
          </Box>
          {selection === "custom" && (
            <Box width="100%" marginBottom={spacing.px16}>
              <Text marginBottom={spacing.px16}>Custom Date</Text>
              <Box flexDirection="row" justifyContent="space-between">
                <Box flexBasis="calc(50% - 4px)">
                  <Text marginBottom={spacing.px8}>Start Date</Text>
                  <DatePicker
                    date={internalFilterDates.fromDate}
                    setDate={setFromDate}
                    selectsStart
                    startDate={internalFilterDates.fromDate ? new Date(internalFilterDates.fromDate) : undefined}
                    endDate={internalFilterDates.toDate ? new Date(internalFilterDates.toDate) : undefined}
                    maxDate={internalFilterDates.toDate ? new Date(internalFilterDates.toDate) : undefined}
                  />
                </Box>
                <Box flexBasis="calc(50% - 4px)">
                  <Text marginBottom={spacing.px8}>End Date</Text>
                  <DatePicker
                    date={internalFilterDates.toDate}
                    setDate={setToDate}
                    selectsEnd
                    startDate={internalFilterDates.fromDate ? new Date(internalFilterDates.fromDate) : undefined}
                    endDate={internalFilterDates.toDate ? new Date(internalFilterDates.toDate) : undefined}
                    minDate={internalFilterDates.fromDate ? new Date(internalFilterDates.fromDate) : undefined}
                  />
                </Box>
              </Box>
            </Box>
          )}
          <TouchableOpacity
            onPress={() => handleSubmit()}
            width="100%"
            // TODO: Revisit this ternary
            justifyContent="center"
            alignItems="center"
            height={42}
            borderRadius="8px"
            zIndex={-1}
            disabled={disabledForCustom()}
            backgroundColor={setButtonColor()}
          >
            <Text color="white" fontFamily="Inter" fontWeight={600} fontSize="14px">
              Save Date Filter
            </Text>
          </TouchableOpacity>
          {/* Uncomment Button below to show how the ui-component doesn't work for My Kolmeo */}
          {/* <Button
            buttonText="Save Date Filter"
            onPress={() => handleSubmit()}
            backgroundColor={disabledForCustom() ? palette.background.disable : colors.primary}
            disabled={disabledForCustom()}
          /> */}
        </Box>
      </Modal>
    </>
  );
};

export default DateFilter;
