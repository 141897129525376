import { Box, Image } from "@kolmeo/ui-core";
import { forwardRef } from "react";
import Carousel from "react-native-reanimated-carousel";
import ArrowIndicator from "./ArrowIndicator";
import { FULLSCREEN_IMAGE_HEIGHT, FULLSCREEN_IMAGE_WIDTH } from "./utils/constants";

type ImageCarouselProps = {
  fullImageRef: any;
  images?: any[];
  currentIndex: number;
  carouselHeight: number;
  carouselWidth: number;
  imageHeight: number;
  imageWidth: number;
  onLeftPressHandler: () => void;
  onRightPressHandler: () => void;
};

const ImageCarousel = forwardRef(
  ({
    fullImageRef,
    images,
    currentIndex,
    carouselHeight,
    carouselWidth,
    imageHeight,
    imageWidth,
    onLeftPressHandler,
    onRightPressHandler
  }: ImageCarouselProps) => {
    // Calculate new image dimensions while maintaining aspect ratio
    const aspectRatio = imageWidth / imageHeight;
    const maxWidth = FULLSCREEN_IMAGE_WIDTH * 0.8; // 80% of screen width
    const maxHeight = FULLSCREEN_IMAGE_HEIGHT; // 80% of screen height

    let newWidth = imageWidth;
    let newHeight = imageHeight;

    if (newWidth > maxWidth) {
      newWidth = maxWidth;
      newHeight = newWidth / aspectRatio;
    }

    if (newHeight > maxHeight) {
      newHeight = maxHeight;
      newWidth = newHeight * aspectRatio;
    }

    return (
      <>
        {currentIndex > 0 && <ArrowIndicator onPressHandler={onLeftPressHandler} direction={"left"} />}
        {images && images.length > currentIndex + 1 && <ArrowIndicator onPressHandler={onRightPressHandler} direction={"right"} />}
        <Carousel
          ref={fullImageRef}
          data={images ? images : []}
          width={carouselWidth}
          defaultIndex={currentIndex}
          loop={false}
          enabled={false}
          onSnapToItem={(index) => console.log("current index:", index)}
          style={{ width: "100%", justifyContent: "center" }}
          height={carouselHeight}
          renderItem={({ item }) => {
            return (
              <Box flex={1} alignItems="center" justifyContent="center">
                <Image
                  source={item}
                  resizeMode="contain"
                  style={{
                    minWidth: newWidth,
                    minHeight: newHeight
                  }}
                />
              </Box>
            );
          }}
        />
      </>
    );
  }
);

export default ImageCarousel;
