import ReactDatePicker from "react-datepicker";
import type { ReactDatePickerProps } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./DatePicker.css";

import { DATE_FORMAT_SHORT } from "@src/utils/dateTransformers";

const DatePicker = ({
  date,
  setDate,
  ...props
}: { date?: IntentionalAny<"Type after success">; setDate: (date: Date) => void } & Omit<
  ReactDatePickerProps,
  "onChange"
>): JSX.Element => {
  return (
    <ReactDatePicker
      {...props}
      showIcon={true}
      selected={date ? new Date(date) : undefined}
      onChange={(date) => date && setDate(date)}
      calendarClassName="mk-date-picker"
      placeholderText="DD/MM/YYYY"
      dateFormat={DATE_FORMAT_SHORT}
    />
  );
};

export default DatePicker;
