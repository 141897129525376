import styled from "styled-components/native";
import { typography, color, space, position, variant, flex, } from "styled-system";
import fonts from "../Theme/fonts";
const Text = styled.Text `
  ${typography}
  ${color}
  ${space}
  ${position}
  ${flex}
  ${variant({
    variants: fonts,
})}
`;
export default Text;
