export default {
    path: {
        d: "M9.1 4.525C9.22384 4.43152 9.32438 4.31067 9.39377 4.17189C9.46316 4.03311 9.49952 3.88016 9.5 3.725V1.5C9.5 1.23478 9.39464 0.98043 9.20711 0.792893C9.01957 0.605357 8.76522 0.5 8.5 0.5H1.5C1.23478 0.5 0.98043 0.605357 0.792893 0.792893C0.605357 0.98043 0.5 1.23478 0.5 1.5V3.75C0.500481 3.90516 0.536838 4.05811 0.606228 4.19689C0.675618 4.33567 0.776161 4.45652 0.9 4.55L2.03125 5.4L4.16875 7L0.9 9.45C0.776161 9.54348 0.675618 9.66433 0.606228 9.80311C0.536838 9.94189 0.500481 10.0948 0.5 10.25V12.5C0.5 12.7652 0.605357 13.0196 0.792893 13.2071C0.98043 13.3946 1.23478 13.5 1.5 13.5H8.5C8.76522 13.5 9.01957 13.3946 9.20711 13.2071C9.39464 13.0196 9.5 12.7652 9.5 12.5V10.275C9.49952 10.1198 9.46316 9.96689 9.39377 9.82811C9.32438 9.68933 9.22384 9.56848 9.1 9.475L5.83125 7L7.25 5.925L9.1 4.525ZM1.5 1.5H8.5V3.725L8.1375 4H1.83125L1.5 3.75V1.5ZM8.5 10.275V12.5H1.5V10.25L4.5 8V9.5C4.5 9.63261 4.55268 9.75979 4.64645 9.85355C4.74021 9.94732 4.86739 10 5 10C5.13261 10 5.25979 9.94732 5.35355 9.85355C5.44732 9.75979 5.5 9.63261 5.5 9.5V8.00625L8.5 10.275Z",
    },
    properties: {
        viewBox: "0 0 14 14",
        outlined: false,
        strokeWidth: "0",
    },
};
