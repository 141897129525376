import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Text, coreTheme } from "@kolmeo/ui-core";
const chipSizeVariants = {
    small: {
        textVariant: "label12SemiBold",
        borderRadius: 40,
        borderWidth: "2px",
        paddingX: "10px",
        paddingY: "2px",
    },
    medium: {
        textVariant: "textHeavy14",
        borderRadius: 30,
        borderWidth: "2px",
        paddingX: "10px",
        paddingY: "2px",
    },
};
export const Chip = ({ text, backgroundColor, borderColor, size, icon, color, ...props }) => {
    const sizeStyles = size ? chipSizeVariants[size] : chipSizeVariants["small"];
    return (_jsx(Box, { borderRadius: sizeStyles.borderRadius, backgroundColor: backgroundColor ? backgroundColor : coreTheme.palette.ateneoBlueLighten15, borderColor: borderColor ? borderColor : coreTheme.palette.ateneoBlueLighten15, borderWidth: sizeStyles.borderWidth, paddingY: sizeStyles.paddingY, paddingX: sizeStyles.paddingX, justifyContent: "center", ...props, children: _jsxs(_Fragment, { children: [_jsx(Text, { variant: sizeStyles.textVariant, color: color ?? coreTheme.palette.white, fontWeight: "600", children: text }), icon] }) }));
};
