import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, Text, TouchableOpacity } from "@kolmeo/ui-core";
import { NativeHapticFeedback } from "../../../utils/system";
import Icon from "../../Icon";
const DropdownTrigger = ({ selected, label, renderItem, isSelectedItemInput, isDropdownOpen, toggleDropDown, triggerProps, setTriggerHeight, isSingleItem, }) => {
    return (_jsx(Box, { ...triggerProps, borderRadius: triggerProps?.borderRadius, position: "relative", children: _jsx(TouchableOpacity, { disabled: isSingleItem, onPress: () => {
                NativeHapticFeedback("clockTick");
                toggleDropDown();
            }, onLayout: (event) => {
                const { height } = event.nativeEvent.layout;
                setTriggerHeight(height);
            }, children: _jsxs(Box, { flexDirection: "row", justifyContent: "space-between", alignItems: "center", children: [_jsx(Box, { flex: 1, children: isSelectedItemInput && selected ? (renderItem({
                            item: selected,
                            index: -1,
                            separators: {
                                highlight: () => { },
                                unhighlight: () => { },
                                updateProps: (select, newProps) => { },
                            },
                        })) : (_jsx(Text, { children: label })) }), !isSingleItem && _jsx(Box, { width: 20, children: _jsx(Icon, { type: isDropdownOpen ? "chevron-up" : "chevron-down", size: "sm" }) })] }) }) }));
};
export default DropdownTrigger;
