import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Box, TouchableOpacity } from "@kolmeo/ui-core";
const variantMap = {
    default: {
        paddingLeft: "8px",
        paddingRight: "8px",
    },
    secondary: {
        paddingLeft: 0,
        paddingRight: 0,
    },
    tertiary: {
        paddingLeft: "16px",
        paddingRight: "16px",
    },
};
const getVariant = (variant) => {
    return variant && variantMap[variant] ? variantMap[variant] : variantMap["default"];
};
const ListItem = ({ disabled, topSeperator, bottomSeperator, variant, onPress, onLongPress, title, actionsLeft, actionsRight, backgroundColor, testID, }) => {
    const variantStyles = getVariant(variant);
    return (_jsx(TouchableListItem, { onPress: onPress, onLongPress: onLongPress, children: _jsxs(Box, { marginLeft: variantStyles.marginLeft, marginRight: variantStyles.marginRight, opacity: disabled ? 0.3 : 1, backgroundColor: backgroundColor, testID: testID, children: [topSeperator, _jsxs(Box, { flexDirection: "row", alignItems: "center", paddingLeft: variantStyles.paddingLeft, paddingRight: variantStyles.paddingRight, children: [actionsLeft, _jsx(Box, { flexGrow: 1, flex: 1, width: "0", children: title }), actionsRight] }), bottomSeperator] }) }));
};
const TouchableListItem = ({ children, onPress, onLongPress, disabled }) => {
    return onLongPress || onPress ? (_jsx(TouchableOpacity, { onPress: onPress, onLongPress: onLongPress, disabled: disabled, children: children })) : (_jsx(_Fragment, { children: children }));
};
export default ListItem;
