export const ProfileType = {
  systemAccount: -1,
  staffMember: 1,
  owner: 2,
  tenant: 3,
  supplier: 4,
  apiConsumer: 5,
  limited: 6
} as const;

export type ProfileTypeCodes = (typeof ProfileType)[keyof typeof ProfileType];
export type ProfileTypeType = keyof typeof ProfileType;
