import { CommonActions, useNavigation } from "@react-navigation/native";
import { useAuth } from "react-oidc-context";

import { useCurrentUser } from "@src/hooks";
import { queryClient } from "@src/lib/http/QueryClient";
import { useGlobalTenant } from "@src/navigation/hooks/useGlobalTenant";

const useKolmeoAuth = () => {
  const navigation = useNavigation();
  const { user, removeUser, signinRedirect } = useAuth();
  const { clearCurrentUser } = useCurrentUser();
  const { removeGlobalDataFromStorageAsync } = useGlobalTenant();
  const login = () => {
    signinRedirect();
  };

  const logout = async () => {
    await clearCurrentUser();
    await queryClient.clear();
    await removeUser();
    await removeGlobalDataFromStorageAsync();
    navigation.dispatch(
      CommonActions.reset({
        index: 1,
        routes: [{ name: "RootScreen" }]
      })
    );
  };

  const getBearerToken = () => {
    return user?.id_token;
  };

  return {
    login,
    logout,
    getBearerToken
  };
};

export default useKolmeoAuth;
