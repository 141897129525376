import { setupWorker } from "msw";
import { getAllOwnerPropertiesHandler } from "@src/screens/Owner/MyProperties/__test__/mocks";

import { tenancyOverviewScreenHandlers } from "@src/screens/Tenant/TenancyOverview/mocks";
import { getUserProfilesErrorHandler } from "@src/screens/RootScreen/__test__/getUserProfiles";
import { getWorkflowDetailsHandler } from "./screens/Tenant/TenancyRequests/RequestDetails/__test__/mocks";

export const restHandler = [
  getUserProfilesErrorHandler,
  getAllOwnerPropertiesHandler,
  getWorkflowDetailsHandler,
  ...tenancyOverviewScreenHandlers
];

export const worker = setupWorker(...restHandler);
