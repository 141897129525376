import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import RotateAnimation from "../NativeAnimations/RotateAnimation";
export function AnimatedIcon({ type, children }) {
    return (_jsx(_Fragment, { children: (() => {
            switch (type) {
                case 'rotate':
                    return _jsx(RotateAnimation, { children: children });
                default:
                    return children;
            }
        })() }));
}
