import { jsx as _jsx } from "react/jsx-runtime";
import { Box } from "@kolmeo/ui-core";
import SVGImage from "../SVGImage";
const StaffFallback = ({ width, height, userTypeId, ...props }) => {
    const setType = () => {
        switch (userTypeId) {
            case 1:
                return "persona-staff";
            default:
                return "persona-staff";
        }
    };
    return (_jsx(Box, { width: width, height: height, borderRadius: 500, overflow: "hidden", ...props, children: _jsx(SVGImage, { type: setType(), width: width, height: height }) }));
};
export default StaffFallback;
