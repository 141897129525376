import { jsx as _jsx } from "react/jsx-runtime";
import { Box } from "@kolmeo/ui-core";
import styled from "styled-components";
import SVGImage from "./components";
const SVGImageComponent = ({ type, height, width }) => {
    const SVGImageComponent = SVGImage[type];
    const StyledSVGImageComponent = styled(SVGImageComponent) ``;
    return (_jsx(Box, { width: width, height: height, children: _jsx(StyledSVGImageComponent, { width: width, height: height }) }));
};
export default SVGImageComponent;
