import styled from 'styled-components/native';
import { color, layout, position, system } from 'styled-system';
const Image = styled.Image `
  ${color}
  ${layout}
  ${position}
  ${system({
    tintColor: true
})}
`;
export default Image;
