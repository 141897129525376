import React, { Children } from "react";
import { Box } from "@kolmeo/ui-core";
import { useMediaQuery } from "@src/hooks";
import { calcThreeQuarters } from "./calcThreeQuarters";

interface ResponsiveContainerProps extends React.PropsWithChildren {
  spacing?: number;
  columns?: {
    isTablet?: number;
    isDesktop?: number;
  };
}

const ResponsiveContainer = ({ children, spacing = 16, columns }: ResponsiveContainerProps) => {
  const { mq, isDesktop, isTablet } = useMediaQuery();
  const defaultColumns = {
    isTablet: 2,
    isDesktop: 3
  };

  const effectiveColumns = {
    ...defaultColumns,
    ...columns
  };

  const getColumns = () => {
    if (isDesktop) {
      return effectiveColumns.isDesktop;
    }
    if (isTablet) {
      return effectiveColumns.isTablet;
    }
    return 1;
  };

  const Item = ({ children, index }: { index: number } & React.PropsWithChildren) => {
    const position = index + 1;

    return (
      <>
        <Box
          width={mq({
            phone: "100%",
            tablet: `calc(100% / ${effectiveColumns.isTablet} - ${spacing / 2}px)`,
            desktop: `calc(100% / ${effectiveColumns.isDesktop} - ${calcThreeQuarters(spacing)}px)`
          })}
          marginBottom={`${spacing}px`}
          flexDirection="row"
        >
          {children}
        </Box>
        {position % getColumns() !== 0 && <Box width={`${spacing}px`} />}
      </>
    );
  };

  const childrenArray = Children.toArray(children);

  return (
    <Box
      flexDirection={mq({
        phone: "column",
        tablet: "row",
        desktop: "row"
      })}
      flexWrap="wrap"
    >
      {childrenArray.map((child, index: number) => (
        <Item key={index} index={index}>
          {child}
        </Item>
      ))}
    </Box>
  );
};

export default ResponsiveContainer;
