export const toTitleCase = (sentence: string): string => {
  // Ensure the sentence is not empty
  if (!sentence.trim()) {
    return "Please provide a sentence.";
  }

  const words = sentence.trim().split(/\s+/);

  const titleCaseWords = words.map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  });

  const titleCaseSentence = titleCaseWords.join(" ");

  return titleCaseSentence;
};
