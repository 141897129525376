import { jsx as _jsx } from "react/jsx-runtime";
import { coreTheme } from "@kolmeo/ui-core";
import { MenuProvider } from "react-native-popup-menu";
const { palette } = coreTheme;
const PopUpMenuProvider = ({ ...props }) => {
    return (_jsx(MenuProvider, { customStyles: {
            backdrop: { backgroundColor: palette.black100, opacity: 0.2 },
        }, ...props }));
};
export default PopUpMenuProvider;
