import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Pressable, Text, Box } from "@kolmeo/ui-core";
import { useRef } from "react";
import { Menu, MenuOptions, MenuTrigger } from "../../components";
import Icon from "../../../Icon";
import { renderers } from "react-native-popup-menu";
const ToolTip = ({ content, position = "auto", iconColor }) => {
    const toolTipRef = useRef(null);
    const { Popover } = renderers;
    return (_jsxs(Menu, { renderer: Popover, ref: toolTipRef, style: {
            position: "relative",
        }, rendererProps: { preferredPlacement: position, placement: position }, children: [_jsx(MenuTrigger, { customStyles: {
                    TriggerTouchableComponent: () => (_jsx(Pressable, { onPress: () => {
                            toolTipRef.current?.open();
                        }, children: _jsx(Icon, { type: "information", size: "xs", color: iconColor }) })),
                } }), _jsx(MenuOptions, { optionsContainerStyle: { borderRadius: 8, padding: 8 }, children: _jsx(Box, { padding: 2, children: _jsx(Text, { children: content }) }) })] }));
};
export default ToolTip;
