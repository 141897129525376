import { NavigationContainer } from "@react-navigation/native";

import RootStackNavigator from "./RootStack";

import { FeatureFlagsProvider } from "@src/lib/FeatureFlags";
import { linking } from "./linking";
import { useCallback, useEffect } from "react";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { NAV_HISTORY_KEY } from "@src/types";
import { InteractionManager } from "react-native";
import { useURL } from "@src/screens/RootScreen/useUrl";
import { checkDeepLinkResult, navigationRef } from "@src/providers/DeepLinkingService";
import { useDeepLinking } from "@src/providers/DeepLinkingProvider";
import { useAuth } from "react-oidc-context";
import { useCurrentUser } from "@src/hooks";
import { initPendoSessionWithEmailOnly } from "@src/lib/Analytics/Pendo/pendoUtils";

const Navigator = (): JSX.Element => {
  const link = useURL();
  const { currentUser } = useCurrentUser();

  const { addDeepLink } = useDeepLinking();
  const { signinRedirect, isAuthenticated, isLoading } = useAuth();

  const handleDeepLink = useCallback(
    (url: string) => {
      const task = InteractionManager.runAfterInteractions(() => {
        const { action, linkPath, permitDeepLinkInit } = checkDeepLinkResult(url);
        if (permitDeepLinkInit && action) {
          addDeepLink({
            rawUrl: linkPath, // called using linkTo()
            action: action // call this action using navigation.dispatch(action)
          });
          // redirect user to b2c login screen
          signinRedirect();
        }
      });

      return () => task.cancel();
    },
    [navigationRef]
  );

  // const { getCurrentUserProfileId } = useCurrentUser();

  useEffect(() => {
    const authenticationCheckCompleted = !isAuthenticated && !isLoading;
    if (!link) return;
    // store the deep link if the user is not authenticated.
    if (authenticationCheckCompleted) {
      handleDeepLink(link);
    }
  }, [link, isLoading]);

  useEffect(() => {
    if (process.env.REACT_APP_Pendo_App_Id && currentUser) {
      initPendoSessionWithEmailOnly(currentUser.emailAddress);
    }
  }, [currentUser]);

  return (
    <NavigationContainer
      ref={navigationRef}
      onStateChange={async (state) => {
        AsyncStorage.setItem(NAV_HISTORY_KEY, JSON.stringify(state));
        // TODO: revisit on next release
        // const currentProfileId = await getCurrentUserProfileId();
        // const currentRoute = state?.routes[state.index]?.name;

        // if (
        //   currentProfileId === undefined &&
        //   currentRoute !== "RootScreen" &&
        //   currentRoute !== "Logout" &&
        //   currentRoute !== "AuthCallback"
        // ) {
        //   navigationRef.current?.navigate("Logout");
        // }
      }}
      linking={linking}
    >
      <FeatureFlagsProvider>
        <RootStackNavigator />
      </FeatureFlagsProvider>
    </NavigationContainer>
  );
};

export default Navigator;
