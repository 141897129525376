const area = {
    bottom: 0,
    left: 0,
    right: 0,
    top: 0,
};
export const initialWindowMetrics = area;
export const useSafeAreaInsets = () => {
    return area;
};
