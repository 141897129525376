export default {
    path: {
        d1: "M4.75 13.25C4.75 12.8358 5.08579 12.5 5.5 12.5H11.5C11.9142 12.5 12.25 12.8358 12.25 13.25C12.25 13.6642 11.9142 14 11.5 14H5.5C5.08579 14 4.75 13.6642 4.75 13.25Z",
        d2: "M4.75 10.25C4.75 9.83579 5.08579 9.5 5.5 9.5H11.5C11.9142 9.5 12.25 9.83579 12.25 10.25C12.25 10.6642 11.9142 11 11.5 11H5.5C5.08579 11 4.75 10.6642 4.75 10.25Z",
        d3: "M0.68934 2.43934C0.970644 2.15804 1.35217 2 1.75 2H5.5C5.91421 2 6.25 2.33579 6.25 2.75C6.25 3.16421 5.91421 3.5 5.5 3.5H1.75L1.75 19.25H15.25V3.5H11.5C11.0858 3.5 10.75 3.16421 10.75 2.75C10.75 2.33579 11.0858 2 11.5 2H15.25C15.6478 2 16.0294 2.15804 16.3107 2.43934C16.592 2.72065 16.75 3.10218 16.75 3.5V19.25C16.75 19.6478 16.592 20.0294 16.3107 20.3107C16.0294 20.592 15.6478 20.75 15.25 20.75H1.75C1.35218 20.75 0.970645 20.592 0.68934 20.3107C0.408035 20.0294 0.25 19.6478 0.25 19.25V3.5C0.25 3.10217 0.408036 2.72064 0.68934 2.43934Z",
        d4: "M8.5 2C7.70435 2 6.94129 2.31607 6.37868 2.87868C5.81607 3.44129 5.5 4.20435 5.5 5H11.5C11.5 4.20435 11.1839 3.44129 10.6213 2.87868C10.0587 2.31607 9.29565 2 8.5 2ZM5.31802 1.81802C6.16193 0.974106 7.30653 0.5 8.5 0.5C9.69347 0.5 10.8381 0.974106 11.682 1.81802C12.5259 2.66193 13 3.80653 13 5V5.75C13 6.16421 12.6642 6.5 12.25 6.5H4.75C4.33579 6.5 4 6.16421 4 5.75V5C4 3.80653 4.47411 2.66193 5.31802 1.81802Z",
    },
    properties: {
        viewBox: "0 0 17 21",
        outlined: false,
        strokeWidth: "0",
    },
};
