import { Box, useTheme, Text } from "@kolmeo/ui-core";
import { TouchableOpacity } from "react-native";

const CustomButton = ({ onPress, label }: { onPress: () => void; label: string | JSX.Element }) => {
  const { colors } = useTheme();

  return (
    <TouchableOpacity onPress={onPress}>
      <Box
        backgroundColor={colors.primary}
        borderColor={colors.primary}
        borderWidth={1}
        borderRadius={8}
        paddingX={12}
        paddingY="6px"
        justifyContent="center"
        alignItems="center"
      >
        <Text fontFamily="Inter" color={"white"} fontWeight={600}>
          {label}
        </Text>
      </Box>
    </TouchableOpacity>
  );
};

export default CustomButton;
