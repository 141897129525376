export default {
    path: {
        d: "M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z",
    },
    properties: {
        viewBox: "0 0 24 24",
        outlined: false,
        strokeWidth: "0",
    },
};
