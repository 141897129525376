export default {
    path: {
        d1: "M14.875 5.375H1.125C0.95924 5.375 0.800269 5.44085 0.683058 5.55806C0.565848 5.67527 0.5 5.83424 0.5 6C0.5 6.16576 0.565848 6.32473 0.683058 6.44194C0.800269 6.55915 0.95924 6.625 1.125 6.625H14.875C15.0408 6.625 15.1997 6.55915 15.3169 6.44194C15.4342 6.32473 15.5 6.16576 15.5 6C15.5 5.83424 15.4342 5.67527 15.3169 5.55806C15.1997 5.44085 15.0408 5.375 14.875 5.375Z",
        d2: "M1.125 1.625H14.875C15.0408 1.625 15.1997 1.55915 15.3169 1.44194C15.4342 1.32473 15.5 1.16576 15.5 1C15.5 0.83424 15.4342 0.675269 15.3169 0.558058C15.1997 0.440848 15.0408 0.375 14.875 0.375H1.125C0.95924 0.375 0.800269 0.440848 0.683058 0.558058C0.565848 0.675269 0.5 0.83424 0.5 1C0.5 1.16576 0.565848 1.32473 0.683058 1.44194C0.800269 1.55915 0.95924 1.625 1.125 1.625Z",
        d3: "M14.875 10.375H1.125C0.95924 10.375 0.800269 10.4408 0.683058 10.5581C0.565848 10.6753 0.5 10.8342 0.5 11C0.5 11.1658 0.565848 11.3247 0.683058 11.4419C0.800269 11.5592 0.95924 11.625 1.125 11.625H14.875C15.0408 11.625 15.1997 11.5592 15.3169 11.4419C15.4342 11.3247 15.5 11.1658 15.5 11C15.5 10.8342 15.4342 10.6753 15.3169 10.5581C15.1997 10.4408 15.0408 10.375 14.875 10.375Z",
    },
    properties: {
        viewBox: "0 0 24 18",
        outlined: false,
        strokeWidth: "0",
    },
};
