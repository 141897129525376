import { jsx as _jsx } from "react/jsx-runtime";
import { Image } from "@kolmeo/ui-core";
import { useState } from "react";
export const useImageWithSize = (image, size) => {
    if (image?.uri && size) {
        return { ...image, uri: `${image.uri}` };
    }
    return image;
};
export var ImageSize;
(function (ImageSize) {
    ImageSize["xs"] = "xs";
    ImageSize["s"] = "s";
    ImageSize["m"] = "m";
    ImageSize["l"] = "l";
})(ImageSize || (ImageSize = {}));
export const useSafeImage = (image, fallbackImg) => {
    const [imageLoadErr, setImgLoadErr] = useState(false);
    const imageIsGood = !!image && !!image.uri && !imageLoadErr;
    const imageSrc = imageIsGood ? image : fallbackImg;
    const handleError = () => setImgLoadErr(true);
    return { imageSrc, handleError, imageIsGood };
};
export const SafeImage = ({ image, fallbackImg, makeProps, size, ...props }) => {
    const sizedImage = useImageWithSize(image, size);
    const { imageSrc, imageIsGood, handleError } = useSafeImage(sizedImage, fallbackImg);
    return _jsx(Image, { source: imageSrc, onError: handleError, ...props, ...makeProps?.(imageIsGood) });
};
export const SafeImageViewFallback = ({ image, fallbackView, size, makeProps, ...props }) => {
    const sizedImage = useImageWithSize(image, size);
    const [imageValid, setImageValid] = useState(true);
    const primaryImage = _jsx(Image, { source: sizedImage, ...props, ...makeProps?.(true), onError: () => { setImageValid(false); } });
    return sizedImage.uri && imageValid ? primaryImage : fallbackView;
};
