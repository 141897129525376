import { Card, Icon } from "@kolmeo/ui-components";
import { Box, Text, useTheme } from "@kolmeo/ui-core";
import { StatusPill } from "@src/components";
import { BankAccountDetailsResponse } from "@src/types/PaymentPreferences";
import { sanitiseAccountNumber } from "@src/utils/textTransformers/sanitiseText";
import { shortenTextWithEllipsis } from "@src/utils/textTransformers/shortenTextWithEllipsis";
import { useTranslation } from "react-i18next";

type PaymentMethodCardType = {
  item: BankAccountDetailsResponse;
  index: number;
  onPress: () => void;
  isFullWidth: boolean;
};
const PaymentMethodCard = ({ item, index, onPress, isFullWidth }: PaymentMethodCardType) => {
  const { palette } = useTheme();
  const { t } = useTranslation("Tenant");
  return (
    <Card
      flexDirection={"row"}
      key={index}
      paddingVertical={23}
      onPress={() => onPress()}
      setTouchableToFlex
      borderRadius={16}
      minHeight={120}
      justifyContent="center"
      alignItems="center"
      width={isFullWidth ? "100%" : "40%"}
    >
      <Box flexDirection={"row"} width={"100%"} justifyContent={"space-evenly"} alignItems={"center"} flexWrap={"wrap"}>
        <Box flexDirection={"column"} flex={0.5} justifyContent={"center"}>
          <Box justifyContent={"center"} alignItems={"center"}>
            <Icon type={"bank"} size={"md"} color={palette.text.secondary} />
          </Box>
        </Box>
        <Box flexDirection={"column"} flex={2}>
          <Text variant="paragraph16" fontStyle={"italic"}>
            {shortenTextWithEllipsis(item.nickname ? item.nickname : t("PaymentMethods.BankAccount"), 25)}
          </Text>
          <Text variant="paragraph16">
            {t("PaymentMethods.BankAccountEndingIn", { bankAccountNumber: sanitiseAccountNumber(item.accountNumber) })}
          </Text>
        </Box>
        <Box flex={2} flexDirection={"row"} alignItems={"center"} justifySelf={"flex-end"}></Box>
        <Box position={"absolute"} flexDirection={"row"} right={0}>
          <Box>{item.isDefault && <StatusPill variant="warning" label={"Default"} capitalise={true}></StatusPill>}</Box>
          <Icon type={"chevron-right"} size={"lg"} color={palette.text.brand} />
        </Box>
      </Box>
    </Card>
  );
};

export default PaymentMethodCard;
