import { createGlobalStyle } from "@kolmeo/ui-core";

import Hornbill from "@src/assets/fonts/Hornbill-Medium.otf";
import InterRegular from "@src/assets/fonts/Inter-Regular.ttf";
import InterMedium from "@src/assets/fonts/Inter-Medium.ttf";
import InterSemiBold from "@src/assets/fonts/Inter-SemiBold.ttf";
import InterBold from "@src/assets/fonts/Inter-Bold.ttf";

const GlobalStyle = createGlobalStyle`
    @font-face {
        font-family: "Hornbill";
        src: url(${Hornbill}) format('woff2'),
            url(${Hornbill}) format('woff');
        font-style: normal;
    }

    @font-face {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 400;
        src: url(${InterRegular}) format('truetype');
    }
    @font-face {
        font-family: 'Inter-400';
        font-style: normal;
        font-weight: 400;
        src: url(${InterRegular}) format('truetype');
    }
    @font-face {
        font-family: 'Inter-500';
        font-style: normal;
        font-weight: 500;
        src: url(${InterMedium}) format('truetype');
    }
    @font-face {
        font-family: 'Inter-600';
        font-style: normal;
        font-weight: 600;
        src: url(${InterSemiBold}) format('truetype');
    }
    @font-face {
        font-family: 'Inter-700';
        font-style: normal;
        font-weight: 700;
        src: url(${InterBold}) format('truetype');
    }

    @font-face {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        src: url(${InterMedium}) format('truetype');
    }

    * {
        box-sizing: border-box;
        
    }
`;

export default GlobalStyle;
