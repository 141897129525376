export default {
    path: {
        d1: "M11 19C11 18.4477 11.4477 18 12 18H20C20.5523 18 21 18.4477 21 19C21 19.5523 20.5523 20 20 20H12C11.4477 20 11 19.5523 11 19Z",
        d2: "M11 15C11 14.4477 11.4477 14 12 14H20C20.5523 14 21 14.4477 21 15C21 15.5523 20.5523 16 20 16H12C11.4477 16 11 15.5523 11 15Z",
        d3: "M5.58579 4.58579C5.96086 4.21071 6.46957 4 7 4H12C12.5523 4 13 4.44772 13 5C13 5.55228 12.5523 6 12 6H7L7 27H25V6H20C19.4477 6 19 5.55228 19 5C19 4.44772 19.4477 4 20 4H25C25.5304 4 26.0391 4.21071 26.4142 4.58579C26.7893 4.96086 27 5.46957 27 6V27C27 27.5304 26.7893 28.0391 26.4142 28.4142C26.0391 28.7893 25.5304 29 25 29H7C6.46957 29 5.96086 28.7893 5.58579 28.4142C5.21071 28.0391 5 27.5304 5 27V6C5 5.46957 5.21071 4.96086 5.58579 4.58579Z",
        d4: "M16 4C14.9391 4 13.9217 4.42143 13.1716 5.17157C12.4214 5.92172 12 6.93913 12 8H20C20 6.93913 19.5786 5.92172 18.8284 5.17157C18.0783 4.42143 17.0609 4 16 4ZM11.7574 3.75736C12.8826 2.63214 14.4087 2 16 2C17.5913 2 19.1174 2.63214 20.2426 3.75736C21.3679 4.88258 22 6.4087 22 8V9C22 9.55228 21.5523 10 21 10H11C10.4477 10 10 9.55228 10 9V8C10 6.4087 10.6321 4.88258 11.7574 3.75736Z",
    },
    properties: {
        viewBox: "0 0 32 32",
        outlined: false,
        strokeWidth: "0",
    },
};
