export const InvoiceCategory = {
  accountant: 4002001,
  advertising: 4002002,
  agencyFees: 4002003,
  agencyLoan: 4002004,
  agencyService: 4002005,
  airConditioningHeating: 4002006,
  antennas: 4002007,
  appliances: 4002008,
  bankFees: 4002009,
  bodyCorporate: 4002010,
  bondAuthority: 4002011,
  bufferLoan: 4002013,
  caretaking: 4002014,
  carpetCleaning: 4002015,
  cityCouncil: 4002016,
  cleaning: 4002017,
  commercialBond: 4002018,
  commercialChargeback: 4002019,
  commercialOther: 4002020,
  commercialOutgoings: 4002021,
  commercialRent: 4002022,
  depreciationSchedules: 4002023,
  dishonourFees: 4002024,
  electrical: 4002025,
  eofyStatementFee: 4002026,
  fencing: 4002028,
  fireProtection: 4002029,
  floorCoverings: 4002030,
  gardening: 4002031,
  generalMaintenance: 4002032,
  glazing: 4002033,
  inspectionFeesOther: 4002035,
  inspectionFeesCondition: 4002036,
  inspectionFeesFinal: 4002037,
  inspectionFeesRoutine: 4002038,
  insurance: 4002039,
  inventoryManagementFees: 4002040,
  keysLocksDoors: 4002041,
  landTax: 4002042,
  leasePreparationFees: 4002043,
  leaseRenewalFees: 4002044,
  legal: 4002045,
  leasingFee: 4002046,
  miscellaneous: 4002048,
  ownerPayment: 4002049,
  painting: 4002050,
  pestControl: 4002051,
  referenceCheckFees: 4002054,
  referrer: 4002055,
  registeredPostage: 4002056,
  renovation: 4002057,
  rentalGuarantee: 4002058,
  roofing: 4002059,
  rubbishRemoval: 4002060,
  securityServices: 4002061,
  surveying: 4002062,
  telephone: 4002063,
  tenantRefund: 4002064,
  tiling: 4002065,
  tribunalFees: 4002066,
  utilityElectrical: 4002067,
  utilityGas: 4002068,
  utilityWater: 4002069,
  utilityWaterUsage: 4002070,
  windowCoverings: 4002071,
  leaseBreakFees: 4002072,
  plumbing: 4002073,
  poolMaintenance: 4002074,
  adminFee: 4002076,
  unknown: 4002077,
  bondClaim: 4002078,
  compensation: 4002079,
  insuranceClaim: 4002080,
  internationalMoneyTransferFee: 4002081,
  invoiceManagementFee: 4002082,
  rentAdjustment: 4002083,
  rentManagementFee: 4002084,
  statementFees: 4002085,
  settlementAdjustment: 4002090,
  tenantTransferFee: 4002092,
  changeOfSharedTenancy: 4002093
} as const;

export type InvoiceCategoryType = keyof typeof InvoiceCategory;
export type InvoiceCategoryCodes = (typeof InvoiceCategory)[keyof typeof InvoiceCategory];
