export const iconSize = {
    major: "80px",
    xxl: "64px",
    xl: "48px",
    lg: "30px",
    md: "24px",
    sm: "20px",
    xs: "16px",
    xxs: "14px",
    micro: "12px",
};
export default iconSize;
