import { Card, coreThemeV2 } from "@kolmeo/ui-components";
import { Text, Box } from "@kolmeo/ui-core";

const CardWithTitle = ({
  children,
  cardTitle,
  secondaryComponent,
  squashToContent = false
}: { cardTitle: string; secondaryComponent?: JSX.Element; squashToContent?: boolean } & React.PropsWithChildren) => {
  const { palette, sizing } = coreThemeV2;
  return (
    <Card
      backgroundColor="white"
      flex={squashToContent ? undefined : 1} // Delete this to squash all the tiles height
      borderColor={palette.border.default}
      borderWidth={1}
      borderRadius={sizing.size20}
      padding={sizing.size20}
    >
      <Box justifyContent="space-between" flexDirection={"row"} alignContent="center">
        <Text variant="textHeavy16" color={palette.black} mb={sizing.size12}>
          {cardTitle}
        </Text>
        {secondaryComponent && secondaryComponent}
      </Box>

      {children}
    </Card>
  );
};

export default CardWithTitle;
