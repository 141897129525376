export default {
  navigation: {
    tab1: "Overview",
    tab2: "Tranasactions",
    tab3: "Statements"
  },
  overview: {
    title: "Property Overview",
    cardOneTitle: "Property Details"
  }
};
