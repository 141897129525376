export default {
    path: {
        d: "M15.7822 8.03217C16.0751 7.73928 16.5499 7.73928 16.8428 8.03217L20.7803 11.9697C21.0732 12.2626 21.0732 12.7374 20.7803 13.0303L16.8428 16.9678C16.5499 17.2607 16.0751 17.2607 15.7822 16.9678C15.4893 16.6749 15.4893 16.2001 15.7822 15.9072L19.1893 12.5L15.7822 9.09283C15.4893 8.79994 15.4893 8.32506 15.7822 8.03217Z M9 12.5C9 12.0858 9.33579 11.75 9.75 11.75H20.25C20.6642 11.75 21 12.0858 21 12.5C21 12.9142 20.6642 13.25 20.25 13.25H9.75C9.33579 13.25 9 12.9142 9 12.5Z M3.43934 3.93934C3.72064 3.65804 4.10217 3.5 4.5 3.5H9.75C10.1642 3.5 10.5 3.83579 10.5 4.25C10.5 4.66421 10.1642 5 9.75 5L4.5 5L4.5 20H9.75C10.1642 20 10.5 20.3358 10.5 20.75C10.5 21.1642 10.1642 21.5 9.75 21.5H4.5C4.10218 21.5 3.72065 21.342 3.43934 21.0607C3.15804 20.7794 3 20.3978 3 20V5C3 4.60217 3.15804 4.22064 3.43934 3.93934Z",
    },
    properties: {
        viewBox: "0 0 24 24",
        outlined: false,
        strokeWidth: "0",
    },
};
