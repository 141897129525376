import { useWindowDimensions } from 'react-native';
export const mediaQuery = {
    phone: 480,
    tablet: 720,
    laptop: 1200,
    desktop: 5000
};
export const useMaxWidth = (query) => {
    const { width } = useWindowDimensions();
    return width <= mediaQuery[query];
};
export const useMinWidth = (query) => {
    const { width } = useWindowDimensions();
    return width >= mediaQuery[query];
};
