export default {
    path: {
        d: "M20 5.83333H4.25556L8.23889 1.65083L6.66667 0L0 7L6.66667 14L8.23889 12.3492L4.25556 8.16667H20V5.83333Z",
    },
    properties: {
        viewBox: "0 0 20 14",
        outlined: false,
        strokeWidth: "0",
    },
};
