import { Box, Text, useTheme } from "@kolmeo/ui-core";
import Donuts from "@src/assets/svg/Donuts.svg";
import KeyHeart from "@src/assets/svg/KeyHeart.svg";

export type ScreenEmptyStateProps = {
  title: string;
  content?: string;
  textColor?: string;
  callToAction?: JSX.Element;
  svgType?: "Donuts" | "KeyHeart";
  setMaxHeight?: boolean;
  size?: "sm" | "md" | "lg";
};

export const ScreenEmptyState = ({
  title,
  content,
  textColor,
  callToAction,
  svgType = "Donuts",
  setMaxHeight,
  size = "md"
}: ScreenEmptyStateProps): JSX.Element => {
  const { colors, spacing, palette } = useTheme();

  const renderSvg = () => {
    switch (svgType) {
      case "KeyHeart": // this logo is currently unused
        return <KeyHeart height="100%" width="100%" viewBox="0 0 64 64" />;
      case "Donuts":
      default:
        return <Donuts height="100%" width="100%" viewBox="0 0 100 149" />;
    }
  };
  const getSize = () => {
    if (size == "sm") {
      return "64px";
    }
    if (size == "md") {
      return "120px";
    }
    if (size == "lg") {
      return "240px";
    }
  };

  return (
    <Box justifyContent="center" alignItems="center" height={setMaxHeight ? "100%" : ""} zIndex={-1}>
      <Box alignItems="center">
        <Box width={getSize()} marginBottom={spacing.px24}>
          {renderSvg()}
        </Box>
        <Text
          color={textColor ?? palette.text.primary}
          variant="textHeavy16"
          marginBottom={content ? spacing.px8 : spacing.px24}
          textAlign="center"
        >
          {title}
        </Text>
        {content && (
          <Text variant="paragraph14" textAlign="center" marginBottom={spacing.px24}>
            {content}
          </Text>
        )}
        <Box width={"100%"} alignItems={"center"}>
          {callToAction}
        </Box>
      </Box>
    </Box>
  );
};
