import { ButtonV2, Icon } from "@kolmeo/ui-components";
import { Box, Text, useTheme } from "@kolmeo/ui-core";
import { Modal } from "@src/components";
import { TenantThemeBrandingObject } from "@src/lib/Kiitos";
import { useTranslation } from "react-i18next";

type AlertModalType = {
  heading: string;
  iconType: "danger" | "success";
  customIcon?: JSX.Element;
  content: string | JSX.Element;
  isVisible: boolean;
  toggleVisibility: () => void;
  brandingObject?: TenantThemeBrandingObject;
};
const AlertModal = ({ heading, content, iconType, isVisible, toggleVisibility, brandingObject, customIcon }: AlertModalType) => {
  const { t } = useTranslation(["Common", "Tenant"]);
  const { spacing, palette, colors } = useTheme();

  const renderIcon = () => {
    if (customIcon) {
      return customIcon;
    }
    if (iconType == "danger") {
      return <Icon type={"warning-circle"} size={"xxl"} color={palette.danger.icon} />;
    }
    if (iconType == "success") {
      return <Icon type={"check-circle"} size={"xxl"} color={palette.success.icon} />;
    }
  };
  return (
    <Modal
      toggleVisible={toggleVisibility}
      isVisible={isVisible}
      title={heading}
      renderHeaderSeperator
      callToAction={
        <>
          <Box flexDirection={"row"} justifyContent={"space-between"}>
            <ButtonV2
              flex={1}
              size={"small"}
              width={"49%"}
              transparentHover
              variant="primary"
              overrideVariant={{
                bgColor: brandingObject?.primaryColor || colors.primary
              }}
              label={t("Common:Generic.OK").toUpperCase()}
              onPress={toggleVisibility}
            />
          </Box>
        </>
      }
    >
      <Box borderRadius={8}>
        <Box alignItems={"center"} marginBottom={spacing.px20}>
          {renderIcon()}
        </Box>
        {typeof content == "string" ? <Text variant="paragraph14">{content}</Text> : content}
      </Box>
    </Modal>
  );
};

export default AlertModal;
