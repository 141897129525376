export const TransactionStatus = {
  invalid: 4501001,
  new: 4501002,
  pending: 4501003,
  process: 4501004,
  complete: 4501005,
  reject: 4501006,
  manual: 4501007,
  error: 4501008,
  awaitingApproval: 4501009,
  transfer: 4501010
} as const;
export type TransactionStatusType = keyof typeof TransactionStatus;
export type TransactionStatusCodes = (typeof TransactionStatus)[keyof typeof TransactionStatus];
