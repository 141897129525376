const grayColors = {
    gray100: "rgba(243, 243, 247, 1)",
    gray200: "rgba(225, 225, 229, 1)",
    gray300: "rgba(187, 187, 190, 1)",
    gray400: "rgba(165, 165, 169, 1)",
    gray500: "rgba(144, 144, 147, 1)",
    gray600: "rgba(115, 115, 118, 1)",
    gray700: "rgba(86, 86, 88, 1)",
    gray800: "rgba(58, 58, 59, 1)",
    gray900: "rgba(29, 29, 29, 1)",
};
const whiteColors = {
    white100: "#ffffff",
    white85: "#ffffffd9",
    white55: "#ffffff8c",
    white40: "#FFFFFF66",
    white25: "#ffffff40",
};
const blackColors = {
    black100: "rgba(0, 0, 0, 1)",
    black85: "rgba(0, 0, 0, 0.85)",
    black55: "rgba(0, 0, 0, 0.55)",
    black40: "rgba(0, 0, 0, 0.4)",
    black25: "rgba(0, 0, 0, 0.25)",
};
const redColors = {
    red100: "#fcdad7",
    red200: "#f9b4af",
    red300: "#f68f88",
    red400: "#f36960",
    red500: "#f04438",
    red600: "#c0362d",
    red700: "#902922",
    red800: "#601b16",
    red900: "#300e0b",
};
const greenColors = {
    green100: "#ccf2dc",
    green200: "#99e5b9",
    green300: "#66d995",
    green400: "#33cc72",
    green500: "#00bf4f",
    green600: "#00993f",
    green700: "#00732f",
    green800: "#004c20",
    green900: "#002610",
};
const yellowColors = {
    yellow100: "#fff5cc",
    yellow200: "#ffeb99",
    yellow300: "#ffe066",
    yellow400: "#ffd633",
    yellow500: "#ffcc00",
    yellow600: "#cca300",
    yellow700: "#997a00",
    yellow800: "#665200",
    yellow900: "#332900",
};
const blueColors = {
    blue100: "#cce4ff",
    blue200: "#99caff",
    blue300: "#66afff",
    blue400: "#3395ff",
    blue500: "#007aff",
    blue600: "#0062cc",
    blue700: "#004999",
    blue800: "#003166",
    blue900: "#001833",
};
const neomintColors = {
    neomintLighten30: "rgba(206, 245, 228, 1)",
    neomintLighten15: "rgba(125, 230, 183, 1)",
    neomintLighten10: "rgba(104, 225, 171, 1)",
    neomint: "rgba(61, 217, 147, 1)",
    neomintTransparent40: "rgba(61, 217, 147, 0.4)",
    neomintDarken10: "rgba(37, 190, 121, 1)",
    neomintDarken15: "rgba(33, 168, 108, 1)",
    neomintDarken15Transparent40: "rgba(33, 168, 108, 0.4)",
};
const ateneoBlueColors = {
    ateneoBlueLighten15: "rgba(1, 185, 255, 1)",
    ateneoBlueLighten10: "rgba(0, 167, 231, 1)",
    ateneoBlue: "rgba(0, 130, 180, 1)",
    ateneoBlueDarken10: "rgba(0, 93, 129, 1)",
    ateneoBlueDarken15: "rgba(0, 75, 103, 1)",
};
const yvesKleinBlueColors = {
    yvesKleinBlue100: "rgba(211, 210, 240, 1)",
    yvesKleinBlue200: "rgba(168, 165, 225, 1)",
    yvesKleinBlue300: "rgba(124, 120, 210, 1)",
    yvesKleinBlue400: "rgba(81, 75, 195, 1)",
    yvesKleinBlue500: "rgba(37, 30, 180, 1)",
    yvesKleinBlue600: "rgba(30, 24, 144, 1)",
    yvesKleinBlue700: "rgba(22, 18, 108, 1)",
    yvesKleinBlue800: "rgba(15, 12, 72, 1)",
    yvesKleinBlue900: "rgba(7, 6, 36, 1)",
};
const persianBlueColors = {
    persianBlueLighten15: "rgba(71, 63, 223, 1)",
    persianBlueLighten10: "rgba(50, 42, 219, 1)",
    persianBlue: "rgba(37, 30, 180, 1)",
    persianBlueDarken10: "rgba(28, 23, 136, 1)",
    persianBlueDarken15: "rgba(24, 19, 114, 1)",
    persionBlueTransparent40: "rgba(37, 30, 180, 0.4)",
};
const taivasColors = {
    taivasLighten15: "rgba(239, 246, 252, 1)",
    taivasLighten10: "rgba(217, 235, 248, 1)",
    taivas: "rgba(174, 213, 240, 1)",
    taivasDarken10: "rgba(131, 191, 232, 1)",
    taivasDarken15: "rgba(109, 180, 228, 1)",
};
const hattaraColors = {
    hattaraLighten15: "rgba(255, 255, 255, 1)",
    hattaraLighten10: "rgba(253, 249, 254, 1)",
    hattara: "rgba(240, 209, 243, 1)",
    hattaraDarken10: "rgba(227, 169, 232, 1)",
    hattaraDarken15: "rgba(220, 148, 227, 1)",
};
const maizeColors = {
    maizeLighten15: "rgba(255, 168, 13, 0.15)",
    maizeLighten10: "rgba(255, 186, 64, 1)",
    maize: "rgba(255, 168, 13, 1)",
    maizeDarken10: "rgba(217, 139, 0, 1)",
    maizeDarken15: "rgba(191, 123, 0, 1)",
};
const kittiColors = {
    kittiLighten15: "rgba(253, 252, 252, 1)",
    kittiLighten10: "rgba(241, 240, 238, 1)",
    kitti: "rgba(219, 215, 209, 1)",
    kittiDarken10: "rgba(197, 190, 180, 1)",
    kittiDarken15: "rgba(185, 178, 166, 1)",
};
const paperiColors = {
    paperi: "rgba(243, 243, 247, 1)",
    paperiDarken10: "rgba(212, 212, 227, 1)",
    paperiDarken15: "rgba(197, 197, 216, 1)",
};
const feedbackColors = {
    successBorder: greenColors.green500,
    successBackground: greenColors.green100,
    successIcon: greenColors.green600,
    successText: greenColors.green800,
    warningBorder: yellowColors.yellow500,
    warningBackground: yellowColors.yellow100,
    warningIcon: yellowColors.yellow600,
    warningText: yellowColors.yellow800,
    dangerBorder: redColors.red500,
    dangerBackground: redColors.red100,
    dangerIcon: redColors.red600,
    destructiveBackground: redColors.red600,
    dangerText: redColors.red800,
};
const backgroundColors = {
    backgroundSurface: whiteColors.white100,
    backgroundDefault: grayColors.gray100,
    backgroundDisable: grayColors.gray300,
    backgroundBackdrop: blackColors.black40,
    backgroundDestructiveDefault: redColors.red600,
    backgroundSuccessDefault: greenColors.green600,
};
const monoColors = {
    kolmeoBlack: "rgba(19, 19, 19, 1)",
    placeHolder: "rgba(99, 99, 99, 1)",
    disabled: "rgba(189, 189, 189, 1)",
    lightGrey: "rgba(243, 243, 247, 1)",
    white: "rgba(255, 255, 255, 1)",
    transparentBlack60: "rgba(19, 19, 19, 0.6)",
};
const borders = {
    borderLight: grayColors.gray200,
    borderDefault: grayColors.gray300,
    borderHeavy: grayColors.gray500,
};
const shadowColors = {
    shadowFocusedBorder: blueColors.blue400,
    shadowFocused: blueColors.blue100,
};
const text = {
    textBrand: yvesKleinBlueColors.yvesKleinBlue600,
    textPrimary: blackColors.black100,
    textPrimaryInverted: whiteColors.white100,
    textSecondary: blackColors.black55,
    textDisabled: blackColors.black25,
    textSecondaryInverted: whiteColors.white55,
};
const tokens = {
    ...blackColors,
};
const utilityColors = {
    highlightLighten35: "rgba(255, 232, 191, 1)",
    highlight: "rgba(255, 168, 13, 1)",
    successLighten50: "rgba(221, 247, 232, 1)",
    success: "rgba(37, 190, 121, 1)",
    warning: "rgba(37, 190, 121, 1)",
    danger: "rgba(212, 63, 17, 1)",
    info: "rgba(204, 230, 240, 1)",
    infoBackground: "rgba(204, 228, 255, 1)",
    infoBorder: "rgba(0, 122, 255, 1)",
    infoIcon: blueColors.blue600,
    infoText: "rgba(0, 49, 102, 1)",
    borderLight: "rgba(225, 225, 229, 1)",
    backgroundPrimaryDefault: yvesKleinBlueColors.yvesKleinBlue600,
    backgroundPrimaryHover: yvesKleinBlueColors.yvesKleinBlue500,
    backgroundDefault: paperiColors.paperi,
    backgroundDisable: "rgba(187, 187, 190, 1)",
    backgroundSurface: monoColors.white,
    inputBorder: kittiColors.kittiDarken10,
    inputBorderFocused: "rgb(51, 149, 255)",
    backgroundPrimaryActive: yvesKleinBlueColors.yvesKleinBlue400,
};
const primaryColors = {
    ...neomintColors,
    ...ateneoBlueColors,
    ...persianBlueColors,
};
const secondaryColors = {
    ...taivasColors,
    ...hattaraColors,
    ...maizeColors,
    ...kittiColors,
    ...paperiColors,
    ...yvesKleinBlueColors,
};
const appColors = {
    ...primaryColors,
    ...secondaryColors,
    ...utilityColors,
    ...monoColors,
    ...borders,
    ...text,
    ...tokens,
    ...feedbackColors,
    ...backgroundColors,
    ...shadowColors,
};
export default appColors;
