export default {
    path: {
        d: "M224,48H32A16,16,0,0,0,16,64V192a16,16,0,0,0,16,16H224a16,16,0,0,0,16-16V64A16,16,0,0,0,224,48ZM80,160a8,8,0,0,1-5.66-2.34l-24-24a8,8,0,0,1,11.32-11.32L80,140.69l50.34-50.35a8,8,0,0,1,11.32,11.32l-56,56A8,8,0,0,1,80,160Zm133.66-58.34-56,56a8,8,0,0,1-11.32,0l-16-16a8,8,0,0,1,11.32-11.32L152,140.69l50.34-50.35a8,8,0,0,1,11.32,11.32Z",
    },
    properties: {
        viewBox: "0 0 256 256",
        outlined: false,
        strokeWidth: "0",
    },
};
