import { OccupancyStatus, OccupancyStatusCodes } from "@src/types";

export const useTenancyOccupancyStatus = (occupancyStatus?: OccupancyStatusCodes) => {
  const availableForLeastStatuses = [OccupancyStatus.active, OccupancyStatus.applied, OccupancyStatus.leaseSigned, OccupancyStatus.vacated];

  if (
    occupancyStatus &&
    availableForLeastStatuses.includes(occupancyStatus as IntentionalAny<"Error with typescript when inferring types">)
  ) {
    return true;
  }

  return false;
};
