import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

export type NavigationState = {
  previousScreenName?: string;
};

const initialState: NavigationState = {
  previousScreenName: ""
};

export const navigationSlice = createSlice({
  name: "navigation",
  initialState,
  reducers: {
    setPreviousScreenName: (state, action: PayloadAction<string | undefined>) => {
      state.previousScreenName = action.payload;
    },
    resetPreviousScreenName: (state) => {
      state.previousScreenName = undefined;
    }
  }
});

export const { setPreviousScreenName, resetPreviousScreenName } = navigationSlice.actions;

export default navigationSlice.reducer;
