import { UserProfile } from "@src/types";
import { transformProfileTypeId } from "@src/utils";

export const initPendoSession = (user: UserProfile, userHasOwnerAndTenantProfiles?: boolean) => {
  const { emailAddress, firstName, lastName, userId, profileTypeId } = user;

  if ((window as IntentionalAny<"Exists on window on Prod">).pendo) {
    (window as IntentionalAny<"Exists on window on Prod">).pendo.initialize({
      visitor: {
        id: userId,
        email: emailAddress,
        full_name: `${firstName} ${lastName}`,
        profile_type: userHasOwnerAndTenantProfiles ? "OwnerTenant" : transformProfileTypeId(profileTypeId),
        ownerOrTenant: "",
        agencyName: "",
        companyName: "",
        officeName: ""
      }
    });
  }
};

export const initPendoSessionWithEmailOnly = (emailAddress: string) => {
  if (!process.env.REACT_APP_Pendo_App_Id) return;

  if ((window as IntentionalAny<"Exists on window on Prod">).pendo) {
    (window as IntentionalAny<"Exists on window on Prod">).pendo.initialize({
      apiKey: process.env.REACT_APP_Pendo_App_Id,
      visitor: {
        id: emailAddress,
        email: emailAddress,
        full_name: emailAddress,
        ownerOrTenant: "",
        agencyName: "",
        companyName: "",
        officeName: ""
      }
    });
  }
};

export const updatePendoWithNewDetails = (ownerOrTenant: string, companyName: string, agencyName: string, officeName: string) => {
  if (!process.env.REACT_APP_Pendo_App_Id) return;

  if ((window as IntentionalAny<"Exists on window on Prod">).pendo) {
    (window as IntentionalAny<"Exists on window on Prod">).pendo.updateOptions({
      visitor: {
        ownerOrTenant: ownerOrTenant, // this can be "owner" or "tenant"
        companyName: companyName,
        agencyName: agencyName,
        officeName: officeName
      }
    });
  }
};

export const setPendoAccountDetails = (account: IntentionalAny<"Not sure yet">) => {
  (window as IntentionalAny<"Exists on window on Prod">).identify({ account });
};
