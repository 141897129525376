import { Icon } from "@kolmeo/ui-components";
import { Box, Text } from "@kolmeo/ui-core";
export interface IStepBadgeProps {
  isComplete: boolean;
  isActive: boolean;
  activeBgColor: string;
  inactiveBgColor: string;
  stepNumber: number;
  activeTextColor: string;
  inactiveTextColor: string;
  completedIconColor: string;
}

const StepBadge = ({
  isActive,
  activeBgColor,
  inactiveBgColor,
  stepNumber,
  activeTextColor,
  inactiveTextColor,
  isComplete,
  completedIconColor
}: IStepBadgeProps) => {
  const textColor = isComplete ? activeTextColor : isActive ? activeTextColor : inactiveTextColor;
  return (
    <Box
      minWidth={22}
      minHeight={22}
      borderRadius={50}
      paddingBottom={"1px"}
      backgroundColor={isComplete ? completedIconColor : isActive ? activeBgColor : "none"}
      borderColor={isActive ? (isComplete ? completedIconColor : activeBgColor) : inactiveBgColor}
      borderWidth={1.5}
      alignItems={"center"}
      justifyContent={"center"}
      marginRight={2}
    >
      {isComplete ? <Icon type={"check"} size={"micro"} color={textColor} /> : <Text color={textColor}>{stepNumber}</Text>}
    </Box>
  );
};

export default StepBadge;
