import { createElement as _createElement } from "react";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, coreTheme, ScrollView, Text, TouchableOpacity } from "@kolmeo/ui-core";
import { useLayoutEffect, useRef, useState } from "react";
import Icon from "../Icon";
const { palette } = coreTheme;
const CarouselNavigation = ({ menuButtons, menuItems, currentIndex, variant }) => {
    const scrollViewRef = useRef(null);
    const [itemPositions, setItemPositions] = useState(new Map());
    const { spacing } = coreTheme;
    useLayoutEffect(() => {
        if (scrollViewRef.current && itemPositions.has(currentIndex)) {
            scrollViewRef.current.scrollTo({
                x: itemPositions.get(currentIndex),
                y: 0,
                animated: false,
            });
        }
    }, [currentIndex, itemPositions]);
    return (_jsx(Box, { overflow: "hidden", paddingBottom: 2, children: _jsx(Box, { paddingTop: 13.5, paddingBottom: 13.5, backgroundColor: palette.kittiLighten15, borderBottomColor: palette.lightGrey, borderBottomWidth: 0.5, style: {
                shadowColor: palette.black,
                shadowOffset: {
                    width: 0,
                    height: 0,
                },
                shadowOpacity: 0.2,
                shadowRadius: 5,
                elevation: 6,
            }, children: _jsxs(ScrollView, { horizontal: true, bounces: true, scrollIndicatorInsets: { top: 0, left: 0, bottom: -6, right: 0 }, contentContainerStyle: {
                    height: 43,
                    justifyContent: "center",
                    alignItems: "flex-start",
                    padding: 0,
                    paddingLeft: spacing.spacing16,
                    paddingRight: spacing.spacing16,
                }, ref: scrollViewRef, children: [menuItems.map(({ index, ...rest }) => (_jsx(Box, { marginRight: "11px", onLayout: (event) => {
                            setItemPositions(new Map(itemPositions.set(index, event.nativeEvent.layout.x)));
                        }, children: _jsx(MenuItem, { index: index, ...rest, variant: variant }) }, index))), menuButtons?.map((props, index) => (_createElement(MenuButton, { ...props, key: index })))] }) }) }));
};
const MenuItem = ({ text, isActive, onPress, variant }) => (_jsx(TouchableOpacity, { disabled: isActive, onPress: onPress, height: "43px", width: "80px", borderRadius: 8, paddingX: "3px", paddingY: "10px", backgroundColor: isActive ? palette.persianBlueDarken15 : variant === "tertiary" ? palette.highlightLighten35 : palette.hattara, alignContent: "center", justifyContent: "center", style: isActive && {
        shadowColor: palette.black,
        shadowOffset: {
            width: 0,
            height: 4,
        },
        shadowOpacity: 0.2,
        shadowRadius: 5,
        elevation: 6,
    }, children: _jsx(Text, { style: { textTransform: "uppercase" }, variant: "buttonHeavy9", textAlign: "center", textBreakStrategy: "highQuality", numberOfLines: 2, color: isActive ? palette.white : palette.black, children: text }) }));
const MenuButton = ({ onPress, text, iconType }) => (_jsxs(TouchableOpacity, { onPress: onPress, marginRight: "11px", height: "43px", width: "80px", borderRadius: 8, paddingX: "3px", paddingY: "10px", backgroundColor: palette.lightGrey, alignItems: "center", justifyContent: "center", children: [_jsx(Box, { height: "12px", width: "12px", marginBottom: "5px", backgroundColor: palette.persianBlueDarken15, borderRadius: "6px", alignItems: "center", justifyContent: "center", children: _jsx(Icon, { type: iconType, size: "xxs", color: palette.white }) }), _jsx(Text, { style: { textTransform: "uppercase" }, variant: "buttonHeavy9", textAlign: "center", textBreakStrategy: "highQuality", numberOfLines: 1, color: palette.black, children: text })] }));
export default CarouselNavigation;
