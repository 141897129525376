import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { TenancyData } from "@src/types/tenancy/tenancy";

export type TenancyState = {
  tenancyId?: number;
  tenancyData?: TenancyData[];
};

const initialState: TenancyState = {
  tenancyId: 0,
  tenancyData: [] as TenancyData[]
};

export const tenancySlice = createSlice({
  name: "Tenancy",
  initialState,
  reducers: {
    setTenancyId: (state, action: PayloadAction<number>) => {
      state.tenancyId = action.payload;
    },
    resetTenancyId: (state) => {
      state.tenancyId = undefined;
    },
    setTenancyData: (state, action: PayloadAction<TenancyData[]>) => {
      console.log(action.payload);
      if (action.payload) state.tenancyData = action.payload;
    },
    resetTenancyData: (state) => {
      state.tenancyData = [];
    }
  }
});
export const { setTenancyId, resetTenancyId, setTenancyData, resetTenancyData } = tenancySlice.actions;

export default tenancySlice.reducer;
