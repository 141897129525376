export default {
    path: {
        fillRule: "evenodd",
        clipRule: "evenodd",
        d: "M14.6515 3.87333C15.02 3.53688 15.501 3.35031 16 3.35031C16.499 3.35031 16.98 3.53688 17.3485 3.87333C17.349 3.87378 17.3495 3.87423 17.35 3.87468L27.3475 12.9599L27.3616 12.973C27.5592 13.1596 27.7175 13.3839 27.8271 13.6326C27.9367 13.8814 27.9955 14.1495 27.9999 14.4213L28 14.4375V26H30C30.5523 26 31 26.4477 31 27C31 27.5523 30.5523 28 30 28H2C1.44772 28 1 27.5523 1 27C1 26.4477 1.44772 26 2 26H4V14.4375L4.00013 14.4213C4.00453 14.1495 4.06326 13.8814 4.17288 13.6326C4.28249 13.3839 4.44076 13.1596 4.63838 12.973L4.65247 12.9599L14.65 3.87468C14.6505 3.87423 14.651 3.87378 14.6515 3.87333ZM6 26H12V20C12 19.4696 12.2107 18.9609 12.5858 18.5858C12.9609 18.2107 13.4696 18 14 18H18C18.5304 18 19.0391 18.2107 19.4142 18.5858C19.7893 18.9609 20 19.4696 20 20V26H26V14.4511C25.9996 14.447 25.9986 14.4429 25.997 14.4392C25.9953 14.4355 25.9932 14.4322 25.9905 14.4292L16.0025 5.35256L16 5.35031L15.9975 5.35256L6.00947 14.4292C6.00682 14.4322 6.00465 14.4355 6.00305 14.4392C6.00138 14.4429 6.00035 14.447 6 14.4511V26ZM18 26V20H14V26H18Z",
    },
    properties: {
        viewBox: "0 0 32 32",
        outlined: false,
        strokeWidth: "0",
    },
};
