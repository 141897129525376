export const spacing = {
    sp4: 4,
    sp8: 8,
    sp12: 12,
    sp16: 16,
    sp20: 20,
    sp24: 24,
    sp32: 32,
    sp40: 40,
    sp64: 64,
    sp80: 80,
};
export default spacing;
