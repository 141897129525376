export const transformScreenName = (screenName: string) => {
  switch (screenName) {
    case "RequestList":
      return "Requests";
    case "MyProperties":
      return "My Properties";
    default:
      return screenName;
  }
};
