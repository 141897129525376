export default {
  WelcomeTitle: "Welcome!",
  AccountNeedsSettingUp: "Your account needs setting up",
  NoPropertiesLinked: "There are no properties linked to the email you’ve logged in with, so there’s not much to see here.",
  ContactPropertyManager: "To use this email for your account, contact your property manager and they will get you set up.",
  SkipUntilNextLogin: "Skip until next login",
  ReadyToViewProperties: "Now you’re ready to view your properties, access your property manager details and more.",
  SelectProfileView: "Select which profile view you’d like to log into first (you can switch between these later).",
  WhichProfileView: "Which profile view would you like to log into?",
  OwnershipProfiles: "Your ownership profiles",
  AcceptProfiles: "By accepting these profiles, you are agreeing to our",
  OwnerTermsConditions: "Owner terms & conditions",
  TenancyProfiles: "Your tenancy profiles",
  TenantTermsConditions: "Tenant terms & conditions",
  StartAcceptingProfiles: "Let’s start by accepting the profiles for properties you’d like to view in your account.",
  NewProfilesFound: "We’ve found new profiles associated to your account in our system."
};
