import { LeaseStatus, RentFrequency, type RentFrequencyCodes } from "@src/types";
import { GetTenancyDetailsResponse } from "@src/navigation/TenancyNavigator/types";
import { addDays, isBefore, isEqual, isToday, parseISO } from "date-fns";
import { LeasePaymentStatus, LeasePaymentStatusValues } from "../../components/PayRentModal/types";
import {
  calculateFrequencyPeriodTo,
  getTodayDateWithTimeZero,
  isTodayMatchTheRentCycleStartDateThisMonth
} from "../../components/PayRentModal/utils/dateFormatters";

export const calculateHasNoRentRecords = (data: GetTenancyDetailsResponse): boolean => {
  return !data.rentAmount && !data.nextRentAmount && !data.rentRecordFrom && !data.rentRecordTo;
};

export const calculateApplicableRentAmount = (data: GetTenancyDetailsResponse): number => {
  return calculateIsNextRentApplicable(data) ? data.nextRentAmount || data.rentAmount : data.rentAmount;
};

export const calculateIsNoCurrentRentRecord = (data: GetTenancyDetailsResponse): boolean => {
  const applicableRentWithNullAndUndefined = calculateIsNextRentApplicable(data) ? data.nextRentAmount : data.rentAmount;
  const activeLeaseVacatedDate = data?.leases?.find((ld) => ld.leaseStatus === LeaseStatus.active)?.vacateDate;
  if (activeLeaseVacatedDate && isBefore(new Date(activeLeaseVacatedDate), getTodayDateWithTimeZero())) {
    return false;
  }
  return applicableRentWithNullAndUndefined === null || applicableRentWithNullAndUndefined === undefined;
};

// applicable arrears calculation should be using this
export const calculateApplicableAddedOverdueRentAmount = (data: GetTenancyDetailsResponse): number => {
  return !data.rentRecordTo || new Date(data.rentRecordTo) < getTodayDateWithTimeZero()
    ? calculateApplicableRentAmount(data)
    : data.rentAmount;
};

export const calculateIsNextRentApplicable = (data: GetTenancyDetailsResponse): boolean => {
  if (
    data.rentRecordTo &&
    !data.nextDueDate &&
    new Date(data.rentRecordTo) < getTodayDateWithTimeZero() &&
    !calculateHasOverpaidInFutureCycles(data)
  ) {
    return true;
  }

  if (data.paidToActual && data.nextDueDate && isEqual(addDays(new Date(data.paidToActual), 1), new Date(data.nextDueDate))) {
    return false;
  }
  return !!data.rentRecordTo && !!data.nextDueDate && new Date(data.rentRecordTo) < new Date(data.nextDueDate);
};

export const calculateApplicableFrequency = (data: GetTenancyDetailsResponse): RentFrequencyCodes => {
  return calculateIsNextRentApplicable(data) ? data.nextRentFrequencyCode || data.rentFrequencyCode : data.rentFrequencyCode;
};

export const calculateHasAdvancedPayment = (data: GetTenancyDetailsResponse): boolean => {
  return !!data.paidToActual && !!data.nextDueDate && new Date(data.paidToActual) > new Date(data.nextDueDate);
};

export const calculateOutstandingAmount = (data: GetTenancyDetailsResponse): number | null => {
  if (!data.arrearsToNextDueDate && data.partPayment && data.rentAmount) {
    return data.rentAmount - data.partPayment;
  }
  return null;
};

export const calculateIsArrearsMultipleCycles = (data: GetTenancyDetailsResponse): boolean => {
  // multi cycle arrears because there is arrears amount and today's cycle is overdue
  if (data.arrearsToNextDueDate && isAddRentAmountIntoArrearsAmount(data)) {
    return true;
  }

  // multi cycle arrears because arrears amount is higher than rent amount
  if (data.arrearsToNextDueDate && data.arrearsToNextDueDate > data.rentAmount) {
    return true;
  }
  return false;
};

export const calculateHasOverpaidInFutureCycles = (data: GetTenancyDetailsResponse): boolean => {
  if (!!data.nextDueDate && !!data.paidToActual && new Date(data.paidToActual) > new Date(data.nextDueDate)) {
    return true;
  }
  return false;
};

export const calculateOverdueArrearsAmount = (data: GetTenancyDetailsResponse): number | null => {
  if (calculateIsArrears(data)) {
    const partPayment = data.partPayment ? data.partPayment : 0;
    if (isAddRentAmountIntoArrearsAmount(data)) {
      if (calculateIsArrearsMultipleCycles(data)) {
        // if multiple cycle, the part payment is already included in arrearsToNextDueDate
        return (data.arrearsToNextDueDate || 0) + calculateApplicableAddedOverdueRentAmount(data);
      }
      return (data.arrearsToNextDueDate || 0) + calculateApplicableAddedOverdueRentAmount(data) - partPayment;
    }
    return data.arrearsToNextDueDate || 0;
  }
  return 0;
};

export const calculateIsArrears = (data: GetTenancyDetailsResponse): boolean => {
  if (isRentUnpaid(data)) {
    return true;
  }
  return data.arrearsToNextDueDate ? data.arrearsToNextDueDate > 0 : false;
};

export const calculateIsPaidToActualAdjustedByPartPayment = (data: GetTenancyDetailsResponse): boolean => {
  const rentRecordStartDate = data.rentRecordFrom ? parseISO(data.rentRecordFrom) : null;
  const paidToActualDate = data.paidToActual ? parseISO(data.paidToActual) : null;

  if (
    paidToActualDate &&
    rentRecordStartDate &&
    isEqual(paidToActualDate, rentRecordStartDate) &&
    data.partPayment &&
    data.partPayment > 0
  ) {
    return true;
  }
  return false;
};

const isLeaseFinishingThisCycle = (data: GetTenancyDetailsResponse): boolean => {
  const activeLeaseVacatedDate = data?.leases?.find((ld) => ld.leaseStatus === LeaseStatus.active)?.vacateDate;

  if (
    !data.nextDueDate &&
    !!activeLeaseVacatedDate &&
    !data.nextRentAmount &&
    data.rentAmount &&
    data.rentRecordTo &&
    !isBefore(new Date(data.rentRecordTo), getTodayDateWithTimeZero()) &&
    !isBefore(new Date(activeLeaseVacatedDate), getTodayDateWithTimeZero())
  ) {
    return true;
  }
  return false;
};

const isLeaseFinishingUpcomingCycle = (data: GetTenancyDetailsResponse): boolean => {
  const activeLeaseVacatedDate = data?.leases?.find((ld) => ld.leaseStatus === LeaseStatus.active)?.vacateDate;

  if (!data.rentRecordTo || !data.rentRecordFrom || !activeLeaseVacatedDate) return false;
  if (!data.nextDueDate) return false;

  const rentRecordToDate = new Date(data.rentRecordTo);
  const nextDueDate = new Date(data.nextDueDate);
  const rentRecordFromDate = new Date(data.rentRecordFrom);
  const vacateDate = new Date(activeLeaseVacatedDate);

  if (calculateIsNextRentApplicable(data)) {
    if (!data.nextRentFrequencyCode) return false;

    if (data.nextRentFrequencyCode === RentFrequency.ProRata && data.nextRentProRataEndDate) {
      return isEqual(parseISO(data.nextRentProRataEndDate), vacateDate);
    }

    // this logic below will not work in some edge case scenario on if the nextRentFrequencyCode is monthly
    // as nextDueDate is passed in as rentRecordFromDate (third parameter) to calculateFrequencyPeriodTo
    const periodEndDateWithNextDueDateAsFromDate = calculateFrequencyPeriodTo(nextDueDate, data.nextRentFrequencyCode, nextDueDate);
    return isEqual(periodEndDateWithNextDueDateAsFromDate, vacateDate);
  }

  if (data.rentFrequencyCode === RentFrequency.ProRata && rentRecordToDate) {
    return isEqual(rentRecordToDate, vacateDate);
  }

  const periodEndDate = calculateFrequencyPeriodTo(nextDueDate, data.rentFrequencyCode, rentRecordFromDate);
  return isEqual(periodEndDate, vacateDate);
};

export const calculateTenantHasNothingMoreToPay = (data: GetTenancyDetailsResponse): boolean => {
  return !data.activeCycleFrom && !data.activeCycleTo && !data.activeNextCycleFrom;
};

export const checkTenantLeaseStatus = (data: GetTenancyDetailsResponse): LeasePaymentStatusValues | null => {
  const leaseEndingUpcomingCycle = isLeaseFinishingUpcomingCycle(data);
  const leaseEndingThisCycle = isLeaseFinishingThisCycle(data);
  const paidUpToDate = calculateTenantHasNothingMoreToPay(data);
  if (leaseEndingThisCycle && paidUpToDate) {
    return LeasePaymentStatus.LeaseEndingThisCycleNoMoreToPay;
  } else if (leaseEndingThisCycle && !paidUpToDate) {
    return LeasePaymentStatus.LeaseEndingThisCycleMoreToPay;
  } else if (leaseEndingUpcomingCycle && paidUpToDate) {
    return LeasePaymentStatus.LeaseEndingUpcomingCycleNoMoreToPay;
  } else if (leaseEndingUpcomingCycle && !paidUpToDate) {
    return LeasePaymentStatus.LeaseEndingUpcomingCycleMoreToPay;
  } else if (paidUpToDate) {
    return LeasePaymentStatus.LeaseActiveNoMoreToPay;
  } else {
    return null;
  }
};

const isRentUnpaid = (data: GetTenancyDetailsResponse): boolean => {
  const rentRecordStartDate = data.rentRecordFrom ? parseISO(data.rentRecordFrom) : null;
  const nextDueDate = data.nextDueDate ? parseISO(data.nextDueDate) : null;

  if (!data.paidToActual && rentRecordStartDate && isToday(rentRecordStartDate)) {
    return true;
  }
  if (!data.paidToActual && nextDueDate && rentRecordStartDate && nextDueDate > rentRecordStartDate) {
    return true;
  }
  if (
    data.paidToActual &&
    isToday(addDays(parseISO(data.paidToActual), 1)) &&
    (!data.rentRecordTo || parseISO(data.paidToActual) < parseISO(data.rentRecordTo))
  ) {
    return true;
  }

  // rent record is before today or is today.
  if (
    rentRecordStartDate &&
    (isBefore(rentRecordStartDate, getTodayDateWithTimeZero()) || isToday(rentRecordStartDate)) &&
    calculateIsPaidToActualAdjustedByPartPayment(data)
  ) {
    return true;
  }
  return false;
};

// ## Refactor: create const for data.paidToActual, data.rentRecordTo
// But do not combine logic until we discovered all testing scenario
// We want the logic for different scenario isolated for now
// do not combine this with isRentUnpaid either
// do not remove console.log until 70053 is merging to dev-release
export const isAddRentAmountIntoArrearsAmount = (data: GetTenancyDetailsResponse) => {
  const rentRecordStartDate = data.rentRecordFrom ? parseISO(data.rentRecordFrom) : null;
  const nextDueDate = data.nextDueDate ? parseISO(data.nextDueDate) : null;
  const rentRecordEndDate = data.rentRecordTo ? parseISO(data.rentRecordTo) : null;

  if (!data.paidToActual && rentRecordStartDate && isToday(rentRecordStartDate)) {
    console.log("isAddRentAmountIntoArrearsAmount - logic S1");
    return true;
  }

  if (
    !data.paidToActual &&
    nextDueDate &&
    rentRecordStartDate &&
    nextDueDate > rentRecordStartDate &&
    (isTodayMatchTheRentCycleStartDateThisMonth(rentRecordStartDate) ||
      data.rentFrequencyCode === RentFrequency.Weekly ||
      data.rentFrequencyCode === RentFrequency.Fortnightly) &&
    isEqual(addDays(calculateFrequencyPeriodTo(getTodayDateWithTimeZero(), data.rentFrequencyCode, rentRecordStartDate), 1), nextDueDate)
  ) {
    console.log("isAddRentAmountIntoArrearsAmount - logic I1");
    return true;
  }

  if (
    !data.paidToActual &&
    nextDueDate &&
    rentRecordStartDate &&
    nextDueDate > rentRecordStartDate &&
    rentRecordEndDate &&
    data.rentFrequencyCode === RentFrequency.ProRata &&
    isEqual(addDays(rentRecordEndDate, 1), nextDueDate)
  ) {
    console.log("isAddRentAmountIntoArrearsAmount - logic I1-PR");
    return true;
  }

  // this logic does not have any equivalent pro rata logic, since pro rata is always only 1 cycle
  if (
    !data.paidToActual &&
    !nextDueDate &&
    rentRecordStartDate &&
    rentRecordEndDate &&
    (isTodayMatchTheRentCycleStartDateThisMonth(rentRecordStartDate) ||
      data.rentFrequencyCode === RentFrequency.Weekly ||
      data.rentFrequencyCode === RentFrequency.Fortnightly) &&
    isEqual(calculateFrequencyPeriodTo(getTodayDateWithTimeZero(), data.rentFrequencyCode, rentRecordStartDate), rentRecordEndDate)
  ) {
    console.log("isAddRentAmountIntoArrearsAmount logic I2");
    return true;
  }

  if (
    data.paidToActual &&
    isToday(addDays(parseISO(data.paidToActual), 1)) &&
    (!data.rentRecordTo || parseISO(data.paidToActual) < parseISO(data.rentRecordTo))
  ) {
    console.log("isAddRentAmountIntoArrearsAmount logic S2");
    return true;
  }

  if (rentRecordStartDate && isToday(rentRecordStartDate) && calculateIsPaidToActualAdjustedByPartPayment(data)) {
    console.log("isAddRentAmountIntoArrearsAmount logic S3");
    return true;
  }

  if (
    data.paidToActual &&
    nextDueDate &&
    rentRecordStartDate &&
    addDays(parseISO(data.paidToActual), 1) < getTodayDateWithTimeZero() &&
    (isTodayMatchTheRentCycleStartDateThisMonth(rentRecordStartDate) ||
      data.rentFrequencyCode === RentFrequency.Weekly ||
      data.rentFrequencyCode === RentFrequency.Fortnightly) &&
    isEqual(addDays(calculateFrequencyPeriodTo(getTodayDateWithTimeZero(), data.rentFrequencyCode, rentRecordStartDate), 1), nextDueDate)
  ) {
    console.log("logic 3");
    return true;
  }

  // similar to above logic, but handle the case that tenant is in their last cycle which means no nextDueDate, so we compare the today's end of cycle
  // to rentRecordEndDate
  // please read the comment above, do not combine logic
  if (
    data.paidToActual &&
    !nextDueDate &&
    rentRecordStartDate &&
    rentRecordEndDate &&
    addDays(parseISO(data.paidToActual), 1) < getTodayDateWithTimeZero() &&
    (isTodayMatchTheRentCycleStartDateThisMonth(rentRecordStartDate) ||
      data.rentFrequencyCode === RentFrequency.Weekly ||
      data.rentFrequencyCode === RentFrequency.Fortnightly) &&
    isEqual(calculateFrequencyPeriodTo(getTodayDateWithTimeZero(), data.rentFrequencyCode, rentRecordStartDate), rentRecordEndDate)
  ) {
    console.log("isAddRentAmountIntoArrearsAmount logic I4");
    return true;
  }

  if (
    data.paidToActual &&
    rentRecordEndDate &&
    isBefore(parseISO(data.paidToActual), rentRecordEndDate) &&
    isEqual(addDays(rentRecordEndDate, 1), getTodayDateWithTimeZero())
  ) {
    console.log("isAddRentAmountIntoArrearsAmount logic A1");
    return true;
  }

  if (
    !data.paidToActual &&
    rentRecordEndDate &&
    nextDueDate &&
    rentRecordEndDate < nextDueDate &&
    isEqual(addDays(rentRecordEndDate, 1), getTodayDateWithTimeZero())
  ) {
    console.log("isAddRentAmountIntoArrearsAmount logic A2");
    return true;
  }

  if (!data.paidToActual && !nextDueDate && rentRecordEndDate && isEqual(addDays(rentRecordEndDate, 1), getTodayDateWithTimeZero())) {
    console.log("isAddRentAmountIntoArrearsAmount logic A3");
    return true;
  }

  return false;
};
