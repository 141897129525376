export default {
    path: {
        d: "M20.25 6C20.25 5.60218 20.092 5.22064 19.8107 4.93934C19.5294 4.65804 19.1478 4.5 18.75 4.5H15.75V1.5C15.75 1.10218 15.592 0.720644 15.3107 0.43934C15.0294 0.158035 14.6478 0 14.25 0H2.25C1.85218 0 1.47065 0.158035 1.18934 0.43934C0.908038 0.720644 0.750003 1.10218 0.750003 1.5V13.5C0.749603 13.642 0.790043 13.7812 0.866503 13.9008C0.942963 14.0205 1.05222 14.1157 1.18125 14.175C1.30763 14.2368 1.44914 14.2608 1.58882 14.2442C1.72849 14.2275 1.86041 14.171 1.96875 14.0813L5.25 11.4281V14.25C5.25 14.6478 5.40804 15.0294 5.68934 15.3107C5.97065 15.592 6.35218 15.75 6.75 15.75H15.525L19.0313 18.5813C19.1396 18.671 19.2715 18.7275 19.4112 18.7442C19.5509 18.7608 19.6924 18.7368 19.8188 18.675C19.9478 18.6157 20.057 18.5205 20.1335 18.4008C20.21 18.2812 20.2504 18.142 20.25 18V6ZM16.2563 14.4188C16.125 14.3087 15.9588 14.2488 15.7875 14.25H6.75V11.25H14.25C14.6478 11.25 15.0294 11.092 15.3107 10.8107C15.592 10.5294 15.75 10.1478 15.75 9.75V6H18.75V16.425L16.2563 14.4188Z",
    },
    properties: {
        viewBox: "0 0 21 19",
        outlined: false,
        strokeWidth: "0",
    },
};
