import { getAllTenanciesHandler } from "./getAllTenancies";
import {
  getTenancyDetailsActiveHandler,
  getTenancyDetailsInactiveHandler,
  getTenancyDetailsDirectDebitHandler,
  getTenancyDetailsFailedHandler
} from "./getTenancyDetails";

export { getAllTenanciesMock } from "./getAllTenancies";
export { getTenancyDetailsBaseMock } from "./getTenancyDetails";

export const tenancyOverviewScreenHandlers = [
  getAllTenanciesHandler,
  getTenancyDetailsActiveHandler,
  getTenancyDetailsInactiveHandler,
  getTenancyDetailsDirectDebitHandler,
  getTenancyDetailsFailedHandler
];
