import { WebStorageStateStore } from "oidc-client-ts";
import { b2cSettings } from "./b2cSettings";
import { AuthProvider as ReactOidcAuthProvider, AuthProviderProps } from "react-oidc-context";
import { ensureTrailingForwardslash } from "../../shared";

const valueOrDefault = (value: string | null | undefined, defaultValue: string) => (value && value != "null" ? value : defaultValue);

const redirectUrlSetting = valueOrDefault(b2cSettings.redirectUrl, "dynamic");
const redirectUrl = ensureTrailingForwardslash(redirectUrlSetting == "dynamic" ? window.location.origin : redirectUrlSetting);
const testAuthBaseUrl = valueOrDefault(b2cSettings.testAuthBaseUrl, "");
const authority = valueOrDefault(b2cSettings.authority, "");
const clientId = valueOrDefault(!testAuthBaseUrl ? b2cSettings.clientId : "", "kolmeo-test-auth");

if (!authority) {
  throw console.error("Error: Authority not specified (openid configuration)");
}

const oidcConfig: AuthProviderProps = {
  authority: authority,
  client_id: clientId,
  redirect_uri: `${redirectUrl}auth-callback/`,
  scope: "openid",
  response_type: "code",
  userStore: new WebStorageStateStore({
    store: localStorage
  })
};

const AuthProvider = ({ children }: React.PropsWithChildren): JSX.Element => {
  return <ReactOidcAuthProvider {...oidcConfig}>{children}</ReactOidcAuthProvider>;
};

export default AuthProvider;
