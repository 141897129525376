import { RentFrequency } from "@src/types";
import { DATE_FORMAT_SHORT } from "@src/utils";
import { addDays, addMonths, endOfMonth, format, formatDistanceStrict, isEqual, subDays } from "date-fns";

export const getTodayDateWithTimeZero = (): Date => {
  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth();
  const day = now.getDate();

  return new Date(year, month, day, 0, 0, 0);
};

export const formatPeriod = (paidToActual: Date, nextDueDate: Date): string => {
  const periodFrom = format(paidToActual, DATE_FORMAT_SHORT);
  const periodTo = format(subDays(nextDueDate, 1), DATE_FORMAT_SHORT);
  return `${periodFrom} - ${periodTo}`;
};

// ## REFACTOR: merge with above formatPeriod and do the subdays outside
export const formatPeriodNoAlteration = (dateFrom: Date, dateTo: Date): string => {
  const periodFrom = format(dateFrom, DATE_FORMAT_SHORT);
  const periodTo = format(dateTo, DATE_FORMAT_SHORT);
  return `${periodFrom} - ${periodTo}`;
};

export const calculateFrequencyPeriodTo = (periodFromDate: Date, rentFrequencyCode: number, rentRecordFromDate: Date): Date => {
  switch (rentFrequencyCode) {
    case RentFrequency.Weekly:
      return addDays(periodFromDate, 6);
    case RentFrequency.Fortnightly:
      return addDays(periodFromDate, 13);
    case RentFrequency.Monthly: {
      const rentRecordDatePart = rentRecordFromDate.getDate();
      const next_lastDayOfMonth = endOfMonth(addMonths(periodFromDate, 1));
      const next_periodFrom = new Date(
        next_lastDayOfMonth.getFullYear(),
        next_lastDayOfMonth.getMonth(),
        rentRecordDatePart <= next_lastDayOfMonth.getDate() ? rentRecordDatePart : next_lastDayOfMonth.getDate()
      );

      return subDays(next_periodFrom, 1);
    }
    default:
      return periodFromDate;
  }
};

export const formatFrequencyPeriod = (nextDueDate: Date, rentFrequencyCode: number, rentRecordFromDate: Date): string => {
  const periodFrom = format(nextDueDate, DATE_FORMAT_SHORT);
  const periodTo = format(calculateFrequencyPeriodTo(nextDueDate, rentFrequencyCode, rentRecordFromDate), DATE_FORMAT_SHORT);
  return `${periodFrom} - ${periodTo}`;
};

export const formatRentPeriod = (fromDate: Date, toDate: Date) => {
  const periodFrom = format(fromDate, DATE_FORMAT_SHORT);
  const periodTo = format(toDate, DATE_FORMAT_SHORT);
  return `${periodFrom} - ${periodTo}`;
};

export const getRentCycleStartDateOfCurrentMonth = (currentDate: Date, rentRecordFromDate: Date): Date => {
  const rentRecordDatePart = rentRecordFromDate.getDate();
  const current_lastDayOfMonth = endOfMonth(currentDate);
  const rentCycleStartDateOfCurrentMonth = new Date(
    currentDate.getFullYear(),
    currentDate.getMonth(),
    rentRecordDatePart <= current_lastDayOfMonth.getDate() ? rentRecordDatePart : current_lastDayOfMonth.getDate()
  );

  return rentCycleStartDateOfCurrentMonth;
};

export const isTodayMatchTheRentCycleStartDateThisMonth = (rentRecordStartDate: Date): boolean => {
  return isEqual(getTodayDateWithTimeZero(), getRentCycleStartDateOfCurrentMonth(getTodayDateWithTimeZero(), rentRecordStartDate));
};

export const formatDaysOrDate = (input: number | Date): string => {
  if (typeof input === "number") {
    return `${input} ${input === 1 ? "day" : "days"}`;
  } else if (input instanceof Date) {
    return formatDistanceStrict(input, new Date(), { unit: "day", roundingMethod: "ceil" });
  } else {
    throw new Error("Input must be a number or a Date");
  }
};
