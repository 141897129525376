import axios from "axios";
import { useState } from "react";

export const useDownloadFile = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const download = async ({
    fileUrl,
    fileName = "download",
    authToken,
    fileType
  }: {
    fileUrl: string;
    fileName?: string;
    authToken?: string;
    fileType?: string;
  }) => {
    setLoading(true);
    setError(null);
    try {
      const response = await axios({
        url: fileUrl,
        method: "GET",
        responseType: "blob",
        headers: authToken ? { Authorization: `Bearer ${authToken}` } : {}
      });

      const extension = fileType?.toLocaleLowerCase() || "";
      const downloadFileName = extension ? `${fileName}.${extension}` : fileName;

      const href = URL.createObjectURL(response.data);
      const link = document.createElement("a");

      link.href = href;
      link.setAttribute("download", downloadFileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    } catch (error) {
      console.error("Download error:", error);
      setError((error as Error).message);
    } finally {
      setLoading(false);
    }
  };

  return { download, loading, error };
};
