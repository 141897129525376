import { useTranslation } from "react-i18next";
import { ActionRequiredModal } from "@src/components";
import { TenantThemeBrandingObject } from "@src/lib/Kiitos";

export type ModalCancelFormProps = {
  toggleVisibility: () => void;
  responsivenessFlag: boolean;
  onConfirmPress: () => void;
  isVisible: boolean;
  brandingObject?: TenantThemeBrandingObject;
  altContent?: string;
};
const ModalCancelForm = ({ isVisible, toggleVisibility, brandingObject, onConfirmPress }: ModalCancelFormProps) => {
  const { t } = useTranslation(["Common"]);
  return (
    <ActionRequiredModal
      heading={t("Common:Generic.AreYouSureYouWantToLeave")}
      iconType={"danger"}
      actionType={"success"}
      brandingObject={brandingObject}
      content={t("Common:Generic.DataLostWarning")}
      isVisible={isVisible}
      toggleVisibility={toggleVisibility}
      rightText={t("Common:Forms.StayOnPage")}
      leftText={t("Common:Generic.Leave")}
      leftButtonDestructive={true}
      onRightButtonPress={toggleVisibility}
      onLeftButtonPress={onConfirmPress}
    />
  );
};

export default ModalCancelForm;
