export interface KolmeoB2CSettings {
  testAuthBaseUrl?: string;
  clientId?: string;
  redirectUrl: string | undefined;
  authority?: string;
}
const FEATURE = process.env.REACT_APP_FEATURE_ENV;
const authority = FEATURE
  ? `https://featurekolmeo.b2clogin.com/featurekolmeo.onmicrosoft.com/b2c_1a_owner_tenant_signinsignup_kol-${FEATURE}/v2.0`
  : process.env.REACT_APP_Azure_B2C_OwnerTenant_Authority;

const testAuthBaseUrl = process.env.REACT_APP_TestAuth_BaseUrl;
const clientId = FEATURE ? process.env.REACT_APP_AzureADB2C_FeatureEnv_ClientId : process.env.REACT_APP_AzureADB2C_OwnerTenant_ClientId;
const redirectUrl = process.env.REACT_APP_AzureADB2C_OwnerTenant_RedirectUrl;

export const b2cSettings: KolmeoB2CSettings = {
  testAuthBaseUrl: testAuthBaseUrl,
  clientId: clientId,
  redirectUrl: redirectUrl,
  authority: authority
};
