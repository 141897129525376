import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import { Platform } from "react-native";
import ReactTooltip from "react-tooltip";
import { Box, coreTheme, Text } from "@kolmeo/ui-core";
import Icon from "../Icon";
const AlertBox = ({ variant, content }) => {
    const { palette } = coreTheme;
    useEffect(() => {
        Platform.OS === "web" && ReactTooltip.rebuild();
    }, []);
    const typeColorCombination = {
        warning: {
            bgColor: palette.maizeLighten15,
            color: palette.kolmeoBlack,
            borderColor: palette.transparent,
            iconColor: palette.maize,
            icon: _jsx(Icon, { type: "information", size: "md", color: palette.maize }),
        },
        none: null,
    };
    const currentVariant = typeColorCombination[variant ? variant : "none"];
    return (_jsxs(Box, { flexDirection: "row", backgroundColor: currentVariant?.bgColor, padding: 2, paddingY: 3, borderRadius: 4, children: [_jsx(Box, { flex: 0.5, alignItems: "center", justifyContent: "center", children: currentVariant?.icon }), _jsx(Box, { flex: 3, children: _jsx(Text, { children: content }) })] }));
};
export default AlertBox;
