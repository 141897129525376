import { useState, useEffect } from "react";

export const useSplitScreenContainer = ({
  isSideScreenActive
}: {
  isSideScreenActive: boolean;
  setSideActionHandler: IntentionalAny<"function that clears state">;
}) => {
  const [isMobileDetailsOpen, setIsMobileDetailsOpen] = useState<boolean>(false);

  useEffect(() => {
    isSideScreenActive ? setIsMobileDetailsOpen(true) : setIsMobileDetailsOpen(false);
  }, [isSideScreenActive]);

  const toggleSideScreen = () => {
    setIsMobileDetailsOpen(!isMobileDetailsOpen);
  };

  return { isMobileDetailsOpen, toggleSideScreen };
};
