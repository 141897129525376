import { Box, Text, useTheme } from "@kolmeo/ui-core";
import { ProfileType, UserProfile } from "@src/types";
import UserProfileType from "./UserProfileType";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useProfileTypeSwitcher } from "@src/hooks/useProfileTypeSwitcher/useProfileTypeSwitcher";

type UserProfileTypeSelectorProps = {
  activeUserProfiles: UserProfile[];
  isFirstTimeLogin: boolean;
};

const UserProfileTypeSelector = ({ isFirstTimeLogin, activeUserProfiles }: UserProfileTypeSelectorProps): JSX.Element => {
  const { t } = useTranslation("Common", { keyPrefix: "Welcome" });
  const { spacing } = useTheme();
  const { showTenantPage, showOwnerPage } = useProfileTypeSwitcher();

  const ownerProfiles = activeUserProfiles.filter((x) => x.profileTypeId === ProfileType.owner);
  const tenantProfiles = activeUserProfiles.filter((x) => x.profileTypeId === ProfileType.tenant);
  const onlyOwnerUserProfiles = ownerProfiles.length > 0 && tenantProfiles.length === 0;
  const onlyTenantUserProfiles = ownerProfiles.length === 0 && tenantProfiles.length > 0;

  useEffect(() => {
    if (onlyOwnerUserProfiles) {
      showOwnerPage();
    }
    if (onlyTenantUserProfiles) {
      showTenantPage();
    }
  }, [onlyOwnerUserProfiles, onlyTenantUserProfiles, showOwnerPage, showTenantPage]);

  const HorizontalLine = (): JSX.Element => {
    const hrStyle = {
      height: "100%",
      color: "#E1E1E5",
      border: "1px solid",
      width: "100%",
      marginTop: spacing.px16,
      marginBottom: spacing.px16
    };

    return <hr style={hrStyle} />;
  };

  return (
    <Box flex={1} justifyContent="normal" alignItems="center" backgroundColor={"#FFFFFF"}>
      <Box padding={spacing.px24}>
        <Box mt={spacing.px24} mb={spacing.px24} justifyContent="center" alignItems="center">
          <Text variant="headingH1" style={{ fontFamily: "Inter" }}>
            {isFirstTimeLogin ? "You’re all set up!" : "Welcome back!"}
          </Text>
          {isFirstTimeLogin ? (
            <>
              <Text variant="textRegular16" mt={spacing.px24}>
                {t("ReadyToViewProperties")}
              </Text>
              <Text variant="textRegular16" mt={spacing.px24}>
                {t("SelectProfileView")}
              </Text>
            </>
          ) : (
            <Text variant="textRegular16" mt={spacing.px24}>
              {t("WhichProfileView")}
            </Text>
          )}
        </Box>
        <HorizontalLine />
        <UserProfileType title="See my investment properties" onPress={showOwnerPage} />
        <HorizontalLine />
        <UserProfileType title="See my tenancy" onPress={showTenantPage} />
        <HorizontalLine />
      </Box>
    </Box>
  );
};

export default UserProfileTypeSelector;
