import { Box, Text, useTheme } from "@kolmeo/ui-core";
import { transformToOrdinalDate } from "@src/utils/dateTransformers";
import PaymentAmount from "./PaymentAmount";

const PaymentsHero = ({ amount, date }: { amount: number; date: string }) => {
  const { palette, spacing } = useTheme();
  const isNegative = amount < 0 ? true : false;

  return (
    <Box justifyContent="center" alignItems="center" borderBottomWidth={1} borderBottomColor={palette.border.default}>
      <Box alignItems="center" paddingBottom={"32px"} paddingTop={spacing.px16}>
        <Text>{isNegative ? "You paid" : "You got paid"}</Text>
        <Box flexDirection="row" alignItems="center">
          <PaymentAmount amount={amount} size="hero" />
        </Box>
        <Text>{transformToOrdinalDate(date)}</Text>
      </Box>
    </Box>
  );
};

export default PaymentsHero;
