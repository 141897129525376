import { Text } from "@kolmeo/ui-core";
import { useTranslation } from "react-i18next";

export const toSentenceCase = (sentence: string): string => {
  if (!sentence.trim()) {
    return "Please provide a sentence.";
  }

  let formattedSentence = sentence.replace(/\s+/g, " ").trim();
  if (!formattedSentence[0].match(/[A-Z]/)) {
    formattedSentence = formattedSentence.charAt(0).toUpperCase() + formattedSentence.slice(1);
  }

  return formattedSentence;
};

const TextSentence = ({ label }: { label: IntentionalAny<"need to type the translation list"> }) => {
  const { t } = useTranslation();
  const formattedIntoSentence = toSentenceCase(t(label));
  return <Text data-copy-id={label}>{formattedIntoSentence}</Text>;
};

export default TextSentence;
