import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { GetAllOwnerProperties } from "@src/screens/Owner/MyProperties/types";

export type PropertyState = {
  groupPropertyId?: number;
  selectedProperty?: GetAllOwnerProperties;
  ownerProperties: GetAllOwnerProperties[];
  selectedPropertyId: number;
};

const initialState: PropertyState = {
  groupPropertyId: 0,
  ownerProperties: [],
  selectedPropertyId: 0
};

export const propertySlice = createSlice({
  name: "Property",
  initialState,
  reducers: {
    setOwnerAllProperties: (state, action: PayloadAction<GetAllOwnerProperties[]>) => {
      state.ownerProperties = action.payload;
    },
    setGroupPropertyId: (state, action: PayloadAction<number>) => {
      state.groupPropertyId = action.payload;
    },
    resetGroupPropertyId: (state) => {
      state.groupPropertyId = undefined;
    },
    setSelectedProperty: (state, action: PayloadAction<GetAllOwnerProperties>) => {
      state.selectedProperty = action.payload;
    },
    resetSelectedProperty: (state) => {
      state.selectedProperty = undefined;
    },
    resetPropertyState: (state) => {
      state.groupPropertyId = undefined;
      state.selectedProperty = undefined;
    },
    setSelectedPropertyId: (state, action) => {
      state.selectedPropertyId = action.payload;
    }
  }
});
export const {
  setOwnerAllProperties,
  setGroupPropertyId,
  resetGroupPropertyId,
  setSelectedProperty,
  resetSelectedProperty,
  resetPropertyState,
  setSelectedPropertyId
} = propertySlice.actions;

export default propertySlice.reducer;
