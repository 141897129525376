import FlowerPotsBackground from "./FlowerPotsBackground";
import SunnyTownBackground from "./SunnyTownBackground";
import MagnifyingGlass from "./MagnifyingGlass";
import House from "./House";
import PersonaStaff from "./Personas/PersonaStaff";
import PersonaTenant from "./Personas/PersonaTenant";
import FourOhFourDog from "./FourOhFourDog";
const SVGImage = {
    "sunny-town": SunnyTownBackground,
    "flowers-pot": FlowerPotsBackground,
    "magnifying-glass": MagnifyingGlass,
    house: House,
    "persona-staff": PersonaStaff,
    "persona-tenant": PersonaTenant,
    "404-dog": FourOhFourDog,
};
export default SVGImage;
