export const ensureTrailingForwardslash = (url?: string, errorMessage?: string) => {
  if (url === undefined) {
    throw new Error(errorMessage ?? "url is undefined");
  }

  if (url.substr(-1) !== "/") {
    url += "/";
  }
  return url;
};
