import type { GetAllOwnerProperties } from "../../types";
import { useMediaQuery } from "@src/hooks";
import PropertySelectCardWeb from "./variants/PropertySelectCardWeb";
import PropertySelectCardMobile from "./variants/PropertySelectCardMobile";

export interface PropertySelectCardProps {
  propertyId: number;
  details: GetAllOwnerProperties;
}
const PropertySelectCard = ({ propertyId, details }: PropertySelectCardProps) => {
  const { isBelowMobile } = useMediaQuery();

  return isBelowMobile ? (
    <PropertySelectCardMobile propertyId={propertyId} details={details} />
  ) : (
    <PropertySelectCardWeb propertyId={propertyId} details={details} />
  );
};

export default PropertySelectCard;
