export default {
    path: {
        d: "M24 6.58752L18.4625 1.03752C18.0896 0.655177 17.5837 0.431318 17.05 0.412517C16.7791 0.40628 16.5098 0.455139 16.2584 0.55613C16.007 0.657121 15.7788 0.808138 15.5875 1.00002L12.2875 4.28752L0.587501 16C0.401123 16.185 0.253243 16.405 0.152405 16.6475C0.0515658 16.8899 -0.000233085 17.1499 7.88436e-07 17.4125V23C7.88436e-07 23.5305 0.210714 24.0392 0.585787 24.4142C0.96086 24.7893 1.46957 25 2 25H7.5875C8.11731 24.9977 8.62486 24.7866 9 24.4125L24 9.41252C24.371 9.03599 24.579 8.52861 24.579 8.00002C24.579 7.47142 24.371 6.96404 24 6.58752ZM20 10.5875L14.4125 5.00002L17 2.41252L22.5875 8.00002L20 10.5875Z",
    },
    properties: {
        viewBox: "0 0 25 25",
        outlined: false,
        strokeWidth: "0",
    },
};
