export default {
    path: {
        d: "M39.4812 35.75C36.6824 30.9285 32.2023 27.3097 26.9 25.5875C29.4415 24.0762 31.4166 21.7722 32.5217 19.0296C33.6268 16.2869 33.8009 13.2573 33.0172 10.4061C32.2335 7.55491 30.5354 5.03988 28.1837 3.24737C25.8321 1.45487 22.9569 0.484047 20 0.484047C17.0431 0.484047 14.1679 1.45487 11.8162 3.24737C9.46457 5.03988 7.76645 7.55491 6.98277 10.4061C6.19908 13.2573 6.37317 16.2869 7.4783 19.0296C8.58344 21.7722 10.5585 24.0762 13.1 25.5875C7.79768 27.3097 3.31762 30.9285 0.518746 35.75C0.38133 35.976 0.308655 36.2355 0.308655 36.5C0.308655 36.7645 0.38133 37.024 0.518746 37.25C0.646953 37.4799 0.834813 37.6709 1.06251 37.8029C1.29021 37.9349 1.54932 38.003 1.8125 38H38.1875C38.4507 38.003 38.7098 37.9349 38.9375 37.8029C39.1652 37.6709 39.353 37.4799 39.4812 37.25C39.6187 37.024 39.6913 36.7645 39.6913 36.5C39.6913 36.2355 39.6187 35.976 39.4812 35.75Z",
    },
    properties: {
        viewBox: "0 0 40 38",
        outlined: false,
        strokeWidth: "0",
    },
};
