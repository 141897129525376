import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, FlatList, TouchableOpacity } from "@kolmeo/ui-core";
import { useEffect, useState } from "react";
import { useSharedValue } from "react-native-reanimated";
import DropdownTrigger from "./components/DropdownTrigger";
import DropdownContentContainer from "./components/DropdownContentContainer";
const DropdownMenu = ({ data, selected, selectedHandler, renderItem, isSelectedItemInput = false, isOpenCallback, borderStyles, triggerProps, label = "Please make a selection", overlay = false, optionsContainerProps, ...rest }) => {
    const y = useSharedValue(0);
    const scrollHandler = (event) => {
        if (event?.nativeEvent?.contentOffset.y > 0) {
            y.value = event?.nativeEvent?.contentOffset.y;
        }
    };
    const isSingleItem = data?.length === 1 ? true : false;
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [triggerHeight, setTriggerHeight] = useState(0);
    const toggleDropDown = () => setIsDropdownOpen(!isDropdownOpen);
    useEffect(() => {
        if (isOpenCallback) {
            isOpenCallback(isDropdownOpen);
        }
    }, [isDropdownOpen]);
    const handleSelectWrapper = (item) => {
        selectedHandler(item);
        toggleDropDown();
    };
    const renderItemWithTouch = (item) => renderItem ? (_jsx(TouchableOpacity, { onPress: () => {
            handleSelectWrapper(item.item);
        }, style: { paddingRight: 20 }, children: renderItem(item) })) : null;
    return (_jsxs(Box, { position: "relative", overflow: !overlay ? "hidden" : undefined, zIndex: overlay ? 999 : "auto", children: [_jsx(Box, { ...borderStyles, children: _jsx(DropdownTrigger, { selected: selected, label: label, renderItem: ({ item, index, separators }) => renderItem({
                        item,
                        index,
                        separators,
                    }), isSelectedItemInput: isSelectedItemInput, isDropdownOpen: isDropdownOpen, toggleDropDown: toggleDropDown, triggerProps: triggerProps, setTriggerHeight: setTriggerHeight, isSingleItem: isSingleItem }) }), isDropdownOpen ? (_jsx(DropdownContentContainer, { overlay: overlay, triggerHeight: triggerHeight, optionsContainerProps: optionsContainerProps, children: _jsx(FlatList, { ...rest, onScroll: scrollHandler, data: isSelectedItemInput ? data?.filter((item) => item !== selected) : data, scrollEventThrottle: 16, keyExtractor: (_, index) => `flat-list-item__${index}`, renderItem: renderItemWithTouch }) })) : null] }));
};
export default DropdownMenu;
