import { FieldError } from "@kolmeo/ui-components";
import { Pressable, Text, Box } from "@kolmeo/ui-core";

const Hyperlink = ({ onPress, text, error }: { onPress: () => void; text: string; error?: string }) => {
  return (
    <Box>
      <Pressable onPress={onPress}>
        <Box flexDirection={"row"} alignItems={"center"}>
          <Text style={{ textDecorationLine: "underline" }}>{text}</Text>
        </Box>
      </Pressable>
      <Box mt={`4px`}>{error && <FieldError message={error} />}</Box>
    </Box>
  );
};

export default Hyperlink;
