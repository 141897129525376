export default {
    path: {
        d: "M0.0454071 0.599609C0.0454071 1.74314 0.0454071 2.86721 0.0454071 3.98397C1.20232 3.98397 2.33977 3.98397 3.48816 3.98397C3.48816 2.85382 3.48816 1.73827 3.48816 0.599609C2.32395 0.599609 1.18042 0.599609 0.0454071 0.599609Z M15.432 16.5025C15.2118 15.5487 14.4648 14.9502 13.5646 14.8115C13.5646 14.8115 10.8858 14.7981 10.7763 14.7981C10.7763 13.696 10.7763 12.5561 10.7763 11.5026L12.5367 11.5062C13.359 11.5062 14.578 11.2009 14.4831 9.06102C14.4831 8.1535 13.7057 7.30437 12.9369 7.30437L7.35913 7.30558C7.35913 11.5756 7.35913 15.8042 7.35913 20.0511C8.14379 20.0511 8.91142 20.0584 9.67782 20.0487C10.7861 20.0353 11.8931 20.0182 12.9977 19.9866C13.258 19.9781 13.5196 19.9221 13.7666 19.8443C15.387 19.32 15.7288 17.7932 15.432 16.5025Z M7.35058 0.599609C7.35058 1.73827 7.35058 2.85504 7.35058 3.99979C7.47224 3.99979 7.57686 3.99857 7.68269 3.99979L13.2714 4.00222C13.8955 4.00222 14.6619 4.16645 15.3504 4.51924C17.118 5.42434 18.3248 6.98027 18.3638 8.87804C18.3723 9.35127 18.32 9.83058 18.2421 10.2989C18.118 11.0629 17.8577 11.7357 17.2701 12.2794C17.0414 12.4923 16.7835 12.7137 16.5171 12.923C16.7458 13.0398 16.9477 13.1517 17.1533 13.2478C17.6813 13.4911 18.1569 13.8561 18.5085 14.3122C19.5024 15.6066 19.646 17.0689 19.3954 18.609C19.2579 19.4521 18.8966 20.2404 18.365 20.9362C17.7226 21.7793 16.9295 22.4094 15.9222 22.7732C14.8395 23.1637 13.7118 23.2634 12.5865 23.2817C9.66562 23.323 6.74476 23.2938 3.82389 23.2938C3.71927 23.2938 3.61829 23.2938 3.48083 23.2938C3.48083 17.9436 3.48083 12.6274 3.48083 7.32454C2.31053 7.32454 1.17552 7.32454 0.0332031 7.32454C0.0332031 13.6955 0.0332031 20.0481 0.0332031 26.3996C7.31409 26.3996 14.5743 26.3996 21.8272 26.3996C21.8321 17.7842 21.8321 9.19555 21.8321 0.599609C16.9915 0.599609 12.1753 0.599609 7.35058 0.599609Z",
    },
    properties: {
        viewBox: "0 0 22 27",
        outlined: false,
        strokeWidth: "0",
    },
};
