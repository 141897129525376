import { ProfileTypeCodes } from "./constants/ProfileType";

export * from "./Payments";
export * from "./PropertyTypes";
export * from "./constants";

// ===  USERS ==
export type UserProfile = {
  mobilePhone: string;
  phonenumber: string;
  userProfileId: number;
  entityId: string;
  userId: number;
  profileTypeId: ProfileTypeCodes;
  recordStatusCode: number;
  emailAddress: string;
  titleCode: string;
  firstName: string;
  lastName: string;
  customSalutation: string;
  businessName: string;
};

export type PendingUserProfileInvite = {
  userProfileId: number;
  profileType: number;
  displayName?: string;
  agencyName?: string;
  propertyNames: string[];
};

export type RestUserProfileResponse = {
  activeUserProfiles: UserProfile[];
  pendingUserProfileInvites: PendingUserProfileInvite[];
};

export enum MobileTabOptions {
  SERVICES = "Services",
  DISCOUNTS = "Discounts",
  ARTICLES = "Helpful Articles"
}
