import { useAxios } from "@src/lib/http/useAxios";
import { useAuth } from "react-oidc-context";
import { RestUserProfileResponse } from "@src/types";
import { useQuery } from "@tanstack/react-query";
import { useFeatureFlags } from "@src/lib/FeatureFlags";

export const useUserProfilesQuery = () => {
  const { instance } = useAxios("GATEWAY");
  const { isAuthenticated } = useAuth();
  const { isLoading } = useFeatureFlags();
  return useQuery<RestUserProfileResponse>({
    queryKey: ["userprofile"],
    queryFn: async () => {
      const response = await instance.get("/userprofile");
      return response.data;
    },
    enabled: isAuthenticated && !isLoading
  });
};
