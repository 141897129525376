import { Box, useTheme, Text, ScrollView } from "@kolmeo/ui-core";
import { TenantThemeBrandingObject, ThemeBrandingObject } from "@src/lib/Kiitos";
import { BackLink } from "@src/components";
import { useMediaQuery } from "@src/hooks";
import { ButtonV2, Icon, IconType } from "@kolmeo/ui-components";
import { VariantTypes } from "@kolmeo/ui-components/src/components/ButtonV2";

interface ConditionalWrapperProps {
  condition: boolean;
  wrapper: React.ComponentType<any>;
  children: React.ReactNode;
}

const ConditionalWrapper: React.FC<ConditionalWrapperProps> = ({ condition, wrapper: WrapperComponent, children }) => {
  return condition ? <WrapperComponent showsVerticalScrollIndicator={false}>{children}</WrapperComponent> : <>{children}</>;
};

const PageContainer = ({
  children,
  headingText,
  headingStyle,
  subHeadingComponent,
  brandingObject,
  isScrollable = false,
  navigateBack,
  navigateBackLabel,
  callToAction,
  iconType,
  ...rest
}: {
  headingText?: string | JSX.Element;
  headingStyle?: IntentionalAny<"Does styled-components export the correct type?">;
  brandingObject?: ThemeBrandingObject | TenantThemeBrandingObject;
  subHeadingComponent?: JSX.Element;
  [rest: string]: IntentionalAny<"Not sure how to type rest spread">;
  canGoBack?: boolean;
  navigateBack?: any;
  navigateBackLabel?: string;
  callToAction?: { label: string | JSX.Element; onPress: () => void; iconType?: IconType; variant?: VariantTypes; pendoId?: string };
  isScrollable?: boolean;
  iconType?: IconType | undefined;
} & React.PropsWithChildren): JSX.Element => {
  const { spacing, colors } = useTheme();
  const { mq } = useMediaQuery();

  return (
    <Box flex={1}>
      {navigateBack && (
        <Box backgroundColor="white">
          <Box
            maxWidth="1280px"
            width="100%"
            margin="0 auto"
            paddingBottom={spacing.px16}
            paddingX={mq({
              phone: spacing.px16,
              tablet: spacing.px16,
              desktop: "0px"
            })}
          >
            <BackLink onPress={navigateBack} label={navigateBackLabel ?? "Back"} />
          </Box>
        </Box>
      )}
      <ConditionalWrapper condition={isScrollable} wrapper={ScrollView}>
        <Box
          maxWidth="1280px"
          width="100%"
          height="100%"
          margin="0 auto"
          paddingX={mq({
            phone: spacing.px16,
            tablet: spacing.px16,
            desktop: "0px"
          })}
          paddingTop={mq({
            phone: spacing.px16,
            tablet: spacing.px16,
            desktop: spacing.px24
          })}
          // paddingBottom={16}
          {...rest}
        >
          <Box width="100%" flexDirection="column">
            {/* {canGoBack && <BackLink onPress={goBack} />} */}
            {(headingText || callToAction) && (
              <Box
                flexDirection="row"
                justifyContent="space-between"
                alignItems={"center"}
                mb={mq({
                  phone: spacing.px16,
                  tablet: spacing.px16,
                  desktop: spacing.px20
                })}
              >
                {headingText && (
                  <Box flexDirection="row">
                    {iconType && (
                      <Box paddingRight={spacing.px8}>
                        <Icon type={iconType} color={brandingObject?.primaryColor || colors.primary} size={"md"} />
                      </Box>
                    )}

                    <Text
                      variant="headingH2"
                      fontWeight={600}
                      style={
                        headingStyle ?? {
                          fontFamily: "Hornbill" //TODO: This isn't scalable yet, need backend help
                        }
                      }
                      color={brandingObject?.primaryColor || colors.primary}
                    >
                      {headingText}
                    </Text>
                  </Box>
                )}
                {callToAction && ( // TODO: we need to update this buttonV2 component to better handle branding object colours
                  <ButtonV2
                    variant={callToAction.variant ?? "primary"}
                    size="small"
                    overrideVariant={
                      callToAction.variant == "outlined"
                        ? {
                            borderColor: brandingObject?.primaryColor || colors.primary,
                            color: brandingObject?.primaryColor || colors.primary,
                            iconColor: brandingObject?.primaryColor || colors.primary
                          }
                        : { bgColor: brandingObject?.primaryColor || colors.primary }
                    }
                    {...callToAction}
                    data-pendo-id={callToAction.pendoId ?? undefined}
                  />
                )}
              </Box>
            )}

            {subHeadingComponent && (
              <Box alignItems="flex-start" mb={spacing.px16}>
                {subHeadingComponent}
              </Box>
            )}
          </Box>

          {children}
        </Box>
      </ConditionalWrapper>
    </Box>
  );
};

export default PageContainer;
