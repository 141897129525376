import ApplicationStack from "./ApplicationStack";
import "./i18n";
import PendoHelmet from "@src/lib/Analytics/Pendo/PendoHelmet";
import { ToastProvider } from "./components/Toast/ToastContext";

const REACT_APP_Pendo_App_Id = process.env.REACT_APP_Pendo_App_Id;

const App = () => {
  return (
    <>
      <ToastProvider>
        {REACT_APP_Pendo_App_Id && <PendoHelmet pendoAppId={REACT_APP_Pendo_App_Id} />}
        <ApplicationStack />
      </ToastProvider>
    </>
  );
};

export default App;
