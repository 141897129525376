export const fetchFileWithAuth = async (url: string, authToken: string): Promise<string | undefined> => {
  try {
    if (!authToken) {
      throw Error("Auth token is required");
    }
    const response = await fetch(url, { headers: { Authorization: `Bearer ${authToken}` } });

    if (!response.ok) {
      throw Error("Network response was not ok" + response.statusText);
    }

    const blob = await response.blob();
    if (blob.size === 0) {
      throw Error("Fetched resource is empty");
    }

    return URL.createObjectURL(blob);
  } catch (error) {
    console.error("Error fetching image:", error);
    return undefined;
  }
};
