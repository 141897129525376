import { ButtonV2, CheckboxSingle, FadeAnimation } from "@kolmeo/ui-components";
import { Box, useTheme, Text } from "@kolmeo/ui-core";
import { Hyperlink } from "@src/components";
import { AddPaymentMethodsFormData } from "@src/types/PaymentPreferences";
import { Controller, FieldErrors, Control } from "react-hook-form";
import { useTranslation } from "react-i18next";
import DebitAuthority from "./DDRSA-DDR.pdf";

type FormStepTwoProps = {
  control: Control<AddPaymentMethodsFormData, any>;
  errors: FieldErrors<AddPaymentMethodsFormData>;
  handleBackPress: () => void;
  handleNextPress: () => void;
  isLoading: boolean;
  expandToFullWidth: boolean;
  brandingObject: any;
  validationTrigger: (fieldName: keyof AddPaymentMethodsFormData) => void;
  onTACPRess: () => void;
  onDDAgreementPress: () => void;
};
const FormStepTwo = ({
  control,
  errors,
  handleBackPress,
  handleNextPress,
  isLoading,
  expandToFullWidth,
  brandingObject,
  validationTrigger,
  onTACPRess
}: FormStepTwoProps) => {
  const { t } = useTranslation(["Common", "Tenant"]);
  const { spacing } = useTheme();

  const handleDebitAuthorityPreview = () => {
    const aElement = document.createElement("a");
    aElement.href = DebitAuthority;
    aElement.setAttribute("target", "_blank");
    aElement.click();
  };

  return (
    <FadeAnimation enterDuration={100} exitDuration={100}>
      <Box flexDirection={"column"}>
        <Text marginBottom={3} variant="textHeavy14">
          {t("Tenant:RentAuthority.AuthorityHeader")}
        </Text>
        <Text marginBottom={3} variant="paragraph14">
          {t("Tenant:RentAuthority.ParagraphOne")}
        </Text>
        <Text marginBottom={3} variant="paragraph14">
          {t("Tenant:RentAuthority.ParagraphOnePointOne")}
        </Text>
        <Text marginBottom={3} variant="paragraph14">
          {t("Tenant:RentAuthority.ParagraphTwo")}
        </Text>
        <Text marginBottom={3} variant="paragraph14">
          {t("Tenant:RentAuthority.ParagraphThree")}
        </Text>
        <Text marginBottom={3} variant="paragraph14">
          {t("Tenant:RentAuthority.ParagraphFour")}
        </Text>
        <Text marginBottom={3} variant="paragraph14">
          {t("Tenant:RentAuthority.ParagraphFive")}
        </Text>
        <Text marginBottom={3} variant="paragraph14">
          {t("Tenant:RentAuthority.TermsAndConditionsOne")}
          <Hyperlink
            onPress={() => {
              onTACPRess();
            }}
            text={t("Tenant:RentAuthority.TermsAndConditionsLink")}
          />
          {t("Tenant:RentAuthority.TermsAndConditionsTwo")}
        </Text>
        <Controller
          control={control}
          name="userViewedServiceAgreement"
          render={({ field: { onChange } }) => (
            <>
              <Text marginBottom={3} variant="paragraph14">
                <Hyperlink
                  onPress={() => {
                    handleDebitAuthorityPreview();
                    onChange(true);
                  }}
                  text={t("Tenant:RentAuthority.ViewDebitAuthority")}
                  error={errors.userViewedServiceAgreement?.message}
                />
              </Text>
            </>
          )}
        />
        <Box>
          <Controller
            control={control}
            name="userAcceptedTermsAndConditions"
            render={({ field: { onChange, value } }) => (
              <CheckboxSingle
                textBold={true}
                value={value}
                disabled={false}
                testId={"test-authority-provided"}
                onPress={(val) => {
                  onChange(val);
                  validationTrigger("userAcceptedTermsAndConditions");
                }}
                content={t("Tenant:PaymentMethods.FormDebitAuthorityAgreement")}
                error={errors.userAcceptedTermsAndConditions?.message}
              />
            )}
          />
        </Box>

        <Box flexDirection="row" justifyContent="space-between" paddingBottom={spacing.px24}>
          <ButtonV2
            width={"49%"}
            variant="outlined"
            size={"small"}
            overrideVariant={{ color: brandingObject?.primaryColor, borderColor: brandingObject?.primaryColor }}
            label={t("Common:Generic.Back")}
            onPress={handleBackPress}
            fullwidth={expandToFullWidth}
            disabled={isLoading}
          />

          <ButtonV2
            width={"49%"}
            variant="primary"
            size={"small"}
            label={t("Tenant:PaymentMethods.FormAddBankAccount")}
            onPress={handleNextPress}
            fullwidth={expandToFullWidth}
            overrideVariant={{ bgColor: brandingObject?.primaryColor }}
            isLoading={isLoading}
            disabled={isLoading}
          />
        </Box>
      </Box>
    </FadeAnimation>
  );
};

export default FormStepTwo;
