import { jsx as _jsx } from "react/jsx-runtime";
import { Text, coreTheme } from "@kolmeo/ui-core";
const { spacing } = coreTheme;
const TopTabBarLabel = ({ label, color, tabBarLabelStyle, tabBarAllowFontScaling }) => {
    return (_jsx(Text, { textAlign: "center", numberOfLines: 1, variant: "textHeavy14", margin: `${spacing.spacing4}px`, backgroundColor: "transparent", style: [
            {
                color,
            },
            tabBarLabelStyle,
        ], allowFontScaling: tabBarAllowFontScaling, children: label }));
};
export default TopTabBarLabel;
