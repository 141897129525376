import { TenantRentPaymentDetails } from "../RentPaymentsCard";

export type RentPaymentDetails = TenantRentPaymentDetails;

export const PayRentSection = {
  MakeRentPayment: "make-rent-payment",
  PaymentSummary: "payment-summary",
  Success: "success"
} as const;

type PayRentSectionKeys = keyof typeof PayRentSection;
export type PayRentSectionValues = (typeof PayRentSection)[PayRentSectionKeys];

export type PayRentSectionInfo = {
  title?: string;
  isInitial?: boolean;
};

export const LeasePaymentStatus = {
  LeaseEndingUpcomingCycleNoMoreToPay: "lease-ending-upcoming-cycle-has-no-more-to-pay",
  LeaseEndingThisCycleNoMoreToPay: "lease-ending-this-cycle-has-no-more-pay",
  LeaseEndingUpcomingCycleMoreToPay: "lease-ending-upcoming-cycle-has-more-to-pay",
  LeaseEndingThisCycleMoreToPay: "lease-ending-this-cycle-has-more-to-pay",
  LeaseActiveNoMoreToPay: "lease-active-has-no-more-to-pay"
} as const;

type LeasePaymentStatusKeys = keyof typeof LeasePaymentStatus;
export type LeasePaymentStatusValues = (typeof LeasePaymentStatus)[LeasePaymentStatusKeys];
