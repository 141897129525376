import { Box, Image, Pressable } from "@kolmeo/ui-core";
import { Icon, coreThemeV2 } from "@kolmeo/ui-components";

type ImageThumbnailProps = {
  index: number;
  id: string;
  onDelete: (id: string) => void;
  previewSrc: IntentionalAny<"Need to set a proper type for this - it is a File type with additional props">;
  height?: number;
  width?: number;
};
const ImageThumbnail = ({ index, id, onDelete, previewSrc, height = 100, width = 100 }: ImageThumbnailProps) => {
  const { spacing, borderRadius } = coreThemeV2;

  return (
    <>
      <Box margin={spacing.sp4 / 2} key={index} position={"relative"}>
        <Box
          style={{
            position: "absolute",
            right: spacing.sp8 / 2,
            top: spacing.sp8 / 2,
            borderRadius: borderRadius.br80,
            backgroundColor: "white",
            padding: spacing.sp4,
            zIndex: 99
          }}
        >
          <Pressable onPress={() => onDelete(id)} padding={1}>
            <Icon type={"close"} size={14} />
          </Pressable>
        </Box>
        <Box width={height} height={width} borderRadius={borderRadius.br8} overflow={"hidden"}>
          <Image key={previewSrc} source={previewSrc} height={height} width={width} />
        </Box>
      </Box>
    </>
  );
};

export default ImageThumbnail;
