import { useEffect, useState } from "react";
import axios from "axios";
import { useAuth } from "react-oidc-context";
import { useCurrentUser } from "@src/hooks";
import useKolmeoAuth from "../AzureAuth/useKolmeoAuth";
import { useEndpointUrls, Endpoints } from "./useEndpointUrls";
import { HttpStatusCode } from "@src/types/constants/HttpStatusCode";

export const useAxios = (endpoint: Endpoints) => {
  const endpoints = useEndpointUrls();
  const instance = axios.create({
    baseURL: endpoints[endpoint],
    headers: {
      "x-version": 1
    }
  });

  const { user, isAuthenticated } = useAuth();
  const { currentUser } = useCurrentUser();
  const { logout } = useKolmeoAuth();

  const [isEnabled, setIsEnabled] = useState<boolean>(false);

  useEffect(() => {
    if (isAuthenticated && currentUser) {
      setIsEnabled(true);
    }
  }, [isAuthenticated, currentUser]);

  instance.interceptors.request.use(
    (request) => {
      if (user) {
        request.headers.Authorization = `Bearer ${user.id_token}`;
      }

      return request;
    },
    (err) => {
      console.log(err);
      return Promise.reject(err);
    }
  );

  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response.status === HttpStatusCode.UNAUTHORIZED || error.response.status === HttpStatusCode.FORBIDDEN) {
        logout();
      }
      return Promise.reject(error);
    }
  );

  return {
    instance,
    isEnabled
  };
};
