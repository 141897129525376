import { endOfDay, isBefore, formatDuration, intervalToDuration, startOfDay } from "date-fns";
import { StatusPill } from "@src/components";

const DurationRemainingPill = ({ leaseToDate }: { leaseToDate: string }) => {
  if (isBefore(new Date(leaseToDate), new Date())) {
    return <StatusPill variant="danger" label="Lease expired" />;
  }

  const leaseDuration = intervalToDuration({
    start: startOfDay(new Date()),
    end: endOfDay(new Date(leaseToDate))
  });
  const pillLabel = formatDuration(leaseDuration, {
    delimiter: " ",
    format: ["months", "days"]
  });
  const labelText = `${pillLabel} left`;

  return <StatusPill label={labelText} variant="info" />;
};

export default DurationRemainingPill;
