import React, { createContext, useState, ReactNode } from "react";
import Toast from "./Toast"; // Import Toast component
import { TextStyle, ViewStyle } from "react-native";
interface ToastOptions {
  headline?: string;
  message?: string;
  showCloseIcon?: boolean;
  showRightIcon?: boolean;
  duration?: number;
  textStyles?: {
    headline?: TextStyle; // You can adjust this based on your styling needs
    message?: TextStyle; // Same here
  };
  containerStyles?: ViewStyle;
}
interface ToastContextType {
  showToast: (options: ToastOptions) => void;
}
export const ToastContext = createContext<ToastContextType | undefined>(undefined);
export const ToastProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [toast, setToast] = useState<ToastOptions | null>(null);
  const showToast = (options: ToastOptions) => {
    setToast(options);
    const duration = options.duration || 3000; // Default duration is 3000ms
    setTimeout(() => setToast(null), duration);
  };
  const hideToast = () => setToast(null);
  return (
    <ToastContext.Provider value={{ showToast }}>
      {children}
      {toast && (
        <Toast
          headline={toast.headline}
          message={toast.message}
          onHide={hideToast}
          showCloseIcon={toast.showCloseIcon}
          showRightIcon={toast.showRightIcon}
          textStyles={toast.textStyles}
          containerStyles={toast.containerStyles}
        />
      )}
    </ToastContext.Provider>
  );
};
export const useToast = (): ToastContextType => {
  const context = React.useContext(ToastContext);
  if (context === undefined) {
    throw new Error("useToast must be used within a ToastProvider");
  }
  return context;
};
