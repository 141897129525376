import { Icon, IconType } from "@kolmeo/ui-components";
import { Box, Fonts, Pressable, Text } from "@kolmeo/ui-core";
import { shortenTextWithEllipsis } from "@src/utils/textTransformers/shortenTextWithEllipsis";

export type RowItemButtonProps = {
  icon: IconType;
  onPress: () => void;
  size?: number;
  color: string;
};
type RowItemType = {
  label?: string | JSX.Element;
  italic?: boolean;
  bold?: boolean;
  alignment?: "left" | "right";
  variant?: keyof Fonts;
  buttonRight?: RowItemButtonProps;
  shorten?: boolean;
};
const RowItem = ({ label, bold, italic, alignment, variant, buttonRight, shorten = true }: RowItemType) => {
  return (
    <Box alignItems={alignment == "right" ? "flex-end" : "space-between"}>
      <Box flexDirection={"row"} alignItems={"space-between"} justifyContent={"space-between"}>
        <Box>
          <Text variant={variant} fontWeight={bold ? 600 : "normal"} lineHeight={20} fontStyle={italic ? "italic" : "normal"}>
            {typeof label == "string" && shorten ? shortenTextWithEllipsis(label, 25) : label}
          </Text>
        </Box>
        {buttonRight && (
          <Box alignItems={"flex-end"} justifyContent={"center"} mr={1}>
            <Pressable onPress={buttonRight.onPress}>
              <Icon type={buttonRight.icon} size={buttonRight.size ?? "sm"} color={buttonRight.color} />
            </Pressable>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default RowItem;
