import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { coreTheme, TouchableOpacity } from "@kolmeo/ui-core";
import Icon from "../Icon";
const IconButton = ({ Component, iconType, iconSize = "md", accessibilityLabel, containerColor, disabled, fillColor, hoverColor, iconColor, onHoverIn, onHoverOut, onLongPress, onPress, size = 25, variant, ...props }) => {
    const { palette } = coreTheme;
    const typeColorCombination = {
        primary: {
            backgroundColor: containerColor ? containerColor : palette.ateneoBlue,
            tintColor: palette.white,
            borderColor: iconColor ? iconColor : palette.ateneoBlue,
            hoverColor: hoverColor ? hoverColor : palette.ateneoBlueLighten15,
            fill: fillColor ? fillColor : palette.white,
        },
        outlined: {
            backgroundColor: palette.transparent,
            tintColor: iconColor ? iconColor : palette.persianBlue,
            borderColor: iconColor ? iconColor : palette.persianBlue,
            hoverColor: hoverColor ? hoverColor : palette.taivasLighten10,
            fill: fillColor ? fillColor : palette.ateneoBlueLighten15,
        },
        iconOnly: {
            backgroundColor: palette.transparent,
            tintColor: iconColor ? iconColor : palette.persianBlue,
            hoverColor: hoverColor ? hoverColor : palette.taivasLighten10,
            borderColor: palette.transparent,
            fill: palette.transparent,
        },
    };
    return (_jsxs(TouchableOpacity, { accessibilityLabel: accessibilityLabel, disabled: disabled, backgroundColor: typeColorCombination[variant ? variant : "primary"]?.backgroundColor, onPress: onPress, onLongPress: onLongPress, onHoverIn: onHoverIn, onHoverOut: onHoverOut, borderRadius: size + 4, width: size + 4, height: size + 4, borderWidth: 2, alignItems: "center", justifyContent: "center", opacity: disabled ? 0.32 : 1, borderColor: typeColorCombination[variant ? variant : "primary"]?.borderColor, hoverStyle: !disabled && {
            backgroundColor: typeColorCombination[variant ? variant : "primary"]?.hoverColor,
        }, ...props, children: [iconType && _jsx(Icon, { type: iconType, size: iconSize, color: iconColor }), Component && _jsx(Component, { fill: typeColorCombination[variant ? variant : "primary"]?.fill })] }));
};
export default IconButton;
