import Email from "@kolmeo/ui-components/src/components/Icon/components/Email";

export default {
  AddNew: "Add New",
  LoggedAs: "Logged in as",
  Save: "Save",
  Next: "Next",
  Cancel: "Cancel",
  OK: "OK",
  No: "No",
  Back: "Back",
  Warning: "Warning",
  Remove: "Remove",
  IrreversibleAction: "This action is irreversible",
  Thanks: "Thanks!",
  AreYouSureYouWantToLeave: "Are you sure you want to leave?",
  DataLostWarning: "If you leave this page your data will be lost.",
  Leave: "Leave",
  ShowMore: "Show more",
  ShowLess: "Show less",
  From: "From",
  To: "To",
  TAC: "Terms & Conditions",
  SwitchProfile: "Switch to {{profileName}} profile",
  Rent: "Rent",
  Phone: "Phone",
  Email: "Email",
  Call: "Call",
  Name: "Name"
};
