export const LeaseStatus = {
  active: 902001,
  deleted: 902002,
  inactive: 902003,
  preparation: 902004,
  vacated: 902005,
  signed: 902006,
  cancelled: 902007
} as const;
export type LeaseStatusCodes = (typeof LeaseStatus)[keyof typeof LeaseStatus];
export type LeaseStatusType = keyof typeof LeaseStatus;
