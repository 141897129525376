import { Box, Text, useTheme } from "@kolmeo/ui-core";
import { useMediaQuery } from "@src/hooks";
import { useTranslation } from "react-i18next";

const OwnerProfileInvite = (): JSX.Element => {
  const { t } = useTranslation("Common", { keyPrefix: "Welcome" });
  const { spacing } = useTheme();
  const { isMobile } = useMediaQuery();

  const textAlign = isMobile ? "left" : "center";

  return (
    <Box mt={spacing.px16}>
      <Text variant="textHeavy15" style={{ fontSize: 18, textAlign: textAlign }}>
        {t("OwnershipProfiles")}
      </Text>
      <Text mt={spacing.px8} style={{ textAlign: textAlign }}>
        {t("AcceptProfiles")}&ensp;
        <a href="https://kolmeo.com/owners/owner-terms-of-use/" target="_blank" style={{ height: "100%", color: "#251EB4" }}>
          {t("OwnerTermsConditions")}
        </a>
      </Text>
    </Box>
  );
};

export default OwnerProfileInvite;
