import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { forwardRef, memo, useState } from "react";
import { Box, coreTheme, Text } from "@kolmeo/ui-core";
import FieldError from "../FieldError";
import FieldTextInput from "./FieldTextInput";
const Field = forwardRef(({ fieldName, fieldType = "input", insideBottomSheet, showCharLimit, error, maxLength, containerStyles, minTextInputHeight, maxTextInputHeight, isTextInputEdited = true, extendTextInputStyles, verticalOverflow, ...props }, ref) => {
    const { palette, spacing } = coreTheme;
    const [focus, setFocus] = useState(false);
    const focusCallback = (e) => {
        setFocus(true);
        props?.onFocus && props?.onFocus(e);
    };
    const blurCallback = (e) => {
        setFocus(false);
        props?.onBlur && props?.onBlur(e);
    };
    const [inputHeight, setInputHeight] = useState(40);
    const handleContentSizeChange = (event) => {
        setInputHeight(event.nativeEvent.contentSize.height);
    };
    return (_jsx(_Fragment, { children: _jsxs(Box, { onMoveShouldSetResponderCapture: () => {
                return true;
            }, ...containerStyles, children: [_jsx(Box, { flexDirection: "row", borderWidth: 1, borderColor: error ? palette.danger : focus ? palette.inputBorderFocused : palette.kittiDarken10, borderRadius: 8, bg: palette.white, boxShadow: focus ? `0px 0px 3px ${palette.inputBorderFocused}` : undefined, children: _jsx(FieldTextInput, { ref: ref, ...props, style: {
                            height: inputHeight,
                        }, maxLength: maxLength, onFocus: focusCallback, onBlur: blurCallback, isBottomSheet: insideBottomSheet, fieldType: fieldType, minTextInputHeight: minTextInputHeight, maxTextInputHeight: maxTextInputHeight, extendTextInputStyles: extendTextInputStyles, onContentSizeChange: verticalOverflow ? handleContentSizeChange : undefined }) }), _jsxs(Box, { mt: `${spacing.spacing4}px`, flexDirection: "row", justifyContent: "space-between", alignItems: "center", children: [_jsx(Box, { children: error && _jsx(FieldError, { message: error }) }), showCharLimit && maxLength && maxLength > 0 && (_jsx(Box, { flex: 1, alignContent: "flex-end", justifyContent: "flex-end", children: _jsxs(Text, { variant: "textMedium10", color: palette.kolmeoBlack, textAlign: "right", style: { paddingTop: 6 }, children: [props?.value?.length ?? 0, " / ", maxLength] }) }))] })] }) }));
});
export default memo(Field);
