export const addAlphaToHex = (hexColor: string): string => {
  // Remove the '#' symbol if present
  hexColor = hexColor.replace("#", "");

  // Parse the hexadecimal color value into RGB components
  const red = parseInt(hexColor.substring(0, 2), 16);
  const green = parseInt(hexColor.substring(2, 4), 16);
  const blue = parseInt(hexColor.substring(4, 6), 16);

  // Calculate the alpha value (50%)
  const alpha = Math.floor(255 * 0.5); // 50% alpha in 0-255 range

  // Convert the RGB and alpha values to hexadecimal format
  const hexAlpha = alpha.toString(16).padStart(2, "0"); // Ensure two-digit hex
  const rgbaHexColor = `${red.toString(16).padStart(2, "0")}${green.toString(16).padStart(2, "0")}${blue
    .toString(16)
    .padStart(2, "0")}${hexAlpha}`;

  return `#${rgbaHexColor}`;
};
