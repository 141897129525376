import palette from "./palette";
import fonts from "./fonts";
import spacing from "./spacing";
import borderRadius from "./borderRadius";
import sizing from "./sizing";
const coreTheme = {
    palette,
    fonts,
    spacing,
    borderRadius,
    sizing,
};
export default coreTheme;
