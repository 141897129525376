import { useState, useEffect } from "react";

export type QueryTakeSkip = { skip: number; take: number };
export const TAKE_ITEMS = 50;
export const INITIAL_PAGINATION_STATE = { take: TAKE_ITEMS, skip: 0 };

export const usePaginationTakeSkip = (take: number = TAKE_ITEMS) => {
  const [pagination, setPagination] = useState<QueryTakeSkip>(INITIAL_PAGINATION_STATE);
  const nextPage = (currentSkip: number) => {
    setPagination({ skip: currentSkip + take, take });
  };

  return { pagination, nextPage, setPagination };
};

export const usePaginatedData = <T>(data: T[] | undefined) => {
  const [paginatedData, setPaginatedData] = useState<T[]>([] as T[]);
  const [isLastPage, setIsLastPage] = useState<boolean>(false);

  useEffect(() => {
    if (data && data.length < TAKE_ITEMS) {
      setIsLastPage(true);
    }
    if (data) {
      setPaginatedData([...paginatedData, ...data]);
    }
  }, [data]);

  return { paginatedData, setPaginatedData, isLastPage };
};
