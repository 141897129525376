const palette = {
    text: {
        brand: "#1e1890",
        primary: "#000000",
        secondary: "#0000008c",
        disable: "#00000040",
        primaryInvert: "#ffffff",
        secondaryInvert: "#ffffff8c",
        disableInvert: "#ffffff40",
        link: "#1e1890",
        error: "#c0362d",
    },
    info: {
        background: "#cce4ff",
        border: "#007AFF",
        icon: "#0062cc",
        text: "#003166",
    },
    success: {
        background: "#ccf2dc",
        border: "#00bf4f",
        icon: "#00993f",
        text: "#004c20",
    },
    warning: {
        background: "#fff5cc",
        border: "#ffcc00",
        icon: "#cca300",
        text: "#665200",
    },
    danger: {
        background: "#fcdad7",
        border: "#f04438",
        icon: "#c0362d",
        text: "#601b16",
    },
    border: {
        light: "#e1e1e5",
        default: "#bbbbbe",
        heavy: "#909093",
    },
    feedback: {
        general: {
            text: "#3A3A3B",
            icon: "#737376",
            border: "#BBBBBE",
            background: "#F3F3F7",
        },
        success: {
            text: "#004C20",
            icon: "#00993F"
        },
    },
    background: {
        surface: "#ffffff",
        default: "#f3f3f7",
        disable: "#bbbbbe",
        backdrop: "#00000066",
        primary: {
            default: "#1e1890",
            hover: "#251eb4",
            pressed: "#16126c",
            active: "#514bc3",
        },
        destructive: {
            default: "#c0362d",
            hover: "#f04438",
            pressed: "#902922",
        },
        secondary: {
            default: "#3a3a3b",
            hover: "#565658",
            pressed: "#1d1d1d",
        },
        success: {
            default: "#00993f",
            hover: "#00bf4f",
            pressed: "#00732f",
            text: "#004C20",
        },
    },
    black: "#00000",
    transparent: "transparent",
};
export default palette;
