import { Card } from "@kolmeo/ui-components";
import { CardProps } from "@kolmeo/ui-components/dist/packages/ui-components/src/components/Card";
import { Link } from "@react-navigation/native";
import { To } from "@react-navigation/native/lib/typescript/src/useLinkTo";
import { RootStackParamList } from "@src/navigation/RootStack";
import { RequestsStackParamsList } from "@src/navigation/TenancyNavigator/RequestsNavigator";

const LinkCard = ({
  children,
  to,
  cardProps
}: { to: To<RequestsStackParamsList & RootStackParamList>; cardProps?: Omit<CardProps, "children"> } & React.PropsWithChildren) => {
  return (
    <Card flex={1} isPressable {...cardProps} marginBottom={6}>
      <Link to={to} style={{ height: "100%" }}>
        {children}
      </Link>
    </Card>
  );
};

export default LinkCard;
