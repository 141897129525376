export default {
    path: {
        d: "M0 18.47H16V16.2971H0V18.47ZM16 6.51882H11.4286V0H4.57143V6.51882H0L8 14.1241L16 6.51882Z",
    },
    properties: {
        viewBox: "0 0 16 19",
        outlined: false,
        strokeWidth: "0",
    },
};
