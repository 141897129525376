export default {
    path: {
        d: "M10 2.5V11.5C10 11.6326 9.94732 11.7598 9.85355 11.8536C9.75979 11.9473 9.63261 12 9.5 12H0.5C0.367392 12 0.240215 11.9473 0.146447 11.8536C0.0526785 11.7598 0 11.6326 0 11.5V2.5C0 2.36739 0.0526785 2.24021 0.146447 2.14645C0.240215 2.05268 0.367392 2 0.5 2H9.5C9.63261 2 9.75979 2.05268 9.85355 2.14645C9.94732 2.24021 10 2.36739 10 2.5ZM11.5 0H2.5C2.36739 0 2.24021 0.0526785 2.14645 0.146447C2.05268 0.240215 2 0.367392 2 0.5C2 0.632608 2.05268 0.759785 2.14645 0.853553C2.24021 0.947321 2.36739 1 2.5 1H11V9.5C11 9.63261 11.0527 9.75979 11.1464 9.85355C11.2402 9.94732 11.3674 10 11.5 10C11.6326 10 11.7598 9.94732 11.8536 9.85355C11.9473 9.75979 12 9.63261 12 9.5V0.5C12 0.367392 11.9473 0.240215 11.8536 0.146447C11.7598 0.0526785 11.6326 0 11.5 0Z",
    },
    properties: {
        viewBox: "0 0 12 12",
        outlined: false,
        strokeWidth: "0",
    },
};
