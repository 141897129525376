import { useQuery } from "@tanstack/react-query";

import { useAxios } from "@src/lib/http/useAxios";
import { ProfileType, PropertyStatus } from "@src/types";
import { useCurrentUser } from "@src/hooks";
import type { GetAllOwnerProperties } from "./types";

const filterOutLeadProperties = (array: GetAllOwnerProperties[]) => array.filter((item) => item.propertyStatus !== PropertyStatus.lead);

export const useGetAllOwnerProperties = () => {
  const { instance, isEnabled } = useAxios("OWNER_API");
  const { currentUser } = useCurrentUser();

  const isUserOwner = currentUser?.profileTypeId === ProfileType.owner ? true : false;

  return useQuery<GetAllOwnerProperties[]>({
    queryKey: ["property"],
    queryFn: async () => {
      const response = await instance.get("/property");
      const data = await response.data;
      return filterOutLeadProperties(data);
    },
    enabled: isEnabled && isUserOwner
  });
};
