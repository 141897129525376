import { Text, TextProps } from "@kolmeo/ui-core";
import { useTranslation } from "react-i18next";
import { toTitleCase } from "@src/utils";

const TextSentence = ({ label, ...textProps }: { label: IntentionalAny<"need to type the translation list"> } & TextProps) => {
  const { t } = useTranslation();
  const formattedToTitle = toTitleCase(t(label));
  return <Text {...textProps}>{formattedToTitle}</Text>;
};

export default TextSentence;
