import styled from "styled-components/native";
import { border, boxShadow, color, flexbox, layout, position, space, } from "styled-system";
const SectionList = styled.SectionList `
  ${color}
  ${space}
  ${layout}
  ${position}
  ${border}
  ${flexbox}
  ${boxShadow}
`;
export default SectionList;
