export default {
    path: {
        d: "M14.4,6L14,4H5V21H7V14H12.6L13,16H20V6H14.4Z",
    },
    properties: {
        viewBox: "0 0 24 24",
        outlined: false,
        strokeWidth: "0",
    },
};
