import styled from 'styled-components/native';
import { color, space, layout, position, border, flexbox, boxShadow } from 'styled-system';
const ScrollView = styled.ScrollView `
  ${color}
  ${space}
  ${layout}
  ${position}
  ${border}
  ${flexbox}
  ${boxShadow}
`;
export default ScrollView;
