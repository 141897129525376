import { PendingUserProfileInvite, UserProfile, IS_FIRST_TIME_LOGIN } from "@src/types";
import { useEffect, useState } from "react";
import { LottieAnimation, kolmeoCirclesLoader } from "@kolmeo/ui-components";
import { useMediaQuery } from "@src/hooks";
import { useUserProfilesQuery } from "./useUserProfilesQuery";
import { Box, Text, useTheme } from "@kolmeo/ui-core";
import UserProfileInvite from "./UserProfileInvite";
import NoActiveProfilesScreen from "./NoActiveProfiles/NoActiveProfilesScreen";
import UserProfileTypeSelector from "./UserProfileSelector/UserProfileTypeSelector";
import { useTranslation } from "react-i18next";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { initPendoSessionWithEmailOnly } from "@src/lib/Analytics/Pendo/pendoUtils";

const WelcomeScreen = (): JSX.Element => {
  const { t } = useTranslation("Common", { keyPrefix: "Welcome" });
  const { spacing } = useTheme();
  const { isMobile } = useMediaQuery();
  const [pendingUserProfileInvites, setPendingUserProfileInvites] = useState<PendingUserProfileInvite[]>([]);
  const [activeUserProfiles, setActiveUserProfiles] = useState<UserProfile[]>([]);
  const [showSkip, setShowSkip] = useState<boolean>(false);
  const [showNoaActiveProfilesError, setShowNoActiveProfilesError] = useState<boolean>(false);
  const [showProfileSelector, setShowProfileSelector] = useState<boolean>(false);
  const { data, refetch, isLoading } = useUserProfilesQuery();
  const [isFirstTimeLogin, setIsFirstTimeLogin] = useState<boolean>(true);

  useEffect(() => {
    setActiveUserProfiles(data?.activeUserProfiles ?? []);
    setPendingUserProfileInvites(data?.pendingUserProfileInvites ?? []);
    setShowNoActiveProfilesError((data?.activeUserProfiles.length ?? 0) === 0 && data?.pendingUserProfileInvites.length === 0);
    setShowProfileSelector((data?.activeUserProfiles.length ?? 0) > 0 && data?.pendingUserProfileInvites.length === 0);
    setShowSkip((data?.activeUserProfiles.length ?? 0) > 0);
    checkIsFirstTimeLogin();
  }, [data, isLoading]);

  const checkIsFirstTimeLogin = async () => {
    let isFirstTimeLogin = await AsyncStorage.getItem(IS_FIRST_TIME_LOGIN);

    if (isFirstTimeLogin === null) {
      isFirstTimeLogin = JSON.stringify((data?.activeUserProfiles.length ?? 0) === 0);
      await AsyncStorage.setItem(IS_FIRST_TIME_LOGIN, isFirstTimeLogin);
    }

    try {
      setIsFirstTimeLogin(JSON.parse(isFirstTimeLogin));
    } catch (error) {
      console.error("Error parsing isFirstTimeLogin value", error);
    }
  };

  const onSuccess = () => refetch();

  const renderComponents = () => {
    if (isLoading) {
      return (
        <Box justifyContent="center" alignItems="center" height="100%">
          <LottieAnimation source={kolmeoCirclesLoader} />
        </Box>
      );
    } else if (showNoaActiveProfilesError) {
      return <NoActiveProfilesScreen />;
    } else if (showProfileSelector) {
      if (activeUserProfiles[0].emailAddress) {
        initPendoSessionWithEmailOnly(activeUserProfiles[0].emailAddress);
      }
      return <UserProfileTypeSelector activeUserProfiles={activeUserProfiles} isFirstTimeLogin={isFirstTimeLogin} />;
    } else {
      return (
        <>
          <Box style={{ backgroundColor: "#FFFFFF", height: "100%" }}>
            {showSkip && (
              <Box style={{ backgroundColor: "#FFFFFF", paddingTop: spacing.px8, paddingRight: spacing.px8 }}>
                <Text
                  onPress={() => setShowProfileSelector(true)}
                  style={{ color: "#251EB4", fontFamily: "Inter", textAlign: isMobile ? "right" : "center" }}
                >
                  {t("SkipUntilNextLogin")}
                </Text>
              </Box>
            )}
            <UserProfileInvite
              isFirstTimeLogin={isFirstTimeLogin}
              pendingUserProfileInvites={pendingUserProfileInvites}
              onSuccess={onSuccess}
            />
          </Box>
        </>
      );
    }
  };

  return renderComponents();
};

export default WelcomeScreen;
