import { Seperator } from "@src/components";
import { Box, FlatList, Text, useTheme } from "@kolmeo/ui-core";
import { useTranslation } from "react-i18next";
import { TenancyPaymentDetails } from "@src/navigation/TenancyNavigator/types";
import { ScreenEmptyState } from "@src/containers";
import VirtualAccountMethodCard from "../ListCards/VirtualAccountMethodCard";

type VirtualAccountDetailsListProps = {
  data?: TenancyPaymentDetails[];
  propertyName?: string;
  isBelowMobile: boolean;
  onListCardPress: (item: TenancyPaymentDetails) => void;
};
const VirtualAccountDetailsList = ({ data, propertyName, onListCardPress, isBelowMobile }: VirtualAccountDetailsListProps) => {
  const { t } = useTranslation(["Common", "Tenant"]);
  const { palette, spacing } = useTheme();

  const separatorComponent = () => {
    return <Seperator margin={spacing.px8} seperatorColor="transparent" />;
  };

  return (
    <FlatList
      ItemSeparatorComponent={separatorComponent}
      style={{ zIndex: -1 }}
      testID="virtual-account-method-list"
      data={data}
      ListHeaderComponent={
        <Box paddingBottom={spacing.px16} zIndex={-1}>
          {data && data?.length > 0 && <Text variant="headingH5">{t("Tenant:PaymentMethods.PaymentDetailsHeader")}</Text>}
        </Box>
      }
      ListEmptyComponent={
        <ScreenEmptyState
          textColor={palette.text.primary}
          title={t("Tenant:PaymentMethods.PaymentDetailsEmpty")}
          content={t("Tenant:PaymentMethods.PaymentDetailsEmptySecondary")}
        />
      }
      renderItem={({ item }) => {
        return (
          <VirtualAccountMethodCard
            item={item}
            propertyName={propertyName}
            onPress={() => onListCardPress(item)}
            isFullWidth={isBelowMobile}
          />
        );
      }}
      contentContainerStyle={{ paddingBottom: 6, paddingHorizontal: 1 }} // to show renderItem box shadows
    />
  );
};

export default VirtualAccountDetailsList;
