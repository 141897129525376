import { Text, Box, Image, useTheme } from "@kolmeo/ui-core";
import { PillItem } from "@kolmeo/ui-components";
import { PropertyStatus } from "@src/types";
import { PropertySelectCardProps } from "../PropertySelectCard";

const PropertySelectCardWeb = ({ details }: PropertySelectCardProps) => {
  const { propertyName, officeName, propertyLogoUrl, propertyStatus } = details;

  const { spacing, statusVariants, palette } = useTheme();

  return (
    <Box width="100%" overflow={"hidden"} flexDirection="column" backgroundColor="transparent">
      <Box>
        <Image
          style={{ minHeight: 160, backgroundColor: "#D9D9D9" }}
          resizeMode={propertyLogoUrl ? "cover" : "contain"}
          accessibilityLabel={propertyLogoUrl ? "property image" : "property image fallback"}
          source={propertyLogoUrl || require("../../assets/house_outline.png")}
        />
      </Box>
      <Box padding={spacing.px16}>
        <Box mb={spacing.px16} mt={spacing.px4}>
          <Text fontWeight={600}>{propertyName}</Text>
        </Box>
        <Box flexDirection="row" justifyContent="space-between" alignItems="flex-end">
          <Text variant="paragraph12" color={palette.text.error}>
            {officeName}
          </Text>
          {propertyStatus === PropertyStatus.inactive && (
            <PillItem
              text="Inactive"
              textProps={{ color: palette.text.secondary }}
              borderColor={palette.text.secondary}
              borderWidth={1}
              backgroundColor={statusVariants.default.backgroundColor}
              paddingY={spacing.px4}
              paddingX={spacing.px8}
              marginTop={spacing.px4}
            />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default PropertySelectCardWeb;
