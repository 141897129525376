import { Box, Text, useTheme, TouchableOpacity } from "@kolmeo/ui-core";

import { CenteredContainer } from "@src/containers";
import IRLtoDigital from "./IRLtoDigital.svg";
import ErrorDog from "./404ErrorDog.svg";
import useKolmeoAuth from "@src/lib/AzureAuth/useKolmeoAuth";
import { useMediaQuery } from "@src/hooks";

const Fallback = ({
  variant = "UnderConstruction",
  errorMessage
}: {
  variant?: "UnderConstruction" | "GenericError";
  errorMessage?: string;
}) => {
  const { spacing, colors } = useTheme();
  const { logout } = useKolmeoAuth();
  const { mq } = useMediaQuery();

  return (
    <CenteredContainer flex={1}>
      <Box justifyContent="center" alignItems="center" height="100%">
        {variant === "UnderConstruction" && (
          <>
            <IRLtoDigital style={{ transform: "scale(1.5)" }} />
            <Text mt={spacing.px16} variant="headingH2" style={{ fontFamily: "Hornbill" }}>
              This page is under construction
            </Text>
          </>
        )}
        {variant === "GenericError" && (
          <>
            <ErrorDog style={{ transform: "scale(1.5)" }} />
            <Text marginY={spacing.px16} variant="headingH2" style={{ fontFamily: "Hornbill" }}>
              {errorMessage ? errorMessage : "Uh oh, a problem has occured. Please contact your Property Manager."}
            </Text>
            <TouchableOpacity
              onPress={() => {
                logout();
              }}
            >
              <Box
                backgroundColor={colors.primary}
                borderColor={colors.primary}
                borderWidth={1}
                borderRadius={8}
                width={mq({ desktop: 220, tablet: 180, phone: "100%" })}
                paddingY={12}
                justifyContent="center"
                alignItems="center"
              >
                <Text fontFamily="Inter" color={"white"} fontWeight={600}>
                  Logout
                </Text>
              </Box>
            </TouchableOpacity>
          </>
        )}
      </Box>
    </CenteredContainer>
  );
};

export default Fallback;
