import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { createContext, useContext } from "react";
export const PortalContext = createContext({
    mount: undefined,
    unmount: undefined,
});
const PortalProvider = ({ children }) => {
    const [portalChildren, setPortalChildren] = React.useState([]);
    const mount = (element) => {
        setPortalChildren((prevChildren) => [...prevChildren, element]);
    };
    const unmount = (element) => {
        setPortalChildren((prevChildren) => prevChildren.filter((child) => child !== element));
    };
    return (_jsxs(PortalContext.Provider, { value: { mount, unmount }, children: [children, _jsx(PortalContainer, { portalChildren: portalChildren })] }));
};
const PortalContainer = ({ portalChildren }) => {
    return _jsx(_Fragment, { children: portalChildren });
};
export const usePortalContext = () => useContext(PortalContext);
export default PortalProvider;
