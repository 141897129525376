import { BottomSheet } from "react-spring-bottom-sheet";
import "react-spring-bottom-sheet/dist/style.css";

import { useMediaQuery } from "@src/hooks";
import { Box, TouchableOpacity, Text, useTheme } from "@kolmeo/ui-core";
import { Icon, coreThemeV2 } from "@kolmeo/ui-components";

const GUTTER = 16;
const PageSeperator = () => <Box width={`${GUTTER}px`} height="100%" />;

const SideViewTitle = ({ title, toggle, headingColor }: { title: string; toggle: () => void; headingColor: string }): JSX.Element => {
  const { isBelowTablet } = useMediaQuery();
  const { sizing } = coreThemeV2;
  return (
    <Box flexDirection="row" justifyContent="center" position="relative" padding={`${sizing.size12}px`}>
      <Text variant="textHeavy14" color={headingColor}>
        {title}
      </Text>
      {isBelowTablet && (
        <TouchableOpacity onPress={() => toggle()} position="absolute" right={`${sizing.size20}px`} top={`${sizing.size12}px`}>
          <Icon type={"close"} size={"xs"} />
        </TouchableOpacity>
      )}
    </Box>
  );
};

const Wrapper = ({ children }: React.PropsWithChildren): JSX.Element => {
  const { spacing, palette } = useTheme();

  return (
    <Box
      backgroundColor="white"
      flexDirection="column"
      flex={1}
      overflow="hidden"
      borderRadius={spacing.px16}
      borderWidth={1}
      borderColor={palette.border.default}
      height="100%"
    >
      {children}
    </Box>
  );
};

const SplitScreenContainer = ({
  mainView,
  sideView,
  isBottomSheetOpen = false,
  toggleBottomSheet,
  sideViewTitle,
  isSideViewBlank = false,
  headingColor
}: {
  mainView?: JSX.Element;
  sideView?: JSX.Element;
  sideViewTitle: string;
  isBottomSheetOpen: boolean;
  toggleBottomSheet: () => void;
  isSideViewBlank?: boolean;
  headingColor: string;
}): JSX.Element => {
  const { isBelowTablet, mq } = useMediaQuery();
  const { spacing } = useTheme();

  const SideViewBody = () => (
    <>
      {isBottomSheetOpen && <SideViewTitle headingColor={headingColor} title={sideViewTitle} toggle={toggleBottomSheet} />}
      {sideView}
    </>
  );

  return (
    <Box flexDirection="row" flex={1} mb={spacing.px16}>
      <Box
        width={mq({
          phone: "100%",
          tablet: "100%",
          desktop: `calc(50% - ${GUTTER / 2}px)`
        })}
        flexDirection="column"
      >
        <Wrapper>{mainView}</Wrapper>
      </Box>
      {isBelowTablet ? (
        <BottomSheet open={isBottomSheetOpen}>
          <Box backgroundColor="white" height="100%" flex={1} flexDirection="column">
            <SideViewBody />
          </Box>
        </BottomSheet>
      ) : (
        <>
          <PageSeperator />
          <Wrapper>{isSideViewBlank ? <></> : <SideViewBody />}</Wrapper>
        </>
      )}
    </Box>
  );
};

export default SplitScreenContainer;
