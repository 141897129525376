import { Box, Text, TouchableOpacity, useTheme } from "@kolmeo/ui-core";
import { CenteredContainer } from "@src/containers";
import HomeIcon from "./HomeIcon.svg";
import { useMediaQuery } from "@src/hooks";
import { useTranslation } from "react-i18next";
import useKolmeoAuth from "@src/lib/AzureAuth/useKolmeoAuth";

const NoActiveProfilesScreen = (): JSX.Element => {
  const { t } = useTranslation("Common", { keyPrefix: "Welcome" });
  const { spacing, colors } = useTheme();
  const { isMobile, mq } = useMediaQuery();
  const { logout } = useKolmeoAuth();

  return (
    <CenteredContainer flex={1} style={{ textAlign: isMobile ? "left" : "center", marginTop: "3rem" }}>
      <Text mt={spacing.px16} variant="headingH1" style={{ fontFamily: "Inter", marginLeft: isMobile ? spacing.px24 : "" }}>
        {t("WelcomeTitle")}
      </Text>
      <Text
        mt={spacing.px16}
        mb={spacing.px16}
        variant="headingH1"
        style={{ fontFamily: "Inter", marginLeft: isMobile ? spacing.px24 : "" }}
      >
        {t("AccountNeedsSettingUp")}
      </Text>
      <Box mt={spacing.px16} mb={spacing.px16} justifyContent="center" alignItems="center" height="auto" padding={spacing.px24}>
        <HomeIcon style={{ transform: "scale(1.5)" }} />
      </Box>

      <Text mt={spacing.px24} mb={spacing.px16} pl={spacing.px16} pr={spacing.px16} style={{ fontFamily: "Inter", textAlign: "center" }}>
        {t("NoPropertiesLinked")}
      </Text>
      <Text mb={spacing.px16} pl={spacing.px16} pr={spacing.px16} style={{ fontFamily: "Inter", textAlign: "center" }}>
        {t("ContactPropertyManager")}
      </Text>
      <TouchableOpacity
        alignItems={"center"}
        padding={spacing.px24}
        onPress={() => {
          logout();
        }}
      >
        <Box
          backgroundColor={"#251eb4"}
          borderColor={colors.primary}
          borderWidth={1}
          borderRadius={8}
          width={mq({ desktop: 220, tablet: 180, phone: "100%" })}
          paddingY={12}
          justifyContent="center"
          alignItems="center"
        >
          <Text fontFamily="Inter" color={"white"} fontWeight={600}>
            Logout
          </Text>
        </Box>
      </TouchableOpacity>
    </CenteredContainer>
  );
};

export default NoActiveProfilesScreen;
