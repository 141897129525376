import { ButtonV2, Icon } from "@kolmeo/ui-components";
import { Box, Text, useTheme } from "@kolmeo/ui-core";
import { Modal } from "@src/components";
import { TenantThemeBrandingObject } from "@src/lib/Kiitos";
import { useTranslation } from "react-i18next";

type ActionRequiredModalModalType = {
  heading: string;
  iconType: "danger" | "success";
  actionType: "danger" | "success";
  content: string;
  isVisible: boolean;
  toggleVisibility: () => void;
  brandingObject?: TenantThemeBrandingObject;
  onRightButtonPress: () => void;
  rightText: string;
  onLeftButtonPress?: () => void;
  leftText?: string;
  leftButtonDestructive?: boolean;
};
const ActionRequiredModal = ({
  heading,
  content,
  iconType,
  isVisible,
  toggleVisibility,
  brandingObject,
  actionType,
  rightText,
  leftText,
  onRightButtonPress,
  onLeftButtonPress,
  leftButtonDestructive
}: ActionRequiredModalModalType) => {
  const { t } = useTranslation(["Common", "Tenant"]);
  const { spacing, sizing, palette } = useTheme();

  return (
    <Modal
      toggleVisible={toggleVisibility}
      isVisible={isVisible}
      title={heading}
      renderHeaderSeperator
      callToAction={
        <>
          <Box flexDirection={"row"} justifyContent={"space-between"}>
            <ButtonV2
              flex={1}
              size={"small"}
              width={"48%"}
              variant={leftButtonDestructive ? "outlinedDanger" : "outlined"}
              label={leftText ?? t("Common:Generic.Cancel")}
              onPress={onLeftButtonPress ?? toggleVisibility}
            />
            <Box width={sizing.size20}></Box>
            <ButtonV2
              flex={1}
              width={"48%"}
              size={"small"}
              variant={actionType == "danger" ? "danger" : "confirm"}
              transparentHover
              label={rightText}
              overrideVariant={
                actionType == "success" ? { bgColor: brandingObject?.primaryColor || palette.background.primary.default } : undefined
              }
              onPress={onRightButtonPress}
            />
          </Box>
        </>
      }
    >
      <Box borderRadius={8}>
        <Box alignItems={"center"} marginBottom={spacing.px20}>
          {iconType == "danger" && <Icon type={"warning-circle"} size={"xl"} color={palette.danger.icon} />}
          {iconType == "success" && <Icon type={"check-circle"} size={"xl"} color={palette.success.icon} />}
        </Box>
        <Text variant="paragraph14">{content}</Text>
      </Box>
    </Modal>
  );
};

export default ActionRequiredModal;
