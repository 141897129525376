import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from "react";
import { Platform } from "react-native";
import ReactTooltip from "react-tooltip";
import { Text, coreTheme, Box } from "@kolmeo/ui-core";
const Label = ({ variant, title, ...props }) => {
    const { palette } = coreTheme;
    useEffect(() => {
        Platform.OS === "web" && ReactTooltip.rebuild();
    }, []);
    const typeCombination = {
        small: {
            color: palette.placeHolder,
            textVariant: "textMedium10"
        },
        medium: {
            color: palette.placeHolder,
            textVariant: "textMedium10"
        },
        large: {
            color: palette.placeHolder,
            textVariant: "textMedium10"
        }
    };
    return (_jsx(Box, { ...props, children: _jsx(Text, { variant: typeCombination[variant].textVariant, color: typeCombination[variant].color, children: title }) }));
};
export default Label;
