export default {
    path: {
        d1: "M29.9125 11.6C29.875 11.5 28.8125 9.15003 26.4625 6.78753C23.325 3.66253 19.3625 2.00003 15 2.00003C14.0786 1.99835 13.1588 2.07361 12.25 2.22503C12.0744 2.25793 11.9106 2.33671 11.7753 2.45337C11.64 2.57003 11.538 2.72041 11.4796 2.88926C11.4212 3.0581 11.4085 3.23938 11.4428 3.41471C11.4771 3.59004 11.5572 3.75317 11.675 3.88753L24.3375 17.8125C24.4311 17.9149 24.5451 17.9967 24.672 18.0526C24.799 18.1086 24.9362 18.1375 25.075 18.1375C25.322 18.1342 25.5604 18.046 25.75 17.8875C28.6 15.325 29.8625 12.525 29.9125 12.4125C29.9679 12.2842 29.9964 12.146 29.9964 12.0063C29.9964 11.8666 29.9679 11.7283 29.9125 11.6Z",
        d2: "M5.73748 0.32502C5.55682 0.136011 5.30931 0.0252788 5.04799 0.0165493C4.78667 0.0078198 4.53232 0.101787 4.33944 0.278319C4.14657 0.454851 4.0305 0.699903 4.01612 0.960974C4.00173 1.22205 4.09017 1.47837 4.26248 1.67502L6.66248 4.31252C2.12498 7.10002 0.174984 11.4 0.0874842 11.6C0.0321063 11.7283 0.00354004 11.8665 0.00354004 12.0063C0.00354004 12.146 0.0321063 12.2842 0.0874842 12.4125C0.124984 12.5 1.18748 14.85 3.53748 17.2125C6.67498 20.3375 10.6375 22 15 22C17.2418 22.0153 19.4614 21.5552 21.5125 20.65L24.2625 23.675C24.3561 23.7774 24.4701 23.8592 24.597 23.9151C24.724 23.9711 24.8612 24 25 24C25.2494 23.9976 25.4894 23.9042 25.675 23.7375C25.7733 23.6498 25.853 23.5433 25.9095 23.4242C25.966 23.3052 25.9981 23.1761 26.004 23.0444C26.0098 22.9128 25.9892 22.7814 25.9434 22.6578C25.8977 22.5342 25.8277 22.4211 25.7375 22.325L5.73748 0.32502ZM15 16.5C14.1776 16.4995 13.3712 16.2736 12.6681 15.8469C11.9651 15.4203 11.3925 14.8091 11.0124 14.0799C10.6323 13.3506 10.4593 12.5312 10.5122 11.7105C10.5652 10.8899 10.8419 10.0994 11.3125 9.42502L17.2125 15.9125C16.5393 16.299 15.7762 16.5016 15 16.5Z",
    },
    properties: {
        viewBox: "0 0 30 24",
        outlined: false,
        strokeWidth: "0",
    },
};
