import { jsx as _jsx } from "react/jsx-runtime";
import { Box } from "@kolmeo/ui-core";
import { useState } from "react";
import { useAnimatedStyle, withSpring } from "react-native-reanimated";
import { createAnimatedComponent } from "../NativeAnimations";
import coreThemeV2 from "../../theme/coreTheme";
const AnimatedBox = createAnimatedComponent(Box);
const MIN_WIDTH = 14;
export const ProgressBar = ({ value, max, barColor }) => {
    const [wrapperWidth, setWrapperWidth] = useState(0);
    const { palette } = coreThemeV2;
    const currentWidth = (value / max) * wrapperWidth;
    const animatedWidth = currentWidth > MIN_WIDTH ? currentWidth : MIN_WIDTH;
    const animatedStyle = useAnimatedStyle(() => {
        return { width: withSpring(animatedWidth, { damping: 20, stiffness: 90 }) };
    });
    return (_jsx(Box, { flex: 1, backgroundColor: palette.background.surface, flexDirection: "row", alignContent: "flex-start", justifyContent: "flex-start", justifyItems: "flex-start", overflow: "hidden", onLayout: (e) => setWrapperWidth(e.nativeEvent.layout.width), height: 14, borderRadius: 7, border: 1, borderColor: palette.border.light, children: _jsx(AnimatedBox, { style: [animatedStyle, { borderRadius: 7 }], backgroundColor: barColor ?? palette.background.success.default }) }));
};
export default ProgressBar;
