export default {
    path: {
        d: "M6,13H18V11H6M3,6V8H21V6M10,18H14V16H10V18Z",
    },
    properties: {
        viewBox: "0 0 24 24",
        outlined: false,
        strokeWidth: "0",
    },
};
