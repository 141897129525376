import { Avatar, MenuList, MenuListOptions } from "@kolmeo/ui-components";
import { useTheme } from "@kolmeo/ui-core";
import { useCurrentUser, useMediaQuery } from "@src/hooks";
import { useTranslation } from "react-i18next";

import { featureList, useFeature } from "@src/lib/FeatureFlags";
import { useCustomNavigation } from "@src/navigation/hooks/useCustomNavigation";
import { useGlobalTenant } from "@src/navigation/hooks/useGlobalTenant";
import { ProfileType, TAC_URL } from "@src/types";
import { Dimensions, Linking } from "react-native";
import { useAuth } from "react-oidc-context";
import { b2cSettings } from "@src/lib/AzureAuth/AuthProvider/b2cSettings";

import { transformProfileTypeId } from "@src/utils";
import { useProfileTypeSwitcher } from "@src/hooks/useProfileTypeSwitcher/useProfileTypeSwitcher";

const DropdownMenu = () => {
  const { t } = useTranslation(["Common", "Tenant"]);

  const { currentUser } = useCurrentUser();
  const { tenancyId, hasMultipleTenancies } = useGlobalTenant();

  const { signoutRedirect } = useAuth();
  const { colors, palette } = useTheme();
  const { isBelowTablet } = useMediaQuery();
  const { navigateToScreen } = useCustomNavigation();
  const showTenantsBankAccountDetailsOption = useFeature(featureList.tenantBankAccountDetails);

  const { userHasOwnerAndTenantProfiles, getAlternativeProfileTypeForUser, handleProfileSwitch } = useProfileTypeSwitcher();

  const menuOptions: MenuListOptions[] = [
    {
      readOnlyOption: true,

      title: `${currentUser?.emailAddress}`,
      textColor: palette.text.primary,
      headerTitle: transformProfileTypeId(currentUser?.profileTypeId),
      headerTitleBold: true,
      titleBold: false,
      secondaryTitleBold: false
    },
    ...(currentUser?.profileTypeId === ProfileType.tenant && showTenantsBankAccountDetailsOption?.enabled && hasMultipleTenancies
      ? [
          {
            title: t("Tenant:PaymentMethods.PaymentSettingsHeader"),
            textColor: palette.text.primary,
            onPress: () => navigateToScreen("PaymentSettings", { screen: "Details", tenancyId })
          }
        ]
      : []),
    ...(userHasOwnerAndTenantProfiles
      ? [
          {
            title: t("Common:Generic.SwitchProfile", { profileName: transformProfileTypeId(getAlternativeProfileTypeForUser) }),
            onPress: () => handleProfileSwitch(),
            titleBold: true
          }
        ]
      : []),
    {
      title: t("Logout"),
      textColor: palette.text.primary,
      iconType: "signout",
      onPress: async () => await signoutRedirect({ post_logout_redirect_uri: `${b2cSettings.redirectUrl}logout` })
    },
    { title: t("Common:Generic.TAC"), textColor: palette.text.primary, isLink: true, onPress: () => Linking.openURL(TAC_URL) }
  ];
  return (
    <MenuList
      triggerComponent={
        <Avatar
          backgroundColor={colors.background}
          userType="Owner"
          name={currentUser ? { firstName: currentUser.firstName, lastName: currentUser.lastName } : undefined}
        />
      }
      options={menuOptions}
      optionsContainerStyle={{
        display: "flex",
        backgroundColor: colors.background,
        minWidth: isBelowTablet ? Dimensions.get("screen").width - 100 : Dimensions.get("screen").width / 5,
        maxWidth: isBelowTablet ? Dimensions.get("screen").width : Dimensions.get("screen").width / 5
      }}
    />
  );
};

export default DropdownMenu;
