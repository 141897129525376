import { LottieAnimation, kolmeoCirclesLoader } from "@kolmeo/ui-components";
import { Box } from "@kolmeo/ui-core";

const MyKolmeoLoader = () => {
  return (
    <Box flex={1} justifyContent="center" alignItems="center">
      <LottieAnimation source={kolmeoCirclesLoader} />
    </Box>
  );
};

export default MyKolmeoLoader;
