import { Box, Text, SectionList, useTheme } from "@kolmeo/ui-core";

import { ReactQueryContainer, PageContainer } from "@src/containers";
import { Seperator } from "@src/components";
import { transformDateSections } from "./Statements.utils";
import { useStatementsHistory } from "./useStatements";

import { StatementsRowItem } from "./StatementsRowItem";
import { useCustomNavigation } from "@src/navigation/hooks/useCustomNavigation";
import { useGroupPropertyId, usePreviousScreenName } from "@src/store";
import { useTranslation } from "react-i18next";
import { useGetBrandingObject } from "../PropertyOverview/usePropertyOverview";
import { variableAlwaysNumber } from "@src/utils/textTransformers/variableAlwaysNumber";
import { useBranding } from "@src/navigation/useBranding";
import { useEffect } from "react";
import { useSetPreviousScreenName } from "@src/hooks/useSetPreviousScreenName/useSetPreviousScreenName";
import { RootStackParamList } from "@src/navigation/RootStack";
import { NativeStackScreenProps } from "@react-navigation/native-stack";

const GroupStatements = ({ route }: NativeStackScreenProps<RootStackParamList, "GroupStatements">): JSX.Element => {
  const { brandingObject } = useBranding();
  const { spacing, palette, colors } = useTheme();
  const { t } = useTranslation(["Owner"]);
  const { goBack } = useCustomNavigation();
  const handleBackLink = () => goBack();
  const groupPropertyId = useGroupPropertyId();

  const { data: brandingData } = useGetBrandingObject(variableAlwaysNumber(route.params.propertyId ?? groupPropertyId));
  const { data, error, isError, isLoading } = useStatementsHistory({ propertyId: route.params.propertyId ?? groupPropertyId });
  const previousScreenName = usePreviousScreenName();
  const { setPreviousScreenNameToStore } = useSetPreviousScreenName();

  useEffect(() => {
    // store previous navigation route
    setPreviousScreenNameToStore(["GroupStatements"]);
  }, []);

  return (
    <PageContainer
      navigateBack={handleBackLink}
      headingText={t("Owner:Statements.PageHeading")}
      headingIcon="file-document"
      brandingObject={brandingData?.branding}
      navigateBackLabel={previousScreenName}
    >
      <Box
        maxHeight="100%"
        height="100%"
        backgroundColor="white"
        flexDirection="column"
        flex={1}
        overflow="hidden"
        borderRadius={spacing.px16}
        borderWidth={1}
        borderColor={palette.border.default}
      >
        <ReactQueryContainer
          isLoading={isLoading}
          isError={isError}
          error={error}
          showEmptyState={data?.length === 0}
          emptyStateContent={{
            title: t("Owner:Statements.EmptyStatementsHeading"),
            content: t("Owner:Statements.EmptyStatementsDescription"),
            textColor: brandingObject?.primaryColor ?? colors.primary,
            setMaxHeight: true
          }}
        >
          {data && data.length > 0 ? (
            <SectionList
              sections={transformDateSections(data)}
              keyExtractor={(_, index) => index.toString()}
              ItemSeparatorComponent={Seperator}
              renderItem={({ item }) => <StatementsRowItem item={item} />}
              renderSectionHeader={({ section: { title } }) => (
                <Box backgroundColor={palette.border.light} justifyContent="center" alignItems="center" height={40}>
                  <Text>{title}</Text>
                </Box>
              )}
            />
          ) : null}
        </ReactQueryContainer>
      </Box>
    </PageContainer>
  );
};

export default GroupStatements;
