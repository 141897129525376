import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import { Platform } from "react-native";
import ReactTooltip from "react-tooltip";
import { Box, Text, Image } from "@kolmeo/ui-core";
import Icon from "../Icon";
const backgroundColorObj = {
    PropertyManager: "#D8F7E9",
    Owner: "#CCE6F0",
    Tenant: "#D3D2F0",
};
const sizes = {
    xl: { container: 64, icon: "lg" },
    lg: { container: 40, icon: "md" },
    md: { container: 32, icon: "sm" },
    sm: { container: 24, icon: "xs" },
    xs: { container: 16, icon: "xs" },
    xxs: { container: 8, icon: "xxs" },
    micro: { container: 8, icon: "micro" },
};
const getInitial = (string) => string.charAt(0).toUpperCase();
const Avatar = ({ size = "lg", imageUrl, userType, name, backgroundColor, padding, circularBg }) => {
    useEffect(() => {
        Platform.OS === "web" && ReactTooltip.rebuild();
    }, []);
    return (_jsx(Box, { borderRadius: circularBg ? "50%" : sizes[size].container / 2, height: sizes[size].container, width: sizes[size].container, backgroundColor: backgroundColor || backgroundColorObj[userType], justifyContent: "center", alignItems: "center", padding: padding, children: imageUrl ? (_jsx(Image, { style: { flex: 1, width: undefined, height: undefined }, resizeMode: "contain", source: imageUrl })) : name ? (_jsxs(Box, { flexDirection: "row", children: [_jsx(Text, { fontWeight: 600, children: getInitial(name.firstName) }), _jsx(Text, { fontWeight: 600, children: getInitial(name.lastName) })] })) : (_jsx(Icon, { type: "avatar", size: sizes[size].icon })) }));
};
export default Avatar;
