import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from "react";
import { Platform, ActivityIndicator } from "react-native";
import ReactTooltip from "react-tooltip";
import { Pressable, Box, Text, coreTheme, toTitleCase } from "@kolmeo/ui-core";
import Icon from "../Icon";
import { AnimatedIcon } from "../Icon/animateIcon";
import { lightenColour } from "./utils/lightenColour";
const ButtonSizes = {
    small: {
        paddingX: "16px",
        paddingY: "8px",
    },
    medium: {
        paddingX: "24px",
        paddingY: "11px",
    },
    large: {
        paddingX: "32px",
        paddingY: "15px",
    },
};
const getButtonSize = (size) => {
    return size && ButtonSizes[size] ? ButtonSizes[size] : ButtonSizes.medium;
};
const ButtonV2 = ({ isLoading = false, iconType, isIconLeft = true, iconSize = "sm", iconAnimation, variant, fullwidth = false, disabled, raised, size, textProps, label, useTitleCase = true, width, onPress, transparentHover = false, overrideVariant, testID, ...props }) => {
    const { palette } = coreTheme;
    const buttonSize = getButtonSize(size);
    useEffect(() => {
        Platform.OS === "web" && ReactTooltip.rebuild();
    }, []);
    const typeColorCombination = {
        primary: {
            buttonBGColor: disabled ? palette.backgroundDisable : palette.backgroundPrimaryDefault,
            tintColor: disabled ? palette.persionBlueTransparent40 : palette.persianBlueDarken15,
            color: palette.white,
            borderColor: palette.transparent,
            spinnerColor: palette.white,
            iconColor: palette.white,
            hoverColor: palette.persianBlueLighten15,
            raised: false,
            underlined: false,
        },
        danger: {
            buttonBGColor: disabled ? palette.persionBlueTransparent40 : palette.backgroundDestructiveDefault,
            tintColor: disabled ? palette.persionBlueTransparent40 : palette.backgroundDestructiveDefault,
            color: palette.white,
            borderColor: palette.transparent,
            spinnerColor: palette.white,
            iconColor: palette.white,
            hoverColor: lightenColour(palette.backgroundDestructiveDefault, 15),
            raised: false,
            underlined: false,
        },
        secondary: {
            buttonBGColor: disabled ? palette.disabled : palette.white,
            tintColor: disabled ? palette.disabled : palette.white,
            color: palette.kolmeoBlack,
            borderColor: palette.transparent,
            spinnerColor: palette.white,
            iconColor: palette.kolmeoBlack,
            hoverColor: palette.persianBlueLighten15,
            raised: true,
            underlined: false,
        },
        confirm: {
            buttonBGColor: disabled ? palette.neomintDarken15Transparent40 : palette.neomintDarken15,
            tintColor: disabled ? palette.neomintDarken15Transparent40 : palette.neomintDarken15,
            color: palette.white,
            borderColor: palette.transparent,
            spinnerColor: palette.white,
            iconColor: palette.kolmeoBlack,
            hoverColor: palette.persianBlueLighten15,
            raised: true,
            underlined: false,
        },
        outlined: {
            buttonBGColor: palette.transparent,
            tintColor: disabled ? palette.disabled : palette.persianBlue,
            color: disabled ? palette.disabled : palette.persianBlue,
            borderColor: disabled ? palette.disabled : palette.persianBlue,
            spinnerColor: disabled ? palette.disabled : palette.persianBlue,
            iconColor: disabled ? palette.disabled : palette.persianBlue,
            hoverColor: palette.taivasLighten10,
            raised: false,
            underlined: false,
        },
        outlinedDanger: {
            buttonBGColor: palette.transparent,
            tintColor: disabled ? palette.disabled : palette.persianBlue,
            color: disabled ? palette.disabled : palette.backgroundDestructiveDefault,
            borderColor: disabled ? palette.disabled : palette.backgroundDestructiveDefault,
            spinnerColor: disabled ? palette.disabled : palette.persianBlue,
            iconColor: disabled ? palette.disabled : palette.backgroundDestructiveDefault,
            hoverColor: transparentHover,
            raised: false,
            underlined: false,
        },
        heroButton: {
            buttonBGColor: palette.persianBlue,
            tintColor: disabled ? palette.disabled : palette.white,
            color: palette.white,
            borderColor: palette.transparent,
            spinnerColor: palette.white,
            iconColor: disabled ? palette.disabled : palette.white,
            hoverColor: palette.persianBlueLighten15,
            raised: false,
            underlined: false,
        },
        modern: {
            buttonBGColor: palette.ateneoBlueLighten15,
            tintColor: disabled ? palette.disabled : palette.white,
            color: palette.white,
            borderColor: palette.transparent,
            spinnerColor: palette.white,
            iconColor: disabled ? palette.disabled : palette.white,
            hoverColor: palette.ateneoBlue,
            raised: false,
            underlined: false,
        },
        none: null,
        custom: {
            buttonBGColor: disabled ? palette.disabled : overrideVariant?.bgColor,
            tintColor: disabled ? palette.disabled : overrideVariant?.bgColor ? overrideVariant?.bgColor : palette.white,
            iconColor: disabled ? palette.disabled : overrideVariant?.iconColor ? overrideVariant?.iconColor : palette.white,
            hoverColor: overrideVariant?.bgColor
                ? lightenColour(overrideVariant.bgColor, 15)
                : lightenColour(overrideVariant?.borderColor, 15),
            borderColor: disabled ? palette.disabled : overrideVariant?.borderColor ? overrideVariant.borderColor : palette.transparent,
            spinnerColor: overrideVariant?.spinnerColor ? overrideVariant.spinnerColor : palette.white,
            raised: false,
            underlined: false,
            color: disabled ? palette.disabled : overrideVariant?.color ? overrideVariant.color : palette.white,
        },
        textButton: {
            color: disabled ? palette.disabled : overrideVariant?.color ? overrideVariant.color : palette.textPrimary,
            buttonBGColor: null,
            tintColor: null,
            iconColor: overrideVariant?.color ? overrideVariant.color : palette.textPrimary,
            hoverColor: overrideVariant?.color ? lightenColour(overrideVariant.color, 15) : palette.ateneoBlue,
            borderColor: null,
            spinnerColor: null,
            raised: false,
            underlined: true,
        },
    };
    if (variant == "textButton") {
        return (_jsxs(Pressable, { disabled: disabled, onPress: onPress, testID: testID, children: [_jsx(Text, { fontWeight: 600, style: { textDecorationLine: "underline" }, color: typeColorCombination[overrideVariant?.color ? "custom" : variant ? variant : "none"]?.color, children: useTitleCase && typeof label == "string" ? toTitleCase(label) : label }), iconType && (_jsx(Box, { position: "relative", overflowY: "visible", justifyContent: "center", height: 0, mr: iconType && isIconLeft ? 2 : 0, ml: iconType && isIconLeft ? 0 : 2, padding: 0, opacity: 1, children: _jsx(AnimatedIcon, { type: iconAnimation, children: _jsx(Icon, { type: iconType, size: iconSize, color: typeColorCombination[overrideVariant?.iconColor ? "custom" : variant ? variant : "none"]?.iconColor }) }) }))] }));
    }
    return (_jsxs(Pressable, { disabled: disabled, onPress: onPress, hoverStyle: !disabled &&
            !transparentHover && {
            backgroundColor: typeColorCombination[overrideVariant?.bgColor ? "custom" : variant ? variant : "none"]?.hoverColor,
        }, width: width ? width : fullwidth ? "100%" : "fit-content", flexDirection: iconType && isIconLeft ? "row" : "row-reverse", justifyContent: "center", alignItems: "center", borderWidth: 1, borderRadius: 8, paddingX: buttonSize.paddingX, paddingY: buttonSize.paddingY, opacity: 1, bg: typeColorCombination[overrideVariant?.bgColor ? "custom" : variant ? variant : "none"]?.buttonBGColor, borderColor: typeColorCombination[overrideVariant?.borderColor ? "custom" : variant ? variant : "none"]?.borderColor, style: typeColorCombination[variant ? variant : "none"]?.raised && {
            shadowColor: "#000000",
            shadowOffset: {
                width: 0,
                height: 4,
            },
            shadowOpacity: 0.19,
            shadowRadius: 5.62,
            elevation: 6,
        }, ...props, children: [iconType && (_jsx(Box, { position: "relative", overflowY: "visible", justifyContent: "center", height: 0, mr: iconType && isIconLeft ? 2 : 0, ml: iconType && isIconLeft ? 0 : 2, padding: 0, opacity: 1, children: _jsx(AnimatedIcon, { type: iconAnimation, children: _jsx(Icon, { type: iconType, size: iconSize, color: typeColorCombination[overrideVariant?.iconColor ? "custom" : variant ? variant : "none"]?.iconColor }) }) })), _jsx(Text, { color: typeColorCombination[overrideVariant?.color ? "custom" : variant ? variant : "none"]?.color, ...textProps, padding: 0, margin: 0, variant: "buttonHeavy14", textAlign: "center", children: useTitleCase && typeof label == "string" ? toTitleCase(label) : label }), isLoading && (_jsx(Box, { mr: 2, overflowY: "visible", children: _jsx(ActivityIndicator, { color: typeColorCombination[overrideVariant?.iconColor ? "custom" : variant ? variant : "none"]?.spinnerColor ?? "none" }) })), props.extraWebElements && _jsx(ReactTooltip, { id: props.extraWebElements?.["data-for"] })] }));
};
export default ButtonV2;
