import { jsx as _jsx } from "react/jsx-runtime";
import { Box } from "@kolmeo/ui-core";
const TopTabBarIcon = ({ descriptors, route, focused, color }) => {
    const { options } = descriptors[route.key];
    if (options.tabBarShowIcon === false) {
        return null;
    }
    if (options.tabBarIcon !== undefined) {
        const icon = options.tabBarIcon({ focused, color });
        return (_jsx(Box, { height: 24, width: 24, style: [options.tabBarIconStyle], children: icon }));
    }
    return null;
};
export default TopTabBarIcon;
