import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, coreTheme, useSafeAreaInsets } from "@kolmeo/ui-core";
import { useNavigation } from "@react-navigation/core";
import { useWindowDimensions } from "react-native";
import Button from "../../Button";
import { TAB_SCREEN_PAGINATION_BTN_HEIGHT } from "../constants";
const { palette, spacing } = coreTheme;
const TabScreenPaginationButtons = ({ nextTab, prevTab, onTabChanged }) => {
    const insets = useSafeAreaInsets();
    const navigation = useNavigation();
    const { width } = useWindowDimensions();
    const buttonWidth = width / 2 - spacing.spacing24;
    const buttonProps = {
        textProps: {
            numberOfLines: 1,
            style: {
                maxWidth: "85%",
            },
        },
        height: TAB_SCREEN_PAGINATION_BTN_HEIGHT,
        width: buttonWidth,
        iconSize: "micro",
    };
    return (_jsx(Box, { flexDirection: "row", alignItems: "center", justifyContent: "center", paddingRight: spacing.spacing16, paddingLeft: spacing.spacing16, borderTopColor: palette.borderLight, borderTopWidth: 1, zIndex: 100, paddingTop: spacing.spacing16, backgroundColor: palette.backgroundSurface, paddingBottom: insets.bottom ? insets.bottom : spacing.spacing16, children: _jsxs(_Fragment, { children: [prevTab && (_jsx(Box, { children: _jsx(Button, { variant: "outlined", buttonText: prevTab.name, onPress: () => {
                            onTabChanged && onTabChanged();
                            navigation.jumpTo(`${prevTab.routeName}`);
                        }, iconType: "caret-left", isIconRight: true, ...buttonProps }) }, prevTab.name)), nextTab ? (_jsx(Box, { marginLeft: "auto", children: _jsx(Button, { variant: "primary", buttonText: nextTab.name, onPress: () => {
                            onTabChanged && onTabChanged();
                            navigation.jumpTo(`${nextTab.routeName}`);
                        }, iconType: "caret-right", isIconRight: false, ...buttonProps }) }, nextTab.name)) : (_jsx(Button, { variant: "primary", buttonText: "Done", flex: !prevTab ? 1 : undefined, marginLeft: "auto", height: buttonProps.height, width: buttonProps.width, onPress: () => {
                        onTabChanged && onTabChanged();
                        navigation?.goBack();
                    } }, "done"))] }) }));
};
export default TabScreenPaginationButtons;
