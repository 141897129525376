import * as Sentry from "@sentry/react";

export const sentryInit = (): void => {
  Sentry.init({
    dsn: process.env.REACT_APP_SentryDSN,
    tracesSampleRate: 0.2,
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    environment: process.env.NODE_ENV,
    debug: true
  });
};
