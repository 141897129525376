import { useCallback, useEffect, useState, useMemo } from "react";
import { ProfileType, UserProfile } from "@src/types";
import { useCurrentUser } from "../useCurrentUser";
import { useNavigation } from "@react-navigation/native";
import { NavParams } from "@src/navigation/RootStack";
import { useUserProfilesQuery } from "@src/screens/Welcome/useUserProfilesQuery";

export const useProfileTypeSwitcher = () => {
  const { setCurrentUserItem, currentUser } = useCurrentUser();
  const { replace } = useNavigation<NavParams["navigation"]>();
  const { data, refetch, isLoading } = useUserProfilesQuery();
  const [activeUserProfiles, setActiveUserProfiles] = useState<UserProfile[]>([]);

  const [ownerProfiles, setOwnerProfiles] = useState<UserProfile[]>([]);
  const [tenantProfiles, setTenantProfiles] = useState<UserProfile[]>([]);
  useEffect(() => {
    if (data) {
      setActiveUserProfiles(data.activeUserProfiles ?? []);
      const ownerProfiles = data.activeUserProfiles.filter((x) => x.profileTypeId === ProfileType.owner);
      const tenantProfiles = data.activeUserProfiles.filter((x) => x.profileTypeId === ProfileType.tenant);
      setOwnerProfiles(ownerProfiles);
      setTenantProfiles(tenantProfiles);
    }
  }, [data]);

  const getAlternativeProfileTypeForUser = useMemo(() => {
    return currentUser?.profileTypeId == ProfileType.owner ? ProfileType.tenant : ProfileType.owner;
  }, [currentUser]);

  const showOwnerPage = useCallback(() => {
    setCurrentUserItem(ownerProfiles[0]);
    replace("MyProperties");
  }, [ownerProfiles, replace, setCurrentUserItem]);

  const showTenantPage = useCallback(() => {
    setCurrentUserItem(tenantProfiles[0]);
    replace("Tenancy");
  }, [tenantProfiles, replace, setCurrentUserItem]);

  const userHasOwnerAndTenantProfiles = useMemo(() => {
    const hasOwnerProfile = data?.activeUserProfiles.some((x) => x.profileTypeId == ProfileType.owner);
    const hasTenantProfile = data?.activeUserProfiles.some((x) => x.profileTypeId == ProfileType.tenant);
    return hasOwnerProfile && hasTenantProfile;
  }, [activeUserProfiles]);

  const handleProfileSwitch = useCallback(() => {
    if (currentUser?.profileTypeId == ProfileType.owner) {
      showTenantPage();
    } else {
      showOwnerPage();
    }
  }, [currentUser]);

  return {
    showOwnerPage,
    showTenantPage,
    userHasOwnerAndTenantProfiles,
    getAlternativeProfileTypeForUser,
    handleProfileSwitch
  };
};
