import { ButtonV2, CheckboxSingle, FadeAnimation, Field, FieldLabel } from "@kolmeo/ui-components";
import { Box, Text, useTheme } from "@kolmeo/ui-core";
import { Controller, FieldErrors, Control } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { BSBDataType } from "@src/types/misc/bsbDataType";
import { AddPaymentMethodsFormData } from "@src/types/PaymentPreferences";
import { MAX_ACCOUNT_NUMBER, MAX_BSB_NUMBER, MIN_ACCOUNT_NUMBER } from "@src/types/constants/PaymentMethods";

type FormStepOneProps = {
  control: Control<AddPaymentMethodsFormData, any>;
  errors: FieldErrors<AddPaymentMethodsFormData>;
  handleBackPress: () => void;
  handleNextPress: () => void;
  isLoading: boolean;
  expandToFullWidth: boolean;
  brandingObject: any;
  validationTrigger?: (fieldName: keyof AddPaymentMethodsFormData) => void;
  bsb?: BSBDataType;
  maxAccountNameLength?: number;
  maxNicknameLength?: number;
  hideSetToDefaultControl: boolean;
};
const FormStepOne = ({
  control,
  errors,
  handleBackPress,
  handleNextPress,
  isLoading,
  expandToFullWidth,
  brandingObject,
  bsb,
  maxAccountNameLength,
  maxNicknameLength,
  hideSetToDefaultControl
}: FormStepOneProps) => {
  const { t } = useTranslation(["Common", "Tenant"]);
  const { spacing, palette } = useTheme();

  return (
    <FadeAnimation enterDuration={100} exitDuration={100}>
      <Box>
        <FieldLabel>{t("Common:Forms.LabelBsb", { numberOfDigits: MAX_BSB_NUMBER })} </FieldLabel>
        <Controller
          control={control}
          name="routingNumber"
          render={({ field: { onChange, onBlur, value } }) => (
            <Field
              placeholder={t("Forms.PlaceholderExample", { example: "123456" })}
              maxLength={MAX_BSB_NUMBER}
              value={value}
              onBlur={onBlur}
              onChangeText={(val) => {
                onChange(val);
              }}
              keyboardType="numeric"
              multiline
              isRequired
              verticalOverflow
              error={errors.routingNumber ? errors.routingNumber.message : ""}
            />
          )}
        />
        {bsb && (
          <Text variant={"textMedium14"} color={palette.text.secondary}>
            {bsb?.BankName} {bsb?.FIELD3}
          </Text>
        )}
      </Box>
      <Box>
        <FieldLabel>{t("Common:Forms.LabelAccountNumber", { minDigits: MIN_ACCOUNT_NUMBER, maxDigits: MAX_ACCOUNT_NUMBER })} </FieldLabel>
        <Controller
          control={control}
          name="accountNumber"
          render={({ field: { onChange, onBlur, value } }) => (
            <Field
              placeholder={t("Forms.PlaceholderExample", { example: "1920930849" })}
              value={value?.toString()}
              onBlur={onBlur}
              onChangeText={(val) => {
                onChange(val);
              }}
              isRequired
              maxLength={MAX_ACCOUNT_NUMBER}
              multiline
              verticalOverflow
              error={errors.accountNumber?.message ? errors.accountNumber.message : ""}
            />
          )}
        />
      </Box>
      <Box>
        <FieldLabel>{t("Forms.LabelAccountName")} </FieldLabel>
        <Controller
          control={control}
          name="accountName"
          render={({ field: { onChange, onBlur, value } }) => (
            <Field
              placeholder={t("Common:Forms.PlaceholderName")}
              showCharLimit
              value={value}
              onBlur={onBlur}
              onChangeText={(val) => {
                onChange(val);
              }}
              multiline
              isRequired
              verticalOverflow
              error={errors.accountName?.message ? errors.accountName.message : ""}
              maxLength={maxAccountNameLength}
            />
          )}
        />
      </Box>
      <Box>
        <FieldLabel>{t("Forms.LabelAccountNickname")} </FieldLabel>
        <Controller
          control={control}
          name="nickname"
          render={({ field: { onChange, onBlur, value } }) => (
            <Field
              placeholder={t("Forms.PlaceholderNickname")}
              showCharLimit
              value={value}
              onBlur={onBlur}
              onChangeText={(val) => {
                onChange(val);
              }}
              isRequired
              multiline
              verticalOverflow
              error={errors.nickname?.message ? errors.nickname.message : ""}
              maxLength={maxNicknameLength}
            />
          )}
        />
      </Box>
      <Box>
        {!hideSetToDefaultControl && (
          <Controller
            control={control}
            name="makeDefaultPaymentAccount"
            render={({ field: { onChange, value } }) => (
              <CheckboxSingle
                value={value}
                disabled={false}
                testId={"test-check-isDefault"}
                onPress={onChange}
                content={t("Tenant:PaymentMethods.FormSetDefault")}
              />
            )}
          />
        )}
      </Box>
      <Box flexDirection="row" justifyContent="space-between" paddingBottom={spacing.px24}>
        <ButtonV2
          label={t("Common:Cancel")}
          variant={"outlinedDanger"}
          width={"49%"}
          size={"small"}
          onPress={handleBackPress}
          marginTop={spacing.px16}
          disabled={isLoading}
          fullwidth={expandToFullWidth}
          isLoading={isLoading}
        />
        <ButtonV2
          label={t("Common:Next")}
          width={"49%"}
          size={"small"}
          overrideVariant={{ bgColor: brandingObject?.primaryColor }}
          variant={"primary"}
          onPress={handleNextPress}
          marginTop={spacing.px16}
          disabled={isLoading}
          fullwidth={expandToFullWidth}
          isLoading={isLoading}
        />
      </Box>
    </FadeAnimation>
  );
};

export default FormStepOne;
