import { Text, View } from "react-native";

const FullScreenImageViewer = () => {
  return (
    <View>
      <Text>FullScreenImageViwer.native</Text>
    </View>
  );
};

export default FullScreenImageViewer;
