export default {
    path: {
        d: "M1.18934 0.43934C1.47064 0.158036 1.85217 0 2.25 0H14.25C14.6478 0 15.0294 0.158035 15.3107 0.43934C15.592 0.720645 15.75 1.10218 15.75 1.5V4.5H18.75C19.1478 4.5 19.5294 4.65804 19.8107 4.93934C20.092 5.22065 20.25 5.60218 20.25 6V18C20.25 18.289 20.084 18.5522 19.8232 18.6768C19.5625 18.8013 19.2534 18.765 19.0286 18.5833L15.5223 15.75H6.75C6.35218 15.75 5.97065 15.592 5.68934 15.3107C5.40804 15.0294 5.25 14.6478 5.25 14.25V11.434L1.97139 14.0833C1.74663 14.265 1.43752 14.3013 1.17677 14.1768C0.916014 14.0522 0.75 13.789 0.75 13.5V1.5C0.75 1.10217 0.908036 0.720644 1.18934 0.43934ZM6.75 11.25V14.25H15.7875C15.9591 14.25 16.1254 14.3088 16.2589 14.4167L18.75 16.4297V6H15.75V9.75C15.75 10.1478 15.592 10.5294 15.3107 10.8107C15.0294 11.092 14.6478 11.25 14.25 11.25H6.75ZM14.25 1.5L2.25 1.5L2.25 11.9297L4.74111 9.91665C4.87455 9.80882 5.04093 9.75 5.2125 9.75H14.25V1.5Z",
    },
    properties: {
        viewBox: "0 0 21 19",
        outlined: false,
        strokeWidth: "0",
    },
};
