import { jsx as _jsx } from "react/jsx-runtime";
import { TouchableOpacity, Box, coreTheme } from "@kolmeo/ui-core";
const Card = ({ onPress, backgroundColor = coreTheme.palette.white, borderRadius = 8, padding = 16, shadowRadius = 4.65, key, accessible, accessibilityLabel, children, isPressable = false, renderShadow = false, setTouchableToFlex = false, forceRemoveShadow = false, ...rest }) => {
    const { palette } = coreTheme;
    const shadowObject = (onPress || isPressable || renderShadow) && !forceRemoveShadow
        ? {
            shadowColor: palette.black,
            shadowOffset: {
                width: 0,
                height: 4,
            },
            shadowOpacity: 0.25,
            shadowRadius,
        }
        : {};
    return (_jsx(Box, { testID: rest.testID, style: {
            ...rest,
            ...shadowObject,
            elevation: 0,
            padding,
        }, backgroundColor: backgroundColor, borderRadius: borderRadius, accessible: accessible, accessibilityLabel: accessibilityLabel, children: onPress ? (_jsx(TouchableOpacity, { onPress: onPress, flex: setTouchableToFlex ? 1 : undefined, children: children })) : (children) }, key));
};
export default Card;
