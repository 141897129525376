// Change the env var in the web:start:feat script in pacakge.json
const FEATURE_ENV = process.env.REACT_APP_FEATURE_ENV;

export const endpoints = {
  GATEWAY: process.env.REACT_APP_OwnerTenant_Gateway_Url,
  OWNER_API: process.env.REACT_APP_OwnerApi_Url + "/v1",
  TENANT_API: process.env.REACT_APP_TenantApi_Url + "/v1"
} as const;

export type Endpoints = keyof typeof endpoints;

export const useEndpointUrls = (): typeof endpoints => {
  if (FEATURE_ENV) {
    return {
      GATEWAY: `https://my-kol-${FEATURE_ENV}.feature.kolmeo.com/api`,
      OWNER_API: `https://ownerapi-kol-${FEATURE_ENV}.feature.kolmeo.com/api/v1`,
      TENANT_API: `https://tenantapi-kol-${FEATURE_ENV}.feature.kolmeo.com/api/v1`
    } as const;
  } else {
    return endpoints;
  }
};
