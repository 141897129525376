import { AppRegistry } from "react-native";
import { sentryInit } from "@src/lib/Analytics";
import App from "./App";

const { NODE_ENV, REACT_APP_MOCK } = process.env;

if (NODE_ENV === "development" && REACT_APP_MOCK === "true") {
  const { worker } = require("./mswSetup");
  worker.start({
    onUnhandledRequest(req, print) {
      const excludedRoutes = ["/favicon.ico", "/manifest.json", "/logo192.png", "/favicon-32x32.png"];
      const isExcluded = excludedRoutes.some((route) => req.url.pathname.includes(route));

      const isStatic = req.url.pathname.startsWith("/static");

      if (isExcluded || isStatic) {
        return;
      }

      print.warning();
    }
  });
}

if (NODE_ENV === "production") {
  sentryInit();
}

if (module.hot) {
  module.hot.accept();
}

AppRegistry.registerComponent("my.kolmeo", () => App);
AppRegistry.runApplication("my.kolmeo", {
  initialProps: {},
  rootTag: document.getElementById("app-root")
});
