import { useEffect, useState } from "react";

import { useGetAllOwnerProperties } from "@src/screens/Owner/MyProperties/useMyProperties";

export const useMultiplePropertiesGoBack = (routeName: string): boolean => {
  const [isGoBackEnabled, setIsGoBackEnabled] = useState<boolean>(false);

  const { data } = useGetAllOwnerProperties();

  useEffect(() => {
    if (routeName === "Property" && data && data?.length > 1) {
      setIsGoBackEnabled(true);
    } else {
      setIsGoBackEnabled(false);
    }
  }, [routeName, data]);

  return isGoBackEnabled;
};
