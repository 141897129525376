//@ts-nocheck
// TODO: We need to figure out how to type this and make it reuseable
import { useCallback } from "react";
import { useNavigation } from "@react-navigation/native";
import { Platform } from "react-native";

const useCustomNavigation = () => {
  const navigation = useNavigation();

  const navigateToScreen = useCallback(
    <T extends NavigatorName>(screenName: keyof NavigatorParams[T], params?: NavigatorParams[T][keyof NavigatorParams[T]]) => {
      navigation.navigate(screenName, params);
    },
    [navigation]
  );
  const goBack = () => {
    if (Platform.OS == "web") {
      history.go(-1);
    } else {
      return navigation.goBack();
    }
  };

  return {
    navigateToScreen,
    goBack
  };
};

export { useCustomNavigation };
