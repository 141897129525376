export default {
    path: {
        d: "M17.5 4C17.5 4.69223 17.2947 5.36892 16.9101 5.9445C16.5256 6.52007 15.9789 6.96867 15.3394 7.23358C14.6999 7.49848 13.9961 7.5678 13.3172 7.43275C12.6383 7.2977 12.0146 6.96436 11.5251 6.47487C11.0356 5.98539 10.7023 5.36175 10.5673 4.68282C10.4322 4.00388 10.5015 3.30015 10.7664 2.66061C11.0313 2.02107 11.4799 1.47444 12.0555 1.08986C12.6311 0.705271 13.3078 0.5 14 0.5C14.9272 0.503294 15.8156 0.873101 16.4712 1.52877C17.1269 2.18443 17.4967 3.07276 17.5 4ZM4 0.5C3.30777 0.5 2.63108 0.705271 2.05551 1.08986C1.47993 1.47444 1.03133 2.02107 0.766423 2.66061C0.501516 3.30015 0.432205 4.00388 0.567253 4.68282C0.702301 5.36175 1.03564 5.98539 1.52513 6.47487C2.01461 6.96436 2.63825 7.2977 3.31719 7.43275C3.99612 7.5678 4.69985 7.49848 5.33939 7.23358C5.97894 6.96867 6.52556 6.52007 6.91015 5.9445C7.29473 5.36892 7.5 4.69223 7.5 4C7.49671 3.07276 7.1269 2.18443 6.47124 1.52877C5.81557 0.873101 4.92725 0.503294 4 0.5ZM24 0.5C23.3078 0.5 22.6311 0.705271 22.0555 1.08986C21.4799 1.47444 21.0313 2.02107 20.7664 2.66061C20.5015 3.30015 20.4322 4.00388 20.5673 4.68282C20.7023 5.36175 21.0356 5.98539 21.5251 6.47487C22.0146 6.96436 22.6383 7.2977 23.3172 7.43275C23.9961 7.5678 24.6999 7.49848 25.3394 7.23358C25.9789 6.96867 26.5256 6.52007 26.9101 5.9445C27.2947 5.36892 27.5 4.69223 27.5 4C27.4967 3.07276 27.1269 2.18443 26.4712 1.52877C25.8156 0.873101 24.9272 0.503294 24 0.5Z",
    },
    properties: {
        viewBox: "0 0 28 8",
        outlined: false,
        strokeWidth: "0",
    },
};
