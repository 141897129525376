import { Icon, LottieAnimation, kolmeoCirclesLoader } from "@kolmeo/ui-components";
import { Box, Text } from "@kolmeo/ui-core";
import { ScreenEmptyState, type ScreenEmptyStateProps } from "./components/EmptyState";

const ReactQueryDisplayHandler = ({
  children,
  isLoading,
  isError,
  error,
  showEmptyState = false,
  emptyStateContent,
  emptyStateComponent
}: {
  isLoading: boolean;
  isError: boolean;
  error: IntentionalAny<"errors returned have no type">;
  showEmptyState?: boolean;
  emptyStateContent?: ScreenEmptyStateProps;
  emptyStateComponent?: JSX.Element;
} & React.PropsWithChildren): JSX.Element => {
  if (isLoading)
    return (
      <Box flex={1} justifyContent="center" alignItems="center">
        <LottieAnimation source={kolmeoCirclesLoader} />
      </Box>
    );

  if (isError) {
    return (
      <Box flex={1} justifyContent="center" alignItems="center">
        <Icon type="information" size="xl" />
        <Text variant="headingH1">{error.toString() as string}</Text>
      </Box>
    );
  }
  if (showEmptyState && emptyStateComponent) return emptyStateComponent;
  if (showEmptyState && emptyStateContent) return <ScreenEmptyState {...emptyStateContent} />;
  return <>{children}</>;
};

export default ReactQueryDisplayHandler;
