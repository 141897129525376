export default {
  HeadingDirectDebitAuthority: "Direct Debit Request",
  HeadingPaymentPreferences: "Payment preferences",
  HeadingDefinitions: "Definitions",
  HeadingDebitingAccount: "Debiting your account",
  HeadingAmendments: "Amendments",
  HeadingObligations: "Your obligations",
  HeadingDispute: "Dispute",
  HeadingAccounts: "Accounts",
  HeadingConfidentiality: "Confidentiality",
  HeadingAddBankAccount: "Add Bank Account Terms of service",
  ParagraphOneAccounts: "You should check: ",
  ParagraphOne:
    "This is your Direct Debit Request Service Agreement with Zai Australia Pty. Ltd., (User ID’s 342203 and 481561) & ABN 96637632645. It explains what your obligations are when undertaking a Direct Debit arrangement with us. It also details what our obligations are to you as your Direct Debit provider. Please keep this agreement for future reference. It forms part of the terms and conditions of your Direct Debit Request (DDR) and should be read in conjunction with your DDR authorisation.",
  ParagraphAmendmentsOne: `We may vary any details of this agreement or a Direct Debit Request at any time by giving you at least fourteen (14) days written notice. `,
  ParagraphAmendmentsTwo: "Amendments by you can",
  ParagraphObligations:
    "It is your responsibility to ensure that there are sufficient clear funds available in your account to allow a debit payment to be made in accordance with the Direct Debit Request, at the time of the request. If there are insufficient clear funds in your account to meet a debit payment: "
};
