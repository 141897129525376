export default {
  AmountMoreThanDue: "Amount is more than rent due",
  ExtraAmountTowardsFutureRent: "The extra amount will go towards future rent.",
  AmountLessThanDue: "Amount is less than rent due",
  NeedExtraPayments: "You will need to make extra rent payments to fully cover the amount due.",
  PayFullAmount: "Pay full amount",
  PayOutstandingAmount: "Pay outstanding amount",
  PayOtherAmount: "Pay other amount",
  CustomRent: "custom-rent",
  RentPayments: "Rent Payments",
  RentAmount: "Rent Amount",
  PaidTo: "Paid to",
  PartPaymentOf: "Incl. part payment of",
  PartPaymentCurrentRentalPeriod: "A part payment has been made towards the current rent period, this has not been paid for in full.",
  PartPaymentNextRentalPeriod: "A part payment has been made towards the next rent period.",
  PaidToDateRoundedDown:
    "When a payment doesn’t cover a whole day, the “Paid to” date has been rounded down so that it only counts whole days that have been paid for.",
  NextPaymentDue: "Next payment due",
  Outstanding: "Outstanding",
  CurrentPaymentMethod: "Current payment method",
  PaymentMethod: "Payment method",
  PayRent: "Pay Rent",
  PaymentMethodRequired: "Payment method required",
  SimplifyRentalPayments: "Simplify your rent payments. Set up your payment method now to use for future payments.",
  SetupMethod: "Set up Method",
  RememberToMakePaymentBPAY: "Please remember to make payment outside the platform via BPAY.",
  ContactPropertyManagerDirectDebit: "Please contact your property manager in relation to your rent payment.",
  BPAY: "BPAY",
  DirectDebit: "Direct Debit",
  CreditCard: "Credit Card",
  DebitNow: "Debit Now",
  VirtualAccount: "Paycode",
  MakeRentalPaymentTitle: "Make a rent payment",
  PaymentSummaryTitle: "Payment summary",
  ConfirmAmountTitle: "Confirm amount",
  PayNowActionLabel: "Pay Now",
  RentalTypeError: "Please enter numeric characters only",
  RentalMinError: "Please enter an amount greater than {{minAmount}}",
  RentalMaxError: "Please enter an amount less than {{maxAmount}}",
  PaymentAmount: "Payment amount",
  PayingWith: "Paying with",
  EndingIn: "Ending in",
  TransactionProcessingMessage:
    "Processing this transaction may take up to 3 business days. To avoid dishonour fees, please ensure there are sufficient funds in your account.",
  PaymentSuccessAmount: "Your rent payment of ${{ paymentAmount }} will be processed within 3 business days.",
  PaymentSuccessDescriptor: "This transaction will appear as {{ successMessage }} in your bank transactions.",
  Overdue: "Overdue",
  PendingDebitPaymentDisclaimer:
    "Your rent payment is being processed, these funds may not be deducted from your bank account for 3 business days.",
  EditAmount: "Edit Amount",
  YourPaymentFailed: "Your payment has failed",
  PaymentFailedMessage:
    "This could be due to invalid account details or insufficient funds. Please check and update if required, then proceed with payment.",
  GoToPaymentSettings: "Go to payment settings",
  ChangeAmount: "Change Amount",
  ConfirmAmountMessage: "You have entered an amount of ${{amount}} or more. Please confirm amount to proceed.",
  PayOverduePlusRent: "Pay overdue plus upcoming rent amount",
  PayOverduePlusProrata: "Pay overdue plus upcoming pro rata amount",
  PayOverdue: "Pay overdue",
  PaidUpto: "You are paid up to",
  OverpaidInFutureCycleMessage: "Paying more today will keep you ahead with your rent payments.",
  PartPaid: "Part paid",
  LeaseIsFinishing: "Your lease is finishing.",
  ChangeToRent: "There was a change to your rent.",
  PayProRataBeforeRegularPay: "Please pay a pro rata amount before regular payments continue.",
  YouHaveFailedPayment: "You have a failed payment",
  GoToPayments: "go to payments for more details",
  PaidUptoDate: "You are paid up to date.",
  PayProRataAmount: "Pay pro rata amount",
  NoMethod: "No method",
  NoRentRecordFound: "No rent record found. Please contact your property manager."
};
