import { Box, useTheme } from "@kolmeo/ui-core";
import { Card, Icon } from "@kolmeo/ui-components";
import { useTranslation } from "react-i18next";
import RowItem, { RowItemButtonProps } from "../RowItem";
import { TenancyPaymentDetails } from "@src/navigation/TenancyNavigator/types";
import Clipboard from "@react-native-clipboard/clipboard";
import { PaymentMethod } from "@src/types";
import { usePaymentMethodLabel } from "@src/screens/Tenant/hooks/usePaymentMethodLabel";

type VirtualAccountMethodContentCardType = {
  item?: TenancyPaymentDetails;
  propertyName?: string;
};

const VirtualAccountMethodContentCard = ({ item, propertyName }: VirtualAccountMethodContentCardType) => {
  const { palette, colors } = useTheme();
  const { t } = useTranslation(["Common", "Tenant"]);
  const onCopyField = (text?: string) => {
    text && Clipboard.setString(text);
  };

  const paymentMethodLabel = usePaymentMethodLabel(PaymentMethod.virtualAccount);

  const accountName = (): string => {
    return propertyName ? propertyName : paymentMethodLabel;
  };

  return (
    <Card
      flexDirection="row"
      marginBottom={1}
      marginHorizontal={1}
      renderShadow
      flex={1}
      borderColor={colors.primary}
      borderWidth={0.4}
      position="relative"
    >
      {/* <Box mb="auto" backgroundColor="green" width="100%"> */}
      <Box flexDirection="row" width="100%" flexWrap="wrap" paddingBottom={3}>
        <Box flexDirection="column" flex={3}>
          <RowItem alignment="right" label={<Icon type="bank" size="lg" color={palette.text.secondary} />} />
          <RowItem
            label={`${t("Tenant:PaymentMethods.AccountName")}: ${accountName()}`}
            buttonRight={
              {
                icon: "copy",
                onPress: () => onCopyField(accountName()),
                size: 12,
                color: palette.text.secondary
              } as RowItemButtonProps
            }
            shorten={false}
          />
          <RowItem
            label={`${t("Tenant:PaymentMethods.BSB")}: ${item?.bsbNumber}`}
            buttonRight={
              { icon: "copy", onPress: () => onCopyField(item?.bsbNumber), size: 12, color: palette.text.secondary } as RowItemButtonProps
            }
          />
          <RowItem
            label={`${t("Tenant:PaymentMethods.Account")}: ${item?.accountNumber}`}
            buttonRight={
              {
                icon: "copy",
                onPress: () => onCopyField(item?.accountNumber),
                size: 12,
                color: palette.text.secondary
              } as RowItemButtonProps
            }
          />
        </Box>
      </Box>
    </Card>
  );
};

export default VirtualAccountMethodContentCard;
