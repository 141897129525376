import { useTranslation } from "react-i18next";
import { StackHeaderProps } from "@react-navigation/stack";
import { Box, Text, useTheme } from "@kolmeo/ui-core";
import { BackLink } from "@src/components";
import { useMultiplePropertiesGoBack } from "./useMultiplePropertiesGoBack";

import { DropdownMenu } from "./components";

const TopNavBar = ({ route, navigation }: StackHeaderProps) => {
  const { t } = useTranslation("Common");
  const { palette } = useTheme();
  const isGoBackEnabled = useMultiplePropertiesGoBack(route.name);

  return (
    <Box height={70} backgroundColor="white" flexDirection="row" justifyContent="center">
      <Box
        paddingX="16px"
        maxWidth="1280px"
        width="100%"
        height="100%"
        flexDirection="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box flexDirection="row">
          {isGoBackEnabled && <BackLink label="My Properties" onPress={() => navigation.navigate("MyProperties")} />}
        </Box>
        <Box flexDirection="row" alignItems="center">
          <Text color={palette.background.disable} marginRight={10} variant="paragraph12">
            {t("Tagline")}
          </Text>
          <DropdownMenu />
        </Box>
      </Box>
    </Box>
  );
};

export default TopNavBar;
