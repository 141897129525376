import { ErrorBoundary as SentryErrorBoundary } from "@sentry/react";
import { PopUpMenuProvider } from "@kolmeo/ui-components";

import Navigator from "@src/navigation/Navigator";
import Fallback from "@src/screens/Fallback/Fallback";

import AuthProvider from "@src/lib/AzureAuth/AuthProvider";
import { QueryClientProvider } from "@src/lib/http";
import { KiitosProvider } from "@src/lib/Kiitos";

import { Intercom, usePendo } from "@src/lib/Analytics";
import { DeepLinkProvider } from "./providers/DeepLinkingProvider";

import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "@src/store";

const ApplicationStack = () => {
  // usePendo();

  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <SentryErrorBoundary fallback={<Fallback variant="GenericError" />}>
          <AuthProvider>
            <QueryClientProvider>
              <KiitosProvider>
                <PopUpMenuProvider>
                  <Intercom>
                    <DeepLinkProvider>
                      <Navigator />
                    </DeepLinkProvider>
                  </Intercom>
                </PopUpMenuProvider>
              </KiitosProvider>
            </QueryClientProvider>
          </AuthProvider>
        </SentryErrorBoundary>
      </PersistGate>
    </Provider>
  );
};

export default ApplicationStack;
