export const OccupancyStatus = {
  active: 703001,
  applied: 703002,
  deleted: 703003,
  inactive: 703004,
  leaseBreak: 703005,
  leaseSigned: 703006,
  noticeGiven: 703007,
  rejected: 703008,
  vacant: 703009,
  vacated: 703010
} as const;

export const OccupancyStatusLabels: Record<OccupancyStatusCodes, string> = {
  [OccupancyStatus.active]: "Active",
  [OccupancyStatus.applied]: "Applied",
  [OccupancyStatus.deleted]: "Deleted",
  [OccupancyStatus.inactive]: "Inactive",
  [OccupancyStatus.leaseBreak]: "Lease Break",
  [OccupancyStatus.leaseSigned]: "Lease Signed",
  [OccupancyStatus.noticeGiven]: "Notice Given",
  [OccupancyStatus.rejected]: "Rejected",
  [OccupancyStatus.vacant]: "Vacant",
  [OccupancyStatus.vacated]: "Vacated"
};

export type OccupancyStatusType = keyof typeof OccupancyStatus;
export type OccupancyStatusCodes = (typeof OccupancyStatus)[keyof typeof OccupancyStatus];
