import { PendingUserProfileInvite, ProfileType } from "@src/types";
import { Box, Text, useTheme } from "@kolmeo/ui-core";
import { useMediaQuery } from "@src/hooks";
import TenantProfileInvite from "./TenantProfileInvite";
import OwnerProfileInvite from "./OwnerProfileInvite";
import UserProfileInviteCard from "./UserProfileInviteCard";
import { useTranslation } from "react-i18next";
import { Seperator } from "@src/components";

type UserProfileInviteProps = {
  isFirstTimeLogin: boolean;
  pendingUserProfileInvites: PendingUserProfileInvite[];
  onSuccess: () => void;
};

const UserProfileInvite = ({ isFirstTimeLogin, pendingUserProfileInvites, onSuccess }: UserProfileInviteProps): JSX.Element => {
  const { t } = useTranslation("Common", { keyPrefix: "Welcome" });
  const { spacing } = useTheme();
  const { isMobile } = useMediaQuery();

  const height = isMobile ? "auto" : "100%";
  const pendingOwnerInvites = pendingUserProfileInvites.filter((x) => x.profileType === ProfileType.owner);
  const pendingTenantInvites = pendingUserProfileInvites.filter((x) => x.profileType === ProfileType.tenant);

  return (
    <Box style={{ justifyContent: "center", alignItems: "center", backgroundColor: "#FFFFFF", height: height, overflow: "scroll" }}>
      <Box padding={spacing.px24}>
        <Text variant="textHeavy15" style={{ fontSize: 20, textAlign: isMobile ? "left" : "center" }}>
          {isFirstTimeLogin ? "Welcome!" : "Welcome back!"}
        </Text>
        {isFirstTimeLogin && (
          <Text variant="textHeavy15" style={{ fontSize: 20, textAlign: isMobile ? "left" : "center" }}>
            {t("AccountNeedsSettingUp")}
          </Text>
        )}
        <Box flex={1} justifyContent={"center"} alignItems={"center"} mt={"3rem"} mb={"3rem"}>
          <Text style={{ fontFamily: "Inter", textAlign: isMobile ? "left" : "center", maxWidth: isMobile ? "100%" : "80%" }}>
            {isFirstTimeLogin ? t("StartAcceptingProfiles") : t("NewProfilesFound")}
          </Text>
        </Box>
        {pendingOwnerInvites.length > 0 && (
          <>
            <Seperator margin={spacing.px8} seperatorColor="#E1E1E5" />
            <OwnerProfileInvite />
            <UserProfileInviteCard invites={pendingOwnerInvites} onSuccess={onSuccess} />
          </>
        )}
        {pendingTenantInvites.length > 0 && (
          <>
            {isMobile && <Seperator margin={spacing.px8} seperatorColor="#E1E1E5" />}

            <TenantProfileInvite />
            <UserProfileInviteCard invites={pendingTenantInvites} onSuccess={onSuccess} />
          </>
        )}
      </Box>
    </Box>
  );
};

export default UserProfileInvite;
