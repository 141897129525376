import { Box, FlatList, Text, useTheme } from "@kolmeo/ui-core";
import { Card } from "@kolmeo/ui-components";
import { PendingUserProfileInvite } from "@src/types";
import { useMediaQuery } from "@src/hooks";
import UserProfileInviteAction from "./UserProfileInviteAction";
import { Seperator } from "@src/components";
import { ListRenderItemInfo } from "react-native";

type UserProfileInviteCardProps = {
  invites: PendingUserProfileInvite[];
  onSuccess: (userProfileId: number) => void;
};

const UserProfileInviteCard = ({ invites, onSuccess }: UserProfileInviteCardProps): JSX.Element => {
  const { palette, spacing } = useTheme();
  const { isMobile, mq } = useMediaQuery();

  const contentContainerStyle = {
    marginLeft: "5rem",
    marginRight: "5rem"
  };

  const NUM_COLUMNS: number = mq({ phone: 1, tablet: 2, desktop: 3 });
  const flex = !isMobile && invites.length <= 2 ? 1 : NUM_COLUMNS > 1 ? 1 / NUM_COLUMNS : null;

  const renderItemWithSeperator = (renderItem: ListRenderItemInfo<PendingUserProfileInvite>) => {
    return invites ? (
      <>
        <Box flex={flex}>
          {
            <Card renderShadow flex={1} alignItems="flex-start" marginTop={spacing.px8} marginBottom={spacing.px16}>
              <Text variant="textHeavy15" color={palette.black}>
                Managed By:
              </Text>
              <Text variant="textHeavy15" color={palette.black} mb={spacing.px8}>
                {renderItem.item.displayName}
              </Text>
              {renderItem.item.propertyNames.length > 0 && (
                <>
                  <hr style={{ color: "#E1E1E5", border: "1px solid", width: "100%" }} />
                  <Box>
                    <Text variant="textHeavy15" color={palette.black} mb={spacing.px8}>
                      Properties:
                    </Text>
                    {renderItem.item.propertyNames?.slice(0, 5).map((y) => (
                      <Text key={y} variant="paragraph14">
                        {y}
                      </Text>
                    ))}
                  </Box>
                </>
              )}
              <UserProfileInviteAction key={renderItem.item.userProfileId} invite={renderItem.item} onSuccess={onSuccess} />
            </Card>
          }
        </Box>
        {(renderItem.index + 1) % NUM_COLUMNS !== 0 && <Seperator margin={spacing.px8} vertical seperatorColor="transparent" />}
      </>
    ) : null;
  };

  return (
    <FlatList
      contentContainerStyle={isMobile ? {} : contentContainerStyle}
      data={invites}
      key={NUM_COLUMNS}
      keyExtractor={(_, index) => `${index.toString()}-${NUM_COLUMNS}`}
      renderItem={renderItemWithSeperator}
      numColumns={NUM_COLUMNS}
      ItemSeparatorComponent={() => <Seperator margin={spacing.px8} seperatorColor="transparent" />}
    />
  );
};

export default UserProfileInviteCard;
