import { Box, Text } from "@kolmeo/ui-core";

type OrderedListItemType = {
  index: number;
  bulletPoint?: boolean;
  value: string;
};
const OrderedListItem = ({ index, bulletPoint, value }: OrderedListItemType) => {
  return (
    <Box key={index} marginLeft={4} flexDirection={"row"}>
      {bulletPoint !== undefined && (
        <Text marginRight={2} variant="paragraph14">
          {bulletPoint ? `\u2022` : `${index + 1}. `}
        </Text>
      )}
      <Text variant="paragraph14">{`${value}`}</Text>
    </Box>
  );
};

export default OrderedListItem;
