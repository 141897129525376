export default {
  ContactPropertyManager: "Contact your Property manager about using Kolmeo",
  OverviewHeading: "Overview",
  OverviewEmptyTitle: "No tenancies found",
  OverviewEmptyContent: "Maybe you have no tenancies with Kolmeo",
  Overview: "Overview",
  Payments: "Payments",
  Requests: "Requests",
  OffersHub: "Explore"
};
