export default {
    path: {
        d: "M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L11.7071 10.2929C12.0976 10.6834 12.0976 11.3166 11.7071 11.7071L1.70711 21.7071C1.31658 22.0976 0.683417 22.0976 0.292893 21.7071C-0.0976311 21.3166 -0.0976311 20.6834 0.292893 20.2929L9.58579 11L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z",
    },
    properties: {
        viewBox: "0 0 12 22",
        outlined: false,
        strokeWidth: "0",
    },
};
