import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Box, coreTheme, Text } from "@kolmeo/ui-core";
import styled from "styled-components/native";
const Row = styled(Box) `
  flex-direction: row;
  padding: 16px;
  border-bottom-color: ${coreTheme.palette.lightGrey};
  border-bottom-width: 1px;
`;
const HeaderRow = styled(Row) `
  border-bottom-color: ${coreTheme.palette.lightGrey};
  border-bottom-width: 1px;
`;
export const TableComponent = ({ data, tableRenderItem, }) => {
    return (_jsxs(Box, { children: [_jsx(HeaderRow, { children: tableRenderItem.map((element, idx) => (_jsx(Box, { width: element.width ? element.width : 1 / tableRenderItem.length, children: _jsx(Text, { variant: "paragraph16", color: coreTheme.palette.ateneoBlueDarken10, fontWeight: "bold", children: element.header }) }, idx))) }), data.map((dataItem, idx) => (_jsx(Row, { children: tableRenderItem.map((element, colIdx) => (_jsx(Box, { width: element.width ? element.width : 1 / tableRenderItem.length, children: _jsx(Text, { variant: "paragraph16", children: element.renderCell(dataItem) }) }, colIdx))) }, idx)))] }));
};
