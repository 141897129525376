import { DateTime } from "luxon";
export const getDynamicGreeting = () => {
    const currentDate = DateTime.local();
    const morningStart = DateTime.now().set({ hour: 0 }).set({ minute: 0 });
    const afternoonStart = DateTime.now().set({ hour: 12 }).set({ minute: 0 });
    const eveningStart = DateTime.now().set({ hour: 17 }).set({ minute: 0 });
    let greeting = "";
    if (currentDate >= morningStart && currentDate <= afternoonStart) {
        greeting = "Morning";
    }
    else if (currentDate >= afternoonStart && currentDate <= eveningStart) {
        greeting = "Afternoon";
    }
    else if (currentDate >= eveningStart && currentDate <= morningStart.plus({ day: 1 })) {
        greeting = "Evening";
    }
    return greeting;
};
