export default {
    path: {
        d: "M12.6337 2.36706V12.6337H2.36706V2.36706H12.6337ZM12.6337 0.900391H2.36706C1.56039 0.900391 0.900391 1.56039 0.900391 2.36706V12.6337C0.900391 13.4404 1.56039 14.1004 2.36706 14.1004H12.6337C13.4404 14.1004 14.1004 13.4404 14.1004 12.6337V2.36706C14.1004 1.56039 13.4404 0.900391 12.6337 0.900391ZM9.06972 7.39772L6.86972 10.2357L5.30039 8.33639L3.10039 11.1671H11.9004L9.06972 7.39772Z",
    },
    properties: {
        viewBox: "0 0 15 15",
        outlined: false,
        strokeWidth: "0",
    },
};
