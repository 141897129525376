import AsyncStorage from "@react-native-async-storage/async-storage";
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client";
import { createAsyncStoragePersister } from "@tanstack/query-async-storage-persister";
import { QueryClient } from "@tanstack/react-query";
// import { ReactQueryDevtools } from "@tanstack/react-query-devtools";

const CACHE_TIME_DAY = 1000 * 60 * 60 * 24;

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      cacheTime: CACHE_TIME_DAY
    }
  }
});

const asyncStoragePersister = createAsyncStoragePersister({
  storage: AsyncStorage
});

export const QueryClientProvider = ({ children }: React.PropsWithChildren) => {
  return (
    <PersistQueryClientProvider client={queryClient} persistOptions={{ persister: asyncStoragePersister }}>
      {children}
      {/* <ReactQueryDevtools /> */}
    </PersistQueryClientProvider>
  );
};
