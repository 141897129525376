export default {
    path: {
        d: "M30 26H28V14.4375C28.0007 14.1587 27.942 13.8829 27.8277 13.6286C27.7135 13.3743 27.5464 13.1472 27.3375 12.9625L17.3375 3.87499C16.9709 3.53856 16.4914 3.3519 15.9937 3.3519C15.4961 3.3519 15.0166 3.53856 14.65 3.87499L4.65 12.9625C4.44526 13.1498 4.28173 13.3777 4.16983 13.6316C4.05792 13.8855 4.00009 14.16 4 14.4375V26H2C1.73478 26 1.48043 26.1053 1.29289 26.2929C1.10536 26.4804 1 26.7348 1 27C1 27.2652 1.10536 27.5196 1.29289 27.7071C1.48043 27.8946 1.73478 28 2 28H30C30.2652 28 30.5196 27.8946 30.7071 27.7071C30.8946 27.5196 31 27.2652 31 27C31 26.7348 30.8946 26.4804 30.7071 26.2929C30.5196 26.1053 30.2652 26 30 26ZM19 26H13V20C13 19.7348 13.1054 19.4804 13.2929 19.2929C13.4804 19.1053 13.7348 19 14 19H18C18.2652 19 18.5196 19.1053 18.7071 19.2929C18.8946 19.4804 19 19.7348 19 20V26Z",
    },
    properties: {
        viewBox: "0 0 32 32",
        outlined: false,
        strokeWidth: "0",
    },
};
