import { Box, FlatList, Text } from "@kolmeo/ui-core";
import { PageContainer } from "@src/containers";

import { TextParagraph14 } from "@src/i18n/components/TextParagraph/TextParagraph14";
import { useTranslation } from "react-i18next";
import OrderedListItem from "./components/OrderedListItem";

// THIS PAGE IS TEMPORARY, the TACs will ideally be hosted externally.
const TermsAndConditions = () => {
  const definitionsList = [
    "account means the account held at your financial institution from which we are authorised to arrange for funds to be debited. ",
    "agreement means this Direct Debit Request Service Agreement between you and us. ",
    "banking day means a day other than a Saturday or a Sunday or a public holiday listed throughout Australia. ",
    "debit day means the day that payment by you to us is initiated. ",
    "debit payment means a particular transaction where a debit is made. ",
    "direct debit request means the Direct Debit Request between us and you.",
    "us or we means Zai Australia Pty. Ltd. trading as Zai (the Debit User) you have authorised by requesting a Direct Debit Request.",
    "you means the customer who has signed or authorised by other means the Direct Debit Request.",
    "your financial institution means the financial institution nominated by you on the DDR at which the account is maintained. ",
    "Agency mean the organisation that is managing the property on behalf of an owner ",
    "Debit Now means the Kolmeo payment method in which you trigger when and how much money is debited "
  ];
  const debitingYourAuthority = [
    "By signing a Direct Debit Request or by providing us with a valid instruction, you have authorised us to arrange for funds to be debited from your account. You should refer to the Direct Debit Request and this agreement for the terms of the arrangement between us and you. ",
    "We will only arrange for funds to be debited from your account as authorised in the Direct Debit Request. Or; ",
    "We will only arrange for funds to be debited from your account if we have received instruction from you via Kolmeo for Tenant which specifies the amount payable by you to us and when it is due. ",
    "If the debit day falls on a day that is not a banking day, we may direct your financial institution to debit your account on the following banking day. If you are unsure about which day your account has or will be debited you should ask your financial institution. "
  ];
  const amendments = [
    `a)  Cancel or suspend the Direct Debit Request; 
    At any time through the Kolmeo for tenants application via deleting the bank account. We will see the bank account status as “deactivated” via the Kolmeo property management platform `,
    `b)  Change individual debit payment method at any time by contacting your agency and request to change your rent payment method. To do so contact:
    Accounts Department 
    Zai 
    Level 18, 
    120 Spencer Street, 
    Melbourne VIC 3000 
    Australia
    Or; 
    by emailing us at helpdesk@hellozai.com during business hours; or 
    arranging it through your own financial institution, which is required to act promptly on your instructions.
    `
  ];
  const obligations = [
    `(a) you may be charged a fee and/or interest by your financial institution;`,
    `(b) you may also incur fees or charges imposed or incurred by us; and`,
    `(c) you must arrange for the debit payment to be made by another method`
  ];

  const dispute = [
    `If you believe that there has been an error in debiting your account, you should notify us directly at helpdesk@hellozai.com and confirm that notice in writing with us as soon as possible so that we can resolve your query more quickly. Alternatively you can take it up directly with your financial institution.`,
    `If we conclude as a result of our investigations that your account has been incorrectly debited we will respond to your query by arranging for your financial institution to adjust your account (including interest and charges) accordingly. We will also notify you in writing of the amount by which your account has been adjusted.`,
    `If we conclude as a result of our investigations that your account has not been incorrectly debited we will respond to your query by providing you with reasons and any evidence for this finding in writing. `
  ];
  const accounts = [
    `with your financial institution whether direct debiting is available from your account as direct debiting is not available on all accounts offered by financial institutions. `,
    `your account details which you have provided to us are correct by checking them against a recent account statement; and `,
    `with your financial institution before completing the Direct Debit Request if you have any queries about how to complete the Direct Debit Request. `
  ];
  const confidentiality = [
    `We will keep any information (including your account details) in your Direct Debit Request confidential. We will make reasonable efforts to keep any such information that we have about you secure and to ensure that any of our employees or agents who have access to information about you do not make any unauthorised use, modification, reproduction or disclosure of that information. `,
    `We will only disclose information that we have about you: 
        a)  to the extent specifically required by law; 
        b)  or for the purposes of this agreement (including disclosing information in connection with any query or claim). 
    `
  ];
  const termsOfService = [
    `By clicking “Add Bank Account”, you: 
    Have understood and agreed to the terms and conditions and indicated your payment preferences. This forms part of the Tenancy Agreement for your property and remains in force in accordance with the terms and period described in that agreement. You can initiate any amount to be debited from your account on the rent due date specified in the Tenancy Agreement, or any date of your choosing. You acknowledge that the amount to be debited is determined by you and may vary based on the amount you choose, or as rent and other charges under the Tenancy Agreement are legally varied;`,
    `For debit now, you have confirmed that the bank account details provided are correct and that you are the account holder able to operate this account as a sole signatory. You understand that by proceeding now you have agreed that debit now is not a scheduled payment, payments can be authorised by you through the tenant access into the platform and you agree to the Terms and Conditions of the Debit authority. Property Managers will not be able to process debits on your behalf when they are set up for direct debit 'debit now'. Only you can control when and how much money is debited from your account while your nominated payment method is Debit Now.`
  ];
  const { t } = useTranslation(["Tenant"]);

  return (
    <PageContainer isScrollable headingText={t("Tenant:TAC.HeadingDirectDebitAuthority")}>
      <Text variant="textHeavy16">{t("Tenant:TAC.HeadingPaymentPreferences")}</Text>

      <TextParagraph14 content={"Tenant:TAC.ParagraphOne"} />
      <Text variant="textHeavy16">{t("Tenant:TAC.HeadingDefinitions")}</Text>

      <Box marginBottom={2}>
        <FlatList
          data={definitionsList}
          renderItem={({ item, index }) => <OrderedListItem index={index} bulletPoint={true} value={item} />}
        />
      </Box>
      <Text variant="textHeavy16">{t("Tenant:TAC.HeadingDebitingAccount")}</Text>

      <Box marginBottom={2}>
        <FlatList
          data={debitingYourAuthority}
          renderItem={({ item, index }) => <OrderedListItem index={index} bulletPoint={false} value={item} />}
        />
      </Box>
      <Text variant="textHeavy16">{t("Tenant:TAC.HeadingAmendments")}</Text>

      <TextParagraph14 content={"Tenant:TAC.ParagraphAmendmentsOne"} />
      <TextParagraph14 content={"Tenant:TAC.ParagraphAmendmentsTwo"} />
      <Box marginBottom={2}>
        <FlatList data={amendments} renderItem={({ item, index }) => <OrderedListItem index={index} value={item} />} />
      </Box>
      <Text variant="textHeavy16">{t("Tenant:TAC.HeadingObligations")}</Text>
      <TextParagraph14 content={"Tenant:TAC.ParagraphObligations"} />

      <Box marginBottom={2}>
        <FlatList data={obligations} renderItem={({ item, index }) => <OrderedListItem index={index} value={item} />} />
      </Box>
      <Text variant="textHeavy16">{t("Tenant:TAC.HeadingDispute")}</Text>

      <Box marginBottom={2}>
        <FlatList data={dispute} renderItem={({ item, index }) => <OrderedListItem index={index} bulletPoint={false} value={item} />} />
      </Box>
      <Text variant="textHeavy16">{t("Tenant:TAC.HeadingAccounts")}</Text>

      <TextParagraph14 content={"Tenant:TAC.ParagraphOneAccounts"} />
      <Box marginBottom={2}>
        <FlatList data={accounts} renderItem={({ item, index }) => <OrderedListItem index={index} bulletPoint={false} value={item} />} />
      </Box>
      <Text variant="textHeavy16">{t("Tenant:TAC.HeadingConfidentiality")}</Text>

      <Box marginBottom={2}>
        <FlatList
          data={confidentiality}
          renderItem={({ item, index }) => <OrderedListItem index={index} bulletPoint={false} value={item} />}
        />
      </Box>
      <Text variant="textHeavy16">{t("Tenant:TAC.HeadingAddBankAccount")}</Text>

      <Box marginBottom={2}>
        <FlatList
          data={termsOfService}
          renderItem={({ item, index }) => <OrderedListItem index={index} bulletPoint={true} value={item} />}
        />
      </Box>
    </PageContainer>
  );
};

export default TermsAndConditions;
