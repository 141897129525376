export const JournalEntryStatus = {
  undefined: 0,
  pending: 1,
  cleared: 2,
  failed: 3,
  rejected: 4,
  reversal: 5
};
export type JournalEntryStatusType = keyof typeof JournalEntryStatus;
export type JournalEntryStatusCodes = (typeof JournalEntryStatus)[keyof typeof JournalEntryStatus];
