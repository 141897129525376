import { jsx as _jsx } from "react/jsx-runtime";
import { Box, coreTheme } from "@kolmeo/ui-core";
import { useAnimatedStyle, withTiming } from "react-native-reanimated";
import Icon from "..";
import { createAnimatedComponent } from "../../NativeAnimations";
const { palette } = coreTheme;
const AnimatedBox = createAnimatedComponent(Box);
const RotatingArrow = ({ isRotateUp, iconType = "chevron-down", iconSize = "sm", iconColor }) => {
    const rIconStyles = useAnimatedStyle(() => {
        const rotate = withTiming(isRotateUp ? "180deg" : "0deg", { duration: 250 });
        return {
            transform: [{ rotate }],
        };
    });
    return (_jsx(AnimatedBox, { style: [rIconStyles], children: _jsx(Icon, { type: iconType, size: iconSize, color: iconColor ?? palette.placeHolder }) }));
};
export default RotatingArrow;
