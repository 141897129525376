import { createStackNavigator } from "@react-navigation/stack";
import PaymentDetails from "@src/screens/Tenant/PaymentPreferences/PaymentDetails";
import { useTranslation } from "react-i18next";
import { NativeStackScreenProps } from "@react-navigation/native-stack";
import AddPaymentMethod from "@src/screens/Tenant/PaymentPreferences/AddPaymentMethod/AddPaymentMethod";

export type PaymentSettingsStackParamsList = {
  Details: { tenancyId?: number; redirectAccountId?: number };
  AddPaymentMethod: undefined;
};

export type PaymentSettingsParamsList = NativeStackScreenProps<PaymentSettingsStackParamsList, "Details">;
export type PaymentMethodParamsList = NativeStackScreenProps<PaymentSettingsStackParamsList, "AddPaymentMethod">;

const PaymentSettingsStack = createStackNavigator<PaymentSettingsStackParamsList>();
export const PaymentSettingsNavigator = () => {
  const { t } = useTranslation(["Tenant"]);

  return (
    <PaymentSettingsStack.Navigator screenOptions={{ headerShown: false }} initialRouteName="Details">
      <PaymentSettingsStack.Screen
        name="Details"
        component={PaymentDetails}
        options={{ title: t("PaymentMethods.PaymentDetailsHeader") }}
      />
      <PaymentSettingsStack.Screen
        name="AddPaymentMethod"
        component={AddPaymentMethod}
        options={{ title: t("PaymentMethods.PaymentDetailsHeader") }}
      />
    </PaymentSettingsStack.Navigator>
  );
};
