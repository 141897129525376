import { Text, TextProps, useTheme } from "@kolmeo/ui-core";
import { useTranslation } from "react-i18next";

export const TextParagraph14 = ({ content }: { content: IntentionalAny<"i18n types"> } & TextProps) => {
  const { spacing } = useTheme();
  const { t } = useTranslation();
  return (
    <Text paddingBottom={spacing.px16} variant="paragraph14">
      {/* @ts-ignore */}
      {t(content)}
    </Text>
  );
};
