import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { BottomSheetTextInput } from "@gorhom/bottom-sheet";
import { TextInput, coreTheme } from "@kolmeo/ui-core";
import { forwardRef, memo } from "react";
import { stringToIntConverter } from "../Icon/iconHelpers";
const { palette, fonts, spacing } = coreTheme;
const FieldTextInput = forwardRef(({ isBottomSheet = false, fieldType, minTextInputHeight, maxTextInputHeight, extendTextInputStyles, fontVariant = "textRegular16", ...props }, ref) => {
    const baseFont = fonts[fontVariant];
    const textInputStyles = {
        flex: 1,
        minHeight: minTextInputHeight ? minTextInputHeight : undefined,
        maxHeight: maxTextInputHeight,
        paddingVertical: 10,
        paddingHorizontal: spacing.spacing16,
        fontSize: stringToIntConverter(baseFont.fontSize),
        fontFamily: baseFont.fontFamily,
        lineHeight: stringToIntConverter(baseFont.lineHeight),
        color: palette.textPrimary,
        ...extendTextInputStyles,
    };
    return (_jsx(_Fragment, { children: !isBottomSheet ? (_jsx(TextInput, { ref: ref, ...props, style: [textInputStyles], placeholderTextColor: palette.textSecondary, color: palette.black, autoCorrect: false, multiline: props?.multiline || fieldType === "textarea", textAlignVertical: "top", rejectResponderTermination: false, textAlign: "left" })) : (_jsx(BottomSheetTextInput, { ...props, ref: ref, style: textInputStyles, placeholderTextColor: palette.kittiDarken15, autoCorrect: false, multiline: props?.multiline || fieldType === "textarea", textAlignVertical: "top", textAlign: "left" })) }));
});
export default memo(FieldTextInput);
