import { DateTime } from "luxon";
import groupBy from "lodash/groupBy";
import { StatementsHistoryResponse } from "./StatementsTypes";

export const formatDateString = (date: string) => {
  const DATE_FORMAT = "dd/MM/yyyy";
  return DateTime.fromISO(date).toFormat(DATE_FORMAT);
};

type SectionFormat = { title: string; data: StatementsHistoryResponse[] }[];

export const transformDateSections = (array: StatementsHistoryResponse[]): SectionFormat => {
  const formattedArray = array.map((item) => ({
    ...item,
    groupByDate: DateTime.fromISO(item.dateTime).year
  }));
  const groupedArray = groupBy(formattedArray, "groupByDate");
  const result = [];
  for (const [key, value] of Object.entries(groupedArray)) {
    result.push({
      title: key,
      data: value.map((item) => {
        const { groupByDate, ...rest } = item;
        return rest;
      })
    });
  }

  return result.sort((a, b) => parseInt(b.title) - parseInt(a.title));
};
