export enum FileTypeLookupValue {
  AVI = "AVI",
  BMP = "BMP",
  CSV = "CSV",
  DOC = "DOC",
  DOCX = "DOCX",
  DOT = "DOT",
  EML = "EML",
  FRM = "FRM",
  GIF = "GIF",
  HTM = "HTM",
  HTML = "HTML",
  IDX = "IDX",
  JPEG = "JPEG",
  JPG = "JPG",
  LOG = "LOG",
  MKV = "MKV",
  MOV = "MOV",
  MP3 = "MP3",
  MP4 = "MP4",
  MSG = "MSG",
  PDF = "PDF",
  PNG = "PNG",
  PPT = "PPT",
  PPTX = "PPTX",
  RTF = "RTF",
  TIF = "TIF",
  TIFF = "TIFF",
  TXT = "TXT",
  URL = "URL",
  WAV = "WAV",
  XLS = "XLS",
  XLSX = "XLSX",
  XML = "XML",
  XNK = "XNK",
  ZIP = "ZIP",
  HEIC = "HEIC",
  MD = "MD",
  OGG = "OGG",
  OCX = "OCX",
  INI = "INI",
  MS = "MS",
  PUB = "PUB",
  DOCM = "DOCM"
}

export const fileTypeLookupCodeMapping: Record<number, FileTypeLookupValue> = {
  302002: FileTypeLookupValue.AVI,
  302003: FileTypeLookupValue.BMP,
  302004: FileTypeLookupValue.CSV,
  302005: FileTypeLookupValue.DOC,
  302006: FileTypeLookupValue.DOCX,
  302007: FileTypeLookupValue.DOT,
  302008: FileTypeLookupValue.EML,
  302009: FileTypeLookupValue.FRM,
  302010: FileTypeLookupValue.GIF,
  302011: FileTypeLookupValue.HTM,
  302012: FileTypeLookupValue.HTML,
  302013: FileTypeLookupValue.IDX,
  302014: FileTypeLookupValue.JPEG,
  302015: FileTypeLookupValue.JPG,
  302016: FileTypeLookupValue.LOG,
  302017: FileTypeLookupValue.MKV,
  302018: FileTypeLookupValue.MOV,
  302019: FileTypeLookupValue.MP3,
  302020: FileTypeLookupValue.MP4,
  302021: FileTypeLookupValue.MSG,
  302022: FileTypeLookupValue.PDF,
  302023: FileTypeLookupValue.PNG,
  302024: FileTypeLookupValue.PPT,
  302025: FileTypeLookupValue.PPTX,
  302026: FileTypeLookupValue.RTF,
  302027: FileTypeLookupValue.TIF,
  302028: FileTypeLookupValue.TIFF,
  302029: FileTypeLookupValue.TXT,
  302030: FileTypeLookupValue.URL,
  302031: FileTypeLookupValue.WAV,
  302032: FileTypeLookupValue.XLS,
  302033: FileTypeLookupValue.XLSX,
  302034: FileTypeLookupValue.XML,
  302035: FileTypeLookupValue.XNK,
  302036: FileTypeLookupValue.ZIP,
  302037: FileTypeLookupValue.HEIC,
  302038: FileTypeLookupValue.MD,
  302039: FileTypeLookupValue.OGG,
  302040: FileTypeLookupValue.OCX,
  302041: FileTypeLookupValue.INI,
  302042: FileTypeLookupValue.MS,
  302043: FileTypeLookupValue.PUB,
  302044: FileTypeLookupValue.DOCM
};

const imageFileTypes = new Set([
  FileTypeLookupValue.BMP,
  FileTypeLookupValue.GIF,
  FileTypeLookupValue.JPEG,
  FileTypeLookupValue.JPG,
  FileTypeLookupValue.PNG,
  FileTypeLookupValue.HEIC
]);

// function to check if the file type for a given code is an image
export function isImageFileTypeByCode(code: number): boolean {
  const fileType = fileTypeLookupCodeMapping[code];
  return fileType ? imageFileTypes.has(fileType) : false;
}
