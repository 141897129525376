import { endpoints } from "@src/lib/http/useEndpointUrls";
import { GetTenancyDetailsResponse, TenancyPropertyManagerDetailsType } from "@src/navigation/TenancyNavigator/types";
import { LeaseStatus, LeaseType, OccupancyStatus, PaymentMethod } from "@src/types";
import { rest } from "msw";

const tenantPropertyManagerDetailsBase: TenancyPropertyManagerDetailsType = {
  officeEntityId: "58894b09-08d7-ea11-af8c-501ac52e6519",
  agencyEntityId: "56894b09-08d7-ea11-af8c-501ac52e6519",
  companyEntityId: "55894b09-08d7-ea11-af8c-501ac52e6519",
  officeName: "Kolmeo Training Office BEATTY",
  officeAddress: "30/649 Margaret Ramble, Beatty, SA, 5320",
  propertyManagerFirstName: "aaa 34",
  propertyManagerLastName: "John",
  propertyManagerPositionTitle: "PM",
  propertyManagerEmail: "stafffff1@gmail.com;staffffffac@gmail.com",
  propertyManagerPhone: "",
  logoUrl: "https://portal.dev.kolmeo.com/api/Documents/entity/image/5080248f-b9d9-ed11-9f71-000d3ad1fa25"
};

export const getTenancyDetailsBaseMock: GetTenancyDetailsResponse = {
  occupancyId: 68994,
  rentAmount: 2000.0,
  rentFrequencyCode: 1002009,
  arrearsTotalDaysToEffective: null,
  paidToActual: "2023-05-09T00:00:00",
  paidToEffective: "2023-05-15T00:00:00",
  partPayment: 1981.34,
  nextDueDate: "2023-08-23T00:00:00",
  arrearsToToday: 27732.95,
  arrearsToNextDueDate: 28018.66,
  paymentMethodCode: 1103003,
  occupancyStatus: OccupancyStatus.active,
  leases: [],
  propertyManagerDetails: tenantPropertyManagerDetailsBase,
  tenancyPaymentDetails: {
    billerCode: "09335",
    reference: "100079058651554"
  }
};

export const getTenancyDetailsActiveHandler = rest.get(`${endpoints.TENANT_API}/tenancy/details/68994`, (_, res, ctx) => {
  return res(ctx.status(200), ctx.json(getTenancyDetailsBaseMock));
});

export const getTenancyDetailsInactiveHandler = rest.get(`${endpoints.TENANT_API}/tenancy/details/72322`, (_, res, ctx) => {
  return res(
    ctx.status(200),
    ctx.json({
      ...getTenancyDetailsBaseMock,
      nextDueDate: null,
      partPayment: null,
      leases: {
        leaseFromDate: "2021-03-01T00:00:00",
        leaseToDate: "2023-02-28T23:59:59",
        leaseTypeCode: LeaseType.fixedTerm,
        leaseStatus: LeaseStatus.inactive
      }
    })
  );
});

export const getTenancyDetailsDirectDebitHandler = rest.get(`${endpoints.TENANT_API}/tenancy/details/72328`, (_, res, ctx) => {
  return res(
    ctx.status(200),
    ctx.json({
      ...getTenancyDetailsBaseMock,
      paymentMethodCode: PaymentMethod.directDebit,
      nextDueDate: "2023-11-01T00:00:00",
      partPayment: null,
      leases: {
        leaseFromDate: "2023-11-01T00:00:00",
        leaseToDate: "2024-11-28T23:59:59",
        leaseTypeCode: LeaseType.fixedTerm,
        leaseStatus: LeaseStatus.signed
      },
      occupancyStatus: OccupancyStatus.leaseSigned,
      paidToActual: null
    })
  );
});

export const getTenancyDetailsFailedHandler = rest.get(`${endpoints.TENANT_API}/tenancy/details/72322`, (_, res, ctx) => {
  return res(ctx.status(404), ctx.json({ error: "Nothing found (Mocked error)" }));
});
