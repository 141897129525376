import { createContext, useContext } from "react";
import { PropertyParamList } from "@src/navigation/RootStack";
import type { ThemeBrandingObject } from "@src/lib/Kiitos";

export type PropertyContextType = {
  brandingObject?: ThemeBrandingObject;
  propertyEntityId?: string;
  isIndividualProperty?: boolean;
} & PropertyParamList;

export const PropertyContext = createContext<PropertyContextType>({
  ...({} as PropertyParamList),
  brandingObject: undefined,
  propertyEntityId: undefined
});
export const useProperty = () => useContext(PropertyContext);
