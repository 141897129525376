export const PaymentMethod = {
  directDebit: 1103001,
  creditCard: 1103002,
  bpay: 1103003,
  debitNow: 1103005,
  virtualAccount: 1103006
} as const;
export type PaymentMethodType = keyof typeof PaymentMethod;
export type PaymentMethodCodes = (typeof PaymentMethod)[keyof typeof PaymentMethod];

export const PaymentHistoryMethod = {
  unknown: 0,
  directDebit: 1,
  bpay: 4,
  directCredit: 6
} as const;
export type PaymentHistoryMethodType = keyof typeof PaymentHistoryMethod;
export type PaymentHistoryMethodCodes = (typeof PaymentHistoryMethod)[keyof typeof PaymentHistoryMethod];
