import { configureStore } from "@reduxjs/toolkit";
import propertyStateReducer from "./features/properties/properties.reducer";
import tenancyStateReducer from "./features/tenancySlice";
import navigationStateReducer from "./features/navigationSlice";
import { persistReducer, persistStore } from "redux-persist";
import AsyncStorage from "@react-native-async-storage/async-storage";

const persistedPropertyReducer = persistReducer(
  {
    key: "_property",
    storage: AsyncStorage
  },
  propertyStateReducer
);
const persistedTenancyReducer = persistReducer(
  {
    key: "_tenancy",
    storage: AsyncStorage
  },
  tenancyStateReducer
);

const persistedNavigationReducer = persistReducer(
  {
    key: "_navigation",
    storage: AsyncStorage
  },
  navigationStateReducer
);

export const store = configureStore({
  reducer: {
    propertyState: persistedPropertyReducer,
    tenancyState: persistedTenancyReducer,
    navigationState: persistedNavigationReducer
  },
  devTools: process.env.NODE_ENV !== "production"
});

export const persistor = persistStore(store);

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
