export const sizing = {
    sizing4: 4,
    sizing8: 8,
    sizing12: 12,
    sizing16: 16,
    sizing20: 20,
    sizing24: 24,
    sizing28: 28,
    sizing32: 32,
    sizing40: 40,
    sizing64: 64,
    sizing80: 80,
    sizing96: 96,
    sizing128: 128,
    sizing160: 160,
    sizing240: 240,
};
export default sizing;
