import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Text, coreTheme } from "@kolmeo/ui-core";
import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";
import { kebabCase } from "lodash";
import { useCallback } from "react";
import { TOP_TAB_BAR_HEIGHT } from "../constants";
import TopTabBar from "./TopTabBar";
const Tab = createMaterialTopTabNavigator();
const { palette, spacing } = coreTheme;
const TabScreen = ({ tabs, initialRouteName, tabScreenOptions, labelComponent, lazyPlaceholderComponent, screenListeners, dependencyListRender, }) => {
    if (tabs.length === 0)
        return null;
    const renderCustomLabelComponent = useCallback(({ focused, label, routeName }) => {
        if (!labelComponent)
            return;
        return labelComponent({ focused, label, routeName });
    }, [...(dependencyListRender ?? [])]);
    return (_jsx(Tab.Navigator, { initialRouteName: initialRouteName, testID: "top-tab-navigator", initialLayout: { height: TOP_TAB_BAR_HEIGHT }, sceneContainerStyle: { backgroundColor: palette.backgroundSurface }, screenListeners: screenListeners, tabBar: (props) => _jsx(TopTabBar, { ...props }), screenOptions: ({ route }) => ({
            tabBarScrollEnabled: true,
            lazy: true,
            lazyPreloadDistance: 0.4,
            lazyPlaceholder: () => lazyPlaceholderComponent,
            tabBarGap: spacing.spacing16,
            tabBarItemStyle: {
                width: "auto",
                padding: 0,
            },
            tabBarIndicatorStyle: {
                backgroundColor: palette.textBrand,
                height: 3,
            },
            tabBarLabel: ({ focused, children: label }) => {
                return (_jsx(_Fragment, { children: labelComponent ? (renderCustomLabelComponent({ focused, label, routeName: route.name })) : (_jsx(Text, { numberOfLines: 1, variant: "textMedium10", color: focused ? palette.textBrand : palette.textSecondary, textAlign: "center", children: label })) }));
            },
            ...tabScreenOptions,
        }), children: tabs.map((tab) => {
            return (_jsx(Tab.Screen, { initialParams: tab.initialParams, name: `${tab.key}` ?? tab.name, children: tab.children, options: {
                    title: tab.name,
                    tabBarTestID: `${kebabCase(tab.name)}-top-tab-action`,
                } }, tab.name));
        }) }));
};
export default TabScreen;
