import { coreThemeV2 } from "@kolmeo/ui-components";
import { useRef, useState } from "react";
import { ICarouselInstance } from "react-native-reanimated-carousel";
import { useSafeAreaInsets } from "react-native-safe-area-context";
import ImageCarousel from "./ImageCarousel";
import { FULLSCREEN_IMAGE_HEIGHT, FULLSCREEN_IMAGE_WIDTH } from "./utils/constants";

type FullScreenImageViewerProps = {
  images: string[];
  indexToOpen: number;
};
const FullScreenImageViewer = ({ images, indexToOpen }: FullScreenImageViewerProps) => {
  const [activeIndex, setActiveIndex] = useState<number>(indexToOpen);
  const insets = useSafeAreaInsets();
  const { spacing } = coreThemeV2;

  // add extra spacing for devices without insets
  const bottomInsets = insets.bottom > 0 ? insets.bottom : spacing.sp16;
  const fullImageRef = useRef<ICarouselInstance>(null);

  const onLeftPressHandler = () => {
    fullImageRef?.current?.scrollTo({ count: -1, animated: true });
    setActiveIndex(activeIndex - 1);
  };

  const onRightPressHandler = () => {
    fullImageRef?.current?.scrollTo({ count: 1, animated: true });
    setActiveIndex(activeIndex + 1);
  };

  return (
    <ImageCarousel
      images={images}
      fullImageRef={fullImageRef}
      currentIndex={activeIndex}
      carouselHeight={(FULLSCREEN_IMAGE_HEIGHT - bottomInsets - insets.top - spacing.sp16) * 0.8}
      carouselWidth={FULLSCREEN_IMAGE_WIDTH}
      imageHeight={FULLSCREEN_IMAGE_HEIGHT * 0.8}
      imageWidth={FULLSCREEN_IMAGE_WIDTH}
      onLeftPressHandler={onLeftPressHandler}
      onRightPressHandler={onRightPressHandler}
    />
  );
};

export default FullScreenImageViewer;
