import { jsx as _jsx } from "react/jsx-runtime";
import styled from "styled-components/native";
import { coreTheme } from "@kolmeo/ui-core";
import Icons, { IconsProps } from "./components";
import IconWrapper from "./IconWrapper";
const getNounArticle = (noun) => (new RegExp(/\b[aeiou]\w*/gm).test(noun) ? "an" : "a");
const Icon = ({ type, outlined, color, size, accessible = true, testID, width, height, ...props }) => {
    const IconComponent = Icons[type];
    if (!IconComponent) {
        throw new Error(`missing icon ${type}`);
    }
    const StyledIconComponent = styled(IconComponent) ``;
    const iconType = IconsProps[type];
    const { palette } = coreTheme;
    return (_jsx(IconWrapper, { ...props, type: type, size: size, width: width, height: height, testID: testID ?? `icon-${type}`, title: accessible ? `${type} icon` : "", description: accessible ? `This is ${getNounArticle(type)} ${type} icon` : "", viewBox: iconType?.viewBox || "0 0 30 30", children: _jsx(StyledIconComponent, { outlined: typeof iconType?.outlined == "undefined" ? outlined : iconType?.outlined, color: color || palette.kolmeoBlack, strokeWidth: iconType?.strokeWidth || "1" }) }));
};
export default Icon;
