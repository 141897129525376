import { jsx as _jsx } from "react/jsx-runtime";
import { Box, coreTheme } from "@kolmeo/ui-core";
import { forwardRef } from "react";
import { Animated } from "react-native";
const AnimatedBox = Animated.createAnimatedComponent(Box);
const { spacing } = coreTheme;
const OnscrollHiddenWrapper = forwardRef(({ innerComponent, yOffset, headerHeight, ...props }, ref) => {
    const animatedTranslateY = yOffset.interpolate({
        inputRange: [0, headerHeight ?? 0],
        outputRange: [0, -(headerHeight ?? 0)],
        extrapolate: "extend",
    });
    return (_jsx(AnimatedBox, { ref: ref, position: "absolute", top: 0, bottom: 0, justifyContent: "center", left: 0, right: 0, style: {
            transform: [{ translateY: animatedTranslateY }],
        }, height: headerHeight, paddingHorizontal: spacing.spacing16, zIndex: 5, ...props, children: innerComponent }));
});
export default OnscrollHiddenWrapper;
