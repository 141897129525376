import { Box, useTheme, Text } from "@kolmeo/ui-core";
import { Card, Icon } from "@kolmeo/ui-components";
import { TenancyPaymentDetails } from "@src/navigation/TenancyNavigator/types";
import { shortenTextWithEllipsis } from "@src/utils/textTransformers/shortenTextWithEllipsis";
import { useTranslation } from "react-i18next";

type BPayMethodCardType = {
  item?: TenancyPaymentDetails;
  index: number;
  onPress: () => void;
  isFullWidth: boolean;
};
const BPayMethodCard = ({ item, onPress, isFullWidth }: BPayMethodCardType) => {
  const { palette } = useTheme();
  const { t } = useTranslation("Tenant");

  return (
    <Card
      flexDirection={"row"}
      paddingVertical={23}
      onPress={() => onPress()}
      setTouchableToFlex
      borderRadius={16}
      minHeight={120}
      justifyContent="center"
      alignItems="center"
      width={isFullWidth ? "100%" : "40%"}
    >
      <Box flexDirection={"row"} width={"100%"} justifyContent={"space-evenly"} alignItems={"center"} flexWrap={"wrap"}>
        <Box flexDirection={"column"} flex={0.5} justifyContent={"center"}>
          <Box justifyContent={"center"} alignItems={"center"}>
            <Icon type={"bpay"} size={"md"} color={palette.text.secondary} />
          </Box>
        </Box>
        <Box flexDirection={"column"} flex={2}>
          <Text variant="paragraph16">{shortenTextWithEllipsis(`${t("PaymentMethods.BillerCode")}: ${item?.billerCode}`, 25)}</Text>
          <Text variant="paragraph16">{shortenTextWithEllipsis(`${t("PaymentMethods.Ref")}: ${item?.reference}`, 25)}</Text>
        </Box>
        <Box flex={1} flexDirection={"row"} alignItems={"center"} justifySelf={"flex-end"}></Box>
        <Box position={"absolute"} flexDirection={"row"} right={0}>
          <Icon type={"chevron-right"} size={"lg"} color={palette.text.brand} />
        </Box>
      </Box>
    </Card>
  );
};

export default BPayMethodCard;
