import styled from "styled-components/native";
import { border, boxShadow, color, flexbox, layout, position, space, } from "styled-system";
const FlatList = styled.FlatList `
  ${color}
  ${space}
  ${layout}
  ${position}
  ${border}
  ${flexbox}
  ${boxShadow}
`;
export default FlatList;
