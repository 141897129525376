export enum FileExtensionGroup {
  ALL = "ALL",
  DOC = "DOC",
  EMAIL = "EMAIL",
  IMAGE = "IMAGE",
  PDF = "PDF",
  ODT = "ODT",
  EXCEL = "EXCEL"
}

export const FileExtensionPresets: Record<FileExtensionGroup, string> = {
  ALL: ".csv,.doc,.docx,.pdf,.eml,.jpeg,.jpg,.png,.msg,.tif,.tiff,.xls,.xlsx,.zip",
  DOC: ".doc,.docx",
  EMAIL: ".eml,.msg",
  IMAGE: ".jpg,.jpeg,.png,.webp,.heic",
  PDF: ".pdf",
  ODT: ".odt",
  EXCEL: ".xls,.xlsx"
};

export const getAcceptedFileExtension = (types: FileExtensionGroup[]) => types.map((t) => FileExtensionPresets[t]).join(",");
