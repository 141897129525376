export const TransactionSubCategory = {
  ACCOUNTANT: 4502004,
  ADVERTISING: 4502005,
  ADMIN_FEE: 4502094,
  AGENCY_FEES: 4502006,
  AGENCY_LOAN: 4502007,
  AGENCY_SERVICE: 4502008,
  AIR_CONDITIONING_HEATING: 4502009,
  ANTENNAS: 4502010,
  APPLIANCES: 4502011,
  BANK_FEES: 4502012,
  BODY_CORPORATE: 4502013,
  BOND_AUTHORITY: 4502014,
  BOND_CLAIM: 4502085,
  BOND_RECEIPT: 4502015,
  BUFFER_LOAN: 4502016,
  CARETAKING: 4502017,
  CARPET_CLEANING: 4502018,
  CITY_COUNCIL: 4502019,
  CLEANING: 4502020,
  COMMERCIAL_BOND: 4502021,
  COMMERCIAL_CHARGEBACK: 4502022,
  COMMERCIAL_OTHER: 4502023,
  COMMERCIAL_OUTGOINGS: 4502024,
  COMMERCIAL_RENT: 4502025,
  DEPRECIATION_SCHEDULES: 4502026,
  DISHONOUR_FEES: 4502027,
  ELECTRICAL: 4502028,
  EOFY_STATEMENT_FEE: 4502029,
  FENCING: 4502031,
  FIRE_PROTECTION: 4502032,
  FLOOR_COVERINGS: 4502033,
  GARDENING: 4502034,
  GENERAL_MAINTENANCE: 4502035,
  GLAZING: 4502036,
  GROUP_REFERRER: 4502037,
  INSPECTION_FEES: 4502041,
  INSPECTION_FEES_CONDITION: 4502038,
  INSPECTION_FEES_FINAL: 4502039,
  INSPECTION_FEES_ROUTINE: 4502040,
  INSURANCE: 4502042,
  INSURANCE_CLAIM: 4502092,
  INVENTORY_MANAGEMENT_FEES: 4502043,
  KEYS: 4502044,
  LOCKS: 4502044,
  DOORS: 4502044,
  LAND_TAX: 4502045,
  LEASE_PREPARATION_FEES: 4502046,
  LEASE_RENEWAL_FEES: 4502047,
  LEGAL: 4502048,
  LAESING_FEES: 4502049,
  MISCELLANEOUS: 4502051,
  NONE: 4502003,
  OWNER_PAYMENT: 4502052,
  PAINTING: 4502053,
  PEST_CONTROL: 4502054,
  PLUMBING_POOL_MAINTENANCE: 4502055,
  PROPERTYINSPECTION: 4502056,
  REFERENCE_CHECK_FEES: 4502057,
  REFERRER: 4502058,
  REGISTERED_POSTAGE: 4502059,
  RENOVATION: 4502060,
  RENTAL_GUARANTEE: 4502061,
  ROOFING: 4502062,
  RUBBISH_REMOVAL: 4502063,
  SECURITY_SERVICES: 4502064,
  SURVEYING: 4502065,
  TELEPHONE: 4502066,
  TENANT_REFUND: 4502067,
  TENANT_TRANSFER_FEE: 4502095,
  TILING: 4502068,
  TRIBUNAL_FEES: 4502069,
  UTILITY_ELECTRICAL: 4502070,
  UTILITY_GAS: 4502071,
  UTILITY_WATER: 4502073,
  UTILITY_WATER_USAGE: 4502072,
  WINDOW_COVERINGS: 4502074,
  RENT_REFUND_ADJUSTMENT: 4502075,
  RENT_REVERSAL_DISHONOUR: 4502076,
  RENT_ADJUSTMENT: 4502077,
  TENANT_PAYMENT: 4502078,
  SUPPLIER_PAYMENT: 4502079,
  SETTLEMENT_ADJUSTMENT: 4502091,
  POOL_MAINTENANCE: 4502082,
  PLUMBING: 4502081,
  LEASE_BREAK_FEES: 4502080,
  COMPENSATION: 4502086,
  INTERNATIONAL_MONEY_TRANSAFER_FEE: 4502087,
  INVOICE_MANAGEMENT_FEE: 4502088,
  RENT_MANAGEMENT_FEE: 4502090,
  STATEMENT_FEE: 4502093
} as const;
export type TransactionSubCategoryType = keyof typeof TransactionSubCategory;
export type TransactionSubCategoryCodes = (typeof TransactionSubCategory)[keyof typeof TransactionSubCategory];
