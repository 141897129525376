import { coreTheme } from "@kolmeo/ui-core";
import { findNodeHandle } from "react-native";
const { spacing } = coreTheme;
const useScrollToInput = ({ scrollRef }) => {
    const onScrollToFocusedInput = (target, extraHeight = spacing.spacing16, keyboardOpeningTime = 250) => {
        scrollRef?.current?.props?.scrollToFocusedInput(findNodeHandle(target), extraHeight, keyboardOpeningTime);
    };
    return {
        onScrollToFocusedInput,
    };
};
export default useScrollToInput;
