import { format, parse } from "date-fns";
import { DATE_FORMAT_SHORT } from "./dateConstants";
import { zonedTimeToUtc } from "date-fns-tz";

const toOrdinal = (n: number) => {
  const s = ["th", "st", "nd", "rd"];
  const v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
};

export const transformToOrdinalDate = (dateString: string): string => {
  const date = parse(dateString, DATE_FORMAT_SHORT, new Date());
  const day = date.getDate();
  const ordinal = toOrdinal(day);
  const formattedDate = format(new Date(dateString), `do MMM, yyyy`).replace(/do/, ordinal);
  return formattedDate;
};

export const transformToLocalOrdinalUTCDate = (dateString: string): string => {
  const localDate = new Date(dateString);
  const utcDate = zonedTimeToUtc(localDate, "UTC");
  return format(utcDate, "d MMMM yyyy");
};
