export default {
    path: {
        d: "M9,16V10H5L12,3L19,10H15V16H9M5,20V18H19V20H5Z",
    },
    properties: {
        viewBox: "0 0 24 24",
        outlined: false,
        strokeWidth: "0",
    },
};
