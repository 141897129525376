import { StyleSheet } from "react-native";
import { Box, useTheme } from "@kolmeo/ui-core";
import { ButtonV2 } from "@kolmeo/ui-components";
import { TenantThemeBrandingObject } from "@src/lib/Kiitos";
import { useTranslation } from "react-i18next";

export type PaymentMethodCallToActionProps = {
  disableSetDefault: boolean;
  onDeleteAccountPress: () => void;
  disableDeactivation: boolean;
  isLoading: boolean;
  brandingObject: TenantThemeBrandingObject | undefined;
  handleSetToDefaultPress: () => void;
  handleDefaultActionPress: () => void;
  hideCta: boolean;
};
const PaymentMethodCallToAction = ({
  onDeleteAccountPress,
  disableDeactivation,
  disableSetDefault,
  isLoading,
  brandingObject,
  hideCta = false,
  handleSetToDefaultPress,
  handleDefaultActionPress
}: PaymentMethodCallToActionProps) => {
  const { spacing, sizing } = useTheme();
  const { t } = useTranslation(["Common", "Tenant"]);

  return !hideCta ? (
    <>
      <Box flexDirection={"row"} justifyContent={"space-between"} marginTop={spacing.px20}>
        <ButtonV2
          flex={1}
          width={"49%"}
          size={"small"}
          variant="outlinedDanger"
          label={t("Tenant:PaymentMethods.RemoveAccount")}
          onPress={onDeleteAccountPress}
          disabled={disableDeactivation}
        />
        <Box width={sizing.size20}></Box>
        <ButtonV2
          flex={1}
          width={"49%"}
          size={"small"}
          variant="outlined"
          disabled={disableSetDefault}
          isLoading={isLoading}
          transparentHover
          overrideVariant={{
            color: brandingObject?.primaryColor,
            borderColor: brandingObject?.primaryColor
          }}
          label={t("Tenant:PaymentMethods.SetAsDefault")}
          onPress={handleSetToDefaultPress}
        />
      </Box>
    </>
  ) : (
    <Box flexDirection="row" justifyContent="space-between" marginLeft={spacing.px20} marginRight={spacing.px20}>
      <ButtonV2
        flex={1}
        width="100%"
        size="small"
        variant="primary"
        label={t("Common:Generic.OK").toUpperCase()}
        onPress={handleDefaultActionPress}
      />
    </Box>
  );
};

export default PaymentMethodCallToAction;

const styles = StyleSheet.create({});
