import { IntercomProvider } from "react-use-intercom";
import { useCurrentUser } from "@src/hooks";
const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID;

export const Intercom = ({ children }: { children: JSX.Element }) => {
  const { currentUser } = useCurrentUser();

  if (INTERCOM_APP_ID && currentUser) {
    return (
      <IntercomProvider
        appId={INTERCOM_APP_ID}
        autoBoot={currentUser ? true : false}
        autoBootProps={{
          verticalPadding: 10,
          horizontalPadding: 10,
          hideDefaultLauncher: false,
          email: currentUser.emailAddress,
          name: `${currentUser.firstName} ${currentUser.lastName}`,
          userId: currentUser.userId?.toString()
        }}
      >
        {children}
      </IntercomProvider>
    );
  }
  return children;
};
