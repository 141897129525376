import AsyncStorage from "@react-native-async-storage/async-storage";
import { NavigationAction } from "@react-navigation/native";
import { DEEP_LINK } from "@src/types";
import { useState, createContext, useCallback, useEffect, useContext } from "react";

export interface IDeepLink {
  rawUrl: string;
  action: NavigationAction;
}

export type DeepLinkContextType = {
  deepLinksState?: IDeepLink;
  addDeepLink: (link: IDeepLink) => void;
  removeDeepLink: () => void;
};

export const DeepLinkContext = createContext<DeepLinkContextType>({} as DeepLinkContextType);

export const DeepLinkProvider = ({ children }: any) => {
  const [deepLinksState, setDeepLinksState] = useState<IDeepLink>();

  useEffect(() => {
    getDeepLinkFromStorageAsync().then((res) => {
      if (res) {
        const dlParsed = JSON.parse(res);
        setDeepLinksState(dlParsed);
      }
    });
  }, []);

  const getDeepLinkFromStorageAsync = async () => {
    return await AsyncStorage.getItem(DEEP_LINK);
  };

  const addDeepLink = (link: IDeepLink) => {
    AsyncStorage.setItem(DEEP_LINK, JSON.stringify(link));
    setDeepLinksState(link);
  };

  const removeDeepLink = useCallback(() => {
    setDeepLinksState(undefined);
    AsyncStorage.removeItem(DEEP_LINK);
  }, []);

  return <DeepLinkContext.Provider value={{ deepLinksState, addDeepLink, removeDeepLink }}>{children}</DeepLinkContext.Provider>;
};

export const useDeepLinking = () => useContext(DeepLinkContext);
