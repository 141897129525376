import { createSlice } from "@reduxjs/toolkit";

export type AppState = {
  isFeatureFlagsComplete: boolean; // Fetch the feature flags
};

const initialState: AppState = {
  isFeatureFlagsComplete: false
};

export const appStateSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    toggleFeatureFlagsComplete: (state) => {
      state.isFeatureFlagsComplete = !state.isFeatureFlagsComplete;
    }
  }
});

export const { toggleFeatureFlagsComplete } = appStateSlice.actions;

export default appStateSlice.reducer;
