export default {
    path: {
        d: "M22 17.5H20.5V8.82811C20.5005 8.619 20.4565 8.41218 20.3708 8.22143C20.2851 8.03068 20.1598 7.86038 20.0031 7.72186L12.5031 0.906235C12.2281 0.653911 11.8685 0.513916 11.4953 0.513916C11.1221 0.513916 10.7625 0.653911 10.4875 0.906235L2.9875 7.72186C2.83394 7.86234 2.7113 8.03325 2.62737 8.2237C2.54344 8.41415 2.50007 8.61998 2.5 8.82811V17.5H1C0.801088 17.5 0.610322 17.579 0.46967 17.7197C0.329018 17.8603 0.25 18.0511 0.25 18.25C0.25 18.4489 0.329018 18.6397 0.46967 18.7803C0.610322 18.921 0.801088 19 1 19H22C22.1989 19 22.3897 18.921 22.5303 18.7803C22.671 18.6397 22.75 18.4489 22.75 18.25C22.75 18.0511 22.671 17.8603 22.5303 17.7197C22.3897 17.579 22.1989 17.5 22 17.5ZM13.75 17.5H9.25V13C9.25 12.8011 9.32902 12.6103 9.46967 12.4697C9.61032 12.329 9.80109 12.25 10 12.25H13C13.1989 12.25 13.3897 12.329 13.5303 12.4697C13.671 12.6103 13.75 12.8011 13.75 13V17.5Z",
    },
    properties: {
        viewBox: "0 0 23 19",
        outlined: false,
        strokeWidth: "0",
    },
};
