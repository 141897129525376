import { Box, useTheme } from "@kolmeo/ui-core";
import { Card, Icon } from "@kolmeo/ui-components";
import { useTranslation } from "react-i18next";
import RowItem, { RowItemButtonProps } from "../RowItem";
import { TenancyPaymentDetails } from "@src/navigation/TenancyNavigator/types";
import Clipboard from "@react-native-clipboard/clipboard";

type BPayMethodContentCardType = {
  item?: TenancyPaymentDetails;
};

const BPayMethodContentCard = ({ item }: BPayMethodContentCardType) => {
  const { palette, colors } = useTheme();
  const { t } = useTranslation(["Common", "Tenant"]);
  const onCopyField = (text?: string) => {
    text && Clipboard.setString(text);
  };
  return (
    <Card
      flexDirection={"row"}
      marginBottom={1}
      marginHorizontal={1}
      renderShadow
      flex={1}
      borderColor={colors.primary}
      borderWidth={0.4}
      position={"relative"}
    >
      {/* <Box mb="auto" backgroundColor={"green"} width={"100%"}> */}
      <Box flexDirection={"row"} width={"100%"} flexWrap={"wrap"} paddingBottom={3}>
        <Box flexDirection={"column"} flex={2}>
          <RowItem alignment="right" label={<Icon type={"bpay"} size={"lg"} color={palette.text.secondary} />} />
          <RowItem label="BPAY" variant="textHeavy16" />
          <RowItem
            label={`${t("Tenant:PaymentMethods.BillerCode")}: ${item?.billerCode}`}
            buttonRight={
              { icon: "copy", onPress: () => onCopyField(item?.billerCode), size: 12, color: palette.text.secondary } as RowItemButtonProps
            }
          />
          <RowItem
            label={`${t("Tenant:PaymentMethods.Ref")}: ${item?.reference}`}
            buttonRight={
              { icon: "copy", onPress: () => onCopyField(item?.reference), size: 12, color: palette.text.secondary } as RowItemButtonProps
            }
          />
        </Box>
      </Box>
    </Card>
  );
};

export default BPayMethodContentCard;
