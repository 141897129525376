import { useTheme } from "@kolmeo/ui-core";
import { PillItem, PillItemProps } from "@kolmeo/ui-components";
import { StatusVariantKey } from "@src/lib/Kiitos/statusVariants";

export type StatusPillProps = {
  label: string;
  variant: StatusVariantKey;
  capitalise?: boolean;
} & PillItemProps;

const StatusPill = ({ label, variant, capitalise, ...props }: StatusPillProps): JSX.Element => {
  const { spacing, statusVariants } = useTheme();

  return (
    <PillItem
      text={capitalise ? label.toUpperCase() : label}
      textProps={{ color: statusVariants[variant].titleColor }}
      borderColor={statusVariants[variant].borderColor}
      borderWidth={1}
      backgroundColor={statusVariants[variant].backgroundColor}
      paddingY={spacing.px4}
      paddingX={spacing.px8}
      {...props}
    />
  );
};

export default StatusPill;
