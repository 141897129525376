import { createContext, useContext, Context } from "react";

type SplitScreenState<T> = {
  transaction: T | undefined;
  setTransaction: React.Dispatch<React.SetStateAction<T | undefined>>;
  setIsSideViewBlank: React.Dispatch<React.SetStateAction<boolean>>;
};

export const creatSplitScreenContext = <T>() => createContext<SplitScreenState<T> | undefined>(undefined);

export const useSplitScreen = <T>(context: Context<T>) => {
  const splitScreenState = useContext(context);
  if (splitScreenState === undefined) {
    throw new Error("useSplitScreen must be inside a SplitScreenProvider");
  }
  return splitScreenState;
};
