export default {
  PaymentSettingsHeader: "Payment Settings",
  PaymentDetailsHeader: "Payment Details",
  OverviewHeading: "Lease Overview",
  OverviewEmptyTitle: "No tenancies found",
  OverviewEmptyContent: "Maybe you have no tenancies with Kolmeo",
  PaymentDetailsEmpty: "No bank accounts to see yet",
  PaymentDetailsEmptyAlt: "Set yourself up to pay rent",
  PaymentDetailsEmptySecondary: "Add the details of bank accounts you wish to pay rent with.",
  PaymentDetailsEmptySecondaryAlt: "Add your bank account details for future rent payments.",
  Overview: "Overview",
  Payments: "Payments",
  Requests: "Requests",
  CTAAddNew: "Add Bank Account",
  PaymentMethodForProperty: "Your rent payment method for ",
  AddPaymentMethod: "Add bank account",
  ChangePaymentMethodMessage: "To change your payment method please contact your property manager.",
  MultipleTenanciesAlert: "To change properties or tenancies please go back to the overview screen and use the dropdown.",
  Agreement: "Agreement",
  RentPaymentMethod: "Rent payment method",
  BankAccountDetailsHeader: "Bank account details",
  BankAccountEndingIn: "Ending in {{bankAccountNumber}}",
  BankAccount: "Bank Account",
  ViewAuthority: "View Debit Request",
  AddNewBankAccountHeader: "Add Bank Account",
  BankDetails: "Bank Details",
  SignAuthority: "Sign Debit Request",
  CancelAddBankAccountConfirmation: "Are you sure you want to cancel adding a bank account? The information you entered will not be saved.",
  FormDebitAuthorityAgreement: "I acknowledge and agree to the terms outlined above.",
  FormSetDefault: "Set as default payment method",
  FormAddBankAccount: "Add bank account",
  BankAccountAddedSuccessHeader: "You are set up to pay rent!",
  BankAccountAddedSuccessDescription:
    "Your bank account details have been successfully added. Please check the generated Debit Request document to make sure your details are correct.",
  BankAccountAddedSuccessDefaultSet: "This is now your default bank account for rent payments.",
  AccountNumber: "Account Number",
  AccountNickname: "Account Nickname",
  AccountName: "Account Name",
  CancelFormWarningDescription:
    "Are you sure you want to cancel adding a bank account? The information you have entered will not be saved.",
  SetAsDefault: "Set as Default",
  RemoveAccount: "Remove Account",
  DeleteConfirmation: "Are you sure you want to remove this bank account?",
  DeleteConfirmationText:
    "You have successfully removed this bank account. Rent payments made in the last 3 days may still be debited from this account.",
  MessagebarDefaultAccount: "This bank account is your default account for payments.",
  HeadingCannotRemoveAccount: "You can't remove this account",
  CannotRemoveAccountDescription:
    "This account is linked to pending payments. Please contact your property manager to confirm when this account can be removed.",
  AddAnotherBankAccount: "To remove this account, please add another bank account first.",
  BillerCode: "Biller Code",
  Ref: "Ref",
  BSB: "BSB",
  Account: "Account #",
  AccountNameShort: "Acc. Name",
  BPAYDetailsHeader: "BPAY details",
  DebitAuthorityLoading: "We are currently generating your debit request, please check again in a moment.",
  NoPaymentMethod: "No method",
  BPAYPaymentMethodEmpty: "No BPAY details to see yet",
  BPAYPaymentMethodEmptyDescription: "Please contact your Property Manager",
  NoPaymentMethodTitle: "No payment method selected",
  NoPaymentMethodDescription: "To set up a payment method please contact your property manager",
  VirtualAccountDetailsHeader: "Paycode Details",
  VirtualAccountDetailsUsage1:
    "Use your preferred banking app to pay rent with paycode and select the option to pay to a bank account. When prompted, copy and paste the details below.",
  VirtualAccountDetailsInfo: "These details are unique to you, to use for rent payments for this specific tenancy."
};
