export default {
    path: {
        d: "M22.8279 21.3757C23.281 20.665 23.5385 19.8102 23.5385 18.9039C23.5385 16.3291 21.4787 14.2692 18.9039 14.2692C16.3291 14.2692 14.2692 16.3291 14.2692 18.9039C14.2692 21.4787 16.3291 23.5385 18.9039 23.5385C19.7999 23.5385 20.6444 23.281 21.3551 22.8382L24.5684 26L26 24.5684L22.8279 21.3757ZM18.9039 21.4787C18.221 21.4787 17.5661 21.2074 17.0832 20.7245C16.6004 20.2417 16.3291 19.5868 16.3291 18.9039C16.3291 18.221 16.6004 17.5661 17.0832 17.0832C17.5661 16.6004 18.221 16.3291 18.9039 16.3291C19.5868 16.3291 20.2417 16.6004 20.7245 17.0832C21.2074 17.5661 21.4787 18.221 21.4787 18.9039C21.4787 19.5868 21.2074 20.2417 20.7245 20.7245C20.2417 21.2074 19.5868 21.4787 18.9039 21.4787ZM8.08975 22.5086V14.2692H5L15.2992 5L25.5983 14.2692H23.7239C22.5086 13.0025 20.7989 12.2094 18.9039 12.2094C15.2168 12.2094 12.2094 15.2168 12.2094 18.9039C12.2094 20.2325 12.6008 21.4787 13.2702 22.5086H8.08975Z",
    },
    properties: {
        viewBox: "0 0 30 30",
        outlined: false,
        strokeWidth: "0",
    },
};
