import { Text, View } from "react-native";

const AppImageUploader = () => {
  return (
    <View>
      <Text>TO BE BUILT WHEN APP SUPPORT IS AVAILABLE</Text>
    </View>
  );
};

export default AppImageUploader;
