export default {
    path: {
        d: "M23 2.99998H19.5375L17.8375 0.44998C17.747 0.310558 17.6229 0.196219 17.4765 0.117557C17.3301 0.0388951 17.1662 -0.00154597 17 -1.93638e-05H9C8.83381 -0.00154597 8.66993 0.0388951 8.52353 0.117557C8.37713 0.196219 8.25295 0.310558 8.1625 0.44998L6.4625 2.99998H3C2.20536 3.00327 1.44421 3.3204 0.882315 3.8823C0.320417 4.44419 0.00329046 5.20534 0 5.99998V20C0.00329046 20.7946 0.320417 21.5558 0.882315 22.1177C1.44421 22.6796 2.20536 22.9967 3 23H23C23.7946 22.9967 24.5558 22.6796 25.1177 22.1177C25.6796 21.5558 25.9967 20.7946 26 20V5.99998C25.9967 5.20534 25.6796 4.44419 25.1177 3.8823C24.5558 3.3204 23.7946 3.00327 23 2.99998ZM17.5 12.5C17.5 13.39 17.2361 14.26 16.7416 15C16.2471 15.7401 15.5443 16.3168 14.7221 16.6574C13.8998 16.998 12.995 17.0871 12.1221 16.9135C11.2492 16.7399 10.4474 16.3113 9.81802 15.682C9.18868 15.0526 8.7601 14.2508 8.58647 13.3779C8.41283 12.505 8.50195 11.6002 8.84254 10.7779C9.18314 9.95564 9.75991 9.25283 10.4999 8.75837C11.24 8.2639 12.11 7.99998 13 7.99998C14.1935 7.99998 15.3381 8.47409 16.182 9.318C17.0259 10.1619 17.5 11.3065 17.5 12.5Z",
    },
    properties: {
        viewBox: "0 0 26 23",
        outlined: false,
        strokeWidth: "0",
    },
};
