import { DateTime } from "luxon";

import { Text, Box, useTheme } from "@kolmeo/ui-core";

import { PropertyLeaseDetails } from "@src/types";
import { addAlphaToHex } from "@src/lib/Kiitos";
import { DATE_FORMAT_DMY } from "@src/utils";

const calculatePercentage = (numerator: number, denominator: number) => (numerator / denominator) * 100;

export type LeaseLengthVisualisationProps = Pick<PropertyLeaseDetails, "leaseFromDate" | "leaseToDate"> & {
  color?: string;
};

const LeaseLengthVisualisation = ({ leaseFromDate, leaseToDate }: LeaseLengthVisualisationProps) => {
  const { spacing, palette } = useTheme();

  const startDate = DateTime.fromISO(leaseFromDate);
  const finishDate = DateTime.fromISO(leaseToDate);

  const n = DateTime.now().diff(startDate, "days").days;
  const d = finishDate.diff(startDate, "days").days;
  const percentageOfLeaseLeft = Math.round(calculatePercentage(n, d));
  const leaseFromToDateFormatted = `${startDate.toFormat(DATE_FORMAT_DMY)} - ${finishDate.toFormat(DATE_FORMAT_DMY)}`;

  return (
    <Box mb={spacing.px16}>
      <Text fontWeight={600} mb={spacing.px4}>
        {leaseFromToDateFormatted}
      </Text>
      <Box
        backgroundColor={addAlphaToHex(palette.feedback.general.icon)}
        width="100%"
        height={8}
        borderRadius={4}
        mb={spacing.px4}
        overflow="hidden"
      >
        <Box height="100%" width={`${percentageOfLeaseLeft}%`} backgroundColor={palette.feedback.general.icon} borderRadius={4} />
      </Box>
    </Box>
  );
};

export default LeaseLengthVisualisation;
