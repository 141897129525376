import { Field, FieldLabel, FieldError } from "@kolmeo/ui-components";
import { Controller, Control, FieldErrors } from "react-hook-form";

const FormTextField = ({
  control,
  fieldLabel,
  fieldName,
  fieldPlaceholder,
  errors,
  maxLength
}: {
  fieldName: string;
  fieldLabel: string;
  fieldPlaceholder: string;
  control: Control<any>;
  errors: FieldErrors[];
  maxLength?: number;
}) => {
  return (
    <>
      <FieldLabel>{fieldLabel} </FieldLabel>
      <Controller
        control={control}
        name={fieldName}
        render={({ field: { onChange, onBlur, value } }) => (
          <Field
            placeholder={fieldPlaceholder}
            maxLength={maxLength}
            showCharLimit
            value={value}
            onBlur={onBlur}
            onChangeText={onChange}
            isRequired
            multiline
            verticalOverflow
          />
        )}
      />
      {errors && errors.map((err) => <FieldError message={(err[fieldName as string]?.message as string) || ""} />)}
    </>
  );
};

export default FormTextField;
