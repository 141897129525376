import { coreThemeV2, Icon } from "@kolmeo/ui-components";
import { TouchableOpacity } from "@kolmeo/ui-core";

type ArrowIndicatorProps = {
  onPressHandler: () => void;
  direction: "left" | "right";
};
const ArrowIndicator = ({ onPressHandler, direction }: ArrowIndicatorProps) => {
  const { palette, borderRadius, spacing } = coreThemeV2;

  switch (direction) {
    case "left":
      return (
        <TouchableOpacity
          activeOpacity={0.8}
          width={"36px"}
          height={"36px"}
          marginLeft={spacing.sp16}
          backgroundColor={palette.background.secondary.default}
          justifyContent={"center"}
          borderRadius={borderRadius.br8}
          alignItems={"center"}
          position={"absolute"}
          left={0}
          bottom={"50%"}
          zIndex={10}
          onPress={onPressHandler}
        >
          <Icon type={"caret-left"} size={"micro"} color={palette.text.primaryInvert} />
        </TouchableOpacity>
      );
    case "right":
      return (
        <TouchableOpacity
          activeOpacity={0.8}
          width={"36px"}
          height={"36px"}
          marginRight={spacing.sp16}
          backgroundColor={palette.background.secondary.default}
          justifyContent={"center"}
          borderRadius={borderRadius.br8}
          alignItems={"center"}
          position={"absolute"}
          bottom={"50%"}
          right={0}
          zIndex={10}
          onPress={onPressHandler}
        >
          <Icon type={"caret-right"} size={"micro"} color={palette.text.primaryInvert} />
        </TouchableOpacity>
      );
  }
};

export default ArrowIndicator;
