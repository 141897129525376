import { jsx as _jsx } from "react/jsx-runtime";
import { Children, useState } from "react";
import { useWindowDimensions } from "react-native";
import { Box } from "@kolmeo/ui-core";
const DEVICE_SIZES = {
    mobile: 490,
    tablet: 768,
    desktop: 1024,
};
const GUTTER = 16;
const useResponsive = () => {
    const width = useWindowDimensions().width;
    const r = (array) => {
        if (width > DEVICE_SIZES.tablet) {
            return array[2];
        }
        else if (width > DEVICE_SIZES.mobile) {
            return array[1];
        }
        else {
            return array[0];
        }
    };
    return r;
};
const ResponsiveGrid = ({ children, ...props }) => {
    const r = useResponsive();
    const [containerWidth, setContainerWidth] = useState(0);
    return (_jsx(Box, { onLayout: (event) => {
            const { width } = event.nativeEvent.layout;
            setContainerWidth(width);
        }, flexDirection: r(["column", "row", "row"]), marginTop: "16px", paddingX: r(["16px", `0px`, `0px`]), justifyContent: "flex-start", flexWrap: "wrap", backgroundColor: "transparent", ...props, children: Children.toArray(children).map((item, index) => (_jsx(Box, { width: r(["100%", containerWidth / 2, containerWidth / 3]), marginBottom: 16, paddingX: r(["0px", `${16 / 2}px`, `${16 / 2}px`]), children: item }, index))) }));
};
export default ResponsiveGrid;
