export default {
    path: {
        d: "M0.292893 0.292893C0.683417 -0.0976311 1.31658 -0.0976311 1.70711 0.292893L11 9.58579L20.2929 0.292893C20.6834 -0.0976311 21.3166 -0.0976311 21.7071 0.292893C22.0976 0.683417 22.0976 1.31658 21.7071 1.70711L11.7071 11.7071C11.3166 12.0976 10.6834 12.0976 10.2929 11.7071L0.292893 1.70711C-0.0976311 1.31658 -0.0976311 0.683417 0.292893 0.292893Z",
    },
    properties: {
        viewBox: "0 0 22 12",
        outlined: false,
        strokeWidth: "0",
    },
};
