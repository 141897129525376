import { jsx as _jsx } from "react/jsx-runtime";
import { coreTheme } from "@kolmeo/ui-core";
import { TabBar, TabBarIndicator } from "react-native-tab-view";
import TopTabBarIcon from "./TopTabBarIcon";
import TopTabBarLabel from "./TopTabBarLabel";
const { palette } = coreTheme;
export default function TopTabBar({ state, navigation, descriptors, ...rest }) {
    const focusedOptions = descriptors[state.routes[state.index].key].options;
    const activeColor = focusedOptions.tabBarActiveTintColor ?? palette.textPrimary;
    const inactiveColor = focusedOptions.tabBarInactiveTintColor ?? palette.textDisabled;
    return (_jsx(TabBar, { ...rest, navigationState: state, scrollEnabled: focusedOptions.tabBarScrollEnabled, bounces: focusedOptions.tabBarBounces, activeColor: activeColor, indicatorScrollOffset: focusedOptions.indicatorScrollOffset, inactiveColor: inactiveColor, pressColor: focusedOptions.tabBarPressColor, pressOpacity: focusedOptions.tabBarPressOpacity, tabStyle: focusedOptions.tabBarItemStyle, indicatorStyle: [{ backgroundColor: palette.textBrand }, focusedOptions.tabBarIndicatorStyle], gap: focusedOptions.tabBarGap, indicatorContainerStyle: focusedOptions.tabBarIndicatorContainerStyle, contentContainerStyle: focusedOptions.tabBarContentContainerStyle, style: [{ backgroundColor: palette.backgroundSurface }, focusedOptions.tabBarStyle], getAccessibilityLabel: ({ route }) => descriptors[route.key].options.tabBarAccessibilityLabel, getTestID: ({ route }) => descriptors[route.key].options.tabBarTestID, onTabPress: ({ route, preventDefault }) => {
            const event = navigation.emit({
                type: "tabPress",
                target: route.key,
                canPreventDefault: true,
            });
            if (event.defaultPrevented) {
                preventDefault();
            }
        }, onTabLongPress: ({ route }) => navigation.emit({
            type: "tabLongPress",
            target: route.key,
        }), renderIcon: (props) => _jsx(TopTabBarIcon, { ...props, descriptors: descriptors }), renderLabel: ({ route, focused, color }) => {
            const { options } = descriptors[route.key];
            if (options.tabBarShowLabel === false) {
                return null;
            }
            const label = options.tabBarLabel !== undefined
                ? options.tabBarLabel
                : options.title !== undefined
                    ? options.title
                    : route.name;
            if (typeof label === "string")
                return (_jsx(TopTabBarLabel, { label: label, color: color, tabBarAllowFontScaling: options.tabBarAllowFontScaling, tabBarLabelStyle: options.tabBarLabelStyle }));
            const children = typeof options.tabBarLabel === "string" ? options.tabBarLabel : options.title !== undefined ? options.title : route.name;
            return label({ focused, color, children });
        }, renderBadge: ({ route }) => {
            const { tabBarBadge } = descriptors[route.key].options;
            return tabBarBadge?.() ?? null;
        }, renderIndicator: ({ navigationState: state, ...rest }) => {
            return focusedOptions.tabBarIndicator ? (focusedOptions.tabBarIndicator({
                state: state,
                ...rest,
            })) : (_jsx(TabBarIndicator, { navigationState: state, ...rest }));
        } }));
}
