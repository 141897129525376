export const TransactionCategory = {
  adjustment: 4503001,
  invoice: 4503002,
  commission: 4503003,
  fees: 4503004,
  rent: 4503005,
  rentRefund: 4503006,
  rentArrears: 4503007,
  payments: 4503011,
  rentManagementFee: 4503009,
  invoiceManagementFee: 4503008,
  bulkDisbursement: 4503013
} as const;

export type TransactionCategoryType = keyof typeof TransactionCategory;
export type TransactionCategoryCodes = (typeof TransactionCategory)[keyof typeof TransactionCategory];
