import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useAxios } from "@src/lib/http/useAxios";

export interface UserProfileAcceptDeclineProps {
  UserProfileId: number;
}

export const useOwnerAcceptMutation = () => {
  const { instance } = useAxios("OWNER_API");

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ UserProfileId }: UserProfileAcceptDeclineProps): Promise<boolean | undefined> => {
      try {
        const response = await instance.post("/userprofile/accept", { UserProfileId });
        return response.data;
      } catch (err) {
        console.error(err);
      }
    },
    onSuccess: () => {
      return queryClient.invalidateQueries({
        queryKey: [""]
      });
    }
  });
};

export const useTenantAcceptMutation = () => {
  const { instance } = useAxios("TENANT_API");

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ UserProfileId }: UserProfileAcceptDeclineProps): Promise<boolean | undefined> => {
      try {
        const response = await instance.post("/userprofile/accept", { UserProfileId });
        return response.data;
      } catch (err) {
        console.error(err);
      }
    },
    onSuccess: () => {
      return queryClient.invalidateQueries({
        queryKey: [""]
      });
    }
  });
};

export const useOwnerDeclineMutation = () => {
  const { instance } = useAxios("OWNER_API");

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ UserProfileId }: UserProfileAcceptDeclineProps): Promise<boolean | undefined> => {
      try {
        const response = await instance.post("/userprofile/decline", { UserProfileId });
        return response.data;
      } catch (err) {
        console.error(err);
      }
    },
    onSuccess: () => {
      return queryClient.invalidateQueries({
        queryKey: [""]
      });
    }
  });
};

export const useTenantDeclineMutation = () => {
  const { instance } = useAxios("TENANT_API");

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ UserProfileId }: UserProfileAcceptDeclineProps): Promise<boolean | undefined> => {
      try {
        const response = await instance.post("/userprofile/decline", { UserProfileId });
        return response.data;
      } catch (err) {
        console.error(err);
      }
    },
    onSuccess: () => {
      return queryClient.invalidateQueries({
        queryKey: [""]
      });
    }
  });
};
